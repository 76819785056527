import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allService: [],
  userService: [],
  currencyRates: {},
};

export const spServiceDetailsSlice = createSlice({
  name: "spServiceDetails",
  initialState,
  reducers: {
    setAllServiceDetails: (state, action) => {
      state.allService = action.payload;
    },
    setUserServiceDetails: (state, action) => {
      state.userService = action.payload;
    },
    setCurrencyRates: (state, action) => {
      state.currencyRates = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllServiceDetails, setUserServiceDetails, setCurrencyRates } =
  spServiceDetailsSlice.actions;

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { TeethV2 } from "../Teeth-Comps/TeethV2";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import RangeInputField from "../InputField-Comps/RangeInputField";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";
import { useGetSoftwareCategoryByNameMutation } from "../../services/softwareCategory";

import {
  useDeleteOrderMissingTeethMutation,
  useDeleteOrderServiceDetailsMutation,
  useDeleteOrderServiceMultiFieldMutation,
  useDeleteOrderServiceTeethMutation,
  useSaveOrderMissingTeethMutation,
  useSaveOrderServiceDetailsMutation,
  useSaveOrderServiceMultiFieldMutation,
  useSaveOrderServiceTeethMutation,
} from "../../services/orders";
import { setRemovablesOrderDetails } from "../../reducers/orderFormReducer";

import classes from "../Teeth-Comps/Teeth.module.scss";
import {
  ARCHES_TO_TREAT,
  CROSSBYTE_OPTIONS,
  DEPROGRAMMER_DESIGN,
  MAJOR_CONNECTOR_TYPE,
  OCCLUSION_SETUP,
  OPTIONS_VALUE,
  ORDER_STAGE,
  SERVICE_TEETH_MAPPING,
  SPLINT_DESIGN,
  TEETH_MOULD_SHAPE,
  TEETH_SETUP_STYLE,
  TEETH_SIZE,
} from "../../constants/orderForms";
import { findExtraElement, updateTeethMapping } from "../../Utils/utility";

import occlusalOption1 from "../../Assets/images/removables/occlusalOption1.jpg";
import occlusalOption2 from "../../Assets/images/removables/occlusalOption2.jpg";
import occlusalOption3 from "../../Assets/images/removables/occlusalOption3.jpg";
import mouldShapeOption1 from "../../Assets/images/removables/mouldShapeOption1.jpg";
import mouldShapeOption2 from "../../Assets/images/removables/mouldShapeOption2.jpg";
import mouldShapeOption3 from "../../Assets/images/removables/mouldShapeOption3.jpg";
import setupStyle1 from "../../Assets/images/removables/setupStyle1.jpg";
import setupStyle2 from "../../Assets/images/removables/setupStyle2.jpg";
import setupStyle3 from "../../Assets/images/removables/setupStyle3.jpg";

export const RemovablesCaseDetailsSection = ({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  categoryObjId,
  submitOrderData,
  selectedServiceCategory,
}) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { removablesOrderDetails } = useSelector((state) => state.orderFormDetails);

  const [saveOrderMissingTeeth] = useSaveOrderMissingTeethMutation();
  const [deleteOrderMissingTeeth] = useDeleteOrderMissingTeethMutation();
  const [saveOrderServiceDetails] = useSaveOrderServiceDetailsMutation();
  const [deleteOrderServiceDetails] = useDeleteOrderServiceDetailsMutation();
  const [saveOrderServiceTeeth] = useSaveOrderServiceTeethMutation();
  const [deleteOrderServiceTeeth] = useDeleteOrderServiceTeethMutation();
  const [saveOrderServiceMultiField] = useSaveOrderServiceMultiFieldMutation();
  const [deleteOrderServiceMultiField] = useDeleteOrderServiceMultiFieldMutation();

  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.hasMissingTeeth === null ? "" : orderCaseData?.hasMissingTeeth ? "Yes" : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeethSelectedTeeth || []
  );
  // eslint-disable-next-line no-unused-vars
  const [notation, setNotation] = useState(orderCaseData?.numberSystem || "FDI");
  const [preferredSoftwares, setPreferredSoftwares] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [restorativeTypeSelected, setRestorativeTypeSelected] = useState(
    orderCaseData?.services?.reduce((prev, curr) => {
      return { ...prev, [curr.serviceObjId]: curr };
    }, {}) ?? {}
  );

  const [getSoftwareCategoryByName] = useGetSoftwareCategoryByNameMutation();

  const readSoftwareCategoryInfo = async (accessToken) => {
    const response = await getSoftwareCategoryByName({
      accessToken,
      softwareCategoryName: "CAD",
    }).unwrap();
    if (response?.data) {
      const softwaresList = response?.data;
      if (softwaresList?.length > 0) setPreferredSoftwares(softwaresList[0]?.softwares);
      else setPreferredSoftwares([]);
    }
  };

  const readSoftwareCategoriesInfoDetails = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await readSoftwareCategoryInfo(accessToken);
  };

  useEffect(() => {
    readSoftwareCategoriesInfoDetails();
  }, []);

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
    setMissingTeethSelectedTeeth(updateTeethMapping(missingTeethSelectedTeeth, event.target.value));

    if (restorativeTypeSelected[selectedService?.serviceObjId]?.length > 0) {
      setRestorativeTypeSelected((prev) => ({
        ...prev,
        [selectedService?.serviceObjId]: {
          ...(prev?.[selectedService?.serviceObjId] ?? {}),
          dentureSetupTeeth: updateTeethMapping(
            restorativeTypeSelected[selectedService?.serviceObjId]?.dentureSetupTeeth ?? [],
            event.target.value
          ),
          noClaspDesignTeeth: updateTeethMapping(
            restorativeTypeSelected[selectedService?.serviceObjId]?.noClaspDesignTeeth ?? [],
            event.target.value
          ),
          teethNeedToExtract: updateTeethMapping(
            restorativeTypeSelected[selectedService?.serviceObjId]?.teethNeedToExtract ?? [],
            event.target.value
          ),
          recontouringGuidesTeeth: updateTeethMapping(
            restorativeTypeSelected[selectedService?.serviceObjId]?.recontouringGuidesTeeth ?? [],
            event.target.value
          ),
          splintExtendToTeeth: updateTeethMapping(
            restorativeTypeSelected[selectedService?.serviceObjId]?.splintExtendToTeeth ?? [],
            event.target.value
          ),
          implantSite: updateTeethMapping(
            restorativeTypeSelected[selectedService?.serviceObjId]?.implantSite ?? [],
            event.target.value
          ),
        },
      }));
    }
  };

  console.log("selectedServiceCategory", selectedServiceCategory);

  const deleteService = async (service) => {
    setRestorativeTypeSelected((prev) => {
      const copy = JSON.parse(JSON.stringify(prev));
      delete copy[service?.serviceObjId];
      return copy;
    });

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });

    deleteOrderServiceDetails({
      accessToken,
      _id: orderByData?._id,
      serviceObjId: service?.serviceObjId,
    });
  };

  const prepareServicesElements = useMemo(() => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              restorativeTypeSelected.hasOwnProperty(eachService?.serviceObjId) &&
              Object.keys(restorativeTypeSelected[eachService?.serviceObjId] ?? {})?.length > 0
            }
            handleSelectedIcon={() => deleteService(eachService)}
            id={categoryObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  }, [
    categoryObjId,
    restorativeTypeSelected,
    selectedService?.serviceObjId,
    selectedServiceCategory?.catservices,
  ]);

  const getRestrictedTeeth = (serviceObjId, key) => {
    const restrictedTeethData = [];
    Object.keys(restorativeTypeSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...(restorativeTypeSelected?.[d]?.[key] ?? []));
      });
    return restrictedTeethData;
  };

  const validateNumber = (value) => {
    return Number(value) ? true : false;
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
    if (event.target.value === "No") {
      setMissingTeethSelectedTeeth([]);
    }
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "additionalInstructions",
      "hasMissingTeeth",
      "handlesDesired",
      "dentureThickness",
      "splintThickness",
      "deprogrammerThickness",
      "finalLevelDistance",
      "impressionMaterialHoles",
      "intraoralScansFor",
      "digitalInversionFor",
      "dentureBaseProcessing",
      "teethProcessing",
    ];

    return requiredFields.includes(fieldName);
  };

  const handleServiceMultiFieldSave = async (value, fieldKey, checked) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const serviceDetails = removablesOrderDetails?.caseDetails?.services?.find(
      (d) => d?.serviceObjId === selectedService?.serviceObjId
    );

    console.log("serviceDetails", serviceDetails);

    if (!serviceDetails?.serviceObjId) {
      dispatch(
        setRemovablesOrderDetails({
          ...removablesOrderDetails,
          caseDetails: {
            ...(removablesOrderDetails?.caseDetails ?? {}),
            services: [
              ...(removablesOrderDetails?.caseDetails?.services ?? []),
              { serviceObjId: selectedService?.serviceObjId, [fieldKey]: value },
            ],
          },
        })
      );
      await saveOrderServiceDetails({
        accessToken: accessToken,
        _id: removablesOrderDetails?._id,
        serviceObjId: selectedService?.serviceObjId,
      });
    }

    if (checked) {
      saveOrderServiceMultiField({
        accessToken,
        _id: orderByData?._id,
        serviceObjId: selectedService?.serviceObjId,
        type: fieldKey,
        optionstring: value,
      });
    } else {
      deleteOrderServiceMultiField({
        accessToken,
        _id: orderByData?._id,
        serviceObjId: selectedService?.serviceObjId,
        type: fieldKey,
        optionstring: value,
      });
    }
  };

  const handleFieldChange = (value, name, isMulti = false, checked = false) => {
    setRestorativeTypeSelected((prev) => {
      let actualValue = value;

      if (isMulti) {
        if (checked) {
          actualValue = [...(prev?.[selectedService?.serviceObjId]?.[name] ?? []), value];
        } else {
          actualValue = (prev?.[selectedService?.serviceObjId]?.[name] ?? [])?.filter(
            (item) => item !== value
          );
        }
      }

      return {
        ...prev,
        [selectedService?.serviceObjId]: {
          ...(prev?.[selectedService?.serviceObjId] ?? {}),
          [name]: actualValue,
        },
      };
    });
  };

  const submitCaseDetailsForm = async () => {
    submitOrderData({
      numberSystem: notation,
      hasMissingTeeth: hasMissingTeeth,
      missingTeethSelectedTeeth: missingTeethSelectedTeeth,
      services: Object.keys(restorativeTypeSelected)?.map((key) => ({
        ...restorativeTypeSelected[key],
        serviceObjId: key,
      })),
    });
    setActiveTab(ORDER_STAGE.UPLOAD_FILES);
  };

  const handleMissingTeethSelection = async (value) => {
    console.log("value", value);
    const actualMissingTeeth = structuredClone(missingTeethSelectedTeeth);
    let result = value(actualMissingTeeth || []);
    setMissingTeethSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualMissingTeeth?.length) {
      teethNo = findExtraElement(actualMissingTeeth, result);
      saveOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualMissingTeeth?.length) {
      teethNo = findExtraElement(result, actualMissingTeeth);
      deleteOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  const handleServiceTeethSave = async (value, teethKey) => {
    console.log("value", value);
    const actualTeeth = structuredClone(
      restorativeTypeSelected[selectedService?.serviceObjId]?.[teethKey] ?? []
    );
    console.log("actualImplantSiteTeeth", actualTeeth);

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });

    const serviceDetails = removablesOrderDetails?.caseDetails?.services?.find(
      (d) => d?.serviceObjId === selectedService?.serviceObjId
    );

    console.log("serviceDetails", serviceDetails);

    if (!serviceDetails?.serviceObjId) {
      await saveOrderServiceDetails({
        accessToken: accessToken,
        _id: removablesOrderDetails?._id,
        serviceObjId: selectedService?.serviceObjId,
      });
      dispatch(
        setRemovablesOrderDetails({
          ...removablesOrderDetails,
          caseDetails: {
            ...(removablesOrderDetails?.caseDetails ?? {}),
            services: [
              ...(removablesOrderDetails?.caseDetails?.services ?? []),
              { serviceObjId: selectedService?.serviceObjId, [teethKey]: value },
            ],
          },
        })
      );
    }

    if (value?.length > actualTeeth?.length) {
      const teethNo = findExtraElement(actualTeeth, value);
      saveOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    } else if (value?.length < actualTeeth?.length) {
      const teethNo = findExtraElement(value, actualTeeth);
      deleteOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    }
  };

  return (
    <div>
      <div className="w-10/12">
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            What tooth numbering system do you prefer?
            <span className="text-red-500 ml-1">*</span>
          </p>
          <div className="flex">
            <div className={classes.radioButtons}>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="UTN"
                  checked={notation === "UTN"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                UTN
              </label>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="FDI"
                  checked={notation === "FDI"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                FDI
              </label>
            </div>
          </div>
          <p className="text-[16px] text-[#777] mb-8">
            The Universal tooth numbering (UTN) system (1-32) and Fédération Dentaire Internationale
            (FDI) system (11-48) are both internationally recognised numbering systems used in
            different parts of the world. Please select a preference that will then apply to all the
            tooth charts in this form.
          </p>
        </div>
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth
            {isFieldRequired("hasMissingTeeth") && <span className="text-red-500 ml-1">*</span>}
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          <div className="mt-4">
            {hasMissingTeeth === "Yes" && (
              <>
                <p className="text-white text-[16px] mb-2">Please select missing teeth</p>
                <TeethV2
                  allTeethData={[]}
                  selectedTeeth={missingTeethSelectedTeeth}
                  setSelectedTeeth={handleMissingTeethSelection}
                  defaultNotation={notation}
                  allowBulkTeethSelection={true}
                  key={`missingTeethSelectedTeeth${notation}`}
                  notationVisible={false}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="font-medium text-[24px] text-white mb-4">Removables Service Type</p>
          {selectedServiceCategory?._id && Object.keys(restorativeTypeSelected).length !== "" && (
            <>
              <div className="CAROUSEL mb-8">
                <div className="md:block hidden">
                  <CustomCarousel
                    rows={1}
                    cols={6}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={6}
                  />
                </div>
                <div className="md:hidden block">
                  <CustomCarousel
                    rows={1}
                    cols={2}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={2}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {["Nightguards"].includes(selectedService?.serviceName) && (
          <div className="text-white flex flex-col mb-8">
            <p className="flex mb-2">
              What arch would you like a nightguard on? <span className="text-red-500 ml-1">*</span>
            </p>

            <p className="text-[#777] mb-5 text-[16px]">
              We only provide flat plane nightguards that extend only to the height of contour of
              the teeth. We prefer intraoral scans in an open bite relationship for nightguard
              design to reduce the need for adjustments. Please obtain intraoral scans of both
              arches and a bite scan with a leaf gauge that records the bite in centric. This bite
              scan in an open relationship needs to allow sufficient thickness for the nightguard
              material.
            </p>
            <div className="flex">
              {Object.values(ARCHES_TO_TREAT)?.map((arches) => (
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={arches}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.archNightguardOn ===
                      arches
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "archNightguardOn")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{arches}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {["Custom impression tray", "Custom implant impression tray"].includes(
          selectedService?.serviceName
        ) && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p className="flex mb-2">
                What arch would you like an impression tray designed for?
                <span className="text-red-500 ml-1">*</span>
              </p>

              <div className="flex">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.UPPER}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.impressionTrayFor ===
                      ARCHES_TO_TREAT.UPPER
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "impressionTrayFor")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.UPPER}</p>
                </div>
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.LOWER}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.impressionTrayFor ===
                      ARCHES_TO_TREAT.LOWER
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "impressionTrayFor")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.LOWER}</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.BOTH}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.impressionTrayFor ===
                      ARCHES_TO_TREAT.BOTH
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "impressionTrayFor")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.BOTH}</p>
                </div>
              </div>
            </div>
          </>
        )}

        {["Custom impression tray", "Custom implant impression tray"]?.includes(
          selectedService?.serviceName
        ) && (
          <>
            <p className="text-[16px] text-[#777] my-4">
              If there are any teeth you intend to extract, these extractions need to be digitally
              simulated before impression trays are made. Please advise if you intend to extract ANY
              teeth BEFORE the impression. Mobile teeth are certainly best extracted before an
              impression as well and can be marked for extraction on this chart as well.
            </p>
            <div className="mb-8">
              <p className="text-white text-[17px] mb-2">
                Please select any teeth that need to be extracted
                <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="">
                <TeethV2
                  allTeethData={[]}
                  key={
                    selectedService?.serviceObjId +
                    restorativeTypeSelected[selectedService?.serviceObjId]?.teethNeedToExtract
                      ?.length +
                    notation
                  }
                  selectedTeeth={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.teethNeedToExtract || []
                  }
                  setSelectedTeeth={(value) => {
                    let result = value(
                      restorativeTypeSelected[selectedService?.serviceObjId]?.teethNeedToExtract ||
                        []
                    );
                    handleFieldChange(result, "teethNeedToExtract");
                    handleServiceTeethSave(result, "teethNeedToExtract");
                  }}
                  defaultNotation={notation}
                  notationVisible={false}
                  missingTeeth={missingTeethSelectedTeeth}
                  restrictedTeeth={getRestrictedTeeth(
                    selectedService?.serviceObjId,
                    "teethNeedToExtract"
                  )}
                />
              </div>
            </div>
          </>
        )}
        {["Custom implant impression tray"].includes(selectedService?.serviceName) && (
          <div className="mb-8">
            <p className="text-white text-[16px] mb-2">
              Please select all implant sites <span className="text-red-500 ml-1">*</span>
            </p>
            <TeethV2
              key={
                selectedService?.serviceObjId +
                restorativeTypeSelected[selectedService?.serviceObjId]?.implantSite?.length +
                notation
              }
              allTeethData={[]}
              restrictedTeeth={getRestrictedTeeth(selectedService?.serviceObjId, "implantSite")}
              restrictedTeethMessage={"This tooth is already selected in other restorative type"}
              selectedTeeth={
                restorativeTypeSelected[selectedService?.serviceObjId]?.implantSite || []
              }
              setSelectedTeeth={(value) => {
                let result = value(
                  restorativeTypeSelected[selectedService?.serviceObjId]?.implantSite || []
                );
                handleFieldChange(result, "implantSite");
                handleServiceTeethSave(result, "implantSite");
              }}
              missingTeethSelectable={true}
              defaultNotation={notation}
              notationVisible={false}
              missingTeeth={missingTeethSelectedTeeth}
            />
          </div>
        )}
        {["Custom impression tray", "Custom implant impression tray"].includes(
          selectedService?.serviceName
        ) && (
          <>
            <div className="text-white flex mb-8">
              Would you like a handle?
              <span className="text-red-500 ml-1">*</span>
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Yes"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.needHandle === "Yes"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "needHandle")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.needHandle === "No"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "needHandle")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
            {restorativeTypeSelected[selectedService?.serviceObjId]?.needHandle === "Yes" && (
              <div className="w-1/2 mb-8">
                <RangeInputField
                  labelColor={"#fff"}
                  labelText={"Number of handles desired?"}
                  errorMessage={"Please enter a valid number of handles"}
                  placeholder={""}
                  htmlFor={"handlesDesired"}
                  name={"handlesDesired"}
                  value={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.handlesDesired ?? "0"
                  }
                  handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                  validationFunctionName={validateNumber}
                  isSubmitted={isFieldRequired}
                  isFieldRequired={isFieldRequired("handlesDesired")}
                  min={0}
                  max={3}
                />
              </div>
            )}
            <div className="text-white flex flex-col mb-8">
              <div
                className={`flex h-fit ${
                  "Custom implant impression tray" === selectedService?.serviceName ? "" : "mb-2"
                }`}
              >
                <p className="m-0">
                  Would you like holes in your{" "}
                  {selectedService?.serviceName === "Custom implant impression tray"
                    ? "implant"
                    : ""}{" "}
                  impression tray for impression material adhesion?&nbsp;
                </p>
                <span className="text-red-500 ml-1 h-fit">*</span>
              </div>
              {"Custom implant impression tray" === selectedService?.serviceName && (
                <p className="text-[#777] mb-5 text-[16px]">
                  (Holes will be automatically provided for your implant sites)
                </p>
              )}
              <div className="flex">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.holesInImpressionTray === "Yes"
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "holesInImpressionTray")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.holesInImpressionTray === "No"
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "holesInImpressionTray")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            </div>
            <div className="w-1/2 mb-8">
              <RangeInputField
                labelColor={"#fff"}
                labelText={
                  "How much spacer would you like for your impression material? (µ microns)"
                }
                subtitle="If you are unsure about how much space you require, please leave the setting at 0µ. A default setting will then be applied to the design."
                errorMessage={"Please enter a valid space value"}
                placeholder={""}
                htmlFor={"impressionMaterialHoles"}
                name={"impressionMaterialHoles"}
                value={
                  restorativeTypeSelected[selectedService?.serviceObjId]?.impressionMaterialHoles ??
                  "0"
                }
                handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                validationFunctionName={validateNumber}
                isSubmitted={isFieldRequired}
                isFieldRequired={isFieldRequired("impressionMaterialHoles")}
                unit="µ"
                min={0}
                max={200}
              />
            </div>
          </>
        )}

        {["Splints", "Deprogrammers"].includes(selectedService?.serviceName) && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                What {selectedService?.serviceName === "Splints" ? "splint" : "deprogrammer"} design
                do you prefer?{" "}
                {isFieldRequired(
                  selectedService?.serviceName === "Splints"
                    ? "preferredSplintDesign"
                    : "preferredDeprogrammerDesign"
                ) && <span className="text-red-500 ml-1">*</span>}
              </p>
              <div className="grid grid-cols-3 gap-y-3 mt-1">
                {selectedService?.serviceName === "Splints" ? (
                  <>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value={SPLINT_DESIGN.MICHIGAN_STYLE}
                        checked={
                          restorativeTypeSelected[selectedService?.serviceObjId]
                            ?.preferredSplintDesign === SPLINT_DESIGN.MICHIGAN_STYLE
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "preferredSplintDesign")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{SPLINT_DESIGN.MICHIGAN_STYLE}</p>
                    </div>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value={SPLINT_DESIGN.NTI}
                        checked={
                          restorativeTypeSelected[selectedService?.serviceObjId]
                            ?.preferredSplintDesign === SPLINT_DESIGN.NTI
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "preferredSplintDesign")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{SPLINT_DESIGN.NTI}</p>
                    </div>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value={SPLINT_DESIGN.TSS_STYLE}
                        checked={
                          restorativeTypeSelected[selectedService?.serviceObjId]
                            ?.preferredSplintDesign === SPLINT_DESIGN.TSS_STYLE
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "preferredSplintDesign")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{SPLINT_DESIGN.TSS_STYLE}</p>
                    </div>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value={SPLINT_DESIGN.DAWSON_B_SPLINT}
                        checked={
                          restorativeTypeSelected[selectedService?.serviceObjId]
                            ?.preferredSplintDesign === SPLINT_DESIGN.DAWSON_B_SPLINT
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "preferredSplintDesign")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{SPLINT_DESIGN.DAWSON_B_SPLINT}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value={DEPROGRAMMER_DESIGN.ANTERIOR_STYLE}
                        checked={
                          restorativeTypeSelected[selectedService?.serviceObjId]
                            ?.preferredDeprogrammerDesign === DEPROGRAMMER_DESIGN.ANTERIOR_STYLE
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "preferredDeprogrammerDesign")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{DEPROGRAMMER_DESIGN.ANTERIOR_STYLE}</p>
                    </div>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value={DEPROGRAMMER_DESIGN.KOIS_STYLE}
                        checked={
                          restorativeTypeSelected[selectedService?.serviceObjId]
                            ?.preferredDeprogrammerDesign === DEPROGRAMMER_DESIGN.KOIS_STYLE
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "preferredDeprogrammerDesign")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{DEPROGRAMMER_DESIGN.KOIS_STYLE}</p>
                    </div>
                  </>
                )}
              </div>

              {selectedService?.serviceName === "Splints" ? (
                <p className="text-[#777] mt-3 text-[16px]">
                  We do not provide Gelb style splints as these require a lingual bar and do not
                  lend themselves to a CAD design and are best fabricated by a laboratory. If you
                  require a repositioning splint, please record and provide an appropriate intraoral
                  bite scan for this. Hard splints are not recommended for protection of cosmetic
                  restorations. Please select a flat plane nightguard (different menu) for this and
                  print in a soft material. Hard splints are only recommended for treatment of
                  bruxism, relief of pain or deprogramming.
                </p>
              ) : (
                <p className="text-[#777] mt-3 text-[16px]">
                  Deprogrammers are only meant to be used mainly for deprogramming an occlusion. Our
                  deprogrammers are specifically designed for this purpose. Our Kois style
                  deprogrammers do not include a labial bow.
                </p>
              )}
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                What arch would you like a{" "}
                {selectedService?.serviceName === "Splints" ? "splint" : "deprogrammer"} on?
                {isFieldRequired(
                  selectedService?.serviceName === "Splints" ? "archSplintOn" : "archDeprorammerOn"
                ) && <span className="text-red-500 ml-1">*</span>}
              </p>
              <div className="grid grid-cols-7 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.UPPER}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.[
                        selectedService?.serviceName === "Splints"
                          ? "archSplintOn"
                          : "archDeprorammerOn"
                      ] === ARCHES_TO_TREAT.UPPER
                    }
                    onChange={(e) =>
                      handleFieldChange(
                        e.target.value,
                        selectedService?.serviceName === "Splints"
                          ? "archSplintOn"
                          : "archDeprorammerOn"
                      )
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.UPPER}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.LOWER}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.[
                        selectedService?.serviceName === "Splints"
                          ? "archSplintOn"
                          : "archDeprorammerOn"
                      ] === ARCHES_TO_TREAT.LOWER
                    }
                    onChange={(e) =>
                      handleFieldChange(
                        e.target.value,
                        selectedService?.serviceName === "Splints"
                          ? "archSplintOn"
                          : "archDeprorammerOn"
                      )
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.LOWER}</p>
                </div>
                {selectedService?.serviceName === "Splints" && (
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value={ARCHES_TO_TREAT.BOTH}
                      checked={
                        restorativeTypeSelected[selectedService?.serviceObjId]?.[
                          selectedService?.serviceName === "Splints"
                            ? "archSplintOn"
                            : "archDeprorammerOn"
                        ] === ARCHES_TO_TREAT.BOTH
                      }
                      onChange={(e) =>
                        handleFieldChange(
                          e.target.value,
                          selectedService?.serviceName === "Splints"
                            ? "archSplintOn"
                            : "archDeprorammerOn"
                        )
                      }
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">{ARCHES_TO_TREAT.BOTH}</p>
                  </div>
                )}
              </div>
              {selectedService?.serviceName === "Splints" ? (
                <p className="text-[#777] mb-5 text-[16px]  my-2">
                  We only provide specific splint designs that extend only to the height of contour
                  of the teeth. We prefer intraoral scans in an open bite relationship for splint
                  design to reduce the need for adjustments. Please obtain intraoral scans of both
                  arches and a bite scan with a leaf gauge that records the bite in centric, in an
                  open relationship that allows sufficient thickness for the splint material.
                  Splints for both arches require a lot of space and are only used with the Dawson B
                  splint.
                </p>
              ) : (
                <p className="text-[#777] mb-5 text-[16px]  my-2">
                  We only provide specific deprogrammer designs. We prefer intraoral scans in an
                  open bite relationship for deprogrammer design to reduce the need for adjustments.
                  Please obtain intraoral scans of both arches and a bite scan with a leaf gauge
                  that records the bite in centric. This open relationship intraoral bite scan
                  should allow the correct thickness for the deprogrammer height desired.
                </p>
              )}
            </div>
            <div className="mb-8">
              <p className="text-white text-[17px] mb-2">
                Please select all teeth that you would like your{" "}
                {selectedService?.serviceName === "Splints" ? "splint" : "deprogrammer"} to extend
                to? <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="">
                <TeethV2
                  allTeethData={[]}
                  selectedTeeth={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.[
                      selectedService?.serviceName === "Splints"
                        ? "splintExtendToTeeth"
                        : "deprogrammerTeeth"
                    ] ?? []
                  }
                  setSelectedTeeth={(value) => {
                    let result = value(
                      restorativeTypeSelected[selectedService?.serviceObjId]?.[
                        selectedService?.serviceName === "Splints"
                          ? "splintExtendToTeeth"
                          : "deprogrammerTeeth"
                      ] || []
                    );
                    handleFieldChange(
                      result,
                      selectedService?.serviceName === "Splints"
                        ? "splintExtendToTeeth"
                        : "deprogrammerTeeth"
                    );
                    handleServiceTeethSave(
                      result,
                      selectedService?.serviceName === "Splints"
                        ? "splintExtendToTeeth"
                        : "deprogrammerTeeth"
                    );
                  }}
                  defaultNotation={notation}
                  key={
                    selectedService?.serviceObjId +
                    restorativeTypeSelected[selectedService?.serviceObjId]?.[
                      selectedService?.serviceName === "Splints"
                        ? "splintExtendToTeeth"
                        : "deprogrammerTeeth"
                    ]?.length +
                    notation
                  }
                  notationVisible={false}
                  missingTeeth={missingTeethSelectedTeeth}
                />
              </div>
            </div>
            <RangeInputField
              rootClassName="mb-8"
              labelColor={"#fff"}
              labelText={`How much thickness would you like for your ${
                selectedService?.serviceName === "Splints (each arch if applicable)"
                  ? "splint"
                  : "deprogrammer"
              }?`}
              subtitle="If you are unsure about how much thickness you require, please leave the setting at 0 mm. A default setting of 2 mm will then be applied to the design."
              errorMessage={"Please enter a valid thickness value"}
              placeholder={""}
              htmlFor={
                selectedService?.serviceName === "Splints (each arch if applicable)"
                  ? "splintThickness"
                  : "deprogrammerThickness"
              }
              name={
                selectedService?.serviceName === "Splints (each arch if applicable)"
                  ? "splintThickness"
                  : "deprogrammerThickness"
              }
              value={
                restorativeTypeSelected[selectedService?.serviceObjId]?.[
                  selectedService?.serviceName === "Splints (each arch if applicable)"
                    ? "splintThickness"
                    : "deprogrammerThickness"
                ] ?? "0"
              }
              handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
              validationFunctionName={validateNumber}
              isSubmitted={isFieldRequired}
              isFieldRequired={isFieldRequired(
                selectedService?.serviceName === "Splints (each arch if applicable)"
                  ? "splintThickness"
                  : "deprogrammerThickness"
              )}
              unit="mm"
              min={0}
              max={4}
            />
            <div className="text-white flex flex-col mb-8">
              <p>
                Will you be printing or milling your{" "}
                {selectedService?.serviceName === "Splints" ? "splint" : "deprogrammer"}?
                {isFieldRequired(
                  selectedService?.serviceName === "Splints"
                    ? "splintProcessing"
                    : "dentureBaseProcessing"
                ) && <span className="text-red-500 ml-1">*</span>}
              </p>
              <p className="text-[#777] mb-4 text-[16px]">
                Deprogrammers can be milled with excellent results. If you are printing your
                deprogrammer, please use a hard splint material.
              </p>
              <div className="flex gap-3">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={OPTIONS_VALUE.PRINTING}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.[
                        selectedService?.serviceName === "Splints"
                          ? "splintProcessing"
                          : "dentureBaseProcessing"
                      ] === OPTIONS_VALUE.PRINTING
                    }
                    onChange={(e) =>
                      handleFieldChange(
                        e.target.value,
                        selectedService?.serviceName === "Splints"
                          ? "splintProcessing"
                          : "dentureBaseProcessing"
                      )
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{OPTIONS_VALUE.PRINTING}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={OPTIONS_VALUE.MILLING}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.[
                        selectedService?.serviceName === "Splints"
                          ? "splintProcessing"
                          : "dentureBaseProcessing"
                      ] === OPTIONS_VALUE.MILLING
                    }
                    onChange={(e) =>
                      handleFieldChange(
                        e.target.value,
                        selectedService?.serviceName === "Splints"
                          ? "splintProcessing"
                          : "dentureBaseProcessing"
                      )
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{OPTIONS_VALUE.MILLING}</p>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedService?.serviceName === "Gingival recontouring guide" && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                What arch would you like a soft tissue recontouring reduction guide for?
                <span className="text-red-500 ml-1">*</span>
              </p>
              <p className="text-[#777] text-[16px] my-2">
                It is highly recommended that you provide at least photographs with an outline of
                what level of recontouring you require. A face scan is an even better tool to
                provide as we can design in accordance with the patient's lip line.
              </p>
              <div className="grid grid-cols-7 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.UPPER}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.softTissueReductionGuideFor === ARCHES_TO_TREAT.UPPER
                    }
                    onChange={(e) =>
                      handleFieldChange(e.target.value, "softTissueReductionGuideFor")
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.UPPER}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.LOWER}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.softTissueReductionGuideFor === ARCHES_TO_TREAT.LOWER
                    }
                    onChange={(e) =>
                      handleFieldChange(e.target.value, "softTissueReductionGuideFor")
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.LOWER}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.BOTH}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.softTissueReductionGuideFor === ARCHES_TO_TREAT.BOTH
                    }
                    onChange={(e) =>
                      handleFieldChange(e.target.value, "softTissueReductionGuideFor")
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.BOTH}</p>
                </div>
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                If you would also like a hard tissue recontouring reduction guide, what arch would
                you like the guide on? <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="grid grid-cols-7 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.UPPER}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.hardTissueReductionGuideFor === ARCHES_TO_TREAT.UPPER
                    }
                    onChange={(e) =>
                      handleFieldChange(e.target.value, "hardTissueReductionGuideFor")
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.UPPER}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.LOWER}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.hardTissueReductionGuideFor === ARCHES_TO_TREAT.LOWER
                    }
                    onChange={(e) =>
                      handleFieldChange(e.target.value, "hardTissueReductionGuideFor")
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.LOWER}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.BOTH}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.hardTissueReductionGuideFor === ARCHES_TO_TREAT.BOTH
                    }
                    onChange={(e) =>
                      handleFieldChange(e.target.value, "hardTissueReductionGuideFor")
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.BOTH}</p>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <p className="text-white text-[17px] mb-2">
                Please select all teeth that you would like your recontouring guides to extend to?
                <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="">
                <TeethV2
                  allTeethData={[]}
                  selectedTeeth={
                    restorativeTypeSelected[selectedService?.serviceObjId]
                      ?.recontouringGuidesTeeth ?? []
                  }
                  setSelectedTeeth={(value) => {
                    let result = value(
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.recontouringGuidesTeeth || []
                    );
                    handleFieldChange(result, "recontouringGuidesTeeth");
                    handleServiceTeethSave(result, "recontouringGuidesTeeth");
                  }}
                  defaultNotation={notation}
                  key={
                    selectedService?.serviceObjId +
                    restorativeTypeSelected[selectedService?.serviceObjId]?.recontouringGuidesTeeth
                      ?.length +
                    notation
                  }
                  notationVisible={false}
                  missingTeeth={missingTeethSelectedTeeth}
                />
              </div>
            </div>
            <RangeInputField
              rootClassName="mb-8"
              labelColor={"#fff"}
              labelText={
                "If selecting a hard tissue recontouring guide as well, how much distance would you like between the final level of soft tissue and the final level of the hard tissue?"
              }
              errorMessage={"Please enter a valid thickness value"}
              placeholder={""}
              htmlFor={"finalLevelDistance"}
              name={"finalLevelDistance"}
              value={
                restorativeTypeSelected[selectedService?.serviceObjId]?.finalLevelDistance ?? "0"
              }
              handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
              validationFunctionName={validateNumber}
              isSubmitted={isFieldRequired}
              isFieldRequired={isFieldRequired("finalLevelDistance")}
              unit="mm"
              min={0}
              max={3}
            />
            <div className="text-white flex flex-col mb-8">
              <p>
                Will you be printing or milling your recontouring reduction guide?
                {isFieldRequired("dentureBaseProcessing") && (
                  <span className="text-red-500 ml-1">*</span>
                )}
              </p>
              <p className="text-[#777] mb-4 text-[16px]">
                Recontouring reduction guides are best printed. They do not lend themselves to easy
                milling.Our recontouring reduction guides are designed to be as close to tissue as
                possible to minimise surgical error. Path of insertion constraints may however
                result in a distance between the guide and tissue (soft and hard).
              </p>
              <div className="flex gap-3">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={OPTIONS_VALUE.PRINTING}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.recontouringGuideProcessing === OPTIONS_VALUE.PRINTING
                    }
                    onChange={(e) =>
                      handleFieldChange(e.target.value, "recontouringGuideProcessing")
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{OPTIONS_VALUE.PRINTING}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={OPTIONS_VALUE.MILLING}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.recontouringGuideProcessing === OPTIONS_VALUE.MILLING
                    }
                    onChange={(e) =>
                      handleFieldChange(e.target.value, "recontouringGuideProcessing")
                    }
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{OPTIONS_VALUE.MILLING}</p>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedService?.serviceName === "Upper and/or lower dentures (resin only) " && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                Are upper and/or lower intraoral scans being provided?
                {isFieldRequired("intraoralScansFor") && (
                  <span className="text-red-500 ml-1">*</span>
                )}
              </p>
              <div className="grid grid-cols-7 gap-y-3 mt-1">
                {Object.values(ARCHES_TO_TREAT)?.map((arches) => (
                  <div className="flex items-center mr-3">
                    <CircularCheckbox
                      value={arches}
                      checked={
                        restorativeTypeSelected[selectedService?.serviceObjId]
                          ?.intraoralScansFor === arches
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "intraoralScansFor")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">{arches}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                Are upper and/or lower impressions being provided for digital inversion?
                {isFieldRequired("digitalInversionFor") && (
                  <span className="text-red-500 ml-1">*</span>
                )}
              </p>
              <div className="grid grid-cols-7 gap-y-3 mt-1">
                {Object.values(ARCHES_TO_TREAT)?.map((arches) => (
                  <div className="flex items-center mr-3">
                    <CircularCheckbox
                      value={arches}
                      checked={
                        restorativeTypeSelected[selectedService?.serviceObjId]
                          ?.digitalInversionFor === arches
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "digitalInversionFor")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">{arches}</p>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {[
          "Upper and/or lower dentures (resin only) ",
          "Upper or Lower Partial dentures (resin)",
          "Partial dentures (metal)",
        ]?.includes(selectedService?.serviceName) && (
          <div className="text-white flex flex-col mb-8">
            <p>
              Is a bite registration base desired? <span className="text-red-500 ml-1">*</span>
            </p>
            <div className="flex py-3">
              <div className="flex items-center mr-3">
                <CircularCheckbox
                  value="Yes"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]
                      ?.biteRegistrationDesired === "Yes"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "biteRegistrationDesired")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]
                      ?.biteRegistrationDesired === "No"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "biteRegistrationDesired")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>

            <p className="text-[#777] mb-5 text-[16px]">
              Our bite registration bases are designed with special alignment markers for a digital
              workflow. Please leave these markers exposed and uncontaminated with wax when you
              print the bases and fabricate occlusal rims on them. Once a bite registration has been
              obtained, please scan only the buccal of upper and/or lower bases OR upper or lower
              antagonist teeth. The scans of the bases must clearly show these alignment markers. If
              a wash impression is obtained in either or both bases, please provide 360 degree scans
              of all bases that have been used for wash impressions. All occlusal rims will clearly
              have to indicate the{" "}
              <strong className="!text-white">
                height of the teeth desired, occlusal plane, labial fullness, canine lines, midline
                and interarch relationship.
              </strong>
            </p>
          </div>
        )}
        {["Partial dentures (metal)"]?.includes(selectedService?.serviceName) && (
          <div className="text-white flex flex-col mb-8">
            <p>
              Will you be metal printing or casting or milling your denture base?
              <span className="text-red-500 ml-1">*</span>
            </p>
            <p className="text-[#777] mb-5 text-[16px]">
              Metal printing of our designs is possible on metal 3D printers. Casting uses casting
              print resin that is then cast into metal. Milling is only available as an option in
              technopolymers.
            </p>
            <div className="flex gap-3 mt-1">
              <div className="flex items-center mr-3">
                <CircularCheckbox
                  value={OPTIONS_VALUE.PRINTING}
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]
                      ?.dentureBaseProcessing === OPTIONS_VALUE.PRINTING
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "dentureBaseProcessing")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">{OPTIONS_VALUE.PRINTING}</p>
              </div>
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value={OPTIONS_VALUE.CASTING}
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]
                      ?.dentureBaseProcessing === OPTIONS_VALUE.CASTING
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "dentureBaseProcessing")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">{OPTIONS_VALUE.CASTING}</p>
              </div>
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value={OPTIONS_VALUE.MILLING}
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]
                      ?.dentureBaseProcessing === OPTIONS_VALUE.MILLING
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "dentureBaseProcessing")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">{OPTIONS_VALUE.MILLING}</p>
              </div>
            </div>
          </div>
        )}

        {[
          "Upper and/or lower dentures (resin only) ",
          "Upper or Lower Partial dentures (resin)",
        ]?.includes(selectedService?.serviceName) && (
          <>
            <div className="text-white flex flex-col mb-8">
              <p>
                Will you be printing or milling your denture base?
                {isFieldRequired("dentureBaseProcessing") && (
                  <span className="text-red-500 ml-1">*</span>
                )}
              </p>
              <div className="flex gap-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={OPTIONS_VALUE.PRINTING}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.dentureBaseProcessing === OPTIONS_VALUE.PRINTING
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "dentureBaseProcessing")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{OPTIONS_VALUE.PRINTING}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={OPTIONS_VALUE.MILLING}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.dentureBaseProcessing === OPTIONS_VALUE.MILLING
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "dentureBaseProcessing")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{OPTIONS_VALUE.MILLING}</p>
                </div>
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                Will you be printing or milling your teeth?
                {isFieldRequired("teethProcessing") && <span className="text-red-500 ml-1">*</span>}
              </p>
              <div className="flex gap-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={OPTIONS_VALUE.PRINTING}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.teethProcessing ===
                      OPTIONS_VALUE.PRINTING
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "teethProcessing")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{OPTIONS_VALUE.PRINTING}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={OPTIONS_VALUE.MILLING}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.teethProcessing ===
                      OPTIONS_VALUE.MILLING
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "teethProcessing")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{OPTIONS_VALUE.MILLING}</p>
                </div>
              </div>
            </div>
            <div className="text-white flex mb-8">
              Is a monobloc print desired for a printed try in?
              <span className="text-red-500 ml-1">*</span>
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Yes"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.monoblocPrintDesired ===
                    "Yes"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "monoblocPrintDesired")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.monoblocPrintDesired ===
                    "No"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "monoblocPrintDesired")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
          </>
        )}
        {[
          "Upper and/or lower dentures (resin only) ",
          "Upper or Lower Partial dentures (resin)",
          "Partial dentures (metal)",
        ]?.includes(selectedService?.serviceName) && (
          <div className="mb-8">
            <p className="text-white text-[16px] mb-2">
              Please indicate all teeth you would like to see in your{" "}
              {selectedService?.serviceName === "Upper or Lower Partial dentures (resin)"
                ? "partial "
                : ""}
              denture set up? <span className="text-red-500 ml-1">*</span>
            </p>
            <TeethV2
              allTeethData={[]}
              selectedTeeth={
                restorativeTypeSelected[selectedService?.serviceObjId]?.[
                  selectedService?.serviceName === "Upper or Lower Partial dentures (resin)"
                    ? "partialDentureSetupTeeth"
                    : "dentureSetupTeeth"
                ] ?? []
              }
              setSelectedTeeth={(value) => {
                let result = value(
                  restorativeTypeSelected[selectedService?.serviceObjId]?.[
                    selectedService?.serviceName === "Upper or Lower Partial dentures (resin)"
                      ? "partialDentureSetupTeeth"
                      : "dentureSetupTeeth"
                  ] ?? []
                );
                handleFieldChange(
                  result,
                  selectedService?.serviceName === "Upper or Lower Partial dentures (resin)"
                    ? "partialDentureSetupTeeth"
                    : "dentureSetupTeeth"
                );
                handleServiceTeethSave(
                  result,
                  selectedService?.serviceName === "Upper or Lower Partial dentures (resin)"
                    ? "partialDentureSetupTeeth"
                    : "dentureSetupTeeth"
                );
              }}
              defaultNotation={notation}
              missingTeethSelectable={true}
              key={
                selectedService?.serviceObjId +
                restorativeTypeSelected[selectedService?.serviceObjId]?.[
                  selectedService?.serviceName === "Upper or Lower Partial dentures (resin)"
                    ? "partialDentureSetupTeeth"
                    : "dentureSetupTeeth"
                ]?.length +
                notation
              }
              notationVisible={false}
              showHoveredIconOnSelect={true}
              missingTeeth={[
                ...missingTeethSelectedTeeth,
                ...(restorativeTypeSelected[selectedService?.serviceObjId]?.teethNeedToExtract ??
                  []),
              ]}
            />
          </div>
        )}
        {["Upper or Lower Partial dentures (resin)", "Partial dentures (metal)"]?.includes(
          selectedService?.serviceName
        ) && (
          <div className="mb-8">
            <p className="text-white text-[16px] mb-2">
              Please indicate teeth that you DO NOT want to clasp in your design?
              <span className="text-red-500 ml-1">*</span>
            </p>
            <TeethV2
              allTeethData={[]}
              selectedTeeth={
                restorativeTypeSelected[selectedService?.serviceObjId]?.noClaspDesignTeeth ?? []
              }
              setSelectedTeeth={(value) => {
                let result = value(
                  restorativeTypeSelected[selectedService?.serviceObjId]?.noClaspDesignTeeth || []
                );
                handleFieldChange(result, "noClaspDesignTeeth");
                handleServiceTeethSave(result, "noClaspDesignTeeth");
              }}
              defaultNotation={notation}
              key={
                selectedService?.serviceObjId +
                restorativeTypeSelected[selectedService?.serviceObjId]?.noClaspDesignTeeth?.length +
                notation
              }
              notationVisible={false}
              missingTeeth={[
                ...missingTeethSelectedTeeth,
                ...(restorativeTypeSelected[selectedService?.serviceObjId]?.teethNeedToExtract ??
                  []),
              ]}
            />
          </div>
        )}
        {["Partial dentures (metal)"]?.includes(selectedService?.serviceName) && (
          <div className="text-white flex flex-col mb-8">
            <p>
              Please indicate the type of major connector you prefer for your design?{" "}
              <small>(You will be advised if you preference is not possible)</small>
              <span className="text-red-500 ml-1">*</span>
            </p>
            <div className="grid grid-cols-5 gap-y-3 mt-1">
              {Object.values(MAJOR_CONNECTOR_TYPE)?.map((connector) => (
                <div className="flex items-center mx-3" key={"majorConnector-" + connector}>
                  <CircularCheckbox
                    value={connector}
                    checked={(
                      restorativeTypeSelected[selectedService?.serviceObjId]?.majorConnector ?? []
                    )?.includes(connector)}
                    onChange={(e) => {
                      handleFieldChange(
                        e.target.value,
                        "majorConnector",
                        true,
                        !(
                          restorativeTypeSelected[selectedService?.serviceObjId]?.majorConnector ??
                          []
                        )?.includes(connector)
                      );
                      handleServiceMultiFieldSave(
                        e.target.value,
                        "majorConnector",
                        !(
                          restorativeTypeSelected[selectedService?.serviceObjId]?.majorConnector ??
                          []
                        )?.includes(connector)
                      );
                    }}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{connector}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {[
          "Upper and/or lower dentures (resin only) ",
          "Upper or Lower Partial dentures (resin)",
          "Partial dentures (metal)",
          "Nightguards for restorative protection",
        ]?.includes(selectedService?.serviceName) && (
          <div className="w-7/12">
            <RangeInputField
              labelColor={"#fff"}
              labelText={`How much thickness would you like for your ${
                selectedService?.serviceName === "Nightguards"
                  ? "nightguard"
                  : selectedService?.serviceName === "Partial dentures (metal)"
                  ? " partial framework"
                  : selectedService?.serviceName === "Upper or Lower Partial dentures (resin)"
                  ? "partial denture"
                  : "denture"
              } (s)? (mm)`}
              subtitle="If you are unsure about how much thickness you require, please leave the setting at 0 mm. A default setting will then be applied to the design"
              errorMessage={"Please enter a valid thickness value"}
              placeholder={""}
              htmlFor={"dentureThickness"}
              name={"dentureThickness"}
              value={
                restorativeTypeSelected[selectedService?.serviceObjId]?.dentureThickness ?? "0"
              }
              handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
              validationFunctionName={validateNumber}
              isSubmitted={isFieldRequired}
              isFieldRequired={isFieldRequired("dentureThickness")}
              unit="mm"
              min={0}
              max={3}
            />
          </div>
        )}

        {["Nightguards"]?.includes(selectedService?.serviceName) && (
          <div className="text-white flex flex-col mb-8 mt-4">
            <p>
              Will you be printing or milling your nightguard?
              <span className="text-red-500 ml-1">*</span>
            </p>
            <div className="flex gap-3 mt-1">
              <div className="flex items-center mr-3">
                <CircularCheckbox
                  value="Yes"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.nightguardProcessing ===
                    "Yes"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "nightguardProcessing")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="No"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.nightguardProcessing ===
                    "No"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "nightguardProcessing")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
          </div>
        )}

        {selectedService?.serviceName === "Upper and/or lower dentures (resin only) " && (
          <div className="text-white flex flex-col mb-8 mt-4">
            <p>
              Is a copy denture workflow desired? <span className="text-red-500 ml-1">*</span>
            </p>
            <p className="text-[#777] mb-0 text-[16px]">
              Please provide a full 360 degree intraoral scanner scan of the upper OR lower denture
              to be copied. If upper AND lower dentures are to be copied, a bite registration scan
              will also be necessary. Impressions are also possible in a copy denture workflow and
              the denture may be used as an impression tray. The same 360 degree intraoral scanner
              scan protocols apply even when in denture impressions are obtained.
            </p>
            <div className="flex py-3">
              <div className="flex items-center mr-3">
                <CircularCheckbox
                  value="Yes"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.copyDentureDesired ===
                    "Yes"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "copyDentureDesired")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.copyDentureDesired ===
                    "No"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "copyDentureDesired")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
          </div>
        )}
        {[
          "Upper and/or lower dentures (resin only) ",
          "Upper or Lower Partial dentures (resin)",
          "Partial dentures (metal)",
        ].includes(selectedService?.serviceName) && (
          <>
            <div className="text-white flex flex-col mb-8 mt-4">
              <p>
                What occlusion set up would you like? <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="grid grid-cols-5 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={OCCLUSION_SETUP.CLASS1}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.occlusionSetup ===
                      OCCLUSION_SETUP.CLASS1
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "occlusionSetup")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={occlusalOption1} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      {OCCLUSION_SETUP.CLASS1}
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={OCCLUSION_SETUP.CLASS2}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.occlusionSetup ===
                      OCCLUSION_SETUP.CLASS2
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "occlusionSetup")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={occlusalOption2} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      {OCCLUSION_SETUP.CLASS2}
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={OCCLUSION_SETUP.CLASS3}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.occlusionSetup ===
                      OCCLUSION_SETUP.CLASS3
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "occlusionSetup")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={occlusalOption3} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      {OCCLUSION_SETUP.CLASS3}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                Tooth mould shape <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="grid grid-cols-5 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={TEETH_MOULD_SHAPE.UNIVERSAL}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.toothMouldShape ===
                      TEETH_MOULD_SHAPE.UNIVERSAL
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "toothMouldShape")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={mouldShapeOption1} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      {TEETH_MOULD_SHAPE.UNIVERSAL}
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={TEETH_MOULD_SHAPE.SQUARE}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.toothMouldShape ===
                      TEETH_MOULD_SHAPE.SQUARE
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "toothMouldShape")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={mouldShapeOption2} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      {TEETH_MOULD_SHAPE.SQUARE}
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={TEETH_MOULD_SHAPE.OVAL}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.toothMouldShape ===
                      TEETH_MOULD_SHAPE.OVAL
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "toothMouldShape")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={mouldShapeOption3} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      {TEETH_MOULD_SHAPE.OVAL}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                Teeth setup style <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="grid grid-cols-5 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={TEETH_SETUP_STYLE.STANDARD}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.teethSetupStyle ===
                      TEETH_SETUP_STYLE.STANDARD
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "teethSetupStyle")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={setupStyle1} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      {TEETH_SETUP_STYLE.STANDARD}
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={TEETH_SETUP_STYLE.MASCULINE}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.teethSetupStyle ===
                      TEETH_SETUP_STYLE.MASCULINE
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "teethSetupStyle")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={setupStyle2} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      {TEETH_SETUP_STYLE.MASCULINE}
                    </p>
                  </div>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={TEETH_SETUP_STYLE.FEMININE}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.teethSetupStyle ===
                      TEETH_SETUP_STYLE.FEMININE
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "teethSetupStyle")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <div className="text-white text-[16px] flex flex-col">
                    <img src={setupStyle3} alt="" className="rounded-xl relative" />
                    <p className="leading-[12px] text-sm mt-2 mx-auto text-center text-white">
                      {TEETH_SETUP_STYLE.FEMININE}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-white flex flex-col mb-8">
              <p>
                Teeth Size <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="grid grid-cols-6 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={TEETH_SIZE.SMALL}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.teethSize ===
                      TEETH_SIZE.SMALL
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "teethSize")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{TEETH_SIZE.SMALL}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={TEETH_SIZE.MEDIUM}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.teethSize ===
                      TEETH_SIZE.MEDIUM
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "teethSize")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{TEETH_SIZE.MEDIUM}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={TEETH_SIZE.LARGE}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.teethSize ===
                      TEETH_SIZE.LARGE
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "teethSize")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{TEETH_SIZE.LARGE}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={TEETH_SIZE.EXTRA_LARGE}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.teethSize ===
                      TEETH_SIZE.EXTRA_LARGE
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "teethSize")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{TEETH_SIZE.EXTRA_LARGE}</p>
                </div>
              </div>
            </div>
            <div className="text-white flex flex-col mb-8">
              <p>
                Is a crossbite to be created? <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="grid grid-cols-6 gap-y-3 mt-1">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value={CROSSBYTE_OPTIONS.ANTERIOR}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.crossbiteCreated ===
                      CROSSBYTE_OPTIONS.ANTERIOR
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "crossbiteCreated")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{CROSSBYTE_OPTIONS.ANTERIOR}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={CROSSBYTE_OPTIONS.POSTERIOR_RIGHT}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.crossbiteCreated ===
                      CROSSBYTE_OPTIONS.POSTERIOR_RIGHT
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "crossbiteCreated")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{CROSSBYTE_OPTIONS.POSTERIOR_RIGHT}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={CROSSBYTE_OPTIONS.POSTERIOR_LEFT}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.crossbiteCreated ===
                      CROSSBYTE_OPTIONS.POSTERIOR_LEFT
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "crossbiteCreated")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{CROSSBYTE_OPTIONS.POSTERIOR_LEFT}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={CROSSBYTE_OPTIONS.BOTH_SIDES}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.crossbiteCreated ===
                      CROSSBYTE_OPTIONS.BOTH_SIDES
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "crossbiteCreated")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{CROSSBYTE_OPTIONS.BOTH_SIDES}</p>
                </div>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value={CROSSBYTE_OPTIONS.NONE}
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.crossbiteCreated ===
                      CROSSBYTE_OPTIONS.NONE
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "crossbiteCreated")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{CROSSBYTE_OPTIONS.NONE}</p>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedService?.serviceName === "Upper and/or lower dentures (resin only) " && (
          <div className="text-white flex flex-col mb-8">
            <p>
              Are you creating dentures for a photogrammetric scan for vertical dimension and bite
              relationship for implants? <span className="text-red-500 ml-1">*</span>
            </p>
            <p className="text-[#777] mb-0 text-[16px]">
              If yes, please provide intraoral scans with either healing abutments/caps or tissue
              scan bodies or photogrammetric scanbodies depending on the workflow you prefer.
              Dentures made for this purpose are usually useful as indicators of bite or vertical
              dimension and also as a backup conversion prosthesis.
            </p>
            <div className="flex py-3">
              <div className="flex items-center mr-3">
                <CircularCheckbox
                  value="Yes"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.dentureForPhotoScan ===
                    "Yes"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "dentureForPhotoScan")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.dentureForPhotoScan ===
                    "No"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "dentureForPhotoScan")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
          </div>
        )}
        {selectedService?.serviceObjId && preferredSoftwares?.length > 0 && (
          <div className="text-white flex flex-col mb-8">
            <p>
              Preferred design software?
              {isFieldRequired("preferredSoftware") && <span className="text-red-500 ml-1">*</span>}
            </p>
            <div className="grid grid-cols-5 gap-y-3 mt-1">
              {preferredSoftwares
                ?.filter(
                  (d) =>
                    !["Dentbird", "Blender for Dental", "Clinux", "Hypsocad"].includes(
                      d?.softwareName
                    )
                )
                ?.map((item) => {
                  return (
                    <div className="flex items-center mr-3" key={item?.softwareObjId}>
                      <CircularCheckbox
                        value={item?.softwareName}
                        checked={
                          restorativeTypeSelected[selectedService?.serviceObjId]
                            ?.preferredSoftware === item?.softwareName
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{item?.softwareName}</p>
                    </div>
                  );
                })}
              <div className="flex items-center mr-3 col-span-1">
                <CircularCheckbox
                  value="No Preferance"
                  checked={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.preferredSoftware ===
                    "No Preferance"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No Preference</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab(ORDER_STAGE.PATIENT_DETAILS);
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitCaseDetailsForm();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
};

import moment from "moment";
import React from "react";
import { FaFile } from "react-icons/fa6";

const OrderNotesHistory = ({ notes }) => {
  return (
    <div className="bg-backgroundV2 text-white p-4 rounded-md flex flex-col gap-3">
      <p className="text-[16px] leading-4 text-white mb-2">Order Deliverables History</p>
      <div className="w-full flex flex-col gap-3">
        {notes?.map((item, index) => (
          <div
            key={`note-item${index}`}
            className="bg-backgroundV3 px-4 py-3 rounded-md w-full flex flex-col gap-2"
          >
            <p className="text-sm">{item?.notes}</p>
            <div className="flex justify-between items-center text-xs text-white">
              {item?.s3Url && (
                <p className="whitespace-nowrap flex items-center gap-2">
                  Delivery Files:{" "}
                  <FaFile
                    className="text-lg cursor-pointer"
                    onClick={() => window.open(item?.s3Url, "_blank")}
                  />
                </p>
              )}
              <p className="text-opacity-40 text-end flex-1">
                {moment(item?.lastUpdatedDate).format("DD/MM/YYYY HH:MM")}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderNotesHistory;

import * as React from "react";
export const Teeth43Hover = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={27}
      height={94}
      fill="none"
      stroke="#FF8503"
      strokeWidth="1"
      paintOrder="stroke"
    >
      <path
        fill="url(#c)"
        d="M24.126 29.563c-.487 1.947-1.035 4.321-1.34 6.695-.486 3.652-.79 7-1.034 10.164-.304 4.383-.304 8.826-.304 13.147 0 8.035.06 16.373-1.948 24.408-.791 3.226-2.009 6.756-4.808 8.338-.487.244-.853.365-1.218.365-1.887 0-1.947-4.2-2.008-6.512 0-.487 0-.913-.061-1.157a145.14 145.14 0 0 0-2.86-19.903c-.244-1.278-.61-2.617-.914-3.895a63.619 63.619 0 0 1-1.217-5.478c-.365-2.191-.609-4.383-.791-6.574-.122-1.034-.183-2.13-.305-3.165l-.365-4.017c-.243-2.8-.487-5.721-1.096-8.46 2.07 3.47 4.444 5.356 7.06 5.6h.61c3.773.06 7.912-3.105 12.599-9.556Z"
      />
      <path
        fill="#fff"
        d="M6.17 6.92c1.4-.67 2.679-1.583 4.2-2.922.183-.182.366-.304.549-.487.73-.669 1.4-1.339 2.252-1.704.486-.182.973-.304 1.46-.304.913 0 1.887.304 2.8.791 2.496 1.461 5.356 4.504 6.939 7.304 1.217 2.191 1.826 4.87 1.826 7.852 0 3.408-.792 6.817-1.522 9.8l-.06.364c0 .061-.062.122-.062.183-5.112 7.426-9.677 10.956-13.512 10.652-3.043-.305-5.721-3.044-8.034-8.217l-.365-1.096a46.36 46.36 0 0 1-1.035-3.104c-.974-3.47-1.156-7.365-.548-12.6.122-1.4.365-2.86 1.157-4.017.852-1.095 2.13-1.704 3.408-2.312.183 0 .365-.061.548-.183Z"
      />
      <path
        fill="#FF8503"
        fillOpacity={0.4}
        d="M5.684 49.222c.182 2.191.426 4.382.79 6.574.305 1.765.792 3.651 1.218 5.477.305 1.279.67 2.618.913 3.896 1.4 6.573 2.313 13.269 2.86 19.903 0 .304.062.67.062 1.156.06 2.253.122 6.513 2.008 6.513.366 0 .73-.121 1.218-.365 2.8-1.583 4.017-5.113 4.808-8.339 2.009-8.095 1.948-16.373 1.948-24.407 0-4.321 0-8.765.304-13.147.244-3.165.548-6.513 1.035-10.164.304-2.374.852-4.748 1.339-6.696-4.687 6.452-8.826 9.556-12.539 9.556h-.608c-2.617-.243-4.991-2.13-7.06-5.6.608 2.74.852 5.661 1.095 8.46.122 1.34.183 2.679.365 4.018.061 1.035.122 2.07.244 3.165ZM2.579 29.197l.366 1.096c2.313 5.173 5.052 7.912 8.034 8.217 3.835.365 8.4-3.226 13.512-10.652 0-.06 0-.122.061-.183l.061-.365c.73-2.921 1.522-6.33 1.522-9.8 0-2.982-.61-5.66-1.826-7.85-1.583-2.862-4.383-5.844-6.94-7.305a5.444 5.444 0 0 0-2.799-.791c-.548 0-1.035.122-1.46.304-.853.366-1.522.974-2.253 1.705-.182.182-.365.365-.547.487-1.522 1.4-2.8 2.312-4.2 2.921-.183.061-.365.183-.548.244-1.278.608-2.556 1.156-3.409 2.312-.79 1.096-1.034 2.557-1.156 4.018-.548 5.234-.365 9.13.548 12.599.304.974.67 2.009 1.034 3.043ZM.328 13.433c.183-1.522.366-3.104 1.34-4.322.912-1.217 2.312-1.886 3.651-2.495.183-.061.366-.183.548-.244 1.34-.608 2.557-1.521 4.017-2.8.183-.182.366-.304.548-.486.73-.67 1.461-1.4 2.435-1.826 1.4-.609 3.287-.365 4.87.548 2.616 1.521 5.538 4.625 7.181 7.608 1.278 2.313 1.887 5.052 1.948 8.156 0 3.53-.791 7-1.522 9.92l-.121.366c-.122.609-.305 1.217-.426 1.765-.487 2.009-1.035 4.322-1.34 6.756-.486 3.652-.79 6.94-1.034 10.104-.305 4.382-.305 8.826-.305 13.086 0 8.095.061 16.434-1.947 24.59-.852 3.348-2.07 7.06-5.113 8.765-.548.304-1.096.487-1.522.487-2.556 0-2.617-4.504-2.678-7.183 0-.486 0-.852-.06-1.156A145.622 145.622 0 0 0 7.935 65.23c-.244-1.278-.609-2.617-.913-3.896a64.884 64.884 0 0 1-1.218-5.538c-.365-2.192-.608-4.444-.79-6.635-.123-1.035-.183-2.07-.305-3.165l-.365-4.017c-.244-3.287-.548-6.695-1.461-9.86-.304-.974-.609-1.887-.913-2.8-.365-1.035-.73-2.07-1.035-3.165-.974-3.47-1.156-7.426-.609-12.721Z"
      />
      {/* <defs>
                <linearGradient
                    id="c"
                    x1={14.074}
                    x2={10.244}
                    y1={63.91}
                    y2={135.437}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8F8B" />
                    <stop offset={1} stopColor="#5A7CEF" stopOpacity={0.05} />
                </linearGradient>
            </defs> */}
    </svg>
  );
};

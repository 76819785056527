import { GUIDANCE_LEVEL, GUIDE_TYPE } from "../../constants/orderForms";

const SurgicalGuidesOrderSummary = ({ orderData, selectedServiceCategory }) => {
  return (
    <>
      {orderData?.caseDetails?.services?.map((service) => {
        const serviceDetails = selectedServiceCategory?.catservices?.find(
          (d) => d?.serviceObjId === service?.serviceObjId
        );
        if (serviceDetails?.serviceObjId) {
          return (
            <div
              key={"serviceItem-" + serviceDetails?.serviceObjId}
              className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 rounded-xl bg-backgroundV2 p-6 my-4"
            >
              <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-primary">
                <p className="text-[15px] text-white font-semibold">Service Name</p>
                <p className="text-primary font-medium mt-2 uppercase">
                  {serviceDetails?.serviceName}
                </p>
              </div>
              {[
                "Single or multiple single implant surgical guide",
                "Bone regeneration surgical mesh",
                "Implant bridge surgical guide",
                "Soft tissue based surgical guide for Locators/Ball attachments or Bar",
                "Soft tissue based surgical guide for All on X",
                "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
              ].includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Specific Guide Type</p>
                    <p className="text-[#555] font-medium mt-2">
                      {(typeof service?.guideType === "string"
                        ? service?.guideType
                        : service?.guideType.join(", ")) || "NA"}
                    </p>
                  </div>
                  {service?.guideType === GUIDE_TYPE.BONE_SUPPORTED && (
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">
                        If bone reduction is necessary and you have not requested it, can this be
                        incorporated into the plan?
                      </p>
                      <p className="text-[#555] font-medium mt-2">
                        {service?.boneReductionInPlan || "NA"}
                      </p>
                    </div>
                  )}
                </>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">
                  {serviceDetails?.serviceName === "Bone regeneration surgical mesh"
                    ? "Please select grafting site(s) where the mesh is required"
                    : serviceDetails?.serviceName === "Endodontic access or microsurgery guide"
                    ? "Please select teeth for endodontic access and/or microsurgery"
                    : "Please select implant site(s)"}
                </p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.[
                    serviceDetails?.serviceName === "Bone regeneration surgical mesh"
                      ? "graftingSiteTeeth"
                      : serviceDetails?.serviceName === "Endodontic access or microsurgery guide"
                      ? "endodonticAccessTeeth"
                      : "implantSiteTeeth"
                  ]?.join(", ") || "NA"}
                </p>
              </div>
              {[
                "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                "Soft tissue based surgical guide for All on X or Bar overdenture",
              ].includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      What additional stackable guide options are desired?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {(
                        service?.additionalGuideOptions &&
                        Object.keys(service?.additionalGuideOptions || {})?.map(
                          (d) => `${d}(${service?.additionalGuideOptions?.[d]})`
                        )
                      )?.join(", ") || "NA"}
                    </p>
                  </div>
                </>
              )}
              {[
                "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
              ].includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      If no provisional denture has been selected, is a conversion denture
                      available?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.conversionDentureAvailable || "NA"}
                    </p>
                  </div>
                </>
              )}
              {[
                "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                "Soft tissue based surgical guide for All on X",
              ].includes(serviceDetails?.serviceName) &&
                !service?.additionalGuideOptions?.["Guided temporary Prosthesis"] &&
                service?.conversionDentureAvailable === "No" && (
                  <>
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">
                        A digital conversion denture can also be provided. Is this required?
                      </p>
                      <p className="text-[#555] font-medium mt-2">
                        {service?.digitalConversionDenture || "NA"}
                      </p>
                    </div>
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">Please indicate abutment preferences</p>
                      <p className="text-[#555] font-medium mt-2">
                        {service?.abutmentPreferences || "NA"}
                      </p>
                    </div>
                  </>
                )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Surgery Date</p>
                <p className="text-[#555] font-medium mt-2">{service?.surgeryDate || "NA"}</p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Preferred design software?</p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.preferredDesignSoftware || "NA"}
                </p>
              </div>
              {serviceDetails?.serviceName === "Endodontic access or microsurgery guide" ? (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Preferred Guided Access drill brand</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.guidedAccessDrillBrand || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Guided Access drill shank diameter (mm)
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.drillShankDiameter || "NA"}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Preferred Implant Company</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.preferredImplantCompany || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Implant Line</p>
                    <p className="text-[#555] font-medium mt-2">{service?.implantLine || "NA"}</p>
                  </div>
                </>
              )}
              {[
                "Bone regeneration surgical mesh",
                "Endodontic access or microsurgery guide",
              ].includes(serviceDetails?.serviceName) ? null : (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Preferred Pin Company</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.preferredPinCompany || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Pin Line</p>
                    <p className="text-[#555] font-medium mt-2">{service?.pinLine || "NA"}</p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Implant Surgical Kit</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.implantSurgicalKit || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Will Densah burs be used?</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.densahBursUsed || "NA"}
                    </p>
                  </div>
                  {service?.densahBursUsed === "Yes" && (
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">Is a Densah bur guide desired?</p>
                      <p className="text-[#555] font-medium mt-2">
                        {service?.densahBurGuideDesired || "NA"}
                      </p>
                    </div>
                  )}
                  {service?.densahBursUsed === "Yes" &&
                    service?.densahBurGuideDesired === "Yes" && (
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">
                          Sites at which Densah burs will be used
                        </p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.densahBursTeeth?.join(", ") || "NA"}
                        </p>
                      </div>
                    )}
                </>
              )}
              {serviceDetails?.serviceName === "Bone regeneration surgical mesh" ? null : (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Will a flap be raised?</p>
                  <p className="text-[#555] font-medium mt-2">{service?.flapBeRaised || "NA"}</p>
                </div>
              )}
              {[
                "Bone regeneration surgical mesh",
                "Endodontic access or microsurgery guide",
              ].includes(serviceDetails?.serviceName) ? null : (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white"> Choose Level of Guidance Desired</p>
                    <p className="text-[#555] font-medium mt-2">{service?.guidanceLevel || "NA"}</p>
                  </div>
                  {service?.guidanceLevel === GUIDANCE_LEVEL.FULLY_GUIDED && (
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">
                        {" "}
                        Will the prosthesis be used as a morphological template for the guide?
                      </p>
                      <p className="text-[#555] font-medium mt-2">
                        {service?.prosthesisBeUsed || "NA"}
                      </p>
                    </div>
                  )}
                </>
              )}
              {["Endodontic access or microsurgery guide"].includes(
                serviceDetails?.serviceName
              ) ? null : (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Choose Services Required</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.servicesRequired?.join(", ") || "NA"}
                    </p>
                  </div>
                </>
              )}

              <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Additional Instructions </p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.additionalInstructions || "NA"}
                </p>
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default SurgicalGuidesOrderSummary;

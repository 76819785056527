import { OPTIONS_VALUE } from "../../constants/orderForms";

const ImplantOrderSummary = ({ orderData, selectedServiceCategory }) => {
  return (
    <>
      {orderData?.caseDetails?.services?.map((service) => {
        const serviceDetails = selectedServiceCategory?.catservices?.find(
          (d) => d?.serviceObjId === service?.serviceObjId
        );
        console.log("serviceDetails", serviceDetails);
        if (serviceDetails?.serviceObjId) {
          return (
            <div
              key={"serviceItem-" + serviceDetails?.serviceObjId}
              className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 rounded-xl bg-backgroundV2 p-6 my-4"
            >
              <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-primary">
                <p className="text-[15px] text-white font-semibold">Service Name</p>
                <p className="text-primary font-medium mt-2 uppercase">
                  {serviceDetails?.serviceName}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Implant sites Teeths</p>
                <p className="text-[#555] font-medium mt-2">{service?.implantSites?.join(", ") || "NA"}</p>
              </div>
              {[
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    {[
                      "Implant bridge (screw retained)",
                      "Implant bridge (cemented)",
                      "Implant bridge with gingiva (screw retained)",
                    ].includes(serviceDetails?.serviceName)
                      ? "Type of Bridge Retainer and Pontic"
                      : "Type of Crown"}
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.typeOfCrown?.length > 0 ? service?.typeOfCrown.join(", ") : "NA"}
                  </p>
                </div>
              )}
              {[
                "Single or multiple implant crowns (screw retained)",
                "Implant bridge (screw retained)",
                "Implant bridge with gingiva (screw retained)",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Would you like a single restoration design (Ti base and restoration) or a split
                    restoration design (Ti base and tissue level abutment and restoration)?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.restorationDesign || "NA"}
                  </p>
                </div>
              )}
              {[
                "Custom abutments",
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
                "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                "All on X hybrid with prep style posts for crowns",
                "All on X custom framework",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Cement Gap (µ microns)</p>
                  <p className="text-[#555] font-medium mt-2">{service?.cementGap || "NA"}</p>
                </div>
              )}
              {[
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
                "All on X hybrid with prep style posts for crowns",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Interproximal Contacts</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.interproximalContacts || "NA"}
                  </p>
                </div>
              )}
              {[
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
                "Implant bar substructure for over denture",
                "All on X Hybrid (monolithic zirconia)",
                "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                "All on X hybrid with prep style posts for crowns",
                "All on X custom framework",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Occlusal Contacts</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.occlusalContacts || "NA"}
                  </p>
                </div>
              )}
              {[
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
                "Implant bar substructure for over denture",
                "All on X Hybrid (monolithic zirconia)",
                "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                "All on X hybrid with prep style posts for crowns",
                "All on X custom framework",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    {[
                      "Implant bar substructure for over denture",
                      "All on X Hybrid (monolithic zirconia)",
                      "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                      "All on X hybrid with prep style posts for crowns",
                      "All on X custom framework",
                    ].includes(serviceDetails?.serviceName)
                      ? "Tissue Interface Design"
                      : "Pontic Design"}
                  </p>
                  <p className="text-[#555] font-medium mt-2">{service?.ponticDesign || "NA"}</p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Do you need to see a design preview?</p>
                <p className="text-[#555] font-medium mt-2">{service?.needDesignPreview || "NA"}</p>
              </div>
              {[
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
                "Implant bar substructure for over denture",
                "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                "All on X hybrid with prep style posts for crowns",
                "All on X custom framework",
              ].includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      {[
                        "Implant bar substructure for over denture",
                        "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                        "All on X custom framework",
                      ].includes(serviceDetails?.serviceName)
                        ? "The substructure is always designed to be milled in our designs. With reference to the superstructure, will you be"
                        : serviceDetails?.serviceName ===
                          "All on X hybrid with prep style posts for crowns"
                        ? "The substructure is always designed to be milled in our designs. With reference to the crowns, will you be"
                        : "Will you be"}
                    </p>
                    <p className="text-[#555] font-medium mt-2">{service?.processType || "NA"}</p>
                  </div>

                  {service?.processType === OPTIONS_VALUE.PRINTING && (
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">
                        If you are printing what restorative space do you prefer? (µ microns)
                      </p>
                      <p className="text-[#555] font-medium mt-2">
                        {service?.processTypePreferredRestorativeSpace || "NA"}
                      </p>
                    </div>
                  )}
                </>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Design file to be provided?</p>
                <p className="text-[#555] font-medium mt-2">{service?.designFileNeeded || "NA"}</p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Preferred design software?</p>
                <p className="text-[#555] font-medium mt-2">{service?.preferredSoftware || "NA"}</p>
              </div>
              {[
                "Custom abutments",
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
                "Implant bar substructure for over denture",
                "All on X Hybrid (monolithic zirconia)",
                "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                "All on X hybrid with prep style posts for crowns",
                "All on X custom framework",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    {[
                      "Implant bar substructure for over denture",
                      "All on X Hybrid (monolithic zirconia)",
                      "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                      "All on X hybrid with prep style posts for crowns",
                      "All on X custom framework",
                    ].includes(serviceDetails?.serviceName)
                      ? "Restorations, superstructures and substructures are normally provided as .STLs, Please advise if a construction.info file is desired."
                      : "Restorations are normally provided as .STLs, Please advise if a construction.info file is desired."}
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.constructionFileNeeded || "NA"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Is a printable model desired?</p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.printableModelRequired || "NA"}
                </p>
              </div>
              {service?.printableModelRequired === "Yes" && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Should the crown and bridge model include dies?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.printableModelincludeDies || "NA"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">
                  If a certain restoration lacks occlusal clearance? Please advise your preferred
                  course of action:{" "}
                </p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.restorationLacksOC || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Additional Instructions </p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.additionalInstructions || "NA"}
                </p>
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default ImplantOrderSummary;

import { useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner";
import { useInitiateOrderPaymentTransactionMutation } from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";

const CheckoutForm = ({
  setActiveTab,
  amount,
  orderObjId,
  paymentProcessing,
  setPaymentProcessing,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const stripe = useStripe();
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const [initiateOrderPaymentTransaction] = useInitiateOrderPaymentTransactionMutation();

  console.log("userProfileDetails", userProfileDetails);

  const handleSubmit = async (event) => {
    setPaymentProcessing(true);
    event.preventDefault();

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const response = await initiateOrderPaymentTransaction({
      accessToken,
      data: {
        currency: "usd",
        productname: "Digital Dentition",
        amount: amount,
        orderObjId: orderObjId,
        baseUrl: window.location.origin,
      },
    }).unwrap();

    console.log("response", response);

    stripe.redirectToCheckout({
      sessionId: response?.sessionId,
    });

    setPaymentProcessing(false);
  };

  return (
    <div className="px-1">
      <form onSubmit={handleSubmit}>
        <div className="flex items-center md:justify-end gap-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab("Order Details");
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={paymentProcessing}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            {paymentProcessing ? <LoaderSpinner className="my-1" /> : "Continue"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;

import { useState, useEffect } from "react";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import hut from "../../Assets/images/hut.svg";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import DynamicTable from "../../Components/Services-Comps/DynamicTable";
import { useNavigate } from "react-router-dom";
import {
  useGetOrderPaymentTransactionsMutation,
  useVerifyOrderPaymentTransactionMutation,
} from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../Login/Login";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import { Tooltip } from "react-tooltip";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";

function PaymentHistory({ userRole, userInfo }) {
  return (
    <div className="flex">
      <Navbar
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active="Payment History"
      />
      <PaymentHistoryTab userRole={userRole} userInfo={userInfo} />
    </div>
  );
}

export default PaymentHistory;

export const PaymentHistoryTab = ({ userRole, userInfo }) => {
  const navigate = useNavigate();

  const headersTransaction = [
    {
      headerId: "orderNumber",
      headerName: "Order Id",
      headerSort: true,
    },
    {
      headerId: "amount",
      headerName: "Amount",
      headerSort: true,
    },
    {
      headerId: "paymentDate",
      headerName: "Payment Date",
      headerSort: true,
    },
    {
      headerId: "status",
      headerName: "Status",
      headerSort: true,
    },
    {
      headerId: "actions",
      headerName: "Actions",
      headerSort: false,
    },
  ];

  const { user, isAuthenticated } = useAuth0();
  const [selectedButton, setSelectedButton] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [verifyOrderPaymentTransaction] = useVerifyOrderPaymentTransactionMutation();
  const [getOrderPaymentTransactions] = useGetOrderPaymentTransactionsMutation();
  const [transactionsData, setTransactionData] = useState([]);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const checkPaymentStatus = async (sessionId, orderObjId, callback) => {
    if (sessionId) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const response = await verifyOrderPaymentTransaction({
        accessToken,
        data: { sessionId: sessionId, orderObjId: orderObjId },
      }).unwrap();

      console.log("payment verification response", response);
      if (response?.success) {
        GetToast(response.message);
      } else if (response?.sessionPaymentStatus === "unpaid") {
        GetToast(response.message);
      }
      if (callback) {
        callback();
      }
    }
  };

  const getTransactionData = async (accessToken) => {
    const resp = await getOrderPaymentTransactions({ accessToken }).unwrap();
    if (resp) {
      setTransactionData(resp?.data);
    }
  };

  const readTransactions = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getTransactionData(accessToken);
  };

  useEffect(() => {
    readTransactions();
  }, []);

  return (
    <div className="MAIN-DASH bg-black p-4 w-full min-h-screen h-full flex flex-col md:py-4 pt-4 pb-20">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user?.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}
          <Notification />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
        <div className="flex flex-1 gap-4 items-center w-full">
          <p className="font-medium text-[34px] text-white">Payment History</p>
          {/* <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search by Service name..."
              className="w-full placeholder:text-[#999] py-2  placeholder:text-[16px] text-[16px] pl-8 bg-[#fff] focus:outline-none border border-[#dcdcdc] rounded-md"
            />
            <img className="absolute top-3 left-2" src={searchIcon} alt="" />
          </div> */}
        </div>
        {userRole === "676dza2" ? (
          <button
            onClick={() => {
              navigate("/addNewOrder");
            }}
            className="text-[16px] font-medium text-white bg-[#FF7E00] rounded-md px-4 py-2"
          >
            + Add New Order
          </button>
        ) : null}
      </div>
      <div className="bg-secondary p-4 rounded-md md:h-[80vh] h-[70vh]">
        <DynamicTable
          setSelectedButton={setSelectedButton}
          headers={headersTransaction}
          data={transactionsData}
          includeImgVideoButton={true}
          paymentTable={true}
          onPaymentRefresh={(item) => {
            checkPaymentStatus(item?.sessionId, item?._id, () => {
              readTransactions();
            });
          }}
        />
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import { FaSort } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import ToggleButton from "../UserManagement-Comps/ToggleButton";
import eye from "../../Assets/images/eye.svg";
import note from "../../Assets/images/note.svg";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import placeHolderImage from "../../Assets/images/image.png";
import { ConfirmToast } from "react-confirm-toast";
import moment from "moment";

const DynamicTable = ({
  headers,
  data,
  userRole,
  includeImgVideoButton,
  categoryTable,
  serviceProviderTable,
  dentistTable,
  orderTable,
  serviceTable,
  onClickEditRow,
  onClickDeleteRow,
  setSelectedButton,
  handleLinkForCategories,
  handleButtonClickForUM,
  softwareTable,
  questionTable,
  modalIsOpen,
  pageSize = 5,
  enableSearch = true,
  paymentTable = false,
  onPaymentRefresh,
}) => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  console.log("table data", data);
  const navigate = useNavigate();
  const handleButtonClick = (info) => {
    console.log(info, "info");
    setSelectedButton(info);
    // console.log(info);
    if (info.statusCode === "104") {
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "102") {
      navigate("/orderDetailsAdmin", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
        },
      });
    } else if (info.statusCode === "105") {
      navigate("/orderDetailsAdmin", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
        },
      });
    } else if (info.statusCode === "106") {
      // debugger
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "107") {
      // debugger
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "112") {
      // debugger
      console.log("first info", info);
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "109") {
      // debugger
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "108") {
      // debugger
      navigate("/orderDetailsAdmin", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
        },
      });
    } else if (info.statusCode === "111") {
      // debugger
      navigate("/orderDetailsAdmin", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
        },
      });
    } else if (info.statusCode === "123") {
      // debugger
      navigate("/orderDetailsAdmin", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
        },
      });
    } else if (info.statusCode === "117") {
      // debugger
      navigate("/orderDetailsAdmin", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
        },
      });
    } else if (info.statusCode === "110") {
      // debugger
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          isPriority: info.isPriority,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
          status: info.status,
        },
      });
    } else if (info.statusCode === "0") {
      // debugger
      navigate(`/${info.orderUrl?.[0]}`, {
        state: {
          orderObjId: info?._id,
          statusCode: info?.statusCode,
          categoryObjId: info?.categoryObjId?.[0],
          orderByData: { _id: info?._id },
        },
      });
    } else if (info.statusCode === "100") {
      // debugger
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "115") {
      // debugger
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "118") {
      // debugger
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "113") {
      // debugger
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
          status: info.status,
        },
      });
    } else if (info.statusCode === "114") {
      // debugger
      navigate("/orderDetailsAdmin", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
          status: info.status,
        },
      });
    } else if (info.statusCode === "119") {
      // debugger
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
          status: info.status,
        },
      });
    } else if (info.statusCode === "116") {
      // debugger
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
          status: info.status,
        },
      });
    } else if (info.statusCode === "124") {
      // debugger
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "127") {
      // debugger
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "130") {
      // debugger
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "125") {
      // debugger
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
          status: info.status,
        },
      });
    } else if (info.statusCode === "122") {
      // debugger
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
          status: info.status,
        },
      });
    } else if (info.statusCode === "128") {
      // debugger
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
          status: info.status,
        },
      });
    } else if (info.statusCode === "131") {
      // debugger
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
          status: info.status,
        },
      });
    } else if (info.statusCode === "134") {
      // debugger
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
          status: info.status,
        },
      });
    } else if (info.statusCode === "137") {
      // debugger
      navigate("/orderDetailsDentist", {
        state: {
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          portalPrice: info.portalPrice?.[0],
          serviceName: info.serviceName,
          status: info.status,
        },
      });
    } else if (info.statusCode === "121") {
      // debugger
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "120") {
      // debugger
      navigate("/orderDetailsAdmin", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "133") {
      // debugger
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "136") {
      // debugger
      navigate("/orderDetailsServiceProvider", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "126") {
      // debugger
      navigate("/orderDetailsAdmin", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "132") {
      // debugger
      navigate("/orderDetailsAdmin", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "129") {
      // debugger
      navigate("/orderDetailsAdmin", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    } else if (info.statusCode === "135") {
      // debugger
      navigate("/orderDetailsAdmin", {
        state: {
          isPriority: info.isPriority,
          orderObjId: info._id,
          statusCode: info.statusCode,
          serviceObjId: info.serviceObjId,
          orderValue: info.orderValue,
          orderCurrency: info.orderCurrency,
          status: info.status,
          serviceName: info.serviceName,
        },
      });
    }
    // if (info.orders === "New Order") {
    //   if (userRole === "89uij7") navigate("/orderDetailsAdmin");
    //   if (userRole === "34fs3") navigate("/orderDetailsServiceProvider");
    // }
    // if (info.orders === "Quote Received") {
    //   if (userRole === "676dza2") navigate("/orderDetailsDentist");
    // }
  };
  useEffect(() => {
    setTotalRows(data.length);
  }, [data]);

  const handleSort = (header) => {
    if (!header.headerSort) return;

    if (sortColumn === header.headerId) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(header.headerId);
      setSortDirection("asc");
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePageSelect = (event) => {
    setCurrentPage(Number(event.target.value));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset the current page when searching
  };

  const sortedData = sortColumn
    ? [...data]?.sort((a, b) => {
        const valueA = String(a[sortColumn]).toLowerCase();
        const valueB = String(b[sortColumn]).toLowerCase();

        if (sortDirection === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      })
    : data;

  const filteredData = (sortedData?.length > 0 ? sortedData : [])?.filter((row) => {
    return Object?.values(row)?.some((value) => {
      return String(value)?.toLowerCase()?.includes(searchTerm?.toLowerCase());
    });
  });

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const dataOnPage = filteredData?.slice(startIndex, endIndex);
  const pageCount = Math.ceil(filteredData.length / pageSize);

  const pageOptions = Array.from({ length: pageCount }, (_, index) => index + 1);

  const renderMedia = (media, type) => {
    if (media.length <= 10) {
      return media.map((item, index) => (
        <div key={index}>
          {type === "image" && (
            <img
              className="h-7 w-7 object-cover rounded-sm cursor-pointer"
              src={item?.s3Url ?? ""}
              alt=""
              onClick={() => openImageModal(item.s3Url)}
            />
          )}
          {type === "video" && (
            <video
              className="h-7 w-7 object-cover rounded-sm cursor-pointer"
              src={item?.s3Url ?? ""}
              // controls
              height={28}
              width={28}
              onClick={() => openVideoModal(item.s3Url)}
            >
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ));
    } else {
      const firstTwoMedia = media.slice(0, 2);
      const remainingCount = media.length - 2;
      return (
        <>
          {firstTwoMedia.map((item, index) => (
            <div key={index}>
              {type === "image" && (
                <img
                  className="h-7 w-7 object-cover rounded-sm cursor-pointer"
                  src={item}
                  alt=""
                  onClick={() => openImageModal(item)}
                />
              )}
              {type === "video" && (
                <video
                  className="h-7 w-7 object-cover rounded-sm cursor-pointer"
                  src={item}
                  // controls
                  height={28}
                  width={28}
                  onClick={() => openVideoModal(item)}
                >
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          ))}
          {remainingCount > 0 && <span>+{remainingCount}</span>}
        </>
      );
    }
  };
  const renderOrderStatus = (rowData) => {
    if (rowData.statusCode === "104") {
      return <div className={`text-[#4E81F4] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "102") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "105") {
      return <div className={`text-[#DBB419] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "106") {
      return <div className={`text-[#4E81F4] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "107") {
      return <div className={`text-[#4E81F4] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "112") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "109") {
      return <div className={`text-[#ff4f3b] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "111") {
      return <div className={`text-[#E971B0] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "123") {
      return <div className={`text-[#E971B0] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "117") {
      return <div className={`text-[#E971B0] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "108") {
      return <div className={`text-[#E971B0] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "110") {
      return <div className={`text-[#4E81F4]  !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "0") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "100") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "113") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "114") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "115") {
      return <div className={`text-[#d4b13f] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "116") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "118") {
      return <div className={`text-[#ff4f3b] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "119") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "120") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "124") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "127") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "130") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "125") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "122") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "121") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "128") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "126") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "137") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "136") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "135") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "133") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "132") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "129") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "134") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    } else if (rowData.statusCode === "131") {
      return <div className={`text-[#1DB569] !text-white`}>{rowData.status}</div>;
    }
  };
  const renderOrderButton = (statusCode) => {
    // if (statusCode === "Order Under Review") {
    //   return "View";
    // } else if (statusCode === "New Order") {
    //   if (userRole === "89uij7") return "View & Assign";
    //   if (userRole === "34fs3") return "Accept/Reject";
    //   return "Accept/Reject";
    // } else if (statusCode === "Quote Received") {
    //   return "View & Accept";
    // } else if (statusCode === "Order Under Progress") {
    //   return "View";
    // } else if (statusCode === "Order Cancelled") {
    //   return "View";
    // } else if (statusCode === "Order Completed") {
    //   return "View Delivery";
    // } else if (statusCode === "Order Disputed") {
    //   return "View Details";
    // }
    if (statusCode === "102") {
      return "View & Assign";
    } else if (statusCode === "104") {
      return "View Order";
    } else if (statusCode === "105") {
      return "View Details";
    } else if (statusCode === "106") {
      return "View & Accept";
    } else if (statusCode === "107") {
      return "View Order";
    } else if (statusCode === "112") {
      return "View Order";
    } else if (statusCode === "109") {
      return "View Order";
    } else if (statusCode === "111") {
      return "View Order";
    } else if (statusCode === "117") {
      return "View Order";
    } else if (statusCode === "108") {
      return "View & Assign";
    } else if (statusCode === "110") {
      return "View Order";
    } else if (statusCode === "0") {
      return "Edit & Submit Order";
    } else if (statusCode === "100") {
      return "View Order";
    } else if (statusCode === "113") {
      return "View Order";
    } else if (statusCode === "114") {
      return "View Order";
    } else if (statusCode === "115") {
      return "View Order";
    } else if (statusCode === "116") {
      return "View Order";
    } else if (statusCode === "118") {
      return "View Order";
    } else if (statusCode === "119") {
      return "View Order";
    } else if (statusCode === "120") {
      return "View Order";
    } else if (statusCode === "123") {
      return "View Order";
    } else if (statusCode === "124") {
      return "View Order";
    } else if (statusCode === "127") {
      return "View Order";
    } else if (statusCode === "130") {
      return "View Order";
    } else if (statusCode === "125") {
      return "View Order";
    } else if (statusCode === "122") {
      return "View Order";
    } else if (statusCode === "121") {
      return "View Order";
    } else if (statusCode === "128") {
      return "View Order";
    } else if (statusCode === "126") {
      return "View Order";
    } else if (statusCode === "136") {
      return "View Order";
    } else if (statusCode === "135") {
      return "View Order";
    } else if (statusCode === "137") {
      return "View Order";
    } else if (statusCode === "133") {
      return "View Order";
    } else if (statusCode === "132") {
      return "View Order";
    } else if (statusCode === "129") {
      return "View Order";
    } else if (statusCode === "134") {
      return "View Order";
    } else if (statusCode === "131") {
      return "View Order";
    }
  };
  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  const openVideoModal = (video) => {
    setSelectedVideo(video);
    setIsVideoModalOpen(true);
  };

  const closeVideoModal = () => {
    setSelectedVideo("");
    setIsVideoModalOpen(false);
  };
  return (
    <div className="flex flex-col h-full">
      <div className="SEARCH-BAR flex justify-between">
        <div className={serviceProviderTable ? "flex md:flex-row flex-col gap-4 items-center" : ""}>
          {serviceProviderTable ? (
            <p className="text-white font-medium text-[26px]">Provider List</p>
          ) : (
            ""
          )}
          {enableSearch && (
            <div className="relative">
              <input
                className="border text-white border-opacity-50  border[#d6d6d6] px-4 py-2 rounded-lg placeholder:text-[16px] bg-secondary"
                type="text"
                name=""
                id=""
                placeholder={
                  serviceProviderTable
                    ? "Search Services"
                    : dentistTable
                    ? "Search Dentists"
                    : orderTable
                    ? "Search Orders"
                    : softwareTable
                    ? "Search Software"
                    : questionTable
                    ? "Search Question"
                    : serviceTable
                    ? "Search Services"
                    : categoryTable
                    ? "Search Provider Levels"
                    : "Search Categories"
                }
                value={searchTerm}
                onChange={handleSearch}
              />
              <BsSearch className="absolute right-4 top-3 text-lg text-[#979797]" />
            </div>
          )}
        </div>

        {/* <button className="border border-gray-600 border-opacity-50 rounded-md px-4 py-2 flex items-center gap-2 text-[#555] text-[15px] font-medium bg-white">
          <BiFilterAlt className="text-[#777]" />
          Filter
        </button> */}
      </div>

      <div className="TABLE w-full my-8 flex-1 overflow-scroll bg-secondary rounded-lg overflow-x-hidden">
        <table className="md:w-full">
          <thead className="HEADERS">
            <tr className="bg-[#1b1c1c] py-2 rounded-lg  flex md:justify-around w-full overflow-scroll overflow-x-hidden px-4 ">
              {headers.map((header, index) => (
                <th
                  style={{
                    justifyContent: `${
                      header?.headerAlignment === "center"
                        ? "center"
                        : header.headerAlignment === "right"
                        ? "flex-end"
                        : header.headerAlignment === "left"
                        ? "flex-start"
                        : ""
                    }`,
                  }}
                  className="font-medium text-white text-[15px] py-2 cursor-pointer w-full flex items-center justify-start gap-4 min-w-[120px] md:mx-0 mx-4 "
                  key={index}
                  onClick={() => handleSort(header)}
                >
                  {header.headerName}
                  {header.headerSort && (
                    <span className=" inline-block">
                      {sortColumn === header.headerId ? (
                        sortDirection === "asc" ? (
                          <HiSortAscending />
                        ) : (
                          <HiSortDescending />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="TABLE-ROWS overflow-auto overflow-x-hidden">
            {dataOnPage.length === 0 ? (
              <div role="status" className="md:space-y-0 md:space-x-8 md:flex md:items-center">
                <div className="w-full flex-col gap-5 p-3 text-white justify-center items-center text-center py-12">
                  No Data Found
                </div>
              </div>
            ) : (
              <>
                {dataOnPage.map((row, index) => (
                  <tr
                    className="flex items-center md:justify-evenly py-4 border-b border-[#efefef] border-opacity-20 w-full  px-4 "
                    key={index}
                  >
                    <td
                      className={`flex priority-container items-center md:gap-4 gap-2 w-full text-[16px] min-w-[120px] md:max-w-full max-w-[120px] md:mx-0 mx-4 text-white ${
                        row?.image || row?.serviceIcon || row?.softwares || row?.softwareImageUrl
                          ? "!items-start flex-col"
                          : ""
                      } ${
                        row?.image || row?.serviceIcon || row?.softwares || row?.softwareImageUrl
                          ? "pr-2"
                          : ""
                      }`}
                    >
                      {(row?.image ||
                        row?.serviceIcon ||
                        row?.softwares ||
                        row?.softwareImageUrl) && (
                        <div className="flex flex-col items-center gap-1">
                          <img
                            className="w-24 h-24 object-cover"
                            src={
                              row?.image ||
                              row?.serviceIcon ||
                              row?.softwares?.softwareImageUrl ||
                              row?.softwareImageUrl ||
                              placeHolderImage
                            }
                            alt="User"
                          />
                          <p className="text-center text-sm">{row?.categoryName?.[0]}</p>
                        </div>
                      )}{" "}
                      {row[headers[0].headerId]}
                    </td>

                    {headers.slice(1).map((header, index) => (
                      <td
                        style={{
                          justifyContent: `${
                            header?.rowAlignment === "center"
                              ? "center"
                              : header.rowAlignment === "right"
                              ? "flex-end"
                              : header.rowAlignment === "left"
                              ? "flex-start"
                              : ""
                          }`,
                        }}
                        className="w-full text-left  text-white text-[16px] flex justify-start items-start gap-4 min-w-[120px] md:max-w-full max-w-[120px] md:mx-0 mx-4  "
                        key={index}
                      >
                        {includeImgVideoButton ? (
                          header.headerName === "Amount" ? (
                            <p>{row[header.headerId] ? `$${row[header.headerId]}` : "$0"}</p>
                          ) : header.headerName === "Images" ? (
                            row.images && row.images.length > 0 ? (
                              renderMedia(row.images, "image")
                            ) : (
                              <span>No Images</span>
                            )
                          ) : header.headerName === "Videos" ? (
                            row.videos && row.videos.length > 0 ? (
                              renderMedia(row.videos, "video")
                            ) : (
                              <span>No Videos</span>
                            )
                          ) : header.hyperLink === true ? (
                            <div
                              className="text-primary font-medium underline cursor-pointer"
                              onClick={() => {
                                handleLinkForCategories(row);
                              }}
                            >
                              {row[header.headerName]}
                            </div>
                          ) : header.headerName === "Softwares" ? (
                            <p>
                              {row[header.headerId]?.softwareName
                                ? row[header.headerId]?.softwareName
                                : "No Software"}
                            </p>
                          ) : header.headerName === "Software Name" ? (
                            <p>
                              s
                              <img
                                className="w-24 h-24 object-cover"
                                src={
                                  row[header.headerId]?.softwareImageUrl || row?.softwareImageUrl
                                }
                                alt="User"
                              />
                              {row[header.headerId]?.softwareName
                                ? row[header.headerId]?.softwareName
                                : "No Software"}
                            </p>
                          ) : header.headerName === "Options" ? (
                            row[header.headerId]?.map((item) => <p>{item?.option}</p>)
                          ) : header.headerName === "Order Date" ? (
                            row[header.headerId] ? (
                              row[header.headerId]?.includes("T") ? (
                                moment(row[header.headerId])?.format("DD-MM-YYYY")
                              ) : row[header.headerId]?.includes("-") ? (
                                row[header.headerId]?.split("-")?.[0]
                              ) : (
                                moment(row[header.headerId])?.format("dd-MM-YYYY")
                              )
                            ) : null
                          ) : header.headerName === "Actions" ? (
                            serviceTable || categoryTable || softwareTable || questionTable ? (
                              <div className="w-full first:text-left text-center text-white text-[16px] flex justify-start items-center gap-4 min-w-[120px] md:max-w-full max-w-[120px] md:mx-0 mx-4 ">
                                <button
                                  className="px-8 py-2 my-2 bg-secondary bg-opacity-40 border border-[#d6d6d6] rounded text-white text-[12px]"
                                  onClick={() => onClickEditRow(row)}
                                >
                                  Edit
                                </button>
                                <ConfirmToast
                                  childrenClassName="custom-confirm-toast"
                                  asModal={true}
                                  customCancel={"No"}
                                  customConfirm={"Yes"}
                                  customFunction={() => {
                                    onClickDeleteRow(row);
                                  }}
                                  message={"Are you sure want to delete?"}
                                  position={"top-right"}
                                  showCloseIcon={false}
                                  theme={"dark"}
                                >
                                  <button
                                    className="px-4 py-2 my-2 bg-transparent rounded-md"
                                    // onClick={() => onClickDeleteRow(row)}
                                  >
                                    <MdDelete className="text-2xl text-red-400" />
                                  </button>
                                </ConfirmToast>
                              </div>
                            ) : serviceProviderTable || dentistTable ? (
                              <>
                                <button
                                  className="px-4 py-2 my-2 text-[#d6d6d6] bg-secondary bg-opacity-60 border border-[#d6d6d6] shadow rounded-md"
                                  onClick={() => {
                                    // handleButtonClick(row);
                                    handleButtonClickForUM(row);
                                  }}
                                >
                                  View Details
                                </button>
                              </>
                            ) : orderTable ? (
                              <button
                                className=" md:min-w-[125px] min-w-[108px] w-fit md:px-4 px-2 py-2 my-2 text-white bg-secondary bg-opacity-60 border border-white shadow rounded-md"
                                onClick={() => {
                                  handleButtonClick(row);
                                }}
                              >
                                {renderOrderButton(row.statusCode)}
                              </button>
                            ) : paymentTable ? (
                              <button
                                className=" md:min-w-[125px] min-w-[108px] w-fit md:px-4 px-2 py-2 my-2 text-white bg-secondary bg-opacity-60 border border-white shadow rounded-md"
                                onClick={() => {
                                  onPaymentRefresh(row);
                                }}
                              >
                                Refresh Status
                              </button>
                            ) : (
                              ""
                            )
                          ) : header.headerName === "Status" && serviceProviderTable === true ? (
                            <div className="flex gap-2 items-center">
                              <ToggleButton />
                              <img
                                className="cursor-pointer"
                                src={eye}
                                alt="eye"
                                onClick={() => {}}
                              />
                              <img
                                className="cursor-pointer"
                                src={note}
                                alt="note"
                                onClick={() => {}}
                              />
                            </div>
                          ) : header.headerName === "Priority Order" ? (
                            row[header.headerId] === true || row[header.headerId] === "True" ? (
                              "True"
                            ) : (
                              "False"
                            )
                          ) : header.headerName === "Order Status" && orderTable === true ? (
                            renderOrderStatus({
                              status: row[header.headerId],
                              statusCode: row["statusCode"],
                            })
                          ) : header.headerName === "Discard" &&
                            orderTable === true &&
                            row.currentStatus === "0" ? (
                            <div className="w-full first:text-left text-center text-white text-[16px] flex justify-start items-center md:max-w-full max-w-[120px] md:mx-0 mx-4 ">
                              <button
                                className="px-4 py-2 my-2 bg-transparent rounded-md"
                                onClick={() => onClickDeleteRow(row)}
                              >
                                <MdDelete className="text-2xl text-red-400" />
                              </button>
                            </div>
                          ) : (
                            row[header.headerId]
                          )
                        ) : (
                          row[header.headerId]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="PAGINATION w-full bg-[#1b1c1c] rounded-lg md:p-4 p-2 ">
        <div className="flex items-center justify-between">
          <div className="text-[15px] leading-4 text-white mr-4 ">
            Showing {currentPage}-{pageCount}
          </div>
          <div className="text-[15px] leading-4 text-white flex items-center">
            <span className="mr-4 text-white">Total Rows: {totalRows}</span>
            <div className=" h-8 w-[1px] bg-[#white] "></div>
            <span className="ml-4 text-white">The page you’re on</span>
            <span className="p-2 bg-black border border-[#d6d6d6] rounded-lg mx-2">
              <select value={currentPage} onChange={handlePageSelect} className="bg-[#1b1c1c]">
                {pageOptions.map((page) => (
                  <option className="bg-[#1b1c1c]" key={page} value={page}>
                    {page}
                  </option>
                ))}
              </select>
            </span>
            <div className=" h-8 w-[1px] bg-[#d6d6d6] "></div>
            <button
              className="bg-backgroundV2 p-2 rounded-lg border border-[#d6d6d6] mx-2"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <IoMdArrowBack className="text-[17px] " />
            </button>
            <button
              className="bg-backgroundV2 p-2 rounded-lg border border-[#d6d6d6]"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === pageCount}
            >
              <IoMdArrowForward className="text-[17px] " />
            </button>
          </div>
        </div>
      </div>
      {isImageModalOpen && (
        <div className="image-modal">
          <Modal
            isOpen={isImageModalOpen}
            onRequestClose={() => setIsImageModalOpen(false)}
            contentLabel="Custom Modal"
            className="image-modal"
            // style={customStyles}
            ariaHideApp={false}
          >
            <>
              <div className="MODAL-BODY flex flex-col h-full">
                <button className="w-full text-right" onClick={() => closeImageModal()}>
                  <AiOutlineClose className="ml-auto" />
                </button>
                <img className="h-full w-full object-contain" src={selectedImage} alt="" />
              </div>
            </>
          </Modal>
        </div>
      )}
      {isVideoModalOpen && (
        <div className="video-modal">
          <Modal
            isOpen={isVideoModalOpen}
            onRequestClose={() => setIsVideoModalOpen(false)}
            contentLabel="Custom Modal"
            className="image-modal"
            // style={customStyles}
            ariaHideApp={false}
          >
            <>
              <div className="MODAL-BODY flex flex-col h-full">
                <button className="w-full text-right" onClick={() => closeVideoModal()}>
                  <AiOutlineClose className="ml-auto" />
                </button>
                <video
                  className="h-full w-full object-contain"
                  src={selectedVideo}
                  alt=""
                  controls
                  autoPlay
                />
              </div>
            </>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default DynamicTable;

import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import ReactModal from "react-modal";
import moment from "moment/moment";

import {
  useReadAdminCommOrdersMutation,
  useReadDentistCommOrdersMutation,
  useReadSPCommOrdersMutation,
  useSaveCommOrdersMutation,
} from "../../services/orders";

import { VscSend } from "react-icons/vsc";
import { AiOutlineClose } from "react-icons/ai";
import { GrFormAttachment } from "react-icons/gr";
import { MdOutlineChat } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";

const OrderCommunication = ({ isOpen, onClose, orderObjId, orderDetails }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const messageInputRef = useRef(null);

  const userRole = localStorage.getItem("crowdHireUserRole");

  console.log("userProfileDetails", userProfileDetails);
  console.log("orderDetails", orderDetails);
  console.log("userRole", userRole);

  const [chats, setChats] = useState([]);

  const [saveCommOrders] = useSaveCommOrdersMutation();
  const [readAdminCommOrders] = useReadAdminCommOrdersMutation();
  const [readSPCommOrders] = useReadSPCommOrdersMutation();
  const [readDentistCommOrders] = useReadDentistCommOrdersMutation();

  const [currentMessage, setCurrentMessage] = useState("");
  const [replyDetails, setReplyDetails] = useState(null);
  const [communicatingTo, setCommunicatingTo] = useState(
    userRole === "89uij7" || userRole === "676dza2"
      ? "serviceprovider"
      : userRole === "34fs3"
      ? "dentist"
      : "admin"
  );

  const readOrderCommunications = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    let resp = null;
    if (userRole === "89uij7") {
      resp = await readAdminCommOrders({ accessToken, orderObjId: orderObjId }).unwrap();
    } else if (userRole === "34fs3") {
      resp = await readSPCommOrders({ accessToken, orderObjId: orderObjId }).unwrap();
    } else if (userRole === "676dza2") {
      resp = await readDentistCommOrders({ accessToken, orderObjId: orderObjId }).unwrap();
    }

    console.log("communication resp", resp);

    setChats(resp?.data);
  };

  useEffect(() => {
    if (orderObjId) readOrderCommunications();
  }, [orderObjId]);

  const handleMessageSend = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let typeofComm;
    let touserAuthId;
    if (userRole === "89uij7" && communicatingTo === "serviceprovider") {
      typeofComm = 1;
      touserAuthId = orderDetails?.serviceproviderdetails?.[0]?.spuserAuthId;
    } else if (userRole === "89uij7" && communicatingTo === "dentist") {
      typeofComm = 2;
      touserAuthId = orderDetails?.dtuserAuthId;
    } else if (userRole === "34fs3" && communicatingTo === "admin") {
      typeofComm = 3;
      touserAuthId = orderDetails?.serviceproviderdetails?.[0]?.userAuthId;
    } else if (userRole === "34fs3" && communicatingTo === "dentist") {
      typeofComm = 4;
      touserAuthId = orderDetails?.dtuserAuthId;
    } else if (userRole === "676dza2" && communicatingTo === "admin") {
      typeofComm = 5;
      touserAuthId = orderDetails?.serviceproviderdetails?.[0]?.userAuthId;
    } else if (userRole === "676dza2" && communicatingTo === "serviceprovider") {
      typeofComm = 6;
      touserAuthId = orderDetails?.serviceproviderdetails?.[0]?.spuserAuthId;
    }

    const payload = {
      orderObjId: orderObjId,
      messagetext: messageInputRef.current.value,
      assetUrl: "",
      typeofComm: typeofComm,
      fromuserAuthId: userProfileDetails?.userAuthId,
      touserAuthId: touserAuthId,
    };
    if (orderDetails?.serviceproviderdetails?.[0]?.spuserAuthId) {
      payload.spuserAuthId = orderDetails?.serviceproviderdetails?.[0]?.spuserAuthId;
    }
    if (replyDetails?._id) {
      payload.parentObjId = replyDetails?.messageObjId;
    }
    const resp = await saveCommOrders({
      accessToken,
      data: payload,
    });

    messageInputRef.current.value = "";
    setReplyDetails("");

    console.log(resp);

    readOrderCommunications();
  };

  console.log('spuserAuthId', orderDetails?.serviceproviderdetails?.[0]?.spuserAuthId)
  console.log('spuserAuthId orderDetails', orderDetails)

  return (
    <>
      {!isOpen && (
        <button
          className="fixed bottom-5 right-5 rounded-full w-12 aspect-square flex items-center justify-center drop-shadow-lg bg-primary z-50"
          onClick={() => onClose(true)}
        >
          <MdOutlineChat className="text-white text-2xl" />
        </button>
      )}
      <ReactModal
        isOpen={isOpen}
        onRequestClose={() => onClose(false)}
        contentLabel="Custom Modal"
        className="custom-modal !p-0 !w-4/12"
        ariaHideApp={false}
      >
        <div className="MODAL-BODY h-full z-[1000] relative">
          <div className="h-full flex flex-col text-white">
            <div className="h-fit bg-background p-3">
              <button className="w-full text-right" onClick={() => onClose(false)}>
                <AiOutlineClose className="ml-auto" />
              </button>
            </div>

            <ul className="flex-1 flex flex-col p-5 overflow-y-auto">
              {chats?.map(
                (item, index) =>
                  item?.fromuserAuthId?.[0] && (
                    <li
                      key={"chat-item" + index}
                      className={`flex w-full flex-col ${
                        item?.fromuserAuthId?.[0] === userProfileDetails?.userAuthId
                          ? "items-end"
                          : "items-start"
                      } ${
                        chats?.[index - 1]?.fromuserAuthId?.[0] === item?.fromuserAuthId?.[0]
                          ? "pt-1"
                          : "pt-2"
                      }`}
                    >
                      {moment(item?.lastUpdatedDate)?.format("DD/MM/YYYY") !==
                        moment(chats?.[index - 1]?.lastUpdatedDate)?.format("DD/MM/YYYY") && (
                        <div className="w-full flex items-center justify-center text-xs py-2.5">
                          <span className="bg-white/10 rounded-md px-2 py-1">
                            {moment(item?.lastUpdatedDate)?.format("DD/MM/YYYY")}
                          </span>
                        </div>
                      )}
                      <div
                        className={`w-fit rounded-lg px-1.5 py-1.5 text-sm max-w-[60%] relative flex flex-col ${
                          chats?.[index - 1]?.fromuserAuthId?.[0] === item?.fromuserAuthId?.[0]
                            ? ""
                            : item?.fromuserAuthId?.[0] === userProfileDetails?.userAuthId
                            ? "rounded-se-none"
                            : "rounded-ss-none"
                        } ${
                          item?.fromuserAuthId?.[0] === userProfileDetails?.userAuthId
                            ? "bg-primary"
                            : "bg-backgroundV3"
                        }`}
                        onDoubleClick={() => {
                          setReplyDetails(item);
                          messageInputRef.current.focus();
                        }}
                      >
                        {chats?.[index - 1]?.fromuserAuthId?.[0] !== item?.fromuserAuthId?.[0] && (
                          <p className="text-xs font-bold">
                            {item?.fromuserAuthId?.[0] === userProfileDetails?.userAuthId
                              ? "You"
                              : `${item?.fromFirstName?.[0] ?? ""} ${item?.fromLastName?.[0] ?? ""}`}
                          </p>
                        )}
                        {item?.parentObjId && (
                          <div className="flex items-center justify-between bg-white/30 pr-2 rounded-md overflow-hidden max-w-full my-1">
                            <div
                              className={`flex-1 !max-w-full flex flex-col text-sm py-1.5 pl-2 pr-4 border-l-4 ${
                                item?.parentfromuserAuthId?.[0] === userProfileDetails?.userAuthId
                                  ? "border-backgroundV3"
                                  : "border-primary"
                              }`}
                            >
                              <p className="text-xs font-semibold w-full">
                                {item?.parentfromuserAuthId?.[0] === userProfileDetails?.userAuthId
                                  ? "You"
                                  : `${item?.parentFirstname?.[0] ?? ""} ${item?.parentLastname?.[0] ?? ""}`}
                              </p>
                              <p className="text-xs whitespace-nowrap overflow-ellipsis overflow-hidden text-nowrap break">
                                {item?.parentMessage}
                              </p>
                            </div>
                          </div>
                        )}
                        <p className="pr-3">{item?.messagetext}</p>
                        <span
                          className={`border-[6px] border-x-transparent border-b-transparent absolute top-0 ${
                            item?.fromuserAuthId?.[0] === userProfileDetails?.userAuthId
                              ? "right-0 translate-x-1/2 border-primary"
                              : "left-0 -translate-x-1/2 border-backgroundV3"
                          } ${
                            chats?.[index - 1]?.fromuserAuthId?.[0] === item?.fromuserAuthId?.[0]
                              ? "hidden"
                              : ""
                          }`}
                        ></span>
                        <p className="flex items-center justify-end text-xxs text-white/80">
                          {moment(item?.lastUpdatedDate)?.format("HH:MM")}
                        </p>
                      </div>
                    </li>
                  )
              )}
            </ul>
            <div className="w-full bg-secondary flex flex-col px-2 py-1.5 gap-1 text-sm">
              {replyDetails && (
                <div className="flex items-center justify-between bg-white/10 pr-2 rounded-md overflow-hidden max-w-full">
                  <div className="flex-1 !max-w-[90%] flex flex-col text-sm border-l-4 border-primary py-1.5 pl-2 pr-4">
                    <p className="text-xs font-semibold w-full">
                      {replyDetails?.fromuserAuthId?.[0] === userProfileDetails?.userAuthId
                        ? "You"
                        : replyDetails?.userName}
                    </p>
                    <p className="text-xs whitespace-nowrap overflow-ellipsis overflow-hidden text-nowrap break">
                      {replyDetails?.messagetext}
                    </p>
                  </div>
                  <IoIosCloseCircleOutline
                    className="text-2xl cursor-pointer"
                    onClick={() => setReplyDetails(null)}
                  />
                </div>
              )}
              <div className="w-full flex items-center gap-2 px-1">
                <GrFormAttachment className="text-3xl text-white" />
                <select
                  className="bg-backgroundV2 h-fit py-2 px-0.5"
                  value={communicatingTo}
                  onChange={(e) => setCommunicatingTo(e.target.value)}
                >
                  {userRole !== "676dza2" && <option value="dentist">Dentist</option>}
                  {userRole !== "34fs3" &&
                    orderDetails?.serviceproviderdetails?.[0]?.spuserAuthId && (
                      <option value="serviceprovider">Service Provider</option>
                    )}
                  {userRole !== "89uij7" && <option value="admin">Admin</option>}
                </select>
                <input
                  type="text"
                  placeholder="Type a message"
                  className="flex-1 py-3 bg-transparent outline-none"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleMessageSend();
                      return;
                    }
                  }}
                  ref={messageInputRef}
                />
                {currentMessage?.length > 0 && (
                  <VscSend
                    className="text-xl text-white cursor-pointer"
                    onClick={handleMessageSend}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default OrderCommunication;

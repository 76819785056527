import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    getProfile: builder.mutation({
      query: (accessToken) => {
        return {
          url: "profile",
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateProfilePicture: builder.mutation({
      query: ({ accessToken, picture }) => {
        return {
          url: "/user/updateProfilePic",
          method: "POST",
          body: { picture },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateRole: builder.mutation({
      query: ({ accessToken, r }) => {
        // const formData = new FormData();
        // formData.append('r', r);
        var formData = {
          r: r,
        };
        return {
          url: "updateUserRole",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    savePersonalDetailsSp: builder.mutation({
      query: ({
        accessToken,
        firstName,
        timeZone,
        lastName,
        gender,
        mobile,
        email,
        alertsForMobile,
        alertsForEmail,
        alreadyWorkingWithDentalLab,
        dentalDesignServiceProvider,
        tncUrl,
        isTncChecked,
        active,
      }) => {
        var formData = {
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
          email: email,
          gender: gender,
          alertsForMobile: alertsForMobile,
          alertsForEmail: alertsForEmail,
          timeZone: timeZone,
          isTncChecked: isTncChecked,
          alreadyWorkingWithDentalLab: alreadyWorkingWithDentalLab === "Yes" ? true : false,
          dentalDesignServiceProvider: dentalDesignServiceProvider === "Yes" ? true : false,
          termsAndConditionUrl: tncUrl,
          active: active,
        };
        console.log(formData, "PINFO");
        return {
          url: "personalDetails/savesp",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    savePersonalDetailsDt: builder.mutation({
      query: ({
        accessToken,
        firstName,
        lastName,
        mobile,
        email,
        alertsForMobile,
        alertsForEmail,
        registrationNumber,
        timeZone,
        active,
        gender,
      }) => {
        var formData = {
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
          email: email,
          alertsForMobile: alertsForMobile,
          alertsForEmail: alertsForEmail,
          registrationNumber: registrationNumber,
          active: active,
          timeZone: timeZone,
          gender: gender,
        };
        console.log(formData, "PINFO");
        return {
          url: "personalDetails/savedt",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    readPersonalDetails: builder.mutation({
      query: ({ accessToken, firstName, _id }) => {
        var formData = {
          firstName: firstName,
          _id: _id,
        };
        return {
          url: "personalDetails/read",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updatePersonalDetails: builder.mutation({
      query: ({
        accessToken,
        firstName,
        lastName,
        mobile,
        gender,
        registrationNumber,
        _id,
        userAuthId,
        alreadyWorkingWithDentalLab,
        dentalDesignServiceProvider,
        timeZone,
        alertsForMobile,
        alertsForEmail,
        tncUrl,
        isTncChecked,
        active,
      }) => {
        var formData = {
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
          gender: gender,
          registrationNumber: registrationNumber,
          alreadyWorkingWithDentalLab: alreadyWorkingWithDentalLab === "Yes" ? true : false,
          dentalDesignServiceProvider: dentalDesignServiceProvider === "Yes" ? true : false,
          timeZone: timeZone,
          _id: _id,
          userAuthId: userAuthId,
          alertsForMobile: alertsForMobile,
          alertsForEmail: alertsForEmail,
          tncUrl: tncUrl,
          isTncChecked: isTncChecked,
          active: active,
        };
        return {
          url: "personalDetails/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateUserCategories: builder.mutation({
      query: ({ accessToken, categoryObjId, userAuthId, _id }) => {
        var formData = {
          categoryObjId: categoryObjId,
          userAuthId: userAuthId,
          _id: _id,
        };
        return {
          url: "personalDetails/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateUserActiveStatus: builder.mutation({
      query: ({ accessToken, active, _id, userAuthId }) => {
        var formData = {
          active: active,
          userAuthId: userAuthId,
          _id: _id,
        };
        return {
          url: "personalDetails/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateAddressDetails: builder.mutation({
      query: ({
        accessToken,
        address,
        latitude,
        longitude,
        country,
        pincode,
        ownPhysicalLaboratory,
        labAddress,
        labCountry,
        labPostalCode,
        labLatitude,
        labLongitude,
        _id,
      }) => {
        var formData = {
          address: address,
          country: country,
          pincode: pincode,
          latitude: latitude,
          longitude: longitude,
          ownPhysicalLaboratory: ownPhysicalLaboratory === "Yes" ? true : false,
          labAddress: labAddress,
          labCountry: labCountry,
          labPostalCode: labPostalCode,
          labLatitude: labLatitude,
          labLongitude: labLongitude,
          _id: _id,
        };
        return {
          url: "addressDetails/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    saveAddressDetails: builder.mutation({
      query: ({
        accessToken,
        address,
        latitude,
        longitude,
        country,
        pincode,
        ownPhysicalLaboratory,
        labAddress,
        labCountry,
        labPostalCode,
        labLatitude,
        labLongitude,
      }) => {
        var formData = {
          address: address,
          latitude: latitude,
          longitude: longitude,
          country: country,
          pincode: pincode,
          ownPhysicalLaboratory: ownPhysicalLaboratory === "Yes" ? true : false,
          labAddress: labAddress,
          labCountry: labCountry,
          labPostalCode: labPostalCode,
          labLatitude: labLatitude,
          labLongitude: labLongitude,
        };
        console.log(formData, "AINFO");
        return {
          url: "addressDetails/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    saveClinicalDetails: builder.mutation({
      query: ({ accessToken, regNumber, regAuthority }) => {
        var formData = {
          regNumber: regNumber,
          regAuthority: regAuthority,
        };
        console.log(formData, "CINFO");
        return {
          url: "clinicalDetails/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateClinicalDetails: builder.mutation({
      query: ({ accessToken, regName, regNumber, regAuthority, _id }) => {
        var formData = {
          regName: regName,
          regNumber: regNumber,
          regAuthority: regAuthority,
          _id: _id,
        };
        return {
          url: "clinicalDetails/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteClinicalDetails: builder.mutation({
      query: ({ accessToken, _id }) => {
        var formData = {
          _id: _id,
        };
        return {
          url: "userService/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    saveBankDetails: builder.mutation({
      query: ({
        accessToken,
        bankName,
        accountNo,
        accountHolderName,
        swiftCode,
        bankAddress,
        iBANNumber,
        currency,
      }) => {
        var formData = {
          bankName: bankName,
          accountNo: accountNo,
          accountHolderName: accountHolderName,
          swiftCode: swiftCode,
          bankAddress: bankAddress,
          iBANNumber: iBANNumber,
          currency: currency,
        };
        console.log(formData, "BINFO");
        return {
          url: "bankDetails/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateBankDetails: builder.mutation({
      query: ({
        accessToken,
        bankName,
        accountNo,
        accountHolderName,
        swiftCode,
        bankAddress,
        iBANNumber,
        currency,
        userAuthId,
        _id,
      }) => {
        var formData = {
          bankName: bankName,
          accountNo: accountNo,
          accountHolderName: accountHolderName,
          swiftCode: swiftCode,
          bankAddress: bankAddress,
          iBANNumber: iBANNumber,
          currency: currency,
          userAuthId: userAuthId,
          _id: _id,
        };
        return {
          url: "bankDetails/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    readServices: builder.mutation({
      query: (accessToken) => {
        return {
          url: "services/read",
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    saveUserServices: builder.mutation({
      query: ({ accessToken, serviceObjId }) => {
        var formData = {
          serviceObjId: serviceObjId,
        };
        return {
          url: "userService/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    readServiceProviders: builder.mutation({
      query: (accessToken) => {
        return {
          url: "/serviceproviders",
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    readDentists: builder.mutation({
      query: (accessToken) => {
        return {
          url: "/dentists",
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetProfileMutation,
  useUpdateProfilePictureMutation,
  useUpdateRoleMutation,
  useSavePersonalDetailsSpMutation,
  useSavePersonalDetailsDtMutation,
  useSaveAddressDetailsMutation,
  useSaveClinicalDetailsMutation,
  useSaveBankDetailsMutation,
  useReadServicesMutation,
  useSaveUserServicesMutation,
  useReadPersonalDetailsMutation,
  useUpdatePersonalDetailsMutation,
  useUpdateUserCategoriesMutation,
  useUpdateUserActiveStatusMutation,
  useUpdateAddressDetailsMutation,
  useUpdateBankDetailsMutation,
  useUpdateClinicalDetailsMutation,
  useDeleteClinicalDetailsMutation,
  useReadServiceProvidersMutation,
  useReadDentistsMutation,
} = userApi;

import { useState, useRef } from "react";
import JSZip from "jszip";

import { AiOutlineArrowUp, AiOutlineCheck } from "react-icons/ai";

const ImageUploadV2 = ({
  placeholderImage,
  imageUrl,
  setImage,
  title,
  id,
  fileFor,
  fileType,
  orderObjId,
  acceptedFileTypes = "images/*",
  allowFolderUpload = false,
  showImagePreview = true,
  fileName = null,
  selected,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const fileInputRef = useRef(null);

  const isDisplayableFormat = (filename) => {
    const extension = filename?.split(".")?.pop();

    if (["png", "jpg", "jpeg", "webp", "bmp"].includes(extension)) {
      return true;
    }
    return false;
  };

  const handleImageUpload = async (event) => {
    // debugger
    let file = null;
    if (allowFolderUpload) {
      const files = event.target.files;
      console.log("files", files);
      const zip = new JSZip();
      console.log("zip", zip);
      [...files].forEach((file) => {
        zip.file(file.name, file);
      });
      console.log("zip with files added", zip);
      const content = await zip.generateAsync({ type: "blob" });
      console.log("zip blob content", content);
      const fileName =
        files?.[0]?.webkitRelativePath?.split("/")?.shift() + "-" + new Date().getTime() + ".zip";
      file = new File([content], fileName, {
        type: "application/zip",
        // lastModified: new Date().getTime(),
        lastModified: "Test",
      });
      console.log("zip file", file);
    } else {
      file = event.target.files[0];
    }

    setImage({ fileFor: fileFor, file: file });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="relative h-full w-full flex flex-col gap-2 items-center">
      <div
        className="image-container relative h-full w-full border rounded-md overflow-hidden"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered && (
          <div className="upload-button h-full absolute w-full top-0 left-0 backdrop-blur-[2px] backdrop-contrast-50 backdrop-brightness-50  cursor-pointer  z-20 rounded-md">
            <button
              className="absolute flex items-center gap-2  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-[12px] font-medium px-4 py-2 rounded-full border border-white"
              onClick={handleUploadButtonClick}
            >
              <AiOutlineArrowUp />
              <span>Upload</span>
            </button>
            <input
              type="file"
              accept={acceptedFileTypes}
              {...(allowFolderUpload
                ? { webkitdirectory: "", mozdirectory: "", directory: "", multiple: true }
                : {})}
              ref={fileInputRef}
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
          </div>
        )}
        {selected && (
          <div className="absolute top-2 right-2 z-50 bg-[#8ED259] h-5 w-5 rounded-full text-white font-medium flex items-center justify-center">
            <AiOutlineCheck className="text-xs" />
          </div>
        )}
        {showImagePreview && isDisplayableFormat(imageUrl) && imageUrl ? (
          <img
            src={imageUrl}
            alt="Uploaded"
            className="absolute top-0 z-10 h-full w-full object-cover overflow-hidden"
          />
        ) : (
          <div className="bg-black w-full h-full flex justify-center flex-col items-center">
            <img
              src={placeholderImage}
              alt="Uploaded"
              className="z-10 w-auto max-w-full object-contain overflow-hidden"
            />
            {((allowFolderUpload && imageUrl) || !isDisplayableFormat(imageUrl)) && (
              <p className="text-primary text-sm mb-1">{fileName}</p>
            )}
          </div>
        )}
      </div>
      {title && <div className="text-base">{title}</div>}
    </div>
  );
};

export default ImageUploadV2;

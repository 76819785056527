import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import bell from "../../Assets/images/bellIcon.svg";
import hut from "../../Assets/images/hut.svg";
import { IoIosArrowForward, IoIosClose } from "react-icons/io";
import circle from "../../Assets/images/circle.png";
import Modal from "react-modal";
import video from "../../Assets/videos/video.mp4";
import DynamicTable from "../Services-Comps/DynamicTable";
import InputFieldsServicesTab from "../Services-Comps/InputFieldsServicesTab";
import { LoginButton } from "../../Screens/Login/Login";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import { Tooltip } from "react-tooltip";
import Notification from "../Notification/Notification";
import { useSelector } from "react-redux";
const headersForServicesTab = [
  { headerName: "Name", headerSort: true },
  { headerName: "Age", headerSort: false },
  { headerName: "Salary", headerSort: true },
  { headerName: "Images", headerSort: false },
  { headerName: "Videos", headerSort: false },
];

const data = [
  {
    image: circle,
    Name: "John Doe",
    Age: 25,
    Salary: 50000,
    images: [hut, "image2.jpg", "image3.jpg", "image4.jpg"],
    videos: [video, "video2.mp4", "video3.mp4"],
  },
  {
    image: circle,
    Name: "Jane Smith",
    Age: 30,
    Salary: 60000,
    images: ["image1.jpg", "image2.jpg", "image3.jpg", "image4.jpg"],
    videos: ["video1.mp4", "video2.mp4", "video3.mp4"],
  },
  {
    image: circle,
    Name: "Bob Johnson",
    Age: 35,
    Salary: 70000,
    images: ["image1.jpg", "image2.jpg", "image3.jpg", "image4.jpg"],
    videos: ["video1.mp4", "video2.mp4", "video3.mp4"],
  },
  // Add more data rows here as needed
];
export const TestominalsAndRatingsTab = ({ userInfo, userRole }) => {
  // modal state and styles
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditCategoryModal, setIsEditCategoryModal] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const closeModal = () => {
    setModalIsOpen(false);
    setIsEditCategoryModal(false);
  };

  return (
    <>
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">
            Testominals & Ratings <IoIosArrowForward />{" "}
            <span className="font-medium text-[15px] text-primary"> Testominals & Ratings</span>
          </span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="HEADING flex md:justify-between items-center gap-4 mb-4 mt-2">
        <p className=" font-medium text-[34px] leading-7 md:mr-8">Services</p>
        <button
          onClick={() => setModalIsOpen(true)}
          className="bg-[#FF7E00] px-2 py-4 text-white rounded-lg text-[16px] leading-4 font-medium"
        >
          + {"   "} Add Services
        </button>
      </div>
      <div className="bg-white p-4 rounded-md md:h-[80vh] h-[70vh]">
        <DynamicTable headers={headersForServicesTab} data={data} includeImgVideoButton={true} />
      </div>

      {/* modal here  */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full" onClick={closeModal}>
              <IoIosClose className="ml-auto text-[32px]" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-8">
              {isEditCategoryModal ? "Edit Categories" : "Add Categories"}
            </p>
            <div className="MODAL-TAB-SECTION h-3/5 md:flex-1">
              <InputFieldsServicesTab closeModal={closeModal} />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import placeHolderImage from "../../Assets/images/image.png";
import { useDispatch } from "react-redux";
import { setServiceProviderServicesOffered } from "../../reducers/registrationReducer";

const AccordionItem = ({
  index,
  title,
  icon,
  content,
  onSelectItem,
  allItems,
  isOpen,
  onToggleAccordion,
  id,
}) => {
  // const inputRef = useRef(null);
  // useEffect(() => {
  //   let changeLength = content.filter((d) => allItems.includes(d.serviceObjId))?.length;
  //   if (changeLength > 0 && changeLength < content?.length) {
  //     inputRef.current.indeterminate = true;
  //   } else {
  //     inputRef.current.indeterminate = false;
  //   }
  // }, [allItems, content]);
  return (
    <div
      className={`accordion-item bg-[#2f2f2f8d] ${
        isOpen ? "border-white-500" : "border-none"
      } p-4 transition-all border rounded-lg mb-2 text-white`}
    >
      <div
        className={`accordion-header ${
          isOpen ? "border-b" : ""
        } py-2 flex items-center justify-between`}
      >
        <div className="flex items-center w-full gap-0">
          {/* <input
            type="checkbox"
            checked={content?.every((d) => allItems.includes(d.serviceObjId))}
            onChange={() => onSelectItem({ id, type: "service" })}
            ref={inputRef}
            className="mr-4"
          /> */}
          <button
            onClick={() => {
              return content.length > 0 ? onToggleAccordion(index) : () => {};
            }}
            className="accordion-title text-[17px] text-white font-medium w-full text-left flex items-center"
          >
            <img
              src={icon ? icon : placeHolderImage}
              className="h-10 w-10 object-cover rounded-full mr-2 bg-white"
              alt={title}
            />
            {title}
          </button>
        </div>
        {content.length > 0 ? <>{isOpen ? <FaAngleDown /> : <FaAngleRight />}</> : null}
      </div>
      {isOpen && (
        <div className="accordion-content my-4 text-[16px] ml-5">
          {content?.map((item) => (
            <div key={item.serviceObjId} className="mt-2 flex items-center">
              <input
                type="checkbox"
                checked={allItems.includes(item.serviceObjId)}
                onChange={() =>
                  onSelectItem({ id: item.serviceObjId, type: "subservice", serviceId: id })
                }
                className="mr-4"
              />
              <img
                src={item?.serviceIcon ?? placeHolderImage}
                className="mr-2 h-5 w-5 object-cover rounded-full bg-white"
                alt={item?.serviceName}
              />

              <span>{item.serviceName}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const AccordionV2 = ({ items, onSelectionChange, allItems, setAllItems }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const dispatch = useDispatch();

  console.log("items", items);
  console.log("allItems", allItems);

  const handleSelectAll = async (event) => {
    const selectedItems = [];
    const promiseArray = [];
    items.forEach(async (item) => {
      if (item?.catservices && item?.catservices?.length > 0) {
        selectedItems.push(item.id);
        promiseArray.push(onSelectionChange({ id: item.id, type: "service" }));
        item.catservices.forEach((subitem) => {
          selectedItems.push(subitem.serviceObjId);
        });
      }
    });
    await Promise.all(promiseArray);
    if (event.target.checked) {
      setAllItems(selectedItems);
      dispatch(setServiceProviderServicesOffered(selectedItems));
    } else {
      setAllItems([]);
      dispatch(setServiceProviderServicesOffered([]));
    }
  };

  const handleSelectItem = ({ id, type, serviceId }) => {
    onSelectionChange({ id, type, serviceId });
  };

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  console.log("items123", items);

  return (
    <div className="accordion">
      <label className="mb-6 flex justify-end items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          className="form-checkbox"
          onChange={(event) => handleSelectAll(event)}
        />
        <span className="text-white">Select All</span>
      </label>
      {items.map(
        (item, index) =>
          item?.catservices?.length > 0 && (
            <AccordionItem
              key={item.id}
              icon={item.icon}
              index={index}
              title={item.title}
              id={item.id}
              content={item?.catservices || []}
              allItems={allItems}
              onSelectItem={handleSelectItem}
              isOpen={openIndex === index}
              onToggleAccordion={toggleAccordion}
            />
          )
      )}
    </div>
  );
};

export default AccordionV2;

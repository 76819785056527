const RemovablesOrderSummary = ({ orderData, selectedServiceCategory }) => {
  return (
    <>
      {" "}
      {orderData?.caseDetails?.services?.map((service) => {
        const serviceDetails = selectedServiceCategory?.catservices?.find(
          (d) => d?.serviceObjId === service?.serviceObjId
        );
        if (serviceDetails?.serviceObjId) {
          return (
            <div
              key={"serviceItem-" + serviceDetails?.serviceObjId}
              className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 rounded-xl bg-backgroundV2 p-6 my-4"
            >
              <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-primary">
                <p className="text-[15px] text-white font-semibold">Service Name</p>
                <p className="text-primary font-medium mt-2 uppercase">
                  {serviceDetails?.serviceName}
                </p>
              </div>
              {["Nightguards"].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    What arch would you like a nightguard on?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.archNightguardOn || "NA"}
                  </p>
                </div>
              )}
              {["Custom impression tray", "Custom implant impression tray"].includes(
                serviceDetails?.serviceName
              ) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    What arch would you like an impression tray designed for?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.impressionTrayFor || "NA"}
                  </p>
                </div>
              )}
              {["Custom impression tray", "Custom implant impression tray"]?.includes(
                serviceDetails?.serviceName
              ) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Please select any teeth that need to be extracted
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.teethNeedToExtract?.join(", ") || "NA"}
                  </p>
                </div>
              )}
              {["Custom implant impression tray"].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Please select all implant sites</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.implantSite?.join(", ") || "NA"}
                  </p>
                </div>
              )}
              {["Custom impression tray", "Custom implant impression tray"].includes(
                serviceDetails?.serviceName
              ) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Would you like a handle?</p>
                    <p className="text-[#555] font-medium mt-2">{service?.needHandle || "NA"}</p>
                  </div>
                  {service?.needHandle === "Yes" && (
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">Number of handles desired?</p>
                      <p className="text-[#555] font-medium mt-2">
                        {service?.handlesDesired || "NA"}
                      </p>
                    </div>
                  )}
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Would you like holes in your{" "}
                      {serviceDetails?.serviceName === "Custom implant impression tray"
                        ? "implant"
                        : ""}{" "}
                      impression tray for impression material adhesion?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.holesInImpressionTray || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      How much spacer would you like for your impression material? (µ microns)
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.impressionMaterialHoles || "NA"}
                    </p>
                  </div>
                </>
              )}
              {["Splints", "Deprogrammers"].includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      What {serviceDetails?.serviceName === "Splints" ? "splint" : "deprogrammer"}{" "}
                      design do you prefer?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.[
                        serviceDetails?.serviceName === "Splints"
                          ? "preferredSplintDesign"
                          : "preferredDeprogrammerDesign"
                      ] || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      What arch would you like a{" "}
                      {serviceDetails?.serviceName === "Splints" ? "splint" : "deprogrammer"} on?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.[
                        serviceDetails?.serviceName === "Splints"
                          ? "archSplintOn"
                          : "archDeprorammerOn"
                      ] || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Please select all teeth that you would like your{" "}
                      {serviceDetails?.serviceName === "Splints" ? "splint" : "deprogrammer"} to
                      extend to?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.[
                        serviceDetails?.serviceName === "Splints"
                          ? "splintExtendToTeeth"
                          : "deprogrammerTeeth"
                      ]?.join(", ") || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      How much thickness would you like for your $
                      {serviceDetails?.serviceName === "Splints (each arch if applicable)"
                        ? "splint"
                        : "deprogrammer"}
                      ?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.[
                        serviceDetails?.serviceName === "Splints (each arch if applicable)"
                          ? "splintThickness"
                          : "deprogrammerThickness"
                      ] || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Will you be printing or milling your{" "}
                      {serviceDetails?.serviceName === "Splints" ? "splint" : "deprogrammer"}?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.[
                        serviceDetails?.serviceName === "Splints"
                          ? "splintProcessing"
                          : "dentureBaseProcessing"
                      ] || "NA"}
                    </p>
                  </div>
                </>
              )}
              {serviceDetails?.serviceName === "Gingival recontouring guide" && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      What arch would you like a soft tissue recontouring reduction guide for?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.softTissueReductionGuideFor || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      If you would also like a hard tissue recontouring reduction guide, what arch
                      would you like the guide on?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.hardTissueReductionGuideFor || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Please select all teeth that you would like your recontouring guides to extend
                      to?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.recontouringGuidesTeeth?.join(", ") || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      If selecting a hard tissue recontouring guide as well, how much distance would
                      you like between the final level of soft tissue and the final level of the
                      hard tissue?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.finalLevelDistance || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Will you be printing or milling your recontouring reduction guide?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.recontouringGuideProcessing || "NA"}
                    </p>
                  </div>
                </>
              )}
              {serviceDetails?.serviceName === "Upper and/or lower dentures (resin only) " && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Are upper and/or lower intraoral scans being provided?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.intraoralScansFor || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Are upper and/or lower impressions being provided for digital inversion?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.digitalInversionFor || "NA"}
                    </p>
                  </div>
                </>
              )}
              {[
                "Upper and/or lower dentures (resin only) ",
                "Upper or Lower Partial dentures (resin)",
                "Partial dentures (metal)",
              ]?.includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Is a bite registration base desired?</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.biteRegistrationDesired || "NA"}
                  </p>
                </div>
              )}
              {["Partial dentures (metal)"]?.includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Will you be metal printing or casting or milling your denture base?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.dentureBaseProcessing || "NA"}
                  </p>
                </div>
              )}
              {[
                "Upper and/or lower dentures (resin only) ",
                "Upper or Lower Partial dentures (resin)",
              ]?.includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Will you be printing or milling your denture base?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.dentureBaseProcessing || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Will you be printing or milling your teeth?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.teethProcessing || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Is a monobloc print desired for a printed try in?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.monoblocPrintDesired || "NA"}
                    </p>
                  </div>
                </>
              )}
              {[
                "Upper and/or lower dentures (resin only) ",
                "Upper or Lower Partial dentures (resin)",
                "Partial dentures (metal)",
              ]?.includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Please indicate all teeth you would like to see in your{" "}
                    {serviceDetails?.serviceName === "Upper or Lower Partial dentures (resin)"
                      ? "partial "
                      : ""}
                    denture set up?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.[
                      serviceDetails?.serviceName === "Upper or Lower Partial dentures (resin)"
                        ? "partialDentureSetupTeeth"
                        : "dentureSetupTeeth"
                    ]?.join(", ") || "NA"}
                  </p>
                </div>
              )}
              {["Upper or Lower Partial dentures (resin)", "Partial dentures (metal)"]?.includes(
                serviceDetails?.serviceName
              ) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Please indicate teeth that you DO NOT want to clasp in your design?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.noClaspDesignTeeth?.join(", ") || "NA"}
                  </p>
                </div>
              )}
              {["Partial dentures (metal)"]?.includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Please indicate the type of major connector you prefer for your design?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.majorConnector?.join(", ") || "NA"}
                  </p>
                </div>
              )}
              {[
                "Upper and/or lower dentures (resin only) ",
                "Upper or Lower Partial dentures (resin)",
                "Partial dentures (metal)",
                "Nightguards for restorative protection",
              ]?.includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    How much thickness would you like for your $
                    {serviceDetails?.serviceName === "Nightguards"
                      ? "nightguard"
                      : serviceDetails?.serviceName === "Partial dentures (metal)"
                      ? " partial framework"
                      : serviceDetails?.serviceName === "Upper or Lower Partial dentures (resin)"
                      ? "partial denture"
                      : "denture"}{" "}
                    (s)? (mm)
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.dentureThickness || "NA"}
                  </p>
                </div>
              )}
              {["Nightguards"]?.includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Will you be printing or milling your nightguard?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.nightguardProcessing || "NA"}
                  </p>
                </div>
              )}
              {serviceDetails?.serviceName === "Upper and/or lower dentures (resin only) " && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Is a copy denture workflow desired?</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.copyDentureDesired || "NA"}
                  </p>
                </div>
              )}
              {[
                "Upper and/or lower dentures (resin only) ",
                "Upper or Lower Partial dentures (resin)",
                "Partial dentures (metal)",
              ].includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">What occlusion set up would you like?</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.occlusionSetup || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Tooth mould shape</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.toothMouldShape || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Teeth setup style?</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.teethSetupStyle || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Teeth Size</p>
                    <p className="text-[#555] font-medium mt-2">{service?.teethSize || "NA"}</p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Is a crossbite to be created?</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.crossbiteCreated || "NA"}
                    </p>
                  </div>
                </>
              )}
              {serviceDetails?.serviceName === "Upper and/or lower dentures (resin only) " && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Are you creating dentures for a photogrammetric scan for vertical dimension and
                    bite relationship for implants?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.dentureForPhotoScan || "NA"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Preferred design software?</p>
                <p className="text-[#555] font-medium mt-2">{service?.preferredSoftware || "NA"}</p>
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default RemovablesOrderSummary;

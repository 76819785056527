import { useState } from "react";
import logo from "../../Assets/images/digitalLogo.png";
import navBg from "../../Assets/images/NewUnion.svg";
import navBg2 from "../../Assets/images/Union2.svg";
import bottomNavBg from "../../Assets/images/bottomNavBg.svg";
import people from "../../Assets/images/People.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { useAuth0 } from "@auth0/auth0-react";
import logoutIcon from "../../Assets/images/logout.svg";
import { MdHomeRepairService, MdOutlineMiscellaneousServices } from "react-icons/md";
import { FaNetworkWired, FaUserDoctor } from "react-icons/fa6";
import { BsPersonWorkspace } from "react-icons/bs";
import Icon3 from "../../Assets/images/icon3.svg";
import { useSelector } from "react-redux";
import { SiJirasoftware } from "react-icons/si";
import { BsQuestionSquareFill } from "react-icons/bs";
import { FaRegFileAlt } from "react-icons/fa";

function Navbar({
  iconArray,
  navHoverCss,
  navActiveCss,
  middleSection,
  middleNavHeading,
  middleNavPosition,
  middleTabTitle,
  middleTabTitleArray,
  middleTabContentObject,
  userRole,
  active,
}) {
  const { userProfilePicture } = useSelector((state) => state.personalDetails);

  const { isAuthenticated, logout, user } = useAuth0();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };
  const [middleNavActive, setMiddleNavActive] = useState(true);
  //  Functions to handle Tab Switching
  const [activeTab, setActiveTab] = useState(middleTabTitleArray[middleNavPosition]);

  const handleTabSuper = (tabName) => {
    //setActiveTab(tabName);
    const removeSpaceString = tabName.replace(/\s+/g, "");
    navigate(`/${removeSpaceString.toLowerCase()}`);
    // closeMiddleNav();
  };
  const closeMiddleNav = () => {
    setMiddleNavActive((prev) => !prev);
  };

  return (
    <>
      <div className="DESKTOP-SIDE-NAV flex-shrink-0 hidden md:flex md:w-20  py-4 flex-col justify-between items-center relative h-screen bg-background">
        <Link to={"/dashboard"}>
          <img src={logo} className="mx-auto w-[60px]" alt="" />
        </Link>
        <div
          style={{
            backgroundImage: userRole === "89uij7" ? `url(${navBg2})` : `url(${navBg})`,
            backgroundRepeat: "cover",
            backgroundSize: userRole === "89uij7" ? "200% 105%" : "100% 100%",
            backgroundPosition: "center",
            height: userRole === "89uij7" ? "100%" : "fit-content",
          }}
          className="w-full"
        >
          <ul
            className={
              userRole === "89uij7"
                ? "NAVBAR relative flex gap-4 flex-col items-center justify-center h-full md:gap-10 py-8 min-h-[500px]"
                : "NAVBAR relative flex gap-4 flex-col items-center justify-center h-full md:gap-12 py-8 min-h-[500px]"
            }
          >
            {iconArray.map((item, i) => (
              <NavLink
                key={item.route}
                to={item.route}
                className={({ isActive, isPending }) =>
                  isPending ? "" : isActive ? navActiveCss : ""
                }
              >
                <li className={`relative ${navHoverCss} cursor-pointer`}>
                  <img
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={item.title}
                    src={active === item.title ? item.hover : item.icon}
                    className="object-contain h-5 w-5"
                    alt=""
                  />
                </li>
              </NavLink>
            ))}
            {isAuthenticated && (
              <li
                className={`relative ${navHoverCss} cursor-pointer pointer-events-auto`}
                onClick={() => handleLogout()}
              >
                <img
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={"Logout"}
                  className="cursor-pointer"
                  src={logoutIcon}
                  alt=""
                />
              </li>
            )}
          </ul>
          <Tooltip className="z-50" id="my-tooltip" place="right" />
        </div>
        <div>
          <div className="rounded-full bg-gray-600  overflow-hidden object-fill">
            {/* {(userRole === "89uij7" || userRole === '676dza2') &&
              < img src={userRole === "89uij7" ? AdminPic : userRole === '676dza2' ? DentistPic : ''} className="object-fill rounded-full h-9 w-9" />} */}
            <img
              src={userProfilePicture ? userProfilePicture : user?.picture ? user.picture : Icon3}
              className="object-fill rounded-full h-9 w-9"
              alt="user profile pic"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${bottomNavBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto auto",
          backgroundPosition: "bottom",
          height: "fit-content",
        }}
        className="MOBILE-BOTTOM-NAV flex flex-col md:hidden bottom-[0rem] fixed pointer-events-none h-full w-[100vw] overflow-auto z-50"
      >
        <ul className="NAVBAR relative flex flex-row   max-w-[84vw] overflow-auto  h-full gap-12 md:gap-8 py-8 pointer-events-auto z-50 mx-auto ">
          {iconArray.map((item, i) => (
            <NavLink
              key={item.route}
              to={item.route}
              style={{ pointerEvents: "auto" }}
              className={({ isActive, isPending }) =>
                isPending ? "" : isActive ? navActiveCss : ""
              }
            >
              <li className={`relative ${navHoverCss} cursor-pointer w-12`}>
                <img className="" src={item.icon} alt="" />
              </li>
            </NavLink>
          ))}
          {isAuthenticated && (
            <NavLink>
              <li
                className={`relative ${navHoverCss} cursor-pointer pointer-events-auto w-12`}
                onClick={() => handleLogout()}
              >
                <img className="cursor-pointer" src={logoutIcon} alt="" />
              </li>
            </NavLink>
          )}
        </ul>
      </div>

      {middleSection && (
        <>
          <MiddleNavLayout
            middleNavActive={middleNavActive}
            activeTab={activeTab}
            middleTabTitleArray={middleTabTitleArray}
            handleTabSuper={handleTabSuper}
            closeMiddleNav={closeMiddleNav}
            middleNavHeading={middleNavHeading}
          />

          <div className="MIDDLE-TABS-OUTLET-SECTION INNER-SECTION w-full h-screen overflow-hidden md:py-4 pt-4 pb-16 px-4 md:px-12 bg-background">
            {/* {activeTab === "tab1" ? <ProfileInfo /> : <ChangePassword />} */}
            {Object.keys(middleTabContentObject).map((key) => {
              // debugger
              if (middleTabTitleArray.includes(key)) {
                return (
                  <div key={key}>
                    {activeTab === key ? (
                      <>
                        <div className="flex flex-col h-full" key={key}>
                          {middleTabContentObject[key]}
                        </div>
                      </>
                    ) : null}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </>
      )}
    </>
  );
}

export default Navbar;
export function MiddleNavLayout({
  middleNavActive,
  activeTab,
  middleTabTitleArray,
  handleTabSuper,
  closeMiddleNav,
  middleNavHeading,
}) {
  return (
    <div className="MIDDLE-TABS-SECTION absolute z-[13] h-screen w-fit  lg:relative border-l border-black border-opacity-10 transition-all shadow-md">
      <div
        className={` w-[50vw] lg:w-[16rem] bg-gray  transition-all ease-in-out bg-secondary h-full ${
          middleNavActive ? "block " : "hidden "
        } `}
      >
        <div className="flex items-center justify-start md:pr-12 md:pl-8 px-4  py-8 gap-2 border-b border-black border-opacity-10 relative text-white">
          <div className="bg-orange-400 h-8 w-8 rounded-full text-[17px] font-medium leading-4 text-white flex justify-center items-center">
            {middleNavHeading === "Orders" ? (
              <FaRegFileAlt className="text-background" />
            ) : (
              <img className="h-4 " src={people} />
            )}
          </div>
          {middleNavHeading}
        </div>
        <div className="MIDDLE-NAV">
          <ul className="py-4">
            {middleTabTitleArray.map((title, i) => (
              <li
                key={i}
                onClick={() => handleTabSuper(title)}
                className={activeTab === title ? "bg-primary bg-opacity-25 text-white" : ""}
              >
                <div className="flex items-center gap-4 px-2 md:px-4 lg:px-8 py-4 cursor-pointer">
                  {title === "Service providers" && (
                    <MdHomeRepairService className="text-orange-500" />
                  )}
                  {title === "Dentist" && <FaUserDoctor className="text-orange-500" />}
                  {title === "Profile Info" && <MdHomeRepairService className="text-orange-500" />}
                  {title === "Change Password" && (
                    <MdOutlineMiscellaneousServices className="text-orange-500" />
                  )}
                  {title === "Service Categories" && (
                    <BsPersonWorkspace className="text-orange-500" />
                  )}
                  {title === "Provider Levels" && <FaNetworkWired className="text-orange-500" />}
                  {title === "Software Categories" && (
                    <SiJirasoftware className="text-orange-500" />
                  )}
                  {title === "Questions" && <BsQuestionSquareFill className="text-orange-500" />}
                  {title === "Orders" && <FaRegFileAlt className="text-orange-500" />}
                  <span className="text-[16px] text-white">{title}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <MiddleNavButton closeMiddleNav={closeMiddleNav} middleNavActive={middleNavActive} />
    </div>
  );
}

export function MiddleNavButton({ closeMiddleNav, middleNavActive }) {
  return (
    <div
      onClick={() => closeMiddleNav()}
      className={`absolute top-[64px]   ${
        middleNavActive ? "right-[-45vw]" : "right-[-95vw]"
      } lg:right-[-16px] shadow-md rounded-full bg-primary cursor-pointer h-8 w-8 flex  items-center justify-center  `}
    >
      {!middleNavActive ? <IoIosArrowForward /> : <IoIosArrowBack />}
    </div>
  );
}

import icon1 from "../Assets/images/icon1.svg";
import icon2 from "../Assets/images/icon2.svg";
import icon3 from "../Assets/images/icon3.svg";
import icon4 from "../Assets/images/icon4.svg";
import icon5 from "../Assets/images/icon5.svg";
import icon6 from "../Assets/images/icon6.svg";
import icon7 from "../Assets/images/icon7.svg";
import icon8 from "../Assets/images/icon8.svg";

import hIcon1 from "../Assets/images/hIcon1.svg";
import hIcon2 from "../Assets/images/hIcon2.svg";
import hIcon3 from "../Assets/images/hIcon3.svg";
import hIcon4 from "../Assets/images/hIcon4.svg";
import hIcon5 from "../Assets/images/hIcon5.svg";
import hIcon6 from "../Assets/images/hIcon6.svg";
import hIcon7 from "../Assets/images/hIcon7.svg";
import hIcon8 from "../Assets/images/hIcon8.svg";

export const iconArray = [
  { icon: icon1, route: "/dashboard", title: "Dashboard", hover: hIcon1 },
  { icon: icon2, route: "/orders", title: "Orders", hover: hIcon7 },
  { icon: icon8, route: "/paymentHistory", title: "Transactions", hover: hIcon8 },
  { icon: icon3, route: "/profileinfo", title: "Profile", hover: hIcon4 },
];

export const spIconArray = [
  { icon: icon1, route: "/dashboard", title: "Dashboard", hover: hIcon1 },
  { icon: icon2, route: "/orders", title: "Orders", hover: hIcon7 },
  { icon: icon3, route: "/profileinfo", title: "Profile", hover: hIcon4 },
  { icon: icon7, route: "/servicesSp", title: "My Services", hover: hIcon2 },
];

export const superAdminIconArray = [
  { icon: icon1, route: "/dashboard", title: "Dashboard", hover: hIcon1 },
  { icon: icon4, route: "/serviceproviders", title: "User Management", hover: hIcon3 },
  { icon: icon2, route: "/orders", title: "Orders", hover: hIcon7 },
  { icon: icon5, route: "/paymentHistory", title: "Payment History", hover: hIcon6 },
  { icon: icon6, route: "/orderDisputes", title: "Order Disputes", hover: hIcon5 },
  { icon: icon7, route: "/servicecategories", title: "Services", hover: hIcon2 },
  { icon: icon3, route: "/profileinfo", title: "Profile", hover: hIcon4 },
];

export const navActiveCss =
  "relative before:absolute before:left-[9px] md:before:left-[-30px] md:before:top-0 before:top-[24px] before:h-[20px] before:w-[2px] before:bg-white md:before:rotate-0 before:rotate-90 ";
export const navHoverCss =
  "hover:before:absolute  before:left-[9px] md:hover:before:left-[-30px] md:hover:before:top-0 hover:before:top-[24px] hover:before:h-[20px] hover:before:w-[2px] hover:before:bg-white  md:hover:before:rotate-0 hover:before:rotate-90 ";

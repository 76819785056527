import { useEffect, useState } from "react";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  superAdminIconArray,
  spIconArray,
} from "../../constants/NavbarRoutesArray";
import Modal from "react-modal";
import hut from "../../Assets/images/hut.svg";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import ServiceCarouselTile from "../../Components/UserManagement-Comps/ServiceCarouselTile";
import CustomCarousel from "../../Components/UserManagement-Comps/CustomCarousel";
import ServiceProviderCard from "../../Components/OrderAdmin-comps/ServiceProviderCard";
import { useLocation } from "react-router-dom";
import {
  useGetOrdersForAdminMutation,
  useGetServiceProviderByServiceMutation,
  useGetServiceProviderOrdersMutation,
  useSaveNotesMutation,
  useSaveOrderDeliveryMutation,
  useUpdateOrderByIdMutation,
} from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../Login/Login";
import { AiOutlineClose } from "react-icons/ai";
import TextAreaInputField from "../../Components/InputField-Comps/TextAreaInputField";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";
import OrderSummary from "../../Components/OrderSummary-Comps/OrderSummary";
import { useGetServicesCategoriesMutation } from "../../services/servicesCategory";
import OrderCommunication from "../Orders/OrderCommunication";

import rejectedIcon from "../../Assets/icons/rejectedIcon.svg";
import assignSuccess from "../../Assets/icons/assignSuccess.svg";
import assignedIcon from "../../Assets/icons/orderAssignedIcon.svg";
import OrderNotesHistory from "../Orders/OrderNotesHistory";

export const OrderDetailSummary = ({ data, orderDetails }) => {
  return <OrderSummary data={orderDetails} showTopTiles={false} />;
};

export const AssignServiceProvider = ({
  orderObjId,
  orderDetails,
  fetchOrderDetails,
  handleUpdateStatus,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [getServiceProviderByService] = useGetServiceProviderByServiceMutation();
  const [getServiceProviderOrders] = useGetServiceProviderOrdersMutation();
  const [getServicesCategories] = useGetServicesCategoriesMutation();

  const [boxSelected, setBoxSelected] = useState("");
  const [isOrderAssigned, setIsOrderAssigned] = useState(false);
  const [isOrderConfirm, setIsOrderConfirm] = useState(false);
  const [serviceProviderData, setServiceProviderData] = useState([]);
  const [serviceProviderOrdersData, setServiceProviderOrdersData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedServiceCategory, setSelectedServiceCategory] = useState({});
  const [notes, setNotes] = useState("");

  console.log("boxSelected", boxSelected);

  const getServicesCategoriesData = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await getServicesCategories(accessToken).unwrap();
    console.log("test data?.categoryObjId", orderDetails?.categoryObjId?.[0]);
    console.log("test resp", resp?.data);
    if (resp?.data && orderDetails?.categoryObjId?.[0]) {
      resp?.data?.forEach((d) => {
        if (d?._id === orderDetails?.categoryObjId?.[0]) {
          setSelectedServiceCategory(d);
        }
      });
    }
  };

  const getServiceProviderByOrderAndServiceData = async (accessToken, serviceObjId, orderObjId) => {
    const respOrder = await getServiceProviderOrders({
      accessToken,
      orderObjId,
    }).unwrap();
    if (
      respOrder?.data?.length > 0 &&
      orderDetails &&
      orderDetails?.statusCode !== "108" &&
      orderDetails?.statusCode !== "117" &&
      orderDetails?.statusCode !== "126"
    ) {
      setServiceProviderOrdersData(respOrder.data);
    } else {
      const respServ = await getServiceProviderByService({ accessToken, serviceObjId }).unwrap();
      if (respServ) {
        setServiceProviderData(respServ.data);
      }
    }
  };

  const readOrderInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken) {
      const serviceObjIds = orderDetails?.services?.map((d) => d?.serviceObjId);
      await getServiceProviderByOrderAndServiceData(accessToken, serviceObjIds, orderObjId);
    }
  };

  useEffect(() => {
    if (isUpdated === true) {
      readOrderInfo();
      fetchOrderDetails();
    }
  }, [isUpdated]);

  useEffect(() => {
    readOrderInfo();
    getServicesCategoriesData();
  }, []);

  const prepareServicePrCardsData = () => {
    if (serviceProviderData?.length) {
      const arrayOfData = [];
      // debugger;
      // Create HTML elements based on servicesData
      serviceProviderData.map((eachCategory) =>
        arrayOfData.push(
          <ServiceCarouselTile
            value={eachCategory.count}
            header={eachCategory.groupName}
            details={eachCategory.users}
            isAdmin={true}
            selected={boxSelected}
            setBoxSelected={setBoxSelected}
          />
        )
      );
      return arrayOfData.reverse();
    }
    return [];
    // Return null if servicesData is empty or null
  };

  // const numberOfSliders = [

  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,

  // ];

  console.log("serviceProviderData", serviceProviderData);

  const selectedCategoryCardsData = () => {
    console.log("serviceProviderData inside", serviceProviderData);
    if (serviceProviderData?.length > 0) {
      const arrayOfData = [];

      console.log("result", serviceProviderData.filter((item) => item?.users?.length > 0)?.[0]);
      // debugger;
      // Create HTML elements based on servicesData
      serviceProviderData
        // .filter((item) => item.groupName === boxSelected)?.[0]
        .filter((item) => item?.users?.length > 0)?.[0]
        ?.users?.map((eachCategory) => {
          console.log("details11", orderDetails?.services);
          console.log("details11 45", eachCategory?.details?.userServiceDetails);
          const requiredServicesCost = orderDetails?.services?.map((serviceItem) => {
            const serviceCost = eachCategory?.details?.userServiceDetails?.find(
              (d) => d?.serviceObjId === serviceItem?.serviceObjId
            );
            const serviceDetails = selectedServiceCategory?.catservices?.find(
              (d) => d?.serviceObjId === serviceItem?.serviceObjId
            );
            console.log("serviceCost", serviceCost);
            console.log("serviceDetails 78", serviceDetails);
            return { ...serviceDetails, ...serviceItem, ...serviceCost };
          });
          arrayOfData.push(
            <ServiceProviderCard
              firstName={eachCategory?.details?.firstName}
              lastName={eachCategory?.details?.lastName}
              crowdHireId={eachCategory?.details?.crowdHireId}
              specialisation={null}
              user_id={eachCategory.user_id}
              userServiceDetails={requiredServicesCost}
              isMultiService={true}
              currency={eachCategory?.details?.currency[0]}
              setIsUpdated={setIsUpdated}
              serviceProviderOrdersData={serviceProviderOrdersData}
              orderObjId={orderObjId}
              isOrderAssigned={isOrderAssigned}
              setIsOrderAssigned={setIsOrderAssigned}
              isOrderConfirm={isOrderConfirm}
              setIsOrderConfirm={setIsOrderConfirm}
              sercategoryObjId={selectedServiceCategory?._id}
              notes={notes}
              setNotes={setNotes}
              orderDetails={orderDetails}
              handleUpdateStatus={handleUpdateStatus}
            />
          );
        });
      console.log("arrayOfData", arrayOfData);
      return arrayOfData;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  // const numberOfSlidersMobile = [
  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,
  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,
  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,
  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,
  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,
  // ];

  console.log("serviceProviderOrdersData", serviceProviderOrdersData);

  return (
    // "No Data to Display"
    <div>
      {serviceProviderOrdersData.length > 0 ? (
        <div>
          <p className="text-2xl font-semibold text-white my-4">Assigned Service Providers</p>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-8">
            {serviceProviderOrdersData?.map((eachCategory) => {
              return (
                <ServiceProviderCard
                  crowdHireId={eachCategory?.userDetails?.crowdHireId?.[0]}
                  serviceDescription={eachCategory?.["serviceObjId"]?.serviceDescription}
                  specialization={eachCategory?.details?.serviceDetails?.[0]?.specialization}
                  user_id={eachCategory.user_id}
                  firstName={eachCategory?.userDetails?.firstName?.[0]}
                  lastName={eachCategory?.userDetails?.lastName?.[0]}
                  actualPrice={eachCategory?.actualPrice}
                  offerPrice={eachCategory?.orderValue}
                  portalPrice={eachCategory?.portalValue}
                  currency={eachCategory?.orderCurrency}
                  setIsUpdated={setIsUpdated}
                  serviceProviderOrdersData={serviceProviderOrdersData}
                  orderObjId={orderObjId}
                  isOrderAssigned={isOrderAssigned}
                  setIsOrderAssigned={setIsOrderAssigned}
                  isOrderConfirm={isOrderConfirm}
                  setIsOrderConfirm={setIsOrderConfirm}
                  orderDetails={orderDetails}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div>
          <p className="text-2xl font-semibold text-white mb-2">Choose Service Provider Category</p>
          {/* <div className="SERVICE-PROVIDER-CAROUSEL">
            <div className="CAROUSEL-DESKTOP md:block hidden">
              <CustomCarousel
                // rows and columns you want per slide with x and y gaps in pixels
                rows={1}
                cols={4}
                gapX={20}
                gapY={20}
                completeSliders={prepareServicePrCardsData()}
                numberOfCardsShownInSingleSlide={4}
                handleCardOnClick={(card) => {
                  console.log("clicked", card);
                }}
              />
            </div>
            <div className="CAROUSEL-MOBILE md:hidden block">
              <CustomCarousel
                // rows and columns you want per slide with x and y gaps in pixels
                rows={1}
                cols={1}
                gapX={20}
                gapY={20}
                completeSliders={prepareServicePrCardsData()}
                numberOfCardsShownInSingleSlide={5}
                handleCardOnClick={(card) => {
                  console.log("clicked", card);
                }}
              />
            </div>
          </div> */}

          <div>
            <div>
              <p className="text-[#555] text-2xl font-semibold my-4">Service Providers</p>
            </div>
            <div className="SERVICE-PROVIDER-CAROUSEL">
              <div className="CAROUSEL-DESKTOP md:block hidden">
                <CustomCarousel
                  // rows and columns you want per slide with x and y gaps in pixels
                  rows={1}
                  cols={4}
                  gapX={20}
                  gapY={20}
                  completeSliders={selectedCategoryCardsData()}
                  numberOfCardsShownInSingleSlide={4}
                  handleCardOnClick={(card) => {
                    console.log("clicked", card);
                  }}
                />
              </div>
              <div className="CAROUSEL-MOBILE md:hidden block">
                <CustomCarousel
                  // rows and columns you want per slide with x and y gaps in pixels
                  rows={1}
                  cols={1}
                  gapX={20}
                  gapY={20}
                  completeSliders={selectedCategoryCardsData()}
                  numberOfCardsShownInSingleSlide={5}
                  handleCardOnClick={(card) => {
                    console.log("clicked", card);
                  }}
                />
              </div>
            </div>
            {!selectedCategoryCardsData()?.length && (
              <div className="text-white py-16 flex items-center justify-center">
                No Service Providers Available
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const Delivery = ({ orderDetails }) => {
  return (
    <div className="flex-col py-6">
      <OrderNotesHistory notes={orderDetails?.notes} />
    </div>
  );
};

const tabNamesArray = ["Order Details", "Assign Service Provider", "Delivery"];

function OrderDetailsAdmin({ userRole }) {
  const location = useLocation();
  return (
    <div className="flex bg-black">
      <Navbar
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active="Orders"
      />
      <OrderTab data={location.state} />
    </div>
  );
}

export default OrderDetailsAdmin;

export const OrderTab = (data) => {
  console.log("order data987", data);

  const { getAccessTokenSilently } = useAuth0();
  const [getOrdersForAdmin] = useGetOrdersForAdminMutation();
  const [updateOrderById] = useUpdateOrderByIdMutation();
  const [saveNotes] = useSaveNotesMutation();
  const [saveOrderDelivery] = useSaveOrderDeliveryMutation();

  const [orderDetails, setOrderDetails] = useState([]);
  const [isCommunicalModelOpen, setIsCommunicalModelOpen] = useState(false);
  const [resolveDisputeModalOpen, setResolveDisputeModalOpen] = useState(false);
  const [isDisputeResolved, setIsDisputeResolved] = useState(false);
  const [requestRefundModalOpen, setRequestRefundModalOpen] = useState(false);
  const [isRefundRequested, setIsRefundRequested] = useState(false);
  const [refundCompletedModalOpen, setRefundCompletedModalOpen] = useState(false);
  const [isRefundCompleted, setIsRefundCompleted] = useState(false);
  const [notes, setNotes] = useState("");

  const getOrderData = async (accessToken, orderId) => {
    const resp = await getOrdersForAdmin({ accessToken, data: { _id: orderId } }).unwrap();
    if (resp) {
      setOrderDetails(resp.data[0]);
    }
  };

  const readOrderInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken && data?.data?.orderObjId) {
      await getOrderData(accessToken, data?.data?.orderObjId);
    }
  };

  useEffect(() => {
    console.log("useEffect services working --- ");
    readOrderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("orderDetails", orderDetails);

  const { user, isAuthenticated } = useAuth0();
  const [activeTab, setActiveTab] = useState(tabNamesArray[0]);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const handleTabSuper = (tabName) => {
    setActiveTab(tabName);
  };

  const handleUpdateStatus = async (type, statusNotes) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (type === "C") {
      const resp = await updateOrderById({
        accessToken,
        orderId: orderDetails?._id,
        currentStatus: "10",
      });
      if (resp) {
        GetToast("Dispute Resolved, and case has been completed.");
      }
    } else if (type === "RR") {
      const resp = await updateOrderById({
        accessToken,
        orderId: orderDetails?._id,
        currentStatus: "11",
      });
      if (resp) {
        GetToast("Refund requested for the raised dispute.");
      }
    } else if (type === "RC") {
      const resp = await updateOrderById({
        accessToken,
        orderId: orderDetails?._id,
        currentStatus: "12",
      });
      if (resp) {
        GetToast("Refund completed successfully.");
      }
    } else if (type === "CLOSED") {
      const resp = await updateOrderById({
        accessToken,
        orderId: orderDetails?._id,
        currentStatus: "13",
      });
      if (resp) {
        GetToast("Order closed successfully.");
      }
    } else if (type === "REACTIVATE") {
      const resp = await updateOrderById({
        accessToken,
        orderId: orderDetails?._id,
        currentStatus: "1",
      });
      if (resp) {
        GetToast("Order closed successfully.");
      }
    } else if (type === "PAYMENT_MADE") {
      const resp = await updateOrderById({
        accessToken,
        orderId: orderDetails?._id,
        currentStatus: "14",
      });
      if (resp) {
        GetToast("Marked as payment made for the order successfully.");
      }
    }

    readOrderInfo();

    let deliveryObjId = null;

    if (
      orderDetails?.serviceproviderdetails?.[orderDetails?.serviceproviderdetails?.length - 1]
        ?.orderdelivery?.[0]?.deliveryObjId
    ) {
      deliveryObjId =
        orderDetails?.serviceproviderdetails?.[orderDetails?.serviceproviderdetails?.length - 1]
          ?.orderdelivery?.[0]?.deliveryObjId;
    } else {
      const resp = await saveOrderDelivery({
        accessToken,
        data: {
          orderObjId: orderDetails?._id,
          spOrderObjId:
            orderDetails?.serviceproviderdetails?.[orderDetails?.serviceproviderdetails?.length - 1]
              ?.spOrderObjId,
        },
      }).unwrap();

      console.log("resp", resp);
      deliveryObjId = resp?.data?.deliveryObjId;
    }

    if (deliveryObjId) {
      await saveNotes({
        accessToken,
        data: {
          notes: orderDetails?.statusCode === "126" ? statusNotes : notes,
          deliveryObjId: deliveryObjId,
          orderObjId: orderDetails?._id,
        },
      });
      setNotes("");
    }

    readOrderInfo();
  };

  const outputObject = {
    "Order Details": (
      <OrderDetailSummary
        data={data.data}
        orderDetails={orderDetails}
        setOrderDetails={setOrderDetails}
      />
    ),
    "Assign Service Provider": (
      <AssignServiceProvider
        orderObjId={data.data.orderObjId}
        orderDetails={orderDetails}
        fetchOrderDetails={readOrderInfo}
        handleUpdateStatus={handleUpdateStatus}
      />
    ),
    Delivery: <Delivery data={data} orderDetails={orderDetails} />,

    // "Order Communications Service Provider": <OrderSpCommunications data={data.data} />,
    // "Order Communications Dentist": <OrderDentistCommunications data={data.data} />,
  };

  return (
    <div className="MAIN-DASH bg-black p-4 w-full min-h-screen h-full flex flex-col md:py-4 pt-4 pb-20 relative">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user?.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {/* <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
            CH00001
            <img
              className="relative top-[4px] right-0"
              src={greenTick}
              alt=""
            />
          </button>
          <img src={bell} alt="" /> */}
          <Notification />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
        <div className="flex flex-1 gap-4 items-center w-full">
          <p className="font-medium text-[34px] text-white ">Orders</p>
        </div>
      </div>

      <div className="bg-secondary p-4 rounded-md flex-1 flex flex-col">
        <div className="flex justify-between items-center">
          <p className="text-xl font-medium text-white my-4">Order Details</p>
          <div className="flex gap-2 items-center">
            {orderDetails?.statusCode === "126" && (
              <>
                <button
                  // onClick={() => setResolveDisputeModalOpen(true)}
                  onClick={() => setActiveTab("Assign Service Provider")}
                  className="px-4 py-2 bg-[#1DB569] text-white rounded-lg font-medium text-[17px]"
                >
                  Resolve Dispute
                </button>
                <button
                  onClick={() => setRequestRefundModalOpen(true)}
                  className="px-4 py-2 bg-[#F35F5D] text-white rounded-lg font-medium text-[17px] whitespace-nowrap"
                >
                  Assigned Refund
                </button>
              </>
            )}
            {orderDetails?.statusCode === "117" && (
              <button
                onClick={() => handleUpdateStatus("CLOSED")}
                className="px-4 py-2 bg-[#F35F5D] text-white rounded-lg font-medium text-[17px] whitespace-nowrap"
              >
                Close Order
              </button>
            )}
            {orderDetails?.statusCode === "123" && (
              <button
                onClick={() => handleUpdateStatus("PAYMENT_MADE")}
                className="px-4 py-2 bg-[#1DB569] text-white rounded-lg font-medium text-[17px] whitespace-nowrap"
              >
                Payment Made
              </button>
            )}
            {orderDetails?.statusCode === "138" && (
              <button
                onClick={() => handleUpdateStatus("REACTIVATE")}
                className="px-4 py-2 bg-[#F35F5D] text-white rounded-lg font-medium text-[17px] whitespace-nowrap"
              >
                Reactivate
              </button>
            )}
            {orderDetails?.statusCode === "132" && (
              <button
                onClick={() => setRefundCompletedModalOpen(true)}
                className="px-4 py-2 bg-[#1DB569] text-white rounded-lg font-medium text-[17px] whitespace-nowrap"
              >
                Refund Completed
              </button>
            )}
          </div>
          {orderDetails?.orderNumber && (
            <p className="text-white">
              Order Id : <strong className="text-primary">{orderDetails?.orderNumber}</strong>
            </p>
          )}
        </div>
        <div className="Tabs flex-1 h-full flex flex-col">
          {/* Tab nav */}
          <ul className="NAV flex items-center gap-4 text-[16px] leading-4 font-medium border-b-2 border-[#222026] border-opacity-10 py-4">
            {tabNamesArray.map((title, i) => {
              return (
                <li key={i + 1} className="relative" onClick={() => handleTabSuper(title)}>
                  <div
                    className={
                      activeTab === title
                        ? "text-primary before:content-[''] before:absolute before:h-1 before:w-full before:bg-primary before:left-0 before:-bottom-4"
                        : "hover:text-primary cursor-pointer text-[#999] font-normal"
                    }
                  >
                    {title}
                  </div>
                </li>
              );
            })}
          </ul>

          <div className="outlet md:h-full flex-1 h-[50vh] flex flex-col overflow-auto pt-4 ">
            {Object.keys(outputObject).map((key) => {
              if (tabNamesArray.includes(key)) {
                return <>{activeTab === key ? <>{outputObject[key]}</> : null}</>;
              }
              return null;
            })}
          </div>
        </div>
      </div>
      <OrderCommunication
        isOpen={isCommunicalModelOpen}
        onClose={setIsCommunicalModelOpen}
        orderObjId={orderDetails?._id}
        orderDetails={orderDetails}
      />
      <Modal
        isOpen={resolveDisputeModalOpen}
        onRequestClose={() => setResolveDisputeModalOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full z-[1000] relative">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => setResolveDisputeModalOpen(false)}
                >
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-white leading-8 mb-0">Resolve Dispute</p>
              </div>

              <div className="flex-1 flex flex-col items-center justify-center">
                {isDisputeResolved ? (
                  <>
                    <img src={assignSuccess} alt="" />
                    <p className="text-2xl font-semibold">Congratulations!</p>
                    <p className="text-center my-0 md:px-12 text-white">
                      Diputed has been resolved and order has been completed.
                    </p>
                  </>
                ) : (
                  <>
                    <img src={assignedIcon} alt="" />
                    <p className="text-center text-white my-4 md:px-12">
                      Are you sure you want to resolve the dispute for this order!
                    </p>
                    <div className="w-full mt-6">
                      <TextAreaInputField
                        labelColor={"#777"}
                        labelText={"Add Resolution Note"}
                        errorMessage={"Note is required"}
                        placeholder={"Type here"}
                        isFieldRequired={true}
                        htmlFor={"notes"}
                        name={"notes"}
                        value={notes}
                        handleInputChange={(e) => setNotes(e.target.value)}
                        rows={6}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end gap-4">
                {isDisputeResolved ? null : (
                  <button
                    onClick={() => setResolveDisputeModalOpen(false)}
                    className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                  >
                    Cancel
                  </button>
                )}

                <button
                  onClick={() => {
                    if (isDisputeResolved) {
                      setResolveDisputeModalOpen(false);
                    } else {
                      setIsDisputeResolved(true);
                      handleUpdateStatus("C");
                    }
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  {isDisputeResolved ? "Okay" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
      <Modal
        isOpen={requestRefundModalOpen}
        onRequestClose={() => setRequestRefundModalOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full z-[1000] relative">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => setRequestRefundModalOpen(false)}
                >
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-white leading-8 mb-0">Request Refund</p>
              </div>

              <div className="flex-1 flex flex-col items-center justify-center">
                {isRefundRequested ? (
                  <>
                    <img src={assignedIcon} alt="" />
                    <p className="text-center my-0 md:px-12 text-white">
                      A request for refund has been initiated.
                    </p>
                  </>
                ) : (
                  <>
                    <img src={rejectedIcon} alt="" />
                    <p className="text-center text-white my-4 md:px-12">
                      Are you sure you want to request refund for the dispute for this order!
                    </p>
                    <div className="w-full mt-6">
                      <TextAreaInputField
                        labelColor={"#777"}
                        labelText={"Add Note"}
                        errorMessage={"Note is required"}
                        placeholder={"Type here"}
                        isFieldRequired={true}
                        htmlFor={"notes"}
                        name={"notes"}
                        value={notes}
                        handleInputChange={(e) => setNotes(e.target.value)}
                        rows={6}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end gap-4">
                {isRefundRequested ? null : (
                  <button
                    onClick={() => setRequestRefundModalOpen(false)}
                    className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                  >
                    Cancel
                  </button>
                )}

                <button
                  onClick={() => {
                    if (isRefundRequested) {
                      setRequestRefundModalOpen(false);
                    } else {
                      setIsRefundRequested(true);
                      handleUpdateStatus("RR");
                    }
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  {isRefundRequested ? "Okay" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
      <Modal
        isOpen={refundCompletedModalOpen}
        onRequestClose={() => setRefundCompletedModalOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full z-[1000] relative">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => setRefundCompletedModalOpen(false)}
                >
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-white leading-8 mb-0">
                  Refund Completed
                </p>
              </div>

              <div className="flex-1 flex flex-col items-center justify-center">
                {isRefundCompleted ? (
                  <>
                    <img src={assignSuccess} alt="" />
                    <p className="text-center my-0 md:px-12 text-white">
                      Refund has been completed and order has been closed.
                    </p>
                  </>
                ) : (
                  <>
                    <img src={assignedIcon} alt="" />
                    <p className="text-center text-white my-4 md:px-12">
                      Are you sure you want to completd the refund for this order!
                    </p>
                    <div className="w-full mt-6">
                      <TextAreaInputField
                        labelColor={"#777"}
                        labelText={"Add Note"}
                        errorMessage={"Note is required"}
                        placeholder={"Type here"}
                        isFieldRequired={true}
                        htmlFor={"notes"}
                        name={"notes"}
                        value={notes}
                        handleInputChange={(e) => setNotes(e.target.value)}
                        rows={6}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end gap-4">
                {isRefundCompleted ? null : (
                  <button
                    onClick={() => setRefundCompletedModalOpen(false)}
                    className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                  >
                    Cancel
                  </button>
                )}

                <button
                  onClick={() => {
                    if (isRefundCompleted) {
                      setRefundCompletedModalOpen(false);
                    } else {
                      setIsRefundCompleted(true);
                      handleUpdateStatus("RC");
                    }
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  {isRefundCompleted ? "Okay" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

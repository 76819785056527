import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

const DropdownFieldV2 = ({
  labelText,
  labelColor,
  labelClass,
  errorMessage,
  placeholder,
  isFieldRequired,
  htmlFor,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validationFunctionName,
  options,
}) => {
  const [ifInvalid, setIfInvalid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState();
  const containerRef = useRef(null);

  console.log("options", options);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        // Clicked outside the dropdown, so close it
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value?.length > 0) {
      setIfInvalid(false);
    }

    const find = options?.find((d) => d?.value === value);
    if (find?.label) setSelectedLabel(find?.label);
  }, [value]);

  return (
    <div className="relative">
      <label htmlFor={htmlFor} className={`text-white ${labelClass}`}>
        {labelText}
        {isFieldRequired ? <span className="text-red-500 ml-1">*</span> : ""}
      </label>
      <div className="relative cursor-pointer" onClick={() => setIsOpen((prev) => !prev)}>
        <div
          className={`border p-2.5 w-full text-white bg-black text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 ${
            (isSubmitted && !validationFunctionName(value) && isFieldRequired) || ifInvalid
              ? "border-red-500"
              : ""
          }`}
        >
          {value ? selectedLabel : placeholder}
        </div>
        <button className="absolute text-white top-1/2 right-3 -translate-y-1/2">
          {isOpen ? <FaAngleUp /> : <FaAngleDown />}
        </button>
        {isOpen && (
          <ul
            className="absolute bottom-0 translate-y-full w-full max-h-[300px] overflow-y-auto bg-background rounded-sm border text-white text-sm"
            ref={containerRef}
          >
            {options?.map((option) => (
              <li
                key={option?.value}
                value={option?.value}
                className="flex items-center px-2 py-0.5"
                onClick={() => handleInputChange({ target: { name: name, value: option?.value } })}
              >
                <img src={option?.image} alt="icon" className="h-14 object-contain" />
                {option?.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* <select
        id={name}
        name={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        onInvalid={(e) => {
          e.preventDefault();
          setIfInvalid(true);
          e.target.setCustomValidity("");
        }}
        onInput={(e) => {
          if (isSubmitted && !validationFunctionName(value) && isFieldRequired) {
            setIfInvalid(false);
          }
        }}
        className={`border p-2 w-full text-white bg-black text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 ${
          (isSubmitted && !validationFunctionName(value) && isFieldRequired) || ifInvalid
            ? "border-red-500"
            : ""
        }`}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options &&
          options?.length &&
          options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select> */}
      {isSubmitted && !validationFunctionName(value) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">{errorMessage}</p>
      )}
      {ifInvalid && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">{errorMessage}</p>
      )}
    </div>
  );
};

export default DropdownFieldV2;

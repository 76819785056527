import { useEffect, useState } from "react";
import zipIcon from "../../Assets/images/zipIcon.jpg";
import folderIcon from "../../Assets/images/folderIcon.jpg";
import dcmFileIcon from "../../Assets/images/dcmFileIcon.jpg";
import face1 from "../../Assets/images/face1.png";
import face2 from "../../Assets/images/face2.png";
import face3 from "../../Assets/images/face3.png";
import face4 from "../../Assets/images/face4.png";
import face5 from "../../Assets/images/face5.png";
import face6 from "../../Assets/images/face6.png";
import face7 from "../../Assets/images/face7.png";
import face8 from "../../Assets/images/face8.png";
import face9 from "../../Assets/images/face9.png";
import face10 from "../../Assets/images/face10.png";
import radioGraphs1 from "../../Assets/images/icons-82.jpg";
import radioGraphs2 from "../../Assets/images/icons-83.jpg";
import radioGraphs3 from "../../Assets/images/icons-84.jpg";

import ImageUpload from "./ImageUpload";
import { useAuth0 } from "@auth0/auth0-react";
import { useSaveFileMutation } from "../../services/uploadFiles";
import FileUploadComponent from "../InputField-Comps/FilePondField";
import { ORDER_STAGE } from "../../constants/orderForms";
import { useDeleteOrderScansMutation, useSaveOrderScansMutation } from "../../services/orders";

function UploadPhotoGraphsSection({
  setActiveTab,
  orderUploadData,
  submitOrderData,
  orderByData,
  setOrderUpdated,
  isFieldRequired,
  categoryObjId,
}) {
  const [saveFile] = useSaveFileMutation();
  const [saveOrderScans] = useSaveOrderScansMutation();
  const [deleteOrderScans] = useDeleteOrderScansMutation();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    console.log("upload useEffect runned");
  }, []);

  console.log("upload orderUploadData", orderUploadData);

  const [imageOneUrl, setImageOneUrl] = useState({
    fileFor: "imageOne",
    ...orderUploadData?.photographs?.imageOne,
  });
  const [imageTwoUrl, setImageTwoUrl] = useState({
    fileFor: "imageTwo",
    ...orderUploadData?.photographs?.imageTwo,
  });
  const [imageThreeUrl, setImageThreeUrl] = useState({
    fileFor: "imageThree",
    ...orderUploadData?.photographs?.imageThree,
  });
  const [imageFourUrl, setImageFourUrl] = useState({
    fileFor: "imageFour",
    ...orderUploadData?.photographs?.imageFour,
  });
  const [imageFiveUrl, setImageFiveUrl] = useState({
    fileFor: "imageFive",
    ...orderUploadData?.photographs?.imageFive,
  });
  const [imageSixUrl, setImageSixUrl] = useState({
    fileFor: "imageSix",
    ...orderUploadData?.photographs?.imageSix,
  });
  const [imageSevenUrl, setImageSevenUrl] = useState({
    fileFor: "imageSeven",
    ...orderUploadData?.photographs?.imageSeven,
  });
  const [imageEightUrl, setImageEightUrl] = useState({
    fileFor: "imageEight",
    ...orderUploadData?.photographs?.imageEight,
  });
  const [imageNineUrl, setImageNineUrl] = useState({
    fileFor: "imageNine",
    ...orderUploadData?.photographs?.imageNine,
  });
  const [imageTenUrl, setImageTenUrl] = useState({
    fileFor: "imageTen",
    ...orderUploadData?.photographs?.imageTen,
  });

  const [oral1, setOral1] = useState({
    fileFor: "upperScan",
    ...orderUploadData?.intraOralScans?.upperScan,
  });
  const [oral2, setOral2] = useState({
    fileFor: "lowerScan",
    ...orderUploadData?.intraOralScans?.lowerScan,
  });
  const [oral3, setOral3] = useState({
    fileFor: "biteScan1",
    ...orderUploadData?.intraOralScans?.biteScan1,
  });
  const [oral4, setOral4] = useState({
    fileFor: "biteScan2",
    ...orderUploadData?.intraOralScans?.biteScan2,
  });

  const [dicom1, setDicom1] = useState({
    fileFor: "fileOne",
    ...orderUploadData?.rawDicomData?.fileOne,
  });
  const [dicom2, setDicom2] = useState({
    fileFor: "fileTwo",
    ...orderUploadData?.rawDicomData?.fileTwo,
  });
  const [dicom3, setDicom3] = useState({
    fileFor: "fileThree",
    ...orderUploadData?.rawDicomData?.fileThree,
  });

  const [radiograph1, setRadiograph1] = useState({
    fileFor: "orthopantomogram",
    ...orderUploadData?.radiographs?.orthopantomogram,
  });
  const [radiograph2, setRadiograph2] = useState({
    fileFor: "periapicalXrays",
    ...orderUploadData?.radiographs?.periapicalXrays,
  });
  const [radiograph3, setRadiograph3] = useState({
    fileFor: "cephalogram",
    ...orderUploadData?.radiographs?.cephalogram,
  });

  const [additionalFiles, setAdditionalFiles] = useState({
    ...orderUploadData?.additionalFiles,
  });
  console.log("additionalFiles", additionalFiles);
  console.log("additionalFiles orderUploadData", orderUploadData);

  const arrayOfPhotoGraphStates = [
    {
      initialState: imageOneUrl,
      setState: setImageOneUrl,
      placeholderImage: face1,
      title: "Profile Right",
    },
    {
      initialState: imageTwoUrl,
      setState: setImageTwoUrl,
      placeholderImage: face2,
      title: "Frontal",
    },
    {
      initialState: imageThreeUrl,
      setState: setImageThreeUrl,
      placeholderImage: face3,
      title: "Profile Left",
    },
    {
      initialState: imageFourUrl,
      setState: setImageFourUrl,
      placeholderImage: face4,
      title: "Frontal Smiling",
    },
    {
      initialState: imageFiveUrl,
      setState: setImageFiveUrl,
      placeholderImage: face5,
      acceptedFileTypes: ".stl, .ply, .obj",
      title: "Face Scan",
      showImagePreview: false,
    },
    {
      initialState: imageSixUrl,
      setState: setImageSixUrl,
      placeholderImage: face6,
      title: "Occlusal Upper",
    },
    {
      initialState: imageSevenUrl,
      setState: setImageSevenUrl,
      placeholderImage: face7,
      title: "Occlusal Lower",
    },
    {
      initialState: imageEightUrl,
      setState: setImageEightUrl,
      placeholderImage: face8,
      title: "Right Occlusion",
    },
    {
      initialState: imageNineUrl,
      setState: setImageNineUrl,
      placeholderImage: face9,
      title: "Frontal Occlusion",
    },
    {
      initialState: imageTenUrl,
      setState: setImageTenUrl,
      placeholderImage: face10,
      title: "Left Occlusion",
    },
  ];

  console.log("arrayOfPhotoGraphStates", arrayOfPhotoGraphStates);

  const arrayOfIntraOralScans = [
    {
      initialState: oral1,
      setState: setOral1,
      placeholderImage: face6,
      title: "Upper Scan",
      showImagePreview: false,
    },
    {
      initialState: oral2,
      setState: setOral2,
      placeholderImage: face7,
      title: "Lower Scan",
      showImagePreview: false,
    },
    {
      initialState: oral3,
      setState: setOral3,
      placeholderImage: face8,
      title: "Bite Scan 1",
      showImagePreview: false,
    },
    {
      initialState: oral4,
      setState: setOral4,
      placeholderImage: face10,
      title: "Bite Scan 2",
      showImagePreview: false,
    },
  ];

  const arrayOfDicomData = [
    {
      initialState: dicom1,
      setState: setDicom1,
      placeholderImage: zipIcon,
      acceptedFileTypes: ".zip, .tar",
      showImagePreview: false,
    },
    {
      initialState: dicom2,
      setState: setDicom2,
      placeholderImage: folderIcon,
      allowFolderUpload: true,
      showImagePreview: false,
    },
    {
      initialState: dicom3,
      setState: setDicom3,
      placeholderImage: dcmFileIcon,
      acceptedFileTypes: ".dcm",
      showImagePreview: false,
    },
  ];

  const arrayOfRadiographs = [
    {
      initialState: radiograph1,
      setState: setRadiograph1,
      placeholderImage: radioGraphs1,
      title: "Orthopantomogram",
    },
    {
      initialState: radiograph2,
      setState: setRadiograph2,
      placeholderImage: radioGraphs2,
      title: "Periapical X-Rays",
    },
    {
      initialState: radiograph3,
      setState: setRadiograph3,
      placeholderImage: radioGraphs3,
      title: "Cephalogram",
    },
  ];

  const photoGraphAllInitialStatesNotNull =
    imageOneUrl !== null ||
    imageTwoUrl !== null ||
    imageThreeUrl !== null ||
    imageFourUrl !== null ||
    imageFiveUrl !== null ||
    imageSixUrl !== null ||
    imageSevenUrl !== null ||
    imageEightUrl !== null ||
    imageNineUrl !== null ||
    imageTenUrl !== null;

  const oralFirstAllInitialStatesNotNull =
    oral1 !== null || oral2 !== null || oral3 !== null || oral4 !== null;

  const oralSecondAllInitialStatesNotNull = dicom1 !== null || dicom2 !== null || dicom3 !== null;

  const submitUploadFilesForm = async () => {
    if (
      photoGraphAllInitialStatesNotNull === true &&
      oralFirstAllInitialStatesNotNull === true &&
      oralSecondAllInitialStatesNotNull === true
    ) {
      // const accessToken = await getAccessTokenSilently({
      //   authorizationParams: {
      //     audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      //   },
      // });
      // const objOfAllImages = {
      //   imageOneUrl,
      //   imageTwoUrl,
      //   imageThreeUrl,
      //   imageFourUrl,
      //   imageFiveUrl,
      //   imageSixUrl,
      //   imageSevenUrl,
      //   imageEightUrl,
      //   imageNineUrl,
      //   imageTenUrl,
      // }

      // if (orderByData?.assets.length === 0) {
      //   const photoGraphResp = await saveOrderScans({
      //     accessToken,
      //     orderObjId: orderByData?._id,
      //     imageOneUrl,
      //     imageTwoUrl,
      //     imageThreeUrl,
      //     imageFourUrl,
      //     imageFiveUrl,
      //     imageSixUrl,
      //     imageSevenUrl,
      //     imageEightUrl,
      //     imageNineUrl,
      //     imageTenUrl,
      //     uploadData: "uploadData"
      //   });

      //   const oralFirstResp = await saveOrderScans({
      //     accessToken,
      //     orderObjId: orderByData?._id,
      //     oral1,
      //     oral2,
      //     oral3,
      //     oral4,
      //     uploadData: "internalScan"
      //   });

      //   const oralSecondtResp = await saveOrderScans({
      //     accessToken,
      //     orderObjId: orderByData?._id,
      //     oral5,
      //     oral6,
      //     oral7,
      //     uploadData: "internalScan2"
      //   });

      //   if (photoGraphResp?.data?.type === 1 && oralFirstResp?.data?.type === 1 && oralSecondtResp?.data?.type === 1) {
      //     GetToast("Your Order Successfully Initiated and in Draft Stage, you can continue submitting other details or come back and edit this order from Orders List !!", "success");
      //     setActiveTab("Treatment Goals");
      //   } else {
      //     GetToast("OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.", "error");
      //   }
      // } else {
      setActiveTab(ORDER_STAGE.DISCLAIMER);
      submitOrderData({
        photographs: {
          imageOne: imageOneUrl,
          imageTwo: imageTwoUrl,
          imageThree: imageThreeUrl,
          imageFour: imageFourUrl,
          imageFive: imageFiveUrl,
          imageSix: imageSixUrl,
          imageSeven: imageSevenUrl,
          imageEight: imageEightUrl,
          imageNine: imageNineUrl,
          imageTen: imageTenUrl,
        },
        radiographs: {
          orthopantomogram: radiograph1,
          periapicalXrays: radiograph2,
          cephalogram: radiograph3,
        },
        intraOralScans: {
          upperScan: oral1,
          lowerScan: oral2,
          biteScan1: oral3,
          biteScan2: oral4,
        },
        rawDicomData: {
          fileOne: dicom1,
          fileTwo: dicom2,
          fileThree: dicom3,
        },
        additionalFiles: additionalFiles,
      });
      // }
    }

    //setUploadData(data);
    // localStorage.setItem("uploadPhotoGraphs", JSON.stringify(data));
  };

  console.log("arrayOfPhotoGraphStates", arrayOfPhotoGraphStates);

  return (
    <div>
      <p className="text-[17px] text-[#777] my-3">
        Please use only compliant formats with all upload buttons. Each button may only be used for
        one file in the right format. Any additional files that are not in a compliant format may be
        uploaded using the Additional Files buttons at the bottom of the form that allow any file
        format.
      </p>
      <div className="mb-8">
        <p className="font-medium text-[24px] text-white my-4">
          Photographs and Face Scans{" "}
          {isFieldRequired("photographs") ? (
            <span className="text-red-500">*</span>
          ) : (
            <small>{"(Optional)"}</small>
          )}
        </p>
        <p className="text-[17px] text-[#777] my-3">
          Please use the appropriate icon to upload photographs or face scans. Images should be of
          sufficient resolution. Mirror photographs should be appropriately rotated and flipped.
          Direct photographs should also ideally be appropriately rotated. Please crop all
          unnecessary data. If a file/folder has been uploaded incorrectly, simply replace the file/folder by
          uploading the right file/folder. A delete functionality is not available.
        </p>
        <p className="text-[17px] text-[#777] my-3">
          (Recognised image formats for photographs include .jpeg, .jpg, .png, .tiff, .bmp, .heif or
          .webp. Recognised face scan formats include .stl, .ply or .obj)
        </p>
        <div className="md:block hidden">
          <UploadPhotoGraph
            rows={2}
            cols={5}
            gapX={8}
            gapY={20}
            height={"150px"}
            width={"190px"}
            acceptedFileTypes={".jpeg, .jpg, .png, .tiff, .bmp, .heif, .webp"}
            arrayOfStates={arrayOfPhotoGraphStates}
            orderObjId={orderByData?._id}
            fileType={"photograhpsAndFaceScans"}
          />
        </div>
        <div className="md:hidden block">
          <UploadPhotoGraph
            rows={""}
            cols={2}
            gapX={16}
            gapY={16}
            height={"130px"}
            width={"100%"}
            acceptedFileTypes={".jpeg, .jpg, .png, .tiff, .bmp, .heif, .webp"}
            arrayOfStates={arrayOfPhotoGraphStates}
            orderObjId={orderByData?._id}
            fileType={"photograhpsAndFaceScans"}
          />
        </div>
        {!(photoGraphAllInitialStatesNotNull === true) ? (
          <p className="text-sm text-red-500">Upload at least one image</p>
        ) : (
          <></>
        )}
      </div>
      <div className="mb-8">
        <p className="font-medium text-[24px] text-white my-4">
          Radiographs{" "}
          {isFieldRequired("radiographs") ? (
            <span className="text-red-500">*</span>
          ) : (
            <small>{"(Optional)"}</small>
          )}
        </p>
        <p className="text-[17px] text-[#777] my-3">
          If a file/folder has been uploaded incorrectly, simply replace the file/folder by uploading the right
          file. A delete functionality is not available.
        </p>
        <p className="text-[#777] text-[17px] my-3">
          (Recognised image formats for radiographs include .jpeg, .jpg, .png, .tiff, .bmp, .heif or
          .webp)
        </p>
        <div className="md:block hidden">
          <UploadPhotoGraph
            rows={1}
            cols={3}
            gapX={10}
            gapY={16}
            height={"156px"}
            width={"100%"}
            arrayOfStates={arrayOfRadiographs}
            orderObjId={orderByData?._id}
            fileType={"radiographs"}
            acceptedFileTypes={".jpeg, .jpg, .png, .tiff, .bmp, .heif, .webp"}
          />
        </div>
        <div className="md:hidden block">
          <UploadPhotoGraph
            rows={1}
            cols={1}
            gapX={10}
            gapY={16}
            height={"156px"}
            width={"100%"}
            arrayOfStates={arrayOfRadiographs}
            orderObjId={orderByData?._id}
            fileType={"radiographs"}
            acceptedFileTypes={".jpeg, .jpg, .png, .tiff, .bmp, .heif, .webp"}
          />
        </div>
        {!(oralSecondAllInitialStatesNotNull === true) ? (
          <p className="text-sm text-red-500">Upload at least one image</p>
        ) : (
          <></>
        )}
      </div>
      <div className="mb-8">
        <p className="font-medium text-[24px] text-white my-4">
          Intra Oral Scans{" "}
          {isFieldRequired("intraOralScans") ? (
            <span className="text-red-500">*</span>
          ) : (
            <small>{"(Optional)"}</small>
          )}
        </p>
        <p className="text-[#777] text-[17px] my-3">
          Please upload intraoral scans using the appropriate icons. If you have only one bite scan,
          please use Bite Scan 1. If you have no bite scans, please upload only the upper and lower
          intraoral scans. If a file/folder has been uploaded incorrectly, simply replace the file/folder by
          uploading the right file/folder. A delete functionality is not available.
        </p>
        <p className="text-[#777] text-[17px] my-3">
          (Recognised scan formats for intraoral scans include .dxd, .stl, .ply, .obj, .dcm or .3sz)
        </p>
        <div className="md:block hidden">
          <UploadPhotoGraph
            rows={1}
            cols={4}
            gapX={16}
            gapY={16}
            height={"156px"}
            width={"211px"}
            arrayOfStates={arrayOfIntraOralScans}
            orderObjId={orderByData?._id}
            fileType={"internalScan"}
            acceptedFileTypes={".dxd, .stl, .ply, .obj, .dcm, .3sz"}
          />
        </div>
        <div className="md:hidden block">
          <UploadPhotoGraph
            rows={""}
            cols={1}
            gapX={16}
            gapY={16}
            height={"156px"}
            width={"100%"}
            arrayOfStates={arrayOfIntraOralScans}
            orderObjId={orderByData?._id}
            fileType={"internalScan"}
            acceptedFileTypes={".dxd, .stl, .ply, .obj, .dcm, .3sz"}
          />
        </div>
        {!(oralFirstAllInitialStatesNotNull === true) ? (
          <p className="text-sm text-red-500">Upload at least one image</p>
        ) : (
          <></>
        )}
      </div>

      <div className="mb-8">
        <p className="font-medium text-[24px] text-white my-4">
          Raw DICOM Data{" "}
          {isFieldRequired("rawDICOMData") ? (
            <span className="text-red-500">*</span>
          ) : (
            <small>{"(Optional)"}</small>
          )}
        </p>
        <p className="text-[#777] text-[17px] my-3">
          Use any one of the three icons below to upload your CBCT data. You can choose between a
          single .DCM file, a compressed .ZIP file containing all your DICOM data or simply upload
          the entire folder containing your data in multiple files. If a file/folder has been uploaded
          incorrectly, simply replace the file/folder by uploading the right file/folder. A delete functionality
          is not available.
        </p>
        <div className="md:block hidden">
          <UploadPhotoGraph
            rows={1}
            cols={3}
            gapX={10}
            gapY={16}
            height={"156px"}
            width={"100%"}
            arrayOfStates={arrayOfDicomData}
            orderObjId={orderByData?._id}
            fileType={"rawDicom"}
            acceptedFileTypes={"*"}
          />
        </div>
        <div className="md:hidden block">
          <UploadPhotoGraph
            rows={1}
            cols={1}
            gapX={10}
            gapY={16}
            height={"156px"}
            width={"100%"}
            arrayOfStates={arrayOfDicomData}
            orderObjId={orderByData?._id}
            fileType={"rawDicom"}
            acceptedFileTypes={"*"}
          />
        </div>
        {!(oralSecondAllInitialStatesNotNull === true) ? (
          <p className="text-sm text-red-500">Upload at least one image</p>
        ) : (
          <></>
        )}
      </div>

      <div className="mb-8">
        <p className="font-medium text-[24px] text-white my-4">
          Additional Files{" "}
          {isFieldRequired("additionalFiles") ? (
            <span className="text-red-500">*</span>
          ) : (
            <small>{"(Optional)"}</small>
          )}
        </p>
        <FileUploadComponent
          className="filepond-grid"
          styleItemPanelAspectRatio={9 / 16}
          allowMultipleFiles={true}
          acceptedFileTypes={["image/jpeg", "image/png"]}
          initialFiles={Object.values(additionalFiles)?.map((d) => ({
            source: d?.s3Url,
            options: { type: "local" },
          }))}
          onUpdate={(fileItems) => {
            setAdditionalFiles((prev) => {
              console.log("fileItems", fileItems);
              console.log("fileItems additionalFiles", prev);
              const newItems = structuredClone(prev);
              if (Object.keys(newItems || {})?.length > fileItems?.length) {
                Object.keys(newItems || {})?.forEach(async (fileKey) => {
                  const findItem = fileItems?.find((d) => d?.id === fileKey);
                  console.log("findItem", findItem);
                  if (!findItem?.id) {
                    console.log("deleted fileItems", fileItems);
                    const accessToken = await getAccessTokenSilently({
                      authorizationParams: {
                        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
                      },
                    });

                    const item = newItems?.[fileKey];

                    await deleteOrderScans({
                      accessToken,
                      _id: item?._id,
                    });
                    delete newItems[fileKey];
                  }
                });
              }
              return newItems;
            });
          }}
          onUpload={async (uploadedFiles, id) => {
            const file = uploadedFiles[0];
            // Access and use the uploaded files here
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
              },
            });
            const resp = await saveFile({
              accessToken,
              file,
            });
            if (resp?.data?.url) {
              const saveResp = await saveOrderScans({
                accessToken,
                orderObjId: orderByData?._id,
                fileType: "additionalFiles",
                fileFor: id,
                s3Url: resp?.data?.url,
              }).unwrap();
              console.log("saveResp", saveResp);
              setAdditionalFiles((prev) => ({
                ...prev,
                [id]: { _id: saveResp?.data[0]?._id, s3Url: resp?.data?.url },
              }));
            }
          }}
        />
      </div>

      <div className="flex items-center md:justify-end gap-4 pt-8 flex-wrap ">
        <button
          onClick={() => {
            setActiveTab(ORDER_STAGE.CASE_DETAILS);
          }}
          className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
        >
          Back
        </button>
        <button
          onClick={() => {
            submitUploadFilesForm();
          }}
          className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
        >
          Save & Continue
        </button>
      </div>
    </div>
  );
}

export default UploadPhotoGraphsSection;

export const UploadPhotoGraph = ({
  rows,
  cols,
  gapX,
  gapY,
  height,
  width,
  arrayOfStates,
  orderObjId,
  fileType,
  acceptedFileTypes,
}) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: `repeat(${rows}, minmax(0, 1fr))`,
        gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
        gridGap: `${gapY}px ${gapX}px `,
      }}
      className="bg-backgroundV2 text-white p-4 rounded-md"
    >
      {arrayOfStates.map((item) => (
        <div style={{ height: `${height}`, width: `${width}` }}>
          <ImageUpload
            placeholderImage={item?.placeholderImage}
            imageUrl={item?.initialState?.s3Url}
            fileName={item?.initialState?.fileName}
            id={item?.initialState?._id}
            fileFor={item?.initialState?.fileFor}
            fileType={fileType}
            setImageUrl={item?.setState}
            title={item?.title}
            orderObjId={orderObjId}
            acceptedFileTypes={item?.acceptedFileTypes ?? acceptedFileTypes}
            showImagePreview={item?.showImagePreview !== false ? true : false}
            allowFolderUpload={item?.allowFolderUpload}
          />
        </div>
      ))}
    </div>
  );
};

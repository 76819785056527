import React, { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import TextInputField from "../../../Components/InputField-Comps/TextInputField";
import NumberInputField from "../../../Components/InputField-Comps/NumberInputField";
// eslint-disable-next-line no-unused-vars
import TextAreaInputField from "../../../Components/InputField-Comps/TextAreaInputField";
import UseAccessToken from "../../../hooks/UseAccessToken";
import headerBG from "../../../Assets/images/headerBG.png";
import ConnectingLine from "../../../Assets/images/connectingLine.png";
import Modal from "react-modal";
import ReactDOMServer from "react-dom/server";

import {
  setServiceProviderPersonalDetails,
  setServiceProviderAddressDetails,
  setServiceProviderBankDetails,
  setServiceProviderServicesOffered,
} from "../../../reducers/registrationReducer";

import {
  useSavePersonalDetailsSpMutation,
  useSaveAddressDetailsMutation,
  useSaveBankDetailsMutation,
  useGetProfileMutation,
  useReadPersonalDetailsMutation,
  useUpdatePersonalDetailsMutation,
} from "../../../services/user";

import {
  useDeleteUserSoftwareMutation,
  useGetCurrenciesMutation,
  useGetRegistrationQuestionsMutation,
  useGetSoftwareCategoriesMutation,
  useSaveUserQnAMutation,
  useSaveUserSoftwareMutation,
} from "../../../services/util";

import CurrencyDropDown from "../../../Components/InputField-Comps/CurrencyDropDown";
import { GetToast } from "../../../Components/Toast-Comps/GetToast";
import useWindowWidth from "../../../Components/Accordian/WindowsWidth";
import { AccordionItem } from "../../../Components/Accordian/Accordian";
import NumberInputWithCountryCodeField from "../../../Components/InputField-Comps/NumberInputWithCountryCodeField";
import { CircularCheckbox } from "../../../Components/UserManagement-Comps/CircularForm";
import ActiveToggleButton from "../../../Components/UserManagement-Comps/ActiveToggleButton";
import SignatureCanvas from "react-signature-canvas";
import jsPDF from "jspdf";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import LocationInputV2 from "../../../Components/InputField-Comps/LocationInputV2";
import TimeZoneInputField from "../../../Components/InputField-Comps/TimeZoneInputField";
import { Checkbox } from "../../../Components/InputField-Comps/CheckboxField";
import AccordionV2 from "../../../Components/InputField-Comps/AccordionV2";
import { useGetServicesCategoriesMutation } from "../../../services/servicesCategory";
import {
  useDeleteUserServiceMutation,
  useSaveUserServiceMutation,
} from "../../../services/userServices";
import { useSaveFileMutation } from "../../../services/uploadFiles";
import { MAP_LIBRARIES } from "../../../Utils/utility";
import { setUserProfileDetails } from "../../../reducers/PersonalDetailsReducer";
import { FaCircle } from "react-icons/fa";
import { USER_SIGNATURE_CHOICE } from "../../../constants/OrderFormsDisclaimers";

const tncList = [
  "You advise that all information provided during this sign up process is true to the best of your knowledge and you agree that providing false or misleading information will make you liable for removal as a service provider.",
  "You agree to provide digital design services and dental laboratory services (if applicable) to Digital Dentition, an online initiative of The ACE Institute, New Zealand.",
  "You agree to use only legal and original software for all design services and undertake to be the sole owner of all software you use and keep all relevant subscriptions up to date.",
  "You agree to receive all payments only into your nominated bank account by bank transfer or online payment and undertake to pay all local taxes and duties as relevant in your country.",
  "You agree to refrain from contacting any of the clients (dentists) of Digital Dentition directly except through the in built messaging system. You further agree not to reveal your own identity or personal details to the clients (dentists). You also understand that any manner of solicitation of the business of Digital Dentition's clients (dentists) will be regarded as a serious infringement of this site's terms and will be prosecuted to the full extent of the law in addition to removal from the site as a service provider.",
  "You agree to submit yourself to the dispute resolution process of this website that provides you with an additional two opportunities to obtain client dentist satisfaction at the sole discretion of the dentist being willing to consider this. You also accept that no payment will be made for services if the client dentist is not fully satisfied with the service provided.",
  "You agree that the legal jurisdiction for resolution of all disputes, arbitration and proceedings will be the courts of New Zealand.",
  "You agree that the identity and other aspects of the patient file are to be protected under privacy laws and you accept that all file transfers with regard to your design services will only be downloaded and uploaded within the site's privacy compliant transfer environment and that no other route will be used for the transmission of digital data. You further agree that any infringement of the patient's privacy will involve legal prosecution to the fullest extent of the law and removal from the site as a service provider.",
  "You agree to never post any data, design file, screenshot or photograph of your services provided to Digital Dentition on any site in the private or public domain including electronic and print or social media of any kind including but not limited to Facebook, Instagram, YouTube, Twitter, LinkedIn, TikTok, Pinterest and other such platforms. You further agree that any infringement of Digital Dentition's privacy policy will involve legal prosecution to the fullest extent of the law and removal from the site as a service provider.",
  "You accept Digital Dentition's 'Privacy Policy' that can be accessed here (hyperlink) and imply that your signature below indicates acceptance of the site's Privacy Policy.",
  "In acceptance of these terms and conditions, you sign this agreement in acceptance below:",
];

function ServiceProviderForm({ emailFromUserProfile, setProfileId, userInfo }) {
  const dispatch = useDispatch();

  const { userProfileDetails } = useSelector((state) => state.personalDetails);
  const serviceProviderDetails = useSelector((state) => state.registrationDetails.serviceProvider);

  console.log("serviceProviderDetails", serviceProviderDetails);

  const [savePersonalDetailsSp] = useSavePersonalDetailsSpMutation();
  const [updatePersonalDetails] = useUpdatePersonalDetailsMutation();
  const [saveAddressDetails] = useSaveAddressDetailsMutation();
  const [saveBankDetails] = useSaveBankDetailsMutation();
  const [getCurrencies] = useGetCurrenciesMutation();
  const [getProfile] = useGetProfileMutation();
  const [saveUserService] = useSaveUserServiceMutation();
  const [deleteUserService] = useDeleteUserServiceMutation();
  const [getServicesCategories] = useGetServicesCategoriesMutation();
  const [getSoftwareCategories] = useGetSoftwareCategoriesMutation();
  const [saveUserSoftware] = useSaveUserSoftwareMutation();
  const [deleteUserSoftware] = useDeleteUserSoftwareMutation();
  const [getRegistrationQuestions] = useGetRegistrationQuestionsMutation();
  const [saveUserQnA] = useSaveUserQnAMutation();
  const [readPersonalDetails] = useReadPersonalDetailsMutation();
  const navigate = useNavigate();
  const accessToken = UseAccessToken();
  const tabNamesArray = [
    "Personal Details",
    "Address Details",
    "Services Offered",
    "Beneficiary Details",
    "Terms & Conditions",
  ];

  const items = [
    {
      id: 1,
      title: "Section 1",
      content: [
        { id: 1, text: "Item 1" },
        { id: 2, text: "Item 2" },
        { id: 3, text: "Item 3" },
      ],
    },
    {
      id: 2,
      title: "Section 2",
      content: [
        { id: 4, text: "Item 4" },
        { id: 5, text: "Item 5" },
        { id: 6, text: "Item 6" },
      ],
    },
    // Add more items as needed
  ];
  const selectedTitlesArray = [];
  //   use states
  const [activeTab, setActiveTab] = useState(tabNamesArray[0]);

  //profile details states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  // const [registrationNumber, setRegistrationNumber] = useState("");
  const [alertsForMobile, setAlertsForMobile] = useState(false);
  const [alertsForEmail, setAlertsForEmail] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [alreadyWorkingWithDentalLab, setAlreadyWorkingWithDentalLab] = useState();
  const [dentalDesignServiceProvider, setDentalDesignServiceProvider] = useState();
  const [tncUrl, setTncUrl] = useState();

  const [ownPhysicalLaboratory, setOwnPhysicalLaboratory] = useState("");
  const [allCadSoftwareCategories, setAllCadSoftwareCategories] = useState([]);
  const [softwaresUsed, setSoftwaresUsed] = useState([]);
  const [allRegistrationQuestions, setAllRegistrationQuestions] = useState([]);
  const [allRegistrationQnA, setAllRegistrationQnA] = useState([]);
  const [isProfileFormSubmitted, setIsProfileFormSubmitted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isProfileValid, setIsProfileValid] = useState(false);
  const { getAccessTokenSilently, logout } = useAuth0();

  // ends here

  //   address details states
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState({ lat: 37.7577, lng: -122.4376 });
  const [bankLocation, setBankLocation] = useState({ lat: 37.7577, lng: -122.4376 });
  const [country, setCountry] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [labAddress, setLabAddress] = useState("");
  const [laboratoryLocation, setLaboratoryLocation] = useState({ lat: 37.7577, lng: -122.4376 });
  const [labCountry, setLabCountry] = useState("");
  const [labLatitude, setLabLatitude] = useState("");
  const [labLongitude, setLabLongitude] = useState("");
  const [labPostalCode, setLabPostalCode] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState("");
  const [locationServiceProvider, setLocationServiceProvider] = useState("");

  const [isAddressFormSubmitted, setIsAddressFormSubmitted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isAddressValid, setIsAddressValid] = useState(false);
  //   ends here

  // services offered here
  // const [selectedTitles, setSelectedTitles] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isServicesFormSubmitted, setIsServicesFormSubmitted] = useState(false);

  // services offered ends here

  // Bank details here
  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [accountHoldersAddress, setAccountHoldersAddress] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [ibanNumber, setIbanNumber] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState("");

  const [isBankFormSubmitted, setIsBankFormSubmitted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isBankValid, setIsBankValid] = useState(false);

  // Tnc Details here
  const [isTncChecked, setIsTncChecked] = useState(false);
  const [userSignature, setUserSignature] = useState(null);

  //Google Maps comps and state here
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  const [formattedServiceData, setFormattedServiceData] = useState([]);

  const getCurrencyAPIData = async (accessToken) => {
    const resp = await getCurrencies({ accessToken }).unwrap();
    setCurrencyOptions(resp);
  };

  const getCurrenciesData = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken) {
      await getCurrencyAPIData(accessToken);
    }
  };

  const readProfileInfo = async (accessToken) => {
    const response = await readPersonalDetails({ accessToken }).unwrap((res) => res);
    dispatch(setUserProfileDetails(response?.data?.[0]));

    return response?.data?.[0];
  };

  // Bank details ends here

  useEffect(() => {
    // Simulating fetching options from an external source
    // setTimeout(() => {
    //   const fetchedCountryOptions = [
    //     { value: "india", label: "india" },
    //     { value: "option2", label: "Option 2" },
    //     { value: "option3", label: "Option 3" },
    //   ];
    //   setCountryOptions(fetchedCountryOptions);
    // }, 1000);
    // setTimeout(() => {
    //   const fetchedCurrencyOptions = [
    //     { value: "dollar", label: "dollar" },
    //     { value: "option2", label: "Option 2" },
    //     { value: "option3", label: "Option 3" },
    //   ];
    //   setCurrencyOptions(fetchedCurrencyOptions);
    // }, 1000);

    getCurrenciesData();
    getAllServices();
    getAllSoftwareCategories();
    getAllRegistrationQuestions();
    getEmailAddress();
  }, []);

  const getEmailAddress = async () => {
    let userEmail = emailFromUserProfile;
    // Check if User Email exists otherwise fetch from profile data
    if (!userEmail) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const response = await getProfile(accessToken).unwrap();
      userEmail = response["email"];
    }
    setEmailAddress(userEmail);
  };

  const getAllServices = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const response = await getServicesCategories(accessToken);
    if (response) {
      const formattedData = response?.data?.data.map((ser) => {
        return {
          id: ser._id,
          title: ser.serCategoryName,
          icon: ser.serCategroyIcon,
          catservices: ser.catservices,
        };
      });
      setFormattedServiceData(formattedData);
    }
  };

  const getAllSoftwareCategories = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const response = await getSoftwareCategories(accessToken);
    if (response) {
      setAllCadSoftwareCategories(response?.data?.data || []);
      if (response?.data?.data && response?.data?.data?.length > 0) {
        setSoftwaresUsed(
          response?.data?.data?.map((d, i) => ({
            _id: d?._id,
            softwareCategoryName: d?.softwareCategoryName,
            selectedItems: [],
          }))
        );
      }
    }
  };

  const getAllRegistrationQuestions = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const response = await getRegistrationQuestions(accessToken);
    if (response) {
      setAllRegistrationQuestions(response?.data?.data || []);
      if (response?.data?.data && response?.data?.data?.length > 0) {
        setAllRegistrationQnA(
          response?.data?.data?.map((d, i) => ({
            _id: d?._id,
            selectedAnswerObjId: "",
          }))
        );
      }
    }
  };

  //   handlers

  const handleTabSuper = (tabName) => {
    setActiveTab(tabName);
  };
  const handleBackTab = (tabName) => {
    const index = tabNamesArray.indexOf(tabName);
    const prevString = index > 0 ? tabNamesArray[index - 1] : null;
    if (index > 0) {
      switch (index) {
        case 1:
          setIsProfileFormSubmitted(false);
          break;
        case 2:
          setIsAddressFormSubmitted(false);
          break;
        case 3:
          setIsServicesFormSubmitted(false);
          break;
        default:
          break;
      }

      setActiveTab(prevString);
    }
  };
  const handleNextTab = (tabName) => {
    const index = tabNamesArray.indexOf(tabName);
    const nextString = tabNamesArray[index + 1];
    switch (tabName) {
      case "Personal Details":
        submitPersonDetails(nextString);
        break;
      case "Address Details":
        submitAddressDetails(nextString);
        break;
      case "Services Offered":
        submitServicesOffered(nextString);
        break;
      case "Beneficiary Details":
        submitBankDetails(nextString);
        break;
      case "Terms & Conditions":
        submitSignatureDetails(nextString);
        break;
      default:
        break;
    }
  };

  //   FIRST FORM SUBMISSION FUNCTION
  const submitPersonDetails = async (nextString) => {
    // Perform validation for each input
    const isFirstNameValid = !isFieldRequired("firstName") || validateFirstName(firstName);
    const isLastNameValid = !isFieldRequired("lastName") || validateLastName(lastName);
    const isMobileNumberValid =
      !isFieldRequired("mobileNumber") || validateMobileNumberWithCountryCode(mobileNumber);
    // const isRegistrationNumberValid =
    //   !isFieldRequired("registrationNumber") ||
    //   validateRegistrationNumber(registrationNumber);
    const isTextMessageCheckedValid =
      !isFieldRequired("textMessages") || validateTextMessageChecked(alertsForMobile);
    const isEmailAlertsCheckedValid =
      !isFieldRequired("emailAlerts") || validateEmailAlertsChecked(alertsForEmail);
    const isTncCheckedValid = !isFieldRequired("tnc") || validateTncChecked(isTncChecked);

    // Set overall form validity
    setIsProfileValid(
      isFirstNameValid &&
        isLastNameValid &&
        isMobileNumberValid &&
        // isRegistrationNumberValid &&
        isTextMessageCheckedValid &&
        isEmailAlertsCheckedValid &&
        isTncCheckedValid
    );

    setIsProfileFormSubmitted(true);

    if (
      isFirstNameValid &&
      isLastNameValid &&
      isMobileNumberValid &&
      // isRegistrationNumberValid &&
      isTextMessageCheckedValid &&
      isEmailAlertsCheckedValid
    ) {
      // Submit the form or perform any other action
      const profileDetailData = {
        firstName: firstName,
        lastName: lastName,
        mobile: mobileNumber,
        gender: genderValue,
        alertsForMobile: alertsForMobile,
        alertsForEmail: alertsForEmail,
        timeZone: selectedTimeZone,
        email: emailAddress,
        dentalDesignServiceProvider: dentalDesignServiceProvider,
        alreadyWorkingWithDentalLab: alreadyWorkingWithDentalLab,
        active: 0,
      };
      dispatch(setServiceProviderPersonalDetails(profileDetailData));
      setActiveTab(nextString);
    } else {
      console.log("Form Not Valid");
    }
  };
  // FIRST FORM VALIDATIONS
  const validateFirstName = (value) => {
    return value?.trim().length > 0;
  };
  const validateGender = (value) => {
    return value?.trim().length > 0;
  };
  const validateConsent = (value) => {
    return value?.trim().length > 0;
  };
  const validateWorkingWithLaboratory = (value) => {
    return value?.trim().length > 0;
  };
  const validateProvideIndependentDesignService = (value) => {
    return value?.trim().length > 0;
  };
  const validateLastName = (value) => {
    return value?.trim().length > 0;
  };
  // const validateMobileNumber = (value) => {
  //   const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number
  //   return phoneRegex.test(value);
  // };

  const validateMobileNumberWithCountryCode = (value) => {
    const phoneRegex = /^(?:\+?\d{1,3})?[-.\s]?\d{7,15}$/; // Assuming a 10-digit phone number
    return phoneRegex.test(value);
  };
  // const validateRegistrationNumber = (value) => {
  //   const phoneRegex = /^.{3,10}$/;
  //   return phoneRegex.test(value);
  // };
  const validateTextMessageChecked = (value) => {
    if (value === true) {
      return true;
    } else {
      return false;
    }
  };
  const validateEmailAlertsChecked = (value) => {
    if (value === true) {
      return true;
    } else {
      return false;
    }
  };
  const validateTncChecked = (value) => {
    if (value) {
      return true;
    } else {
      return false;
    }
  };
  // FIRST FORM VALIDATIONS ENDS

  //   SECOND FORM SUBMISSION FUNCTION
  const submitAddressDetails = (nextString) => {
    // Perform validation for each input
    const isAddressValid = !isFieldRequired("address") || validateAddress(address);
    const isCountryValid = !isFieldRequired("country") || country.length > 0;
    //|| validateCountryDropDown(selectedCountryOption);
    const isPinCodeValid = !isFieldRequired("pinCode") || validatePinCode(pinCode);

    // Set overall form validity
    setIsAddressValid(isAddressValid && isCountryValid && isPinCodeValid);
    setIsAddressFormSubmitted(true);

    if (isAddressValid && isCountryValid && isPinCodeValid) {
      // Submit the form or perform any other action
      const addressData = {
        address: address,
        country: country,
        pincode: pinCode,
        latitude: latitude ?? "0",
        longitude: longitude ?? "0",
        ownPhysicalLaboratory: ownPhysicalLaboratory,
        labAddress: labAddress,
        labCountry: labCountry,
        labPostalCode: labPostalCode,
        labLatitude: labLatitude ?? "0",
        labLongitude: labLongitude ?? "0",
        // country: selectedCountryOption,
        //coordinates: location,
      };

      dispatch(setServiceProviderAddressDetails(addressData));
      setActiveTab(nextString);
    } else {
      console.log("Form Not Valid");
    }
  };

  // console.log(location.split(",")[0], 'LOCATION')
  // SECONDS FORM VALIDATION FUNCTION
  const validateAddress = (value) => {
    return value?.trim().length > 0;
  };
  const validateCountry = (value) => {
    return value?.trim().length > 0;
  };
  const validateCountryDropDown = () => {
    if (!selectedCountryOption) {
      return false;
    }
    return true;
  };
  const validatePinCode = (value) => {
    return value?.trim().length > 0;
  };
  // SECOND FORM VALIDATION ENDS

  //THIRD FORM SUBMISSION HERE
  const submitServicesOffered = (nextString) => {
    setActiveTab(nextString);
  };
  //THIRD FORM SUBMISSION ENDS

  //FOURTH FORM SUBMISSION HERE
  const submitBankDetails = async (nextString) => {
    // Perform validation for each input
    const isBankNameValid = !isFieldRequired("bankName") || validateBankName(bankName);
    const isBankAccountNumberValid =
      !isFieldRequired("bankAccountNumber") || validateBankAccountNumber(bankAccountNumber);
    const isAccountHolderNameValid =
      !isFieldRequired("accountHolderName") || validateAccountHolderName(accountHolderName);
    const isSwiftCodeValid = !isFieldRequired("swiftCode") || validateSwiftCode(swiftCode);
    const isAccountHoldersAddressValid =
      !isFieldRequired("accountHoldersAddress") || validateAddress(accountHoldersAddress);
    const isBankAddressValid = !isFieldRequired("bankAddress") || validateAddress(bankAddress);
    const isIbanNumberValid = !isFieldRequired("ibanNumber") || validateIbanNumber(ibanNumber);
    const isCurrencyValid =
      !isFieldRequired("currency") || validateCurrencyDropDown(selectedCurrencyOption);

    // Set overall form validity
    setIsBankValid(
      isBankNameValid &&
        isBankAccountNumberValid &&
        isAccountHolderNameValid &&
        isSwiftCodeValid &&
        isAccountHoldersAddressValid &&
        isBankAddressValid &&
        isIbanNumberValid &&
        isCurrencyValid
    );
    setIsBankFormSubmitted(true);

    if (
      isBankNameValid &&
      isBankAccountNumberValid &&
      isAccountHolderNameValid &&
      isSwiftCodeValid &&
      isAccountHoldersAddressValid &&
      isIbanNumberValid &&
      isCurrencyValid
    ) {
      // Submit the form or perform any other action
      const bankData = {
        bankName: bankName,
        accountNo: bankAccountNumber,
        accountHolderName: accountHolderName,
        swiftCode: swiftCode,
        bankAddress: bankAddress,
        accountHolderAddress: accountHoldersAddress,
        iBANNumber: ibanNumber,
        currency: selectedCurrencyOption,
      };
      dispatch(setServiceProviderBankDetails(bankData));
      setActiveTab(nextString);
      // await saveServiceProviderDetails(bankData);
      // SUBMIT THE DATA AND NAVIGATE TO THE SERVICE PROViDER DASHBOARD
    } else {
      console.log("Form Not Valid");
    }
  };

  const submitSignatureDetails = async () => {
    await saveServiceProviderDetails();
  };

  const saveServiceProviderDetails = async () => {
    let personalDetailsPromise = null;
    if (!userProfileDetails?._id) {
      savePersonalDetailsSp({
        accessToken,
        ...serviceProviderDetails.personalDetails,
        isTncChecked: isTncChecked,
        tncUrl: isTncChecked ? tncUrl : "",
      }).unwrap();
    } else {
      updatePersonalDetails({
        accessToken,
        ...serviceProviderDetails.personalDetails,
        isTncChecked: isTncChecked,
        tncUrl: isTncChecked ? tncUrl : "",
        userAuthId: userProfileDetails?.userAuthId,
        _id: userProfileDetails?._id,
      }).unwrap();
    }
    await Promise.all([
      personalDetailsPromise,
      saveAddressDetails({
        accessToken,
        ...serviceProviderDetails.addressDetails,
      }).unwrap(),
      saveBankDetails({
        accessToken,
        ...serviceProviderDetails.bankDetails,
      }).unwrap(),
    ]);
    GetToast(
      "Thank you for registering as a service provider with digitaldentition.com. We will be in touch with you shortly following approval of your registration to complete the process with an onboarding session",
      "success"
    );
    navigate("/dashboard");
  };

  //FOURTH FORM VALIDATION HERE
  const validateBankName = (value) => {
    return value?.trim().length > 0;
  };
  const validateBankAccountNumber = (value) => {
    // return ((value?.trim().length >= 8) && (value?.trim().length <= 16));
    return value?.trim().length > 0;
  };
  const validateAccountHolderName = (value) => {
    return value?.trim().length > 0;
  };
  const validateSwiftCode = (value) => {
    return value?.trim().length >= 6;
  };
  const validateIbanNumber = (value) => {
    // return value?.trim().length === 22;
    return value?.trim().length > 0;
  };
  const validateTimeZone = (value) => {
    return value?.trim().length > 0;
  };
  const validateCurrencyDropDown = () => {
    if (!selectedCurrencyOption) {
      return false;
    }
    return true;
  };
  //FOURTH FORM VALIDATION ENDS

  // required fields here (do not delete) -> comment those fields which are not required fields
  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "firstName",
      "lastName",
      "mobileNumber",
      "registrationNumber",
      "gender",
      "alreadyWorkingWithDentalLab",
      "dentalDesignServiceProvider",
      "timeZone",
      // "textMessages",
      //"emailAlerts",

      "address",
      "country",
      "pinCode",

      "bankName",
      "bankAccountNumber",
      "accountHolderName",
      "swiftCode",
      "accountHoldersAddress",
      "bankAddress",
      // "ibanNumber",
      "currency",
      "tnc",
    ];
    return requiredFields.includes(fieldName);
  };
  //Data for Tabs

  const tabContentObject = {
    "Personal Details": (
      <PersonalDetails
        firstName={firstName}
        setFirstName={setFirstName}
        validateFirstName={validateFirstName}
        lastName={lastName}
        setLastName={setLastName}
        validateLastName={validateLastName}
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
        validateMobileNumberWithCountryCode={validateMobileNumberWithCountryCode}
        // registrationNumber={registrationNumber}
        // setRegistrationNumber={setRegistrationNumber}
        // validateRegistrationNumber={validateRegistrationNumber}
        alertsForMobile={alertsForMobile}
        setAlertsForMobile={setAlertsForMobile}
        validateTextMessageChecked={validateTextMessageChecked}
        alertsForEmail={alertsForEmail}
        selectedTimeZone={selectedTimeZone}
        setSelectedTimeZone={setSelectedTimeZone}
        setAlertsForEmail={setAlertsForEmail}
        validateEmailAlertsChecked={validateEmailAlertsChecked}
        isProfileFormSubmitted={isProfileFormSubmitted}
        isFieldRequired={isFieldRequired}
        emailAddress={emailAddress}
        alreadyWorkingWithDentalLab={alreadyWorkingWithDentalLab}
        setAlreadyWorkingWithDentalLab={setAlreadyWorkingWithDentalLab}
        dentalDesignServiceProvider={dentalDesignServiceProvider}
        setDentalDesignServiceProvider={setDentalDesignServiceProvider}
        validateTimeZone={validateTimeZone}
        genderValue={genderValue}
        setGenderValue={setGenderValue}
        validateGender={validateGender}
        validateWorkingWithLaboratory={validateWorkingWithLaboratory}
        validateProvideIndependentDesignService={validateProvideIndependentDesignService}
        validateConsent={validateConsent}
        allCadSoftwareCategories={allCadSoftwareCategories}
        softwaresUsed={softwaresUsed}
        setSoftwaresUsed={setSoftwaresUsed}
        saveUserSoftware={saveUserSoftware}
        deleteUserSoftware={deleteUserSoftware}
        allRegistrationQuestions={allRegistrationQuestions}
        allRegistrationQnA={allRegistrationQnA}
        setAllRegistrationQnA={setAllRegistrationQnA}
        saveUserQnA={saveUserQnA}
        userProfileDetails={userProfileDetails}
        readProfileInfo={readProfileInfo}
        savePersonalDetailsSp={savePersonalDetailsSp}
      />
    ),
    "Address Details": (
      <AddressDetails
        address={address}
        setAddress={setAddress}
        labAddress={labAddress}
        setLabAddress={setLabAddress}
        validateAddress={validateAddress}
        selectedCountryOption={selectedCountryOption}
        setSelectedCountryOption={setSelectedCountryOption}
        countryOptions={countryOptions}
        validateCountryDropDown={validateCountryDropDown}
        pinCode={pinCode}
        setPinCode={setPinCode}
        labPostalCode={labPostalCode}
        setLabPostalCode={setLabPostalCode}
        validatePinCode={validatePinCode}
        locationServiceProvider={locationServiceProvider}
        setLocationServiceProvider={setLocationServiceProvider}
        isAddressFormSubmitted={isAddressFormSubmitted}
        isFieldRequired={isFieldRequired}
        location={location}
        setLocation={setLocation}
        laboratoryLocation={laboratoryLocation}
        setLaboratoryLocation={setLaboratoryLocation}
        setLatitude={setLatitude}
        setLongitude={setLongitude}
        setLabLatitude={setLabLatitude}
        setLabLongitude={setLabLongitude}
        country={country}
        setCountry={setCountry}
        labCountry={labCountry}
        setLabCountry={setLabCountry}
        validateCountry={validateCountry}
        isLoaded={isLoaded}
        validateConsent={validateConsent}
        ownPhysicalLaboratory={ownPhysicalLaboratory}
        setOwnPhysicalLaboratory={setOwnPhysicalLaboratory}
      />
    ),
    "Services Offered": (
      <ServicesOffered
        items={formattedServiceData}
        selectedTitlesArray={selectedTitlesArray}
        getAccessTokenSilently={getAccessTokenSilently}
        saveUserService={saveUserService}
        deleteUserService={deleteUserService}
        servicesOffered={serviceProviderDetails.servicesOffered}
      />
    ),
    "Beneficiary Details": (
      <BankDetails
        bankName={bankName}
        setBankName={setBankName}
        validateBankName={validateBankName}
        bankAccountNumber={bankAccountNumber}
        setBankAccountNumber={setBankAccountNumber}
        validateBankAccountNumber={validateBankAccountNumber}
        accountHolderName={accountHolderName}
        setAccountHolderName={setAccountHolderName}
        validateAccountHolderName={validateAccountHolderName}
        swiftCode={swiftCode}
        setSwiftCode={setSwiftCode}
        validateSwiftCode={validateSwiftCode}
        accountHoldersAddress={accountHoldersAddress}
        setAccountHoldersAddress={setAccountHoldersAddress}
        bankAddress={bankAddress}
        setBankAddress={setBankAddress}
        validateAddress={validateAddress}
        ibanNumber={ibanNumber}
        setIbanNumber={setIbanNumber}
        validateIbanNumber={validateIbanNumber}
        currencyOptions={currencyOptions}
        selectedCurrencyOption={selectedCurrencyOption}
        setSelectedCurrencyOption={setSelectedCurrencyOption}
        validateCurrencyDropDown={validateCurrencyDropDown}
        isFieldRequired={isFieldRequired}
        isBankFormSubmitted={isBankFormSubmitted}
        isLoaded={isLoaded}
        location={location}
        setLocation={setLocation}
        bankLocation={bankLocation}
        setBankLocation={setBankLocation}
      />
    ),
    "Terms & Conditions": (
      <TermsAndConditons
        isFieldRequired={isFieldRequired}
        isTncChecked={isTncChecked}
        setIsTncChecked={setIsTncChecked}
        userSignature={userSignature}
        setUserSignature={setUserSignature}
        validateTncChecked={validateTncChecked}
        getAccessTokenSilently={getAccessTokenSilently}
        setTncUrl={setTncUrl}
      />
    ),
  };

  const handleLogout = () => {
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  const windowWidth = useWindowWidth();
  const HideDiv = windowWidth < 750;

  const [activeAccordion, setActiveAccordion] = useState(0);

  const toggleAccordion = (index) => {
    setActiveAccordion((prevActiveAccordion) => (prevActiveAccordion === index ? -1 : index));
  };

  const openAccordionBasedOnTab = () => {
    if (activeTab === "Personal Details") {
      setActiveAccordion(0);
    } else if (activeTab === "Address Details") {
      setActiveAccordion(1);
    } else if (activeTab === "Services Offered") {
      setActiveAccordion(2);
    } else if (activeTab === "Beneficiary Details") {
      setActiveAccordion(3);
    } else if (activeTab === "Terms & Conditions") {
      setActiveAccordion(4);
    }
  };

  useEffect(() => {
    openAccordionBasedOnTab();
  }, [activeTab]);

  return (
    // <div className="bg-[#353535] to-100% bg-opacity-[0.05] min-h-screen w-screen md:px-16 md:py-12 p-4  flex flex-col gap-8">
    //   <div className="flex justify-between items-center">
    //     <p className="font-medium text-[34px] leading-[30px] text-[#555]]">
    //       Select Service
    //     </p>
    //     {/* <div
    //       onClick={() => {
    //         saveServiceProviderDetails(serviceProviderDetails.bankDetails);
    //       }}
    //       style={{
    //         color: "rgb(255 126 0)",
    //         textDecoration: "underline",
    //         cursor: "pointer",
    //       }}
    //     >
    //       Skip this step
    //     </div> */}
    //     {/* <Link to={"/"}>
    //       <AiOutlineClose />
    //     </Link> */}
    //     <button
    //       onClick={() => handleLogout()}
    //       className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
    //     >
    //       Logout

    //     </button>
    //   </div>
    //   <div className="h-full w-full flex flex-col gap-8">
    //     <div className="flex-1 flex md:flex-row flex-col drop-shadow-md rounded-md">
    //       <div className="TABS-NAV bg-[#F5F5F7] md:w-[220px] w-full md:py-0 py-4">
    //         <ul className="NAV flex flex-col justify-evenly items-center md:h-full h-fit md:gap-0 gap-8">
    //           {tabNamesArray.map((title, i) => {
    //             return (
    //               <li
    //                 key={title}
    //                 className="relative cursor-pointer w-full flex items-center justify-center"
    //                 onClick={() => handleTabSuper(title)}
    //               >
    //                 <div
    //                   className={
    //                     activeTab === title
    //                       ? "text-[#4E81F4] font-medium"
    //                       : "hoverClass"
    //                   }
    //                 >
    //                   {title}
    //                 </div>

    //                 <div
    //                   className={`${activeTab === title
    //                     ? "text-white bg-[#4E81F4]"
    //                     : "text-black bg-white"
    //                     }  rounded-full flex justify-center items-center md:h-8 md:w-8 h-6 w-6 absolute md:-right-4 right-20 md:top-0  shadow-md`}
    //                 >
    //                   {i + 1}
    //                 </div>
    //               </li>
    //             );
    //           })}
    //         </ul>
    //       </div>
    //       <div className="TABS-OUTLET bg-white flex-1 h-full">
    //         {Object.keys(tabContentObject).map((key) => {
    //           if (tabNamesArray.includes(key)) {
    //             return (
    //               <div key={key}>
    //                 {activeTab === key ? (
    //                   <>
    //                     <div key={key}>
    //                       <div className="">{tabContentObject[key]}</div>
    //                     </div>
    //                   </>
    //                 ) : null}
    //               </div>
    //             );
    //           }
    //           return null;
    //         })}
    //       </div>
    //     </div>
    //     <div className="flex items-center justify-end gap-8">
    //       <button
    //         onClick={() => handleBackTab(activeTab)}
    //         className={`${activeTab === tabNamesArray[0] ? "" : ""
    //           } p-3 rounded-full bg-white `}
    //         disabled={activeTab === tabNamesArray[0] ? true : false}
    //       >
    //         <MdKeyboardBackspace
    //           className={`text-xl ${activeTab === tabNamesArray[0]
    //             ? "text-opacity-20 text-[#555]"
    //             : ""
    //             } `}
    //         />
    //       </button>
    //       <button
    //         onClick={() => handleNextTab(activeTab)}
    //         className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
    //       >
    //         Next
    //       </button>
    //     </div>
    //   </div>
    // </div>

    <>
      {HideDiv ? (
        <div className="mt-7">
          <div className="mb-7 ">
            <div className="flex justify-center rounded-full card-gradient text-lg ml-3 p-4 mr-3 font-medium text-white ">
              {" "}
              Service Provider Details
            </div>
          </div>
          <AccordionItem
            title={"Personal Details"}
            isOpen={activeAccordion === 0}
            onToggle={() => toggleAccordion(0)}
            index={"1"}
          >
            <div className="Mainbar flex flex-col">
              <div className="bg-secondary min-h-[75dvh]">
                <div>
                  <div className="TABS-OUTLET bg-secondary flex-1 h-full">
                    {Object.keys(tabContentObject).map((key) => {
                      if (tabNamesArray.includes(key)) {
                        return (
                          <div key={key}>
                            {activeTab === key ? (
                              <>
                                <div key={key}>
                                  <div className="">{tabContentObject[key]}</div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              </div>
              <div className="footer border-t bg-white border-gray-300 pt-2">
                <div className="flex items-center justify-end p-4 w-[90%] gap-8">
                  <button
                    onClick={() => handleBackTab(activeTab)}
                    className={`${
                      activeTab === tabNamesArray[0] ? "" : ""
                    } p-3 rounded-md py-3 px-8 border-2 bg-white `}
                    disabled={activeTab === tabNamesArray[0] ? true : false}
                  >
                    <p
                      className={`${
                        activeTab === tabNamesArray[0] ? "text-opacity-20 text-[#555]" : ""
                      } `}
                    >
                      Back
                    </p>
                  </button>
                  <button
                    onClick={() => handleNextTab(activeTab)}
                    className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </AccordionItem>
          <AccordionItem
            title={"Address Details"}
            isOpen={activeAccordion === 1}
            onToggle={() => toggleAccordion("")}
            index={"2"}
          >
            <div className="Mainbar flex flex-col">
              <div className="bg-secondary min-h-[75dvh]">
                <div>
                  <div className="TABS-OUTLET bg-secondary flex-1 h-full">
                    {Object.keys(tabContentObject).map((key) => {
                      if (tabNamesArray.includes(key)) {
                        return (
                          <div key={key}>
                            {activeTab === key ? (
                              <>
                                <div key={key}>
                                  <div className="">{tabContentObject["Address Details"]}</div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              </div>
              <div className="footer border-t bg-white border-gray-300 pt-2">
                <div className="flex items-center justify-end p-4 w-[90%] gap-8">
                  <button
                    onClick={() => handleBackTab(activeTab)}
                    className={`${
                      activeTab === tabNamesArray[0] ? "" : ""
                    } p-3 rounded-md py-3 px-8 border-2 bg-white `}
                    disabled={activeTab === tabNamesArray[0] ? true : false}
                  >
                    <p
                      className={`${
                        activeTab === tabNamesArray[0] ? "text-opacity-20 text-[#555]" : ""
                      } `}
                    >
                      Back
                    </p>
                  </button>
                  <button
                    onClick={() => handleNextTab(activeTab)}
                    className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </AccordionItem>
          <AccordionItem
            title={"Services Offered"}
            isOpen={activeAccordion === 2}
            onToggle={() => toggleAccordion("")}
            index={"3"}
          >
            <div className="Mainbar flex flex-col">
              <div className="bg-white min-h-[75dvh]">
                <div>
                  <div className="TABS-OUTLET bg-white flex-1 h-full">
                    {Object.keys(tabContentObject).map((key) => {
                      if (tabNamesArray.includes(key)) {
                        return (
                          <div key={key}>
                            {activeTab === key ? (
                              <>
                                <div key={key}>
                                  <div className="">{tabContentObject["Services Offered"]}</div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              </div>
              <div className="footer border-t bg-white border-gray-300 pt-2">
                <div className="flex items-center justify-end p-4 w-[90%] gap-8">
                  <button
                    onClick={() => handleBackTab(activeTab)}
                    className={`${
                      activeTab === tabNamesArray[0] ? "" : ""
                    } p-3 rounded-md py-3 px-8 border-2 bg-white `}
                    disabled={activeTab === tabNamesArray[0] ? true : false}
                  >
                    <p
                      className={`${
                        activeTab === tabNamesArray[0] ? "text-opacity-20 text-[#555]" : ""
                      } `}
                    >
                      Back
                    </p>
                  </button>
                  <button
                    onClick={() => handleNextTab(activeTab)}
                    className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </AccordionItem>
          <AccordionItem
            title={"Beneficiary Details"}
            isOpen={activeAccordion === 3}
            onToggle={() => toggleAccordion("")}
            index={"4"}
          >
            <div className="Mainbar flex flex-col">
              <div className="bg-white min-h-[75dvh]">
                <div>
                  <div className="TABS-OUTLET bg-white flex-1 h-full">
                    {Object.keys(tabContentObject).map((key) => {
                      if (tabNamesArray.includes(key)) {
                        return (
                          <div key={key}>
                            {activeTab === key ? (
                              <>
                                <div key={key}>
                                  <div className="">{tabContentObject["Beneficiary Details"]}</div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              </div>
              <div className="footer border-t bg-white border-gray-300 pt-2">
                <div className="flex items-center justify-end p-4 w-[90%] gap-8">
                  <button
                    onClick={() => handleBackTab(activeTab)}
                    className={`${
                      activeTab === tabNamesArray[0] ? "" : ""
                    } p-3 rounded-md py-3 px-8 border-2 bg-white `}
                    disabled={activeTab === tabNamesArray[0] ? true : false}
                  >
                    <p
                      className={`${
                        activeTab === tabNamesArray[0] ? "text-opacity-20 text-[#555]" : ""
                      } `}
                    >
                      Back
                    </p>
                  </button>
                  <button
                    onClick={() => handleNextTab(activeTab)}
                    className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </AccordionItem>
          <AccordionItem
            title={"Terms & Conditions"}
            isOpen={activeAccordion === 4}
            onToggle={() => toggleAccordion("")}
            index={"5"}
          >
            <div className="Mainbar flex flex-col">
              <div className="bg-white min-h-[75dvh]">
                <div>
                  <div className="TABS-OUTLET bg-white flex-1 h-full">
                    {Object.keys(tabContentObject).map((key) => {
                      if (tabNamesArray.includes(key)) {
                        return (
                          <div key={key}>
                            {activeTab === key ? (
                              <>
                                <div key={key}>
                                  <div className="">{tabContentObject["Terms & Conditions"]}</div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              </div>
              <div className="footer border-t bg-white border-gray-300 pt-2">
                <div className="flex items-center justify-end p-4 w-[90%] gap-8">
                  <button
                    onClick={() => handleBackTab(activeTab)}
                    className={`${
                      activeTab === tabNamesArray[0] ? "" : ""
                    } p-3 rounded-md py-3 px-8 border-2 bg-white `}
                    disabled={activeTab === tabNamesArray[0] ? true : false}
                  >
                    <p
                      className={`${
                        activeTab === tabNamesArray[0] ? "text-opacity-20 text-[#555]" : ""
                      } `}
                    >
                      Back
                    </p>
                  </button>
                  <button
                    onClick={() => handleNextTab(activeTab)}
                    className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </AccordionItem>
        </div>
      ) : (
        <div className="bg-[#353535] bg-opacity-[0.05] min-h-screen w-screen flex">
          <div className="side-card-gradient min-h-screen w-[25%] flex flex-col gap-7">
            <div className="flex justify-center">
              <div className="w-[70%] py-7 mt-5">
                <p className="font-bold text-3xl text-center text-white">Service Provider</p>
              </div>{" "}
            </div>
            <div className="side-card-gradient mt-[-15px]">
              <div className="TABS-NAV w-full flex justify-center">
                <ul className="NAV flex flex-col justify-evenly items-center gap-12 p-5">
                  {tabNamesArray.map((title, i) => {
                    return (
                      <li
                        key={title}
                        className="w-full flex gap-4 items-center"
                        // onClick={() => handleTabSuper(title)}
                      >
                        <div className="relative flex justify-center items-center">
                          <div
                            className={`${
                              activeTab === title
                                ? "text-white bg-primary border"
                                : "text-primary bg-white border"
                            }  rounded-full flex justify-center items-center h-8 w-8 md:top-0  shadow-md`}
                          >
                            {i + 1}
                          </div>
                          {i < tabNamesArray.length - 1 && (
                            <img className="absolute inset-y-0 pt-8" src={ConnectingLine} />
                          )}
                        </div>
                        <div
                          className={
                            activeTab === title ? "text-white font-medium" : "text-white hoverClass"
                          }
                        >
                          {title}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="Mainbar w-[75%] flex flex-col">
            <div className="h-[15dvh] relative">
              <img
                className="absolute inset-0 h-full w-full bg-black z-[9]"
                src={headerBG}
                alt="header background"
              />
              <p className="font-bold text-2xl pl-16 pt-12 pb-7 text-white relative z-10">
                {activeTab}
              </p>
            </div>
            <div className="bg-secondary min-h-[75dvh]">
              <div>
                <div className="TABS-OUTLET bg-secondary flex-1 h-full">
                  {Object.keys(tabContentObject).map((key) => {
                    if (tabNamesArray.includes(key)) {
                      return (
                        <div key={key}>
                          {activeTab === key ? (
                            <>
                              <div key={key}>
                                <div className="">{tabContentObject[key]}</div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
            <div className="footer border-t bg-secondary border-gray-300 pt-2">
              <div className="flex items-center justify-end p-4 w-[90%] gap-8">
                <button
                  onClick={() => handleBackTab(activeTab)}
                  className={`${
                    activeTab === tabNamesArray[0] ? "" : ""
                  } p-3 rounded-lg py-[10px] px-8 border-2 bg-white `}
                  disabled={activeTab === tabNamesArray[0] ? true : false}
                >
                  <p className={`${activeTab === tabNamesArray[0] ? " text-black" : ""} `}>Back</p>
                </button>
                <button
                  onClick={() => handleNextTab(activeTab)}
                  className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ServiceProviderForm;

const PersonalDetails = ({
  firstName,
  setFirstName,
  validateFirstName,
  lastName,
  setLastName,
  emailAddress,
  validateLastName,
  mobileNumber,
  setMobileNumber,
  validateMobileNumberWithCountryCode,
  // registrationNumber,
  // setRegistrationNumber,
  // validateRegistrationNumber,
  alertsForMobile,
  setAlertsForMobile,
  validateTextMessageChecked,
  alertsForEmail,
  setSelectedTimeZone,
  selectedTimeZone,
  setAlertsForEmail,
  validateEmailAlertsChecked,
  isFieldRequired,
  isProfileFormSubmitted,
  validateTimeZone,
  alreadyWorkingWithDentalLab,
  setAlreadyWorkingWithDentalLab,
  dentalDesignServiceProvider,
  setDentalDesignServiceProvider,
  genderValue,
  setGenderValue,
  validateGender,
  validateWorkingWithLaboratory,
  validateProvideIndependentDesignService,
  validateConsent,
  allCadSoftwareCategories,
  softwaresUsed,
  setSoftwaresUsed,
  saveUserSoftware,
  deleteUserSoftware,
  allRegistrationQuestions,
  allRegistrationQnA,
  setAllRegistrationQnA,
  saveUserQnA,
  userProfileDetails,
  readProfileInfo,
  savePersonalDetailsSp,
}) => {
  const { getAccessTokenSilently, user } = useAuth0();

  // handles input changes in the form fields
  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === "firstName") setFirstName(value);
    if (name === "lastName") setLastName(value);
    if (name === "mobileNumber") setMobileNumber(value);
    // if (name === "registrationNumber") setRegistrationNumber(value);
    if (name === "textMessages") setAlertsForMobile(checked);
    if (name === "emailAlerts") setAlertsForEmail(checked);
    if (name === "timeZone") setSelectedTimeZone(value);
    if (name === "gender") setGenderValue(value);
    if (name === "alreadyWorkingWithDentalLab") setAlreadyWorkingWithDentalLab(value);
    if (name === "dentalDesignServiceProvider") setDentalDesignServiceProvider(value);
  };

  const getUserDetails = async () => {
    let userDetails = userProfileDetails;
    if (!userDetails?._id) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });

      await savePersonalDetailsSp({
        accessToken,
        email: emailAddress,
        isTncChecked: false,
        tncUrl: "",
      }).unwrap();
      userDetails = await readProfileInfo(accessToken);
    }
    return userDetails;
  };

  const handleQuestionInputChange = async (event, questionIndex) => {
    const { name, value, checked } = event.target;
    const newQnA = JSON.parse(JSON.stringify(allRegistrationQnA));
    newQnA[questionIndex].selectedAnswerObjId = value;
    setAllRegistrationQnA(newQnA);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const userDetails = await getUserDetails();
    await saveUserQnA({
      accessToken: accessToken,
      _id: userDetails?._id,
      questionObjId: newQnA[questionIndex]._id,
      answerObjId: value,
    });
  };

  const handleSoftwaresCheckboxChange = async (value, checked, categoryIndex) => {
    if (checked) {
      // If the checkbox is checked, add the value to the array
      const newSoftwaresUsed = JSON.parse(JSON.stringify(softwaresUsed));
      newSoftwaresUsed[categoryIndex]?.selectedItems.push(value);
      setSoftwaresUsed(newSoftwaresUsed);
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const userDetails = await getUserDetails();
      await saveUserSoftware({
        accessToken: accessToken,
        _id: userDetails?._id,
        sofwareCategoryObjId: newSoftwaresUsed[categoryIndex]._id,
        softwareObjId: value,
      });
    } else {
      // If the checkbox is unchecked, remove the value from the array
      const newSoftwaresUsed = JSON.parse(JSON.stringify(softwaresUsed));
      newSoftwaresUsed[categoryIndex].selectedItems = newSoftwaresUsed[
        categoryIndex
      ]?.selectedItems.filter((item) => item !== value);
      setSoftwaresUsed(newSoftwaresUsed);
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const userDetails = await getUserDetails();
      await deleteUserSoftware({
        accessToken: accessToken,
        _id: userDetails?._id,
      });
    }
  };

  return (
    <div className="md:px-16 md:py-12 p-4 bg-secondary">
      {/* {HideDiv &&
        <p className="font-medium text-[22px] leading-8 md:mb-4 md:mt-0 my-4 md:px-0 px-4">
          Personal Details
        </p>} */}
      <div className="grid md:grid-cols-2 grid-cols-1 gap-x-8 md:p-0 px-4 py-2">
        <div>
          <TextInputField
            labelColor={"#777"}
            labelText={"First Name "}
            errorMessage={"This field is required"}
            placeholder={"Enter your First Name"}
            isFieldRequired={isFieldRequired("firstName")}
            htmlFor={"firstName"}
            name={"firstName"}
            value={firstName}
            handleInputChange={handleInputChange}
            validationFunctionName={validateFirstName}
            isSubmitted={isProfileFormSubmitted}
          />
        </div>
        <div>
          <TextInputField
            labelColor={"#777"}
            labelText={"Last Name "}
            errorMessage={"Please enter your Last Name"}
            placeholder={"Enter your Last Name"}
            isFieldRequired={isFieldRequired("lastName")}
            htmlFor={"lastName"}
            name={"lastName"}
            value={lastName}
            handleInputChange={handleInputChange}
            validationFunctionName={validateLastName}
            isSubmitted={isProfileFormSubmitted}
          />
        </div>
        <div>
          <NumberInputWithCountryCodeField
            labelColor={"#777"}
            labelText={"Mobile Number "}
            errorMessage={"Please enter your Mobile Number"}
            placeholder={"Enter your Mobile Number"}
            isFieldRequired={isFieldRequired("mobileNumber")}
            htmlFor={"mobileNumber"}
            name={"mobileNumber"}
            value={mobileNumber}
            handleInputChange={setMobileNumber}
            validationFunctionName={validateMobileNumberWithCountryCode}
            isSubmitted={isProfileFormSubmitted}
          />
        </div>
        <div>
          <TextInputField
            labelColor={"#777"}
            labelText={"Email "}
            errorMessage={"Please enter your email"}
            placeholder={"Enter your Email"}
            isFieldRequired={false}
            htmlFor={"email"}
            name={"email"}
            value={emailAddress}
            handleInputChange={() => {}}
            validationFunctionName={validateLastName}
            isSubmitted={isProfileFormSubmitted}
            disabled={true}
          />
        </div>
        {/* <div>
          <NumberInputField
            labelColor={"#777"}
            labelText={"Mobile Number "}
            errorMessage={"Please enter your Mobile Number"}
            placeholder={"Enter your Mobile Number"}
            isFieldRequired={isFieldRequired("mobileNumber")}
            htmlFor={"mobileNumber"}
            name={"mobileNumber"}
            value={mobileNumber}
            handleInputChange={handleInputChange}
            validationFunctionName={validateMobileNumber}
            isSubmitted={isProfileFormSubmitted}
          />
        </div> */}
        {/* <div>
          <NumberInputField
            labelColor={"#777"}
            labelText={"Registration Number "}
            errorMessage={"Please enter your Registration Number"}
            placeholder={"Enter your Registration Number"}
            isFieldRequired={isFieldRequired("registrationNumber")}
            htmlFor={"registrationNumber"}
            name={"registrationNumber"}
            value={registrationNumber}
            handleInputChange={handleInputChange}
            validationFunctionName={validateRegistrationNumber}
            isSubmitted={isProfileFormSubmitted}
          />
        </div> */}
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-x-8 md:p-0 px-4 py-2">
        <div className="mb-2">
          <div className="relative flex items-center">
            <ActiveToggleButton
              currentState={alertsForMobile}
              setState={(value) => setAlertsForMobile(value ? true : false)}
            />
            <label className="text-[16px] leading-4 text-white ml-2" htmlFor="textMessages">
              Allow Text Messages to Send Alerts{" "}
              {isFieldRequired("textMessages") ? <span className="text-red-500">*</span> : ""}
            </label>
            {isFieldRequired("textMessages") &&
              validateTextMessageChecked(alertsForMobile) === false &&
              isProfileFormSubmitted && (
                <p className="text-red-500  absolute -bottom-6 text-[15px] left-0  ">
                  Please Check This to Continue.
                </p>
              )}
          </div>
        </div>
        <div className="mb-2">
          <div className="relative flex items-center">
            <ActiveToggleButton
              currentState={alertsForEmail}
              setState={(value) => setAlertsForEmail(value ? true : false)}
            />
            <label className="text-[16px] leading-4 text-white ml-2" htmlFor="emailAlerts">
              Allow Emails to Send Alerts{" "}
              {isFieldRequired("emailAlerts") ? <span className="text-red-500">*</span> : ""}
            </label>
            {isFieldRequired("emailAlerts") &&
              validateEmailAlertsChecked(alertsForEmail) === false &&
              isProfileFormSubmitted && (
                <p className="text-red-500  absolute -bottom-6 text-[15px] left-0  ">
                  Please Check This to Continue.
                </p>
              )}
          </div>
        </div>
      </div>
      <div className="mt-2 mb-4 relative">
        <p className="text-[16px] leading-4 text-gray-500">
          (Both options need to be selected to allow effective communication)
        </p>
      </div>
      <div className="relative">
        <TimeZoneInputField
          labelColor={"#777"}
          labelText={"Time Zone "}
          errorMessage={"Please Select a Timezone"}
          placeholder={"Select Timezone"}
          isFieldRequired={isFieldRequired("timeZone")}
          htmlFor={"timeZone"}
          name={"timeZone"}
          value={selectedTimeZone}
          handleInputChange={handleInputChange}
          validationFunctionName={validateTimeZone}
          isSubmitted={isProfileFormSubmitted}
        />
      </div>
      <div className="relative">
        <label className="text-[16px] text-white" htmlFor="gender">
          Gender
          {isFieldRequired("gender") ? <span className="text-red-500 ml-1">*</span> : ""}
        </label>
        <div className="flex-1 mt-4 mb-4 flex md:flex-row flex-col md:gap-12 gap-4">
          <div className="flex">
            <CircularCheckbox
              value="Male"
              name="gender"
              checked={genderValue === "Male"}
              onChange={handleInputChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-[16px] text-white">Male</p>
          </div>
          <div className="flex">
            <CircularCheckbox
              value="Female"
              name="gender"
              checked={genderValue === "Female"}
              onChange={handleInputChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-[16px] text-white">Female</p>
          </div>
          <div className="flex">
            <CircularCheckbox
              value="Others"
              name="gender"
              checked={genderValue === "Others"}
              onChange={handleInputChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-[16px] text-white">Others</p>
          </div>
        </div>
        {isFieldRequired("gender") &&
          validateGender(genderValue) === false &&
          isProfileFormSubmitted && (
            <p className="text-red-500 text-[15px]">This field is required</p>
          )}
      </div>
      <div>
        <div className="text-white flex mb-4 text-[16px]">
          Are you working with a dental laboratory?
          {isFieldRequired("alreadyWorkingWithDentalLab") ? (
            <span className="text-red-500 ml-1">*</span>
          ) : (
            ""
          )}
          <div className="flex items-center mx-3">
            <CircularCheckbox
              value="Yes"
              name="alreadyWorkingWithDentalLab"
              checked={alreadyWorkingWithDentalLab === "Yes"}
              onChange={handleInputChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">Yes</p>
          </div>
          <div className="flex items-center">
            <CircularCheckbox
              value="No"
              name="alreadyWorkingWithDentalLab"
              checked={alreadyWorkingWithDentalLab === "No"}
              onChange={handleInputChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">No</p>
          </div>
        </div>
        {isFieldRequired("alreadyWorkingWithDentalLab") &&
          validateWorkingWithLaboratory(alreadyWorkingWithDentalLab) === false &&
          isProfileFormSubmitted && (
            <p className="text-red-500 text-[15px]">This field is required</p>
          )}
      </div>
      <div>
        <div className="text-white flex mb-4 text-[16px]">
          Do you provide independent dental design services?
          {isFieldRequired("dentalDesignServiceProvider") ? (
            <span className="text-red-500 ml-1">*</span>
          ) : (
            ""
          )}
          <div className="flex items-center mx-3">
            <CircularCheckbox
              value="Yes"
              name="dentalDesignServiceProvider"
              checked={dentalDesignServiceProvider === "Yes"}
              onChange={handleInputChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">Yes</p>
          </div>
          <div className="flex items-center">
            <CircularCheckbox
              value="No"
              name="dentalDesignServiceProvider"
              checked={dentalDesignServiceProvider === "No"}
              onChange={handleInputChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">No</p>
          </div>
        </div>
        {isFieldRequired("dentalDesignServiceProvider") &&
          validateProvideIndependentDesignService(dentalDesignServiceProvider) === false &&
          isProfileFormSubmitted && (
            <p className="text-red-500 text-[15px]">This field is required</p>
          )}
      </div>
      <div>
        <p className="font-medium text-[24px] text-white mt-4 mb-4">
          What software do you use ?
          {isFieldRequired("softwaresUsed") ? <span className="text-red-500 ml-1">*</span> : ""}
        </p>
        <div className="ml-3 mb-5">
          {allCadSoftwareCategories?.length > 0 &&
            allCadSoftwareCategories.map((categoryItem, categoryIndex) => {
              return (
                categoryItem?.softwares?.length > 0 && (
                  <div key={"category-parent-" + categoryIndex}>
                    <p className="font-medium text-[16px] text-[#FF8503] mt-2 mb-4">
                      {categoryItem?.softwareCategoryName}
                    </p>
                    <div className="grid md:grid-cols-4 grid-cols-1 ml-4 gap-x-8 gap-y-4 text-[16px] text-white">
                      {categoryItem?.softwares?.length > 0 &&
                        categoryItem?.softwares?.map((item, index) => {
                          return (
                            <Checkbox
                              key={"category-child-" + index}
                              label={item?.softwareName || ""}
                              value={item?.softwareObjId || ""}
                              icon={item?.softwareImageUrl || ""}
                              iconClass="!w-10/12"
                              checked={softwaresUsed[categoryIndex]?.selectedItems?.includes(
                                item.softwareObjId
                              )}
                              onChange={(value, checked) => {
                                handleSoftwaresCheckboxChange(value, checked, categoryIndex);
                              }}
                            />
                          );
                        })}
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </div>
      {allRegistrationQuestions?.length > 0 &&
        allRegistrationQuestions.map((questionItem, questionIndex) => {
          return (
            <div className="relative">
              <label className="text-[16px] text-white" htmlFor={`consent${questionIndex + 1}`}>
                {questionItem?.question}
              </label>
              <div className="flex-1 mt-4 mb-4 flex md:flex-row flex-col md:gap-12 gap-4">
                {questionItem?.answers?.length > 0 &&
                  questionItem?.answers?.map((item, index) => {
                    return (
                      <div className="flex">
                        <CircularCheckbox
                          value={item.answerObjId}
                          name={item.answerObjId}
                          checked={
                            allRegistrationQnA[questionIndex]?.selectedAnswerObjId ===
                            item.answerObjId
                          }
                          onChange={(event) => {
                            handleQuestionInputChange(event, questionIndex);
                          }}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-[16px] text-white">{item.option}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}

      {/* <div className="mb-3">
        <label className="text-[16px] text-white" htmlFor="consent3">
          ● Following the onboarding process, Digital Dentition will grade your certification as
          Basic, Intermediate or Advanced depending on our own assessment of your skills and the
          spectrum of services that you offer. Are you happy to accept this?
          {isFieldRequired("consent3") ? <span className="text-red-500 ml-1">*</span> : ""}
          <p className="text-[16px] leading-4 text-gray-500">
            (Future upgradation of your certification may definitely be considered if you are able
            to pass another skill assessment interview.)
          </p>
        </label>
        <div className="flex-1 mt-4 mb-4 flex md:flex-row flex-col md:gap-12 gap-4">
          <div className="flex">
            <CircularCheckbox
              value="Yes"
              name="consent3"
              checked={consent3 === "Yes"}
              onChange={handleInputChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-[16px] text-white">Yes</p>
          </div>
          <div className="flex">
            <CircularCheckbox
              value="No"
              name="consent3"
              checked={consent3 === "No"}
              onChange={handleInputChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-[16px] text-white">No</p>
          </div>
        </div>
        {isFieldRequired("consent3") &&
          validateConsent(consent3) === false &&
          isProfileFormSubmitted && (
            <p className="text-red-500 text-[15px]">This field is required</p>
          )}
      </div> */}
    </div>
  );
};
const AddressDetails = ({
  address,
  setAddress,
  validateAddress,
  countryOptions,
  selectedCountryOption,
  setSelectedCountryOption,
  validateCountryDropDown,
  pinCode,
  setPinCode,
  validatePinCode,
  locationServiceProvider,
  setLocationServiceProvider,
  isAddressFormSubmitted,
  isFieldRequired,
  location,
  setLocation,
  country,
  setCountry,
  setLatitude,
  setLongitude,
  validateCountry,
  isLoaded,
  validateConsent,
  ownPhysicalLaboratory,
  setOwnPhysicalLaboratory,
  labAddress,
  setLabAddress,
  labCountry,
  setLabCountry,
  setLabPostalCode,
  labPostalCode,
  setLabLatitude,
  laboratoryLocation,
  setLaboratoryLocation,
  setLabLongitude,
}) => {
  // handles input changes in the form fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "address") {
      setAddress(value);
    }
    // if (name === "country") setSelectedCountryOption(value);
    if (name === "country") setCountry(value);
    if (name === "pinCode") setPinCode(value);
    if (name === "ownPhysicalLaboratory") {
      setOwnPhysicalLaboratory(value);
      setLabAddress("");
      setLabCountry("");
      setLaboratoryLocation("");
      setLabLatitude("");
      setLabLongitude("");
      setLabPostalCode("");
    }
    if (name === "labAddress") setLabAddress(value);
    if (name === "labCountry") setLabCountry(value);
    if (name === "labPostalCode") setLabPostalCode(value);
  };

  const windowWidth = useWindowWidth();
  const HideDiv = windowWidth < 750;

  return (
    <div className="md:px-16 md:py-12 p-4 min-h-full">
      {/* {HideDiv &&
        <p className="font-medium text-[22px] leading-8 mb-4" >Address Details</p>} */}
      <div
        className={`min-h-[428px] ${
          ownPhysicalLaboratory === "Yes" ? "grid md:grid-cols-2 gap-8" : ""
        }`}
      >
        <div className="flex flex-col gap-0">
          <label htmlFor="address" className="text-[16px] leading-4 text-white">
            Address
            {isFieldRequired("address") && <span className="text-red-500 ml-1">*</span>}
          </label>
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <div className=" col-span-2">
                <LocationInputV2
                  setValue={setAddress}
                  placeholder={"Search an address location"}
                  name={"addressSearch"}
                  setCountry={setCountry}
                  setLatitude={setLatitude}
                  setLocation={setLocation}
                  setLongitude={setLongitude}
                  setPinCode={setPinCode}
                />
              </div>
              <div className="col-span-2 row-span-2 mb-2">
                <GoogleMap
                  mapContainerClassName="h-[140px]"
                  center={location}
                  zoom={10}
                  onClick={(e) => {
                    setLatitude(e.latLng.lat());
                    setLongitude(e.latLng.lng());
                    setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                  }}
                  clickableIcons={true}
                >
                  <Marker position={location} />
                </GoogleMap>
              </div>
            </>
          )}

          <div className="col-span-2 row-span-2">
            <p className="text-sm text-gray-500">Please correct address manually as required</p>
            <TextAreaInputField
              labelColor={"#777"}
              labelText={null}
              errorMessage={"Enter Valid Address"}
              placeholder={"Address"}
              isFieldRequired={isFieldRequired("address")}
              htmlFor={"address"}
              name={"address"}
              value={address}
              handleInputChange={handleInputChange}
              validationFunctionName={validateAddress}
              isSubmitted={isAddressFormSubmitted}
              rows={4}
              cols={5}
            />
          </div>
          <div className="flex gap-8">
            <div className="col-span-1">
              {/* <div>
            <DropdownField
              labelColor={"#777"}
              labelText={"Country"}
              errorMessage={"Please Select a Country"}
              placeholder={"Select a Country"}
              isFieldRequired={isFieldRequired("country")}
              htmlFor={"country"}
              name={"country"}
              value={selectedCountryOption}
              handleInputChange={handleInputChange}
              validationFunctionName={validateCountryDropDown}
              isSubmitted={isAddressFormSubmitted}
              options={countryOptions}
            />
          </div> */}
              <TextInputField
                labelColor={"#777"}
                labelText={"Country "}
                errorMessage={"Please enter a country name"}
                placeholder={"Country name"}
                htmlFor={"country"}
                name={"country"}
                value={country}
                handleInputChange={handleInputChange}
                validationFunctionName={validateCountry}
                isSubmitted={isAddressFormSubmitted}
                isFieldRequired={isFieldRequired("country")}
              />
            </div>
            <div className="col-span-1">
              <NumberInputField
                labelColor={"#777"}
                labelText={"Postal code (Zip code/Pin code)"}
                errorMessage={"Please Enter Valid Pin Code"}
                placeholder={"Please Enter your Pin Code"}
                isFieldRequired={isFieldRequired("pinCode")}
                htmlFor={"pinCode"}
                name={"pinCode"}
                value={pinCode}
                handleInputChange={handleInputChange}
                validationFunctionName={validatePinCode}
                isSubmitted={isAddressFormSubmitted}
              />
            </div>
          </div>
          <div className="relative mt-2">
            <label className="text-[16px] text-white" htmlFor="ownPhysicalLaboratory">
              If you own or operate or work with a physical dental laboratory that wishes to produce
              and locally ship physically produced dental devices or appliances, please give us your
              details below :
              {isFieldRequired("ownPhysicalLaboratory") ? (
                <span className="text-red-500 ml-1">*</span>
              ) : (
                ""
              )}
            </label>
            <div className="flex-1 mt-4 mb-4 flex md:flex-row flex-col md:gap-12 gap-4">
              <div className="flex">
                <CircularCheckbox
                  value="Yes"
                  name="ownPhysicalLaboratory"
                  checked={ownPhysicalLaboratory === "Yes"}
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">Yes</p>
              </div>
              <div className="flex">
                <CircularCheckbox
                  value="No"
                  name="ownPhysicalLaboratory"
                  checked={ownPhysicalLaboratory === "No"}
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">No</p>
              </div>
            </div>
            {isFieldRequired("ownPhysicalLaboratory") &&
              validateConsent(ownPhysicalLaboratory) === false &&
              isAddressFormSubmitted && (
                <p className="text-red-500 text-[15px]">This field is required</p>
              )}
          </div>
        </div>
        <div className={`flex flex-col gap-0 ${ownPhysicalLaboratory === "Yes" ? "" : "hidden"}`}>
          <label htmlFor="labAddress" className="text-[16px] leading-4 text-white">
            Laboratory Address
            {isFieldRequired("labAddress") && <span className="text-red-500 ml-1">*</span>}
          </label>
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <div className=" col-span-2">
                <LocationInputV2
                  setValue={setLabAddress}
                  placeholder={"Search an address location"}
                  name={"addressSearch"}
                  setCountry={setLabCountry}
                  setLatitude={setLabLatitude}
                  setLocation={setLaboratoryLocation}
                  setLongitude={setLabLongitude}
                  setPinCode={setLabPostalCode}
                />
              </div>
              <div className="col-span-2 row-span-2 mb-2">
                <GoogleMap
                  mapContainerClassName="h-[140px]"
                  center={laboratoryLocation}
                  zoom={10}
                  onClick={(e) => {
                    setLabLatitude(e.latLng.lat());
                    setLabLongitude(e.latLng.lng());
                    setLaboratoryLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                  }}
                  clickableIcons={true}
                >
                  <Marker position={laboratoryLocation} />
                </GoogleMap>
              </div>
            </>
          )}

          <div className="col-span-2 row-span-2">
            <p className="text-sm text-gray-500">Please correct address manually as required</p>
            <TextAreaInputField
              labelColor={"#777"}
              labelText={null}
              errorMessage={"Enter Valid Address"}
              placeholder={"Address"}
              isFieldRequired={isFieldRequired("labAddress")}
              htmlFor={"labAddress"}
              name={"labAddress"}
              value={labAddress}
              handleInputChange={handleInputChange}
              validationFunctionName={validateAddress}
              isSubmitted={isAddressFormSubmitted}
              rows={4}
              cols={5}
            />
          </div>
          <div className="flex gap-8">
            <div className="col-span-1">
              {/* <div>
            <DropdownField
              labelColor={"#777"}
              labelText={"Country"}
              errorMessage={"Please Select a Country"}
              placeholder={"Select a Country"}
              isFieldRequired={isFieldRequired("country")}
              htmlFor={"country"}
              name={"country"}
              value={selectedCountryOption}
              handleInputChange={handleInputChange}
              validationFunctionName={validateCountryDropDown}
              isSubmitted={isAddressFormSubmitted}
              options={countryOptions}
            />
          </div> */}
              <TextInputField
                labelColor={"#777"}
                labelText={"Country"}
                errorMessage={"Please enter a country name"}
                placeholder={"Country name"}
                htmlFor={"labCountry"}
                name={"labCountry"}
                value={labCountry}
                handleInputChange={handleInputChange}
                validationFunctionName={validateCountry}
                isSubmitted={isAddressFormSubmitted}
                isFieldRequired={isFieldRequired("labCountry")}
              />
            </div>
            <div className="col-span-1">
              <NumberInputField
                labelColor={"#777"}
                labelText={"Postal code (Zip code/Pin code)"}
                errorMessage={"Please Enter Valid Pin Code"}
                placeholder={"Please Enter your Pin Code"}
                isFieldRequired={isFieldRequired("labPostalCode")}
                htmlFor={"labPostalCode"}
                name={"labPostalCode"}
                value={labPostalCode}
                handleInputChange={handleInputChange}
                validationFunctionName={validatePinCode}
                isSubmitted={isAddressFormSubmitted}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ServicesOffered = ({
  items,
  selectedTitlesArray,
  getAccessTokenSilently,
  saveUserService,
  deleteUserService,
  servicesOffered,
}) => {
  const dispatch = useDispatch();
  const [allItems, setAllItems] = useState(servicesOffered || []);

  const handleSaveUserService = async ({ serviceObjId, offerPrice, actualPrice, active }) => {
    return new Promise(async (resolve, reject) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const response = await saveUserService({
        accessToken,
        serviceObjId,
        offerPrice,
        actualPrice,
        active,
      });
      if (response) {
        resolve();
        //show response if required
      } else {
        reject();
      }
    });
  };

  const handleDeleteUserService = async ({ _id }) => {
    return new Promise(async (resolve, reject) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const response = await deleteUserService({ accessToken, _id });
      if (response) {
        resolve();
        //show response if required
      } else {
        reject();
      }
    });
  };

  const handleSelectionChange = async ({ id: selectedItemId, type, serviceId }) => {
    return new Promise(async (resolve, reject) => {
      const selectedItems = [...allItems];
      if (selectedItems.includes(selectedItemId)) {
        const processList = [];
        if (type === "service") {
          items.forEach((parentItem) => {
            if (parentItem.id === selectedItemId) {
              parentItem?.catservices?.forEach((childItem) => {
                if (selectedItems.includes(childItem.serviceObjId)) {
                  selectedItems.splice(selectedItems.indexOf(childItem.serviceObjId), 1);
                  processList.push(
                    handleDeleteUserService({
                      _id: childItem.serviceObjId,
                    })
                  );
                }
              });
            }
          });
        } else {
          if (selectedItems.includes(serviceId)) {
            selectedItems.splice(selectedItems.indexOf(serviceId), 1);
          }
          processList.push(
            handleDeleteUserService({
              _id: selectedItemId,
            })
          );
        }

        selectedItems.splice(selectedItems.indexOf(selectedItemId), 1);
        setAllItems(selectedItems);
        await Promise.all(processList);
      } else {
        const processList = [];
        if (type === "service") {
          items.forEach((parentItem) => {
            if (parentItem.id === selectedItemId) {
              parentItem?.catservices?.forEach((childItem) => {
                if (!selectedItems.includes(childItem.serviceObjId)) {
                  selectedItems.push(childItem.serviceObjId);
                  processList.push(
                    handleSaveUserService({
                      serviceObjId: childItem.serviceObjId,
                      offerPrice: "",
                      actualPrice: "",
                      active: true,
                    })
                  );
                }
              });
            }
          });
        } else {
          if (!selectedItems.includes(serviceId)) {
            selectedItems.push(serviceId);
          }
          processList.push(
            handleSaveUserService({
              serviceObjId: selectedItemId,
              offerPrice: "",
              actualPrice: "",
              active: true,
            })
          );
        }
        if (!selectedItems.includes(selectedItemId)) selectedItems.push(selectedItemId);
        setAllItems(selectedItems);
        await Promise.all(processList);
      }
      dispatch(setServiceProviderServicesOffered(selectedItems));
      resolve();
    });
  };

  return (
    <div className="md:px-16 md:py-12 p-4">
      <p className="font-medium text-[22px] leading-8 mb-4 text-white">
        Select Services you wish to offer <span className="text-red-500">*</span>
      </p>
      <div className="md:max-h-[370px] overflow-auto">
        <AccordionV2
          items={items}
          onSelectionChange={handleSelectionChange}
          setAllItems={setAllItems}
          allItems={allItems}
        />
      </div>
    </div>
  );
};
const BankDetails = ({
  bankName,
  setBankName,
  validateBankName,
  bankAccountNumber,
  setBankAccountNumber,
  validateBankAccountNumber,
  accountHolderName,
  setAccountHolderName,
  validateAccountHolderName,
  swiftCode,
  setSwiftCode,
  validateSwiftCode,
  accountHoldersAddress,
  setAccountHoldersAddress,
  bankAddress,
  setBankAddress,
  validateAddress,
  ibanNumber,
  setIbanNumber,
  validateIbanNumber,
  currencyOptions,
  selectedCurrencyOption,
  setSelectedCurrencyOption,
  validateCurrencyDropDown,
  isFieldRequired,
  isBankFormSubmitted,
  isLoaded,
  location,
  setLocation,
  bankLocation,
  setBankLocation,
}) => {
  // handles input changes in the form fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "bankName") setBankName(value);
    if (name === "bankAccountNumber") setBankAccountNumber(value);
    if (name === "accountHolderName") setAccountHolderName(value);
    if (name === "swiftCode") setSwiftCode(value);
    if (name === "accountHoldersAddress") setAccountHoldersAddress(value);
    if (name === "bankAddress") setBankAddress(value);
    if (name === "ibanNumber") setIbanNumber(value);
    if (name === "currency") setSelectedCurrencyOption(value);
  };

  const windowWidth = useWindowWidth();
  const HideDiv = windowWidth < 750;

  return (
    <div className="md:px-16 md:py-12 p-4">
      {/* {HideDiv &&
        <p className="font-medium text-[22px] leading-8 mb-4">Bank Details</p>} */}
      <div className="md:grid md:grid-cols-2 gap-x-8">
        <div className="">
          <TextInputField
            labelColor={"#777"}
            labelText={"Bank Name "}
            errorMessage={"Bank Name cannot be empty"}
            placeholder={"Enter your Bank Name"}
            isFieldRequired={isFieldRequired("bankName")}
            htmlFor={"bankName"}
            name={"bankName"}
            value={bankName}
            handleInputChange={handleInputChange}
            validationFunctionName={validateBankName}
            isSubmitted={isBankFormSubmitted}
          />
        </div>
        <div className="">
          <NumberInputField
            labelColor={"#777"}
            labelText={"Bank Account Number "}
            errorMessage={"Account Number must be not less than 8 and greater than 16"}
            placeholder={"Enter your account number"}
            isFieldRequired={isFieldRequired("bankAccountNumber")}
            htmlFor={"bankAccountNumber"}
            name={"bankAccountNumber"}
            value={bankAccountNumber}
            handleInputChange={handleInputChange}
            validationFunctionName={validateBankAccountNumber}
            isSubmitted={isBankFormSubmitted}
          />
        </div>
        <div>
          <TextInputField
            labelColor={"#777"}
            labelText={"Account Holder Name "}
            errorMessage={"Holder Name cannot be empty"}
            placeholder={"Enter Account Holder Name"}
            isFieldRequired={isFieldRequired("accountHolderName")}
            htmlFor={"accountHolderName"}
            name={"accountHolderName"}
            value={accountHolderName}
            handleInputChange={handleInputChange}
            validationFunctionName={validateAccountHolderName}
            isSubmitted={isBankFormSubmitted}
          />
        </div>
        <div className="">
          <NumberInputField
            labelColor={"#777"}
            labelText={"SWIFT Code"}
            errorMessage={"SWIFT Code must be at least 6 digits"}
            placeholder={"Enter your SWIFT code"}
            isFieldRequired={isFieldRequired("swiftCode")}
            htmlFor={"swiftCode"}
            name={"swiftCode"}
            value={swiftCode}
            handleInputChange={handleInputChange}
            validationFunctionName={validateSwiftCode}
            isSubmitted={isBankFormSubmitted}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div className="">
          <label htmlFor={"accountHoldersAddress"} className="text-[16px] leading-4 text-white">
            Account Holders Address as Registered with bank
            {isFieldRequired("accountHoldersAddress") && (
              <span className="text-red-500 ml-1">*</span>
            )}
          </label>
          <div className="grid grid-cols-2 mt-1">
            {!isLoaded ? (
              <h1>Loading...</h1>
            ) : (
              <>
                <div className="col-span-2">
                  <LocationInputV2
                    setValue={setAccountHoldersAddress}
                    placeholder={"Search Account Holders Address"}
                    name={"accountHoldersAddressSearch"}
                    setLocation={setLocation}
                  />
                </div>
                <div className="col-span-2 row-span-3 mb-2">
                  <GoogleMap
                    mapContainerClassName="h-[140px]"
                    center={location}
                    zoom={10}
                    onClick={(e) => {
                      setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                    }}
                    clickableIcons={true}
                  >
                    <Marker position={location} />
                  </GoogleMap>
                </div>
              </>
            )}
            <div className="col-span-2 row-span-2">
              <p className="text-sm text-gray-500">Please correct address manually as required</p>
              <TextAreaInputField
                labelColor={"#777"}
                labelText={null}
                errorMessage={"Enter Valid Address"}
                placeholder={"Address"}
                isFieldRequired={isFieldRequired("accountHoldersAddress")}
                htmlFor={"accountHoldersAddress"}
                name={"accountHoldersAddress"}
                value={accountHoldersAddress}
                handleInputChange={handleInputChange}
                validationFunctionName={validateAddress}
                isSubmitted={isBankFormSubmitted}
                rows={4}
                cols={5}
              />
            </div>
          </div>
        </div>
        <div className="">
          <label htmlFor={"bankAddress"} className="text-[16px] leading-4 text-white">
            Bank Address
            {isFieldRequired("bankAddress") && <span className="text-red-500 ml-1">*</span>}
          </label>
          <div className="grid grid-cols-2 mt-1">
            {!isLoaded ? (
              <h1>Loading...</h1>
            ) : (
              <>
                <div className="col-span-2">
                  <LocationInputV2
                    setValue={setBankAddress}
                    placeholder={"Search Bank Address"}
                    name={"bankAddressSearch"}
                    setLocation={setBankLocation}
                  />
                </div>
                <div className="col-span-2 row-span-3 mb-2">
                  <GoogleMap
                    mapContainerClassName="h-[140px]"
                    center={bankLocation}
                    zoom={10}
                    onClick={(e) => {
                      setBankLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                    }}
                    clickableIcons={true}
                  >
                    <Marker position={bankLocation} />
                  </GoogleMap>
                </div>
              </>
            )}
            <div className="col-span-2 row-span-2">
              <p className="text-sm text-gray-500">Please correct address manually as required</p>
              <TextAreaInputField
                labelColor={"#777"}
                labelText={null}
                errorMessage={"Enter Valid Address"}
                placeholder={"Address"}
                isFieldRequired={isFieldRequired("bankAddress")}
                htmlFor={"bankAddress"}
                name={"bankAddress"}
                value={bankAddress}
                handleInputChange={handleInputChange}
                validationFunctionName={validateAddress}
                isSubmitted={isBankFormSubmitted}
                rows={4}
                cols={5}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="md:grid md:grid-cols-2 gap-x-8">
        <div className="">
          <NumberInputField
            labelColor={"#777"}
            labelText={"IBAN/BSB/ABA or other routing number"}
            errorMessage={"IBAN Number cannot be empty"}
            placeholder={"Enter your IBAN/BSB/ABA or other routing number"}
            isFieldRequired={isFieldRequired("ibanNumber")}
            htmlFor={"ibanNumber"}
            name={"ibanNumber"}
            value={ibanNumber}
            handleInputChange={handleInputChange}
            validationFunctionName={validateIbanNumber}
            isSubmitted={isBankFormSubmitted}
          />
        </div>
        <div>
          <CurrencyDropDown
            labelColor={"#777"}
            labelText={"Account Currency "}
            errorMessage={"Please Select a Currency"}
            placeholder={"Select Currency"}
            isFieldRequired={isFieldRequired("currency")}
            htmlFor={"currency"}
            name={"currency"}
            value={selectedCurrencyOption}
            handleInputChange={handleInputChange}
            validationFunctionName={validateCurrencyDropDown}
            isSubmitted={isBankFormSubmitted}
            options={currencyOptions}
          />
        </div>
        {/* <div>
          <DropdownField
            labelColor={"#777"}
            labelText={"Account Currency "}
            errorMessage={"Please Select a Currency"}
            placeholder={"Select Currency"}
            isFieldRequired={isFieldRequired("currency")}
            htmlFor={"currency"}
            name={"currency"}
            value={selectedCurrencyOption}
            handleInputChange={handleInputChange}
            validationFunctionName={validateCurrencyDropDown}
            isSubmitted={isBankFormSubmitted}
            options={currencyOptions}
          />
        </div> */}
        <p className="mt-3 mb-3 text-[16px] leading-4 text-gray-500 col-span-2">
          Disclaimer : All payments for services will only be made in US dollars into your nominated
          bank account above. This field only allows display of the cost of services in your
          currency of choice for your easy understanding. All conversions are provided by Google and
          will be used for calculation of payment.
        </p>
      </div>
    </div>
  );
};

const TermsAndConditons = ({
  isFieldRequired,
  isTncChecked,
  setIsTncChecked,
  validateTncChecked,
  userSignature,
  setUserSignature,
  getAccessTokenSilently,
  setTncUrl,
}) => {
  const [signatureModalIsOpen, setSignatureModalIsOpen] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [userSignatureChoice, setUserSignatureChoice] = useState(null);
  const [userFullName, setUserFullName] = useState(null);
  const signPadRef = useRef();
  const [saveFile] = useSaveFileMutation();

  const updateUserChoice = (e) => {
    const { value } = e.currentTarget;
    console.log("value", value, e.target, e);
    setUserSignatureChoice(value);
    setConfirmModalIsOpen(false);
    setSignatureModalIsOpen(true);
  };

  const clearCanvas = () => {
    signPadRef.current.clear();
  };

  const updateSignature = () => {
    let signature = signPadRef.current.getTrimmedCanvas().toDataURL("image/png");
    setUserSignature(signature);
    return signature;
  };

  const getTncPdfFormat = (tempSignature) => {
    return (
      <div className="w-[500px] font-medium text-black bg-white font-[sans-serif]">
        <h1 className="text-center text-xl font-bold">Terms & Conditions</h1>
        <ul className="list-none w-full text-sm flex flex-col gap-2 py-3 px-3 pl-5">
          {tncList?.map((d, i) => {
            return <li key={"tnc-list-item-preview" + i}>{d}</li>;
          })}
        </ul>
        <div className="flex justify-end">
          {tempSignature ? (
            <img src={tempSignature} alt="User Signature" className="md:w-40 mt-3" />
          ) : (
            <p>{userFullName}</p>
          )}
        </div>
      </div>
    );
  };

  const saveDocument = async (file) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const tempFile = new File([file], new Date().getTime() + ".pdf", {
      type: "application/pdf",
      lastModified: new Date().getTime(),
    });
    const resp = await saveFile({
      accessToken,
      file: tempFile,
    });
    return resp;
  };

  const generatePDFDocument = (tempSignature) => {
    var doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [780, 540],
      hotfixes: ["px_scaling"],
    });
    doc.html(ReactDOMServer.renderToStaticMarkup(getTncPdfFormat(tempSignature)), {
      callback: async function (doc) {
        doc.save("Tnc_agreement_" + new Date().getTime());
        const res = await saveDocument(doc.output("blob"));
        setTncUrl(res?.data?.url ?? null);
      },
      margin: [25, 20, 30, 20],
      width: 500,
      windowWidth: 500,
    });
  };

  return (
    <div className="md:px-16 md:py-12 h-full p-4 flex flex-col justify-between">
      <div className="relative">
        <ul className="my-4 list-[circle] text-base text-white flex flex-col gap-2 py-3 px-3 pl-6 border rounded-md">
          {tncList?.map((d, i) => {
            return <li key={"tnc-list-item" + i}>{d}</li>;
          })}
        </ul>
        <div className="">
          <input
            type="checkbox"
            name="tnc"
            id="tnc"
            checked={isTncChecked}
            onChange={(e) => {
              if (e.currentTarget.checked) {
                setConfirmModalIsOpen(true);
              } else {
                setIsTncChecked(false);
                setUserSignature(null);
              }
            }}
            required={isFieldRequired("tnc")}
          />
          <label className="text-[16px] leading-4 text-[#868686] ml-2" htmlFor="tnc">
            Sign Document to accept terms and conditions{" "}
            {isFieldRequired("tnc") ? <span className="text-red-500">*</span> : ""}
          </label>
          {isFieldRequired("tnc") && validateTncChecked(isTncChecked) === false && (
            <p className="text-red-500 text-[15px]">Please Check Box to Continue.</p>
          )}
        </div>
        {userSignature && (
          <div className="">
            <img src={userSignature} alt="User Signature" className="bg-white md:w-52 w-[50%]" />
          </div>
        )}
        <Modal
          isOpen={confirmModalIsOpen}
          onRequestClose={() => {
            setConfirmModalIsOpen(false);
          }}
          contentLabel="Custom Modal"
          className="w-full h-full flex justify-center items-center"
          // style={customStyles}
          ariaHideApp={false}
        >
          <div className="w-5/12 flex flex-col bg-[#171818] text-white p-3">
            <button className="w-full text-right" onClick={() => setConfirmModalIsOpen(false)}>
              <AiOutlineClose className="ml-auto text-white" />
            </button>
            <div className="p-8">
              <p className="text-[26px] font-medium text-white leading-8 mb-6">
                How would you like to proceed with signing the Terms and Conditions?
              </p>
              <div className="flex gap-7 mb-2">
                <button
                  value={USER_SIGNATURE_CHOICE.SIGNATURE}
                  onClick={updateUserChoice}
                  className="flex items-center gap-3"
                >
                  <FaCircle />
                  Draw Signature
                </button>
                <button
                  value={USER_SIGNATURE_CHOICE.TEXT}
                  onClick={updateUserChoice}
                  className="flex items-center gap-3"
                >
                  <FaCircle />
                  Type Signature
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={signatureModalIsOpen}
          onRequestClose={() => {
            setSignatureModalIsOpen(false);
          }}
          contentLabel="Custom Modal"
          className="custom-modal"
          // style={customStyles}
          ariaHideApp={false}
        >
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full text-right" onClick={() => setSignatureModalIsOpen(false)}>
              <AiOutlineClose className="ml-auto text-white" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-6">
              {userSignatureChoice === USER_SIGNATURE_CHOICE.SIGNATURE ? "Draw" : "Type"} signature
              in acceptance of terms and conditions
            </p>
            <p className="text-[14px] text-[#777] mb-4"></p>
            <div className="MODAL-TAB-SECTION flex-1 flex flex-col">
              {userSignatureChoice === USER_SIGNATURE_CHOICE.SIGNATURE ? (
                <SignatureCanvas
                  canvasProps={{ className: "bg-white", height: 400, onChange: updateSignature }}
                  ref={signPadRef}
                />
              ) : (
                <div className="relative">
                  <TextInputField
                    labelText={" Please type your Full Name below"}
                    labelColor={"#fff"}
                    errorMessage={"this is error"}
                    placeholder={"type here"}
                    name={"userFullName"}
                    value={userFullName}
                    handleInputChange={(e) => setUserFullName(e.target.value)}
                    isSubmitted={false}
                    validateName={userFullName?.length > 0}
                    isFieldRequired={isFieldRequired("serviceCode")}
                  />
                </div>
              )}
              <div className="flex items-center justify-end gap-4 pt-4 mt-auto">
                <button
                  onClick={clearCanvas}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Try Again
                </button>
                <button
                  onClick={() => {
                    if (userSignatureChoice === USER_SIGNATURE_CHOICE.SIGNATURE) {
                      const tempSignature = updateSignature();
                      generatePDFDocument(tempSignature);
                    } else {
                      generatePDFDocument();
                    }
                    setIsTncChecked(true);
                    setSignatureModalIsOpen(false);
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#FF7E00] rounded-xl"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { MdDateRange } from "react-icons/md";
import moment from "moment/moment";

export default function DateInputFieldV2({
  labelText,
  className,
  errorMessage,
  placeholder,
  isFieldRequired,
  htmlFor,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validationFunctionName,
  margin,
  max = null,
  min = null,
}) {
  const [ifInvalid, setIfInvalid] = useState(false);
  useEffect(() => {
    if (value?.length > 0) {
      setIfInvalid(false);
    }
  }, [value]);
  return (
    <div className={`relative flex flex-col ${className ?? ""}`}>
      <label htmlFor={htmlFor} className="text-[16px] leading-4 text-white pb-2.5">
        {labelText}
        {isFieldRequired ? <span className="text-red-500 ml-1">*</span> : ""}
      </label>

      {/* <DatePicker
        id={name}
        name={name}
        value={value}
        onChange={handleInputChange}
        format={"MMMM dd, yyyy"}
        placeholderText={"Click to select date"}
        required={isFieldRequired}
        className={`mydateV2 border p-2 w-full text-[16px] text-white leading-4 mt-2 mb-4 rounded-md h-10 bg-backgroundV2 ${
          (isSubmitted && !validationFunctionName(value) && isFieldRequired) || ifInvalid
            ? "border-red-500"
            : ""
        }`}
        calendarClassName={"!bg-backgroundV2"}
      /> */}

      <div
        className={`mydateV2 border flex items-center justify-between p-2 w-full text-[16px] text-white leading-4 mt-2 mb-4 rounded-md h-10 bg-black ${
          (isSubmitted && !validationFunctionName(value) && isFieldRequired) || ifInvalid
            ? "border-red-500"
            : ""
        }`}
      >
        <span>{value ? moment(value).format("MMMM DD, yyyy") : "Click icon to select date"}</span>
        <span className="datepicker-toggle flex items-center">
          <MdDateRange className="datepicker-toggle-button ml-1 text-xl" />
          <input
            type="date"
            className="datepicker-input"
            id={name}
            {...(max ? { max: max } : {})}
            {...(min ? { min: min } : {})}
            name={name}
            value={value}
            onChange={(e) => {
              handleInputChange(e);
              e.target.blur();
            }}
            required={isFieldRequired}
          />
        </span>
      </div>

      {isSubmitted && !validationFunctionName(value) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">{errorMessage}</p>
      )}
      {ifInvalid && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">{errorMessage}</p>
      )}
    </div>
  );
}

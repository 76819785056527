import React, { useState, useEffect } from "react";
import bell from "../../Assets/images/bellIcon.svg";
import hut from "../../Assets/images/hut.svg";
import { IoIosArrowForward, IoIosClose } from "react-icons/io";
import Modal from "react-modal";
import {
  useDeleteQuestionMutation,
  useDeleteServicesMutation,
  useGetServicesMutation,
  useReadQuestionMutation,
} from "../../services/services";
import { useAuth0 } from "@auth0/auth0-react";
import greenTick from "../../Assets/images/greenTick.svg";
import { LoginButton } from "../../Screens/Login/Login";
import { GetToast } from "../Toast-Comps/GetToast";
import Notification from "../Notification/Notification";
import { useSelector } from "react-redux";
import InputFieldsServicesTab from "../Services-Comps/InputFieldsServicesTab";
import DynamicTable from "../Services-Comps/DynamicTable";
import InputFieldQuestionTab from "./InputFieldQuestionTab";

const headersForSoftwareTab = [
  {
    headerId: "question",
    headerName: "Question",
    headerSort: true,
  },
  { headerId: "answers", headerName: "Options", headerSort: true },
  // {
  //   headerId: "createdDate",
  //   headerName: "Created Date",
  //   headerSort: true,
  //   rowAlignment: "left",
  //   headerAlignment: "left",
  // },

  { headerId: "actions", headerName: "Actions", headerSort: false },
];

export const QuestionsTab = () => {
  // modal state and styles
  const { user, isAuthenticated } = useAuth0();
  const [isServicesUpdated, setIsServicesUpdated] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditServiceModal, setIsEditServiceModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [questionData, setQuestionData] = useState([]);
  const [deleteServices] = useDeleteServicesMutation();
  const [editingServicesRow, setEditingServicesRow] = useState(null);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);
  const [readQuestion] = useReadQuestionMutation();
  const [deleteQuestion] = useDeleteQuestionMutation();

  console.log(questionData, "questionData");

  const openEditCategoryModal = (row) => {
    setIsEditServiceModal(true);
    setModalIsOpen(true);
    setEditingServicesRow(row);
  };

  const readQuestionsData = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const res = await readQuestion({ accessToken }).unwrap();
    console.log(res, "reswegwgw");
    if (res?.type === 1) {
      setQuestionData(res?.data);
    }
  };

  useEffect(() => {
    readQuestionsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
    setIsEditServiceModal(false);
  };

  const handleDeleteQuestion = async (id) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const res = await deleteQuestion({
      accessToken: accessToken,
      _id: id,
    }).unwrap();

    if (res?.type === 1) {
      GetToast("Question Successfully Deleted!");
      setQuestionData(questionData.filter((item) => item?._id !== id));
    }
  };

  return (
    <>
      <div className="NAVBAR flex justify-between items-center mb-4">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">
            Settings <IoIosArrowForward />{" "}
            <span className="font-medium text-[15px] text-primary"> Questions</span>
          </span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {/* <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
              CH00001
              <img
                className="relative top-[4px] right-0"
                src={greenTick}
                alt=""
              />
            </button>
            <img src={bell} alt="" /> */}
          <Notification />
        </div>
      </div>
      <div className="HEADING flex md:justify-between items-center gap-4 mb-4 mt-2">
        <p className=" font-medium text-[34px] leading-7 md:mr-8 text-white">Questions</p>
        <button
          onClick={() => {
            setModalIsOpen(true);
            setEditingServicesRow(null);
          }}
          className="bg-[#FF7E00] px-2 py-4 text-white rounded-lg text-[16px] leading-4 font-medium"
        >
          + {"   "} Add Question
        </button>
      </div>
      <div className="bg-secondary p-4 rounded-md overflow-auto md:h-[80vh] h-[70vh]">
        <DynamicTable
          headers={headersForSoftwareTab}
          data={questionData}
          includeImgVideoButton={true}
          questionTable={true}
          onClickEditRow={openEditCategoryModal}
          onClickDeleteRow={(row) => {
            handleDeleteQuestion(row?._id);
          }}
        />
      </div>

      {/* modal here  */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full" onClick={closeModal}>
              <IoIosClose className="ml-auto text-[32px]" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-8">
              {isEditServiceModal ? "Edit Question" : "Add Question"}
            </p>
            <div className="MODAL-TAB-SECTION h-3/5 md:flex-1">
              <InputFieldQuestionTab
                editingServicesRow={editingServicesRow}
                isEditModal={isEditServiceModal}
                setModalIsOpen={setModalIsOpen}
                getData={readQuestionsData}
                serviceData={[]}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

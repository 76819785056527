const SmileOrderSummary = ({ orderData, selectedServiceCategory }) => {
  return (
    <>
      {orderData?.caseDetails?.services?.map((service) => {
        const serviceDetails = selectedServiceCategory?.catservices?.find(
          (d) => d?.serviceObjId === service?.serviceObjId
        );
        if (serviceDetails?.serviceObjId) {
          return (
            <div
              key={"serviceItem-" + serviceDetails?.serviceObjId}
              className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 rounded-xl bg-backgroundV2 p-6 my-4"
            >
              <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-primary">
                <p className="text-[15px] text-white font-semibold">Service Name</p>
                <p className="text-primary font-medium mt-2 uppercase">
                  {serviceDetails?.serviceName}
                </p>
              </div>
              {[
                "Partial wax-up for selected teeth",
                "Full arch upper and lower wax-up",
                "Injectable composite models with alternate tooth wax-up",
                "Simulation services for patient motivation",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Please select all wax up/simulation teeth as applicable
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.waxupSimulationTeeth?.join(", ") || "NA"}
                  </p>
                </div>
              )}
              {[
                "Partial wax-up for selected teeth",
                "Full arch upper and lower wax-up",
                "Injectable composite models with alternate tooth wax-up",
                "Simulation services for patient motivation",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Do you need to see a design preview?</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.needDesignPreview || "NA"}
                  </p>
                </div>
              )}
              {[
                "Partial wax-up for selected teeth",
                "Full arch upper and lower wax-up",
                "Injectable composite models with alternate tooth wax-up",
                "Simulation services for patient motivation",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Design file to be provided?</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.designFileNeeded || "NA"}
                  </p>
                </div>
              )}
              {[
                "Partial wax-up for selected teeth",
                "Full arch upper and lower wax-up",
                "Injectable composite models with alternate tooth wax-up",
                "Simulation services for patient motivation",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Preferred design software?</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.preferredSoftware || "NA"}
                  </p>
                </div>
              )}
              {[
                "Partial wax-up for selected teeth",
                "Full arch upper and lower wax-up",
                "Injectable composite models with alternate tooth wax-up",
                "Simulation services for patient motivation",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    {serviceDetails?.serviceName ===
                    "Injectable composite models with alternate tooth wax-up"
                      ? "Would you like to receive two printable model files in .STL format with alternate and complete restorative wax ups ?"
                      : "Would you like to receive a printable model file in .STL format ?"}
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.[
                      serviceDetails?.serviceName ===
                      "Injectable composite models with alternate tooth wax-up"
                        ? "needTwoPrintableModelFile"
                        : "needPrintableModelFile"
                    ] || "NA"}
                  </p>
                </div>
              )}
              {[
                "Partial wax-up for selected teeth",
                "Full arch upper and lower wax-up",
                "Simulation services for patient motivation",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Would you like to receive printable eggshell temporary files in .STL format?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.needPrintableEggshellFiles || "NA"}
                  </p>
                </div>
              )}
              {["Nightguards for restorative protection"].includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      What arch would you like a nightguard on?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.archNightguardOn || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Preferred design software?</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.preferredSoftware || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      How much thickness would you like for your nightguard(s)? (mm)
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.nightguardThickness || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Will you be printing or milling your nightguard?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.nightguardProcessing || "NA"}
                    </p>
                  </div>
                </>
              )}
              {serviceDetails?.serviceName ===
                "Gingival recontouring guide for restorative dentistry" && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      What arch would you like a soft tissue recontouring reduction guide for?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.softTissueReductionGuideFor || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      If you would also like a hard tissue recontouring reduction guide, what arch
                      would you like the guide on?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.hardTissueReductionGuideFor || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Preferred design software?</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.preferredSoftware || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Please select all teeth that you would like your recontouring guides to extend
                      to?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.recontouringGuidesTeeth?.join(", ") || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      If selecting a hard tissue recontouring guide as well, how much distance would
                      you like between the final level of soft tissue and the final level of the
                      hard tissue?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.finalLevelDistance || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Will you be printing or milling your recontouring reduction guide?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.recontouringGuideProcessing || "NA"}
                    </p>
                  </div>
                </>
              )}
              {["Deprogrammers for restorative dentistry"].includes(
                serviceDetails?.serviceName
              ) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      What deprogrammer design do you prefer?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.preferredDeprogrammerDesign || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      What arch would you like a deprogrammer on?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.archDeprorammerOn || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Preferred design software?</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.preferredSoftware || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Please select all teeth that you would like your deprogrammer to extend to?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.deprogrammerTeeth?.join(", ") || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      How much thickness would you like for your deprogrammer?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.deprogrammerThickness || "NA"}
                    </p>
                  </div>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Will you be printing or milling your deprogrammer?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.deprogrammerProcessing || "NA"}
                    </p>
                  </div>
                </>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Additional Instructions</p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.additionalInstructions || "NA"}
                </p>
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default SmileOrderSummary;

import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "react-tooltip";

import { LoginButton } from "../Login/Login";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import Notification from "../../Components/Notification/Notification";
import { DisclaimerSection } from "../../Components/Order-Comps/DisclaimerSection";
import { removablesTermsAndConditions } from "../../constants/OrderFormsDisclaimers";
import UploadPhotoGraphsSection from "../../Components/Order-Comps/UploadPhotoGraphsSection";
import { PatientDetailsSection } from "../../Components/Order-Comps/PatientDetailsSection";
import { RemovablesCaseDetailsSection } from "../../Components/Order-Comps/RemovablesCaseDetailsSection";
import CrownOrderConfirmation from "../OrderConfirmation/CrownOrderConfirmation";

import {
  useGetOrdersForDentistMutation,
  useSaveOrderDisclaimerMutation,
  useSaveOrderMutation,
  useSaveOrderPatientMutation,
  useSaveOrderServiceDetailsMutation,
  useUpdateOrderDisclaimerMutation,
  useUpdateOrderMutation,
  useUpdateOrderPatientMutation,
  useUpdateOrderServiceDetailsMutation,
} from "../../services/orders";
import {
  setOrderFormLastProgressTab,
  setRemovablesOrderDetails,
} from "../../reducers/orderFormReducer";
import { useGetServicesCategoriesMutation } from "../../services/servicesCategory";

import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import { checkObjects, getServicePriceRange, scrollToTop } from "../../Utils/utility";

import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import hut from "../../Assets/images/hut.svg";
import logo from "../../Assets/images/LOGO.svg";
import { FaAngleRight } from "react-icons/fa";
import { AiOutlineCheck } from "react-icons/ai";
import { ORDER_STAGE, SERVICE_TEETH_MAPPING_REVERSE } from "../../constants/orderForms";
import RemovablesOrderConfirmation from "../OrderConfirmation/RemovablesOrderConfirmation";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import PriceSlip from "./PriceSlip";

function RemovablesOrderDetails({ userRole, userInfo }) {
  const location = useLocation();
  return (
    <div className="flex bg-black">
      <Navbar
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active="Orders"
      />
      <OrderDetailsTab
        orderObjId={location?.state?.orderObjId ?? ""}
        categoryObjId={location?.state?.categoryObjId ?? ""}
        orderByIdData={location?.state?.orderByData}
        userInfo={userInfo}
        userRole={userRole}
      />
    </div>
  );
}

export default RemovablesOrderDetails;

export const OrderDetailsTab = ({
  userRole,
  userInfo,
  categoryObjId,
  orderByIdData,
  orderObjId,
}) => {
  const dispatch = useDispatch();
  const [updateOrder] = useUpdateOrderMutation();
  const [saveOrder] = useSaveOrderMutation();
  const [saveOrderPatient] = useSaveOrderPatientMutation();
  const [updateOrderPatient] = useUpdateOrderPatientMutation();
  const [saveOrderDisclaimer] = useSaveOrderDisclaimerMutation();
  const [getOrdersForDentist] = useGetOrdersForDentistMutation();
  const [updateOrderDisclaimer] = useUpdateOrderDisclaimerMutation();
  const [saveOrderServiceDetails] = useSaveOrderServiceDetailsMutation();
  const [updateOrderServiceDetails] = useUpdateOrderServiceDetailsMutation();

  const [orderByData, setOrderByData] = useState(orderByIdData);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);
  const { removablesOrderDetails, orderFormLastProgressTab } = useSelector(
    (state) => state.orderFormDetails
  );

  useEffect(() => {
    if (orderByData?._id) {
      dispatch(
        setRemovablesOrderDetails({
          ...removablesOrderDetails,
          categoryObjId: categoryObjId,
          _id: orderByData?._id,
        })
      );
    }
  }, [orderByData]);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isDisclaimerAccepted, setIsDisclaimerAccepted] = useState(false);
  const [selectedServiceCategory, setSelectedServiceCategory] = useState({});
  const [isTncChecked, setIsTncChecked] = useState(false);
  const [disclaimerFileUrl, setDisclaimerFileUrl] = useState(null);
  const [isDisclaimerFormValid, setIsDisclaimerFormValid] = useState(true);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);

  const tabNamesArray = [
    ORDER_STAGE.PATIENT_DETAILS,
    ORDER_STAGE.CASE_DETAILS,
    ORDER_STAGE.UPLOAD_FILES,
    ORDER_STAGE.DISCLAIMER,
  ];

  const getExistingOrderDetails = async (orderObjId) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const newresp = await getOrdersForDentist({
      accessToken,
      data: { _id: orderByData?._id },
    }).unwrap();
    const orderDetails = newresp?.data?.[0];
    if (orderDetails) {
      dispatch(
        setRemovablesOrderDetails({
          expectedDelivery: orderDetails?.expectedDelivery || "",
          categoryObjId: categoryObjId,
          isPriority: orderDetails?.isPriority !== undefined ? orderDetails?.isPriority : null,
          patientDetails: {
            patientInitials: orderDetails?.patientDetails?.patientInitials || "",
            detailsAnonymized: orderDetails?.patientDetails?.detailsAnonymized || false,
            dob: orderDetails?.patientDetails?.dob || "",
            gender: orderDetails?.patientDetails?.gender || "",
            address: orderDetails?.patientDetails?.address || "",
            country: orderDetails?.patientDetails?.country || "",
            pinCode: orderDetails?.patientDetails?.pinCode || "",
            location: {
              lat: orderDetails?.patientDetails?.latitude
                ? parseInt(orderDetails?.patientDetails?.latitude)
                : "",
              lng: orderDetails?.patientDetails?.longitude
                ? parseInt(orderDetails?.patientDetails?.longitude)
                : "",
            },
          },
          caseDetails: {
            numberSystem: orderDetails?.numberSystem || "",
            hasMissingTeeth:
              orderDetails?.hasMissingTeeth !== undefined ? orderDetails?.hasMissingTeeth : null,
            missingTeethSelectedTeeth: (orderDetails?.missingTeeth || [])?.reduce((acc, item) => {
              if (item?.expiryDate === null) {
                acc.push(item?.teethno);
              }
              return acc;
            }, []),
            services: (orderDetails?.services || []).reduce((acc, item) => {
              const serviceTooths = item?.serviceTooth?.reduce((prevAcc, toothItem) => {
                prevAcc[SERVICE_TEETH_MAPPING_REVERSE[toothItem.type]] = [
                  ...(prevAcc?.[SERVICE_TEETH_MAPPING_REVERSE[toothItem.type]] || []),
                  toothItem.teethno,
                ];
                return prevAcc;
              }, {});
              const serviceOptions = item?.serviceOptions?.reduce((prevAcc, optionItem) => {
                prevAcc[optionItem.type] = [
                  ...(prevAcc?.[optionItem?.type] || []),
                  optionItem?.optionstring,
                ];
                return prevAcc;
              }, {});
              acc.push({ ...item, ...serviceTooths, ...serviceOptions });
              return acc;
            }, []),
          },
          uploadFiles: {
            photographs: (orderDetails?.assets || [])?.reduce((acc, item) => {
              if (item?.fileType === "photograhpsAndFaceScans") {
                acc[item?.fileFor] = {
                  fileType: item?.fileType || "",
                  s3Url: item?.s3Url || "",
                  _id: item?._id || "",
                };
              }
              return acc;
            }, {}),
            radiographs: (orderDetails?.assets || [])?.reduce((acc, item) => {
              if (item?.fileType === "radiographs") {
                acc[item?.fileFor] = {
                  fileType: item?.fileType || "",
                  s3Url: item?.s3Url || "",
                  _id: item?._id || "",
                };
              }
              return acc;
            }, {}),
            intraOralScans: (orderDetails?.assets || [])?.reduce((acc, item) => {
              if (item?.fileType === "internalScan") {
                acc[item?.fileFor] = {
                  fileType: item?.fileType || "",
                  s3Url: item?.s3Url || "",
                  _id: item?._id || "",
                };
              }
              return acc;
            }, {}),
            rawDicomData: (orderDetails?.assets || [])?.reduce((acc, item) => {
              if (item?.fileType === "rawDicom") {
                acc[item?.fileFor] = {
                  fileType: item?.fileType || "",
                  s3Url: item?.s3Url || "",
                  _id: item?._id || "",
                };
              }
              return acc;
            }, {}),
            additionalFiles: (orderDetails?.assets || [])?.reduce((acc, item) => {
              if (item?.fileType === "additionalFiles") {
                acc[item?.fileFor] = {
                  fileType: item?.fileType || "",
                  s3Url: item?.s3Url || "",
                  _id: item?._id || "",
                };
              }
              return acc;
            }, {}),
          },
          disclaimers: {
            isTncChecked: orderDetails?.isTncChecked || false,
            tncDocument: orderDetails?.tncDocument || null,
          },
        })
      );
      setOrderByData(orderDetails);
    }
  };

  useEffect(() => {
    if (orderObjId) {
      getExistingOrderDetails(orderObjId);
    }
  }, [orderObjId]);

  const [activeTab, setActiveTab] = useState(tabNamesArray[0]);
  const [priceRange, setPriceRange] = useState(null);

  const [getServicesCategories] = useGetServicesCategoriesMutation();
  const getServicesCategoriesData = async (accessToken) => {
    const resp = await getServicesCategories(accessToken).unwrap();
    if (resp?.data && categoryObjId) {
      resp?.data?.forEach((d) => {
        if (d?._id === categoryObjId) {
          setSelectedServiceCategory(d);
          setPriceRange(getServicePriceRange(d?.catservices));
        }
      });
    }
  };

  const readServicesInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getServicesCategoriesData(accessToken);
  };

  useEffect(() => {
    readServicesInfo();

    return () => {
      dispatch(setRemovablesOrderDetails(removablesOrderDetails));
      dispatch(setOrderFormLastProgressTab(0));
    };
  }, []);

  const fetchOrderDetails = async (accessToken) => {
    const newresp = await getOrdersForDentist({
      accessToken,
      data: { _id: orderByData?._id },
    });
    setOrderByData(newresp?.data?.data?.[0]);
  };

  const submitPatientDetails = async (data) => {
    const { isPriority, expectedDelivery, spLevelObjId, ...patientDetails } = data;
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let resp;
    const requestList = [];
    if (removablesOrderDetails?._id) {
      requestList.push(
        updateOrder({
          accessToken,
          data: {
            _id: removablesOrderDetails?._id,
            isPriority: isPriority,
            expectedDelivery: expectedDelivery,
            spLevelObjId: spLevelObjId,
          },
        })
      );
      if (orderByData?.patientDetails) {
        requestList.push(
          updateOrderPatient({
            accessToken,
            _id: removablesOrderDetails?._id,
            patientInitials: patientDetails?.patientInitials,
            detailsAnonymized: patientDetails?.detailsAnonymized,
            dob: patientDetails?.dob,
            gender: patientDetails?.gender,
            address: patientDetails?.address,
            country: patientDetails?.country,
            pinCode: patientDetails?.pinCode,
            latitude: patientDetails?.location?.lat,
            longitude: patientDetails?.location?.lng,
          })
        );
      } else {
        requestList.push(
          saveOrderPatient({
            accessToken,
            _id: removablesOrderDetails?._id,
            patientInitials: patientDetails?.patientInitials,
            detailsAnonymized: patientDetails?.detailsAnonymized,
            dob: patientDetails?.dob,
            gender: patientDetails?.gender,
            address: patientDetails?.address,
            country: patientDetails?.country,
            pinCode: patientDetails?.pinCode,
            latitude: patientDetails?.location?.lat,
            longitude: patientDetails?.location?.lng,
          })
        );
      }
      dispatch(
        setRemovablesOrderDetails({
          ...removablesOrderDetails,
          isPriority: isPriority,
          expectedDelivery: expectedDelivery,
          spLevelObjId: spLevelObjId,
          patientDetails: patientDetails,
        })
      );
      await Promise.all(requestList);
      fetchOrderDetails(accessToken);
    } else {
      resp = await saveOrder({
        accessToken,
        data: {
          isPriority: isPriority,
          expectedDelivery: expectedDelivery,
          spLevelObjId: spLevelObjId,
          categoryObjId: removablesOrderDetails?.categoryObjId,
        },
      }).unwrap();
      await saveOrderPatient({
        accessToken,
        _id: resp?.data?.data?.[0]?._id,
        patientInitials: patientDetails?.patientInitials,
        detailsAnonymized: patientDetails?.detailsAnonymized,
        dob: patientDetails?.dob,
        gender: patientDetails?.gender,
        address: patientDetails?.address,
        country: patientDetails?.country,
        pinCode: patientDetails?.pinCode,
        latitude: patientDetails?.location?.lat,
        longitude: patientDetails?.location?.lng,
      });
      dispatch(
        setRemovablesOrderDetails({
          ...removablesOrderDetails,
          _id: resp?.data?.data?.[0]?._id,
          isPriority: isPriority,
          expectedDelivery: expectedDelivery,
          spLevelObjId: spLevelObjId,
          patientDetails: patientDetails,
        })
      );
      fetchOrderDetails(accessToken);
    }
    dispatch(setOrderFormLastProgressTab(1));
  };

  const submitDisclaimerForm = () => {
    const isTncCheckedValid = !isFieldRequired("tnc") || isTncChecked;
    setIsDisclaimerFormValid(isTncCheckedValid);

    if (isTncCheckedValid) {
      console.log("submit the form");
      submitDisclaimerData({
        isTncChecked: isTncChecked,
        tncDocument: disclaimerFileUrl,
      });
    } else {
      console.log("not checked");
    }
  };

  const submitDisclaimerData = async (data) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (removablesOrderDetails?.tncDocument) {
      await updateOrderDisclaimer({
        accessToken: accessToken,
        _id: removablesOrderDetails?._id,
        isTncChecked: data?.isTncChecked,
        tncDocument: data?.tncDocument,
      });
    } else {
      await saveOrderDisclaimer({
        accessToken: accessToken,
        _id: removablesOrderDetails?._id,
        isTncChecked: data?.isTncChecked,
        tncDocument: data?.tncDocument,
      });
    }
    dispatch(
      setRemovablesOrderDetails({
        ...removablesOrderDetails,
        disclaimers: data,
      })
    );
    setIsDisclaimerAccepted(true);
  };

  const submitCaseDetails = async (data) => {
    dispatch(
      setRemovablesOrderDetails({
        ...removablesOrderDetails,
        caseDetails: data,
      })
    );

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const requestList = [];

    requestList.push(
      updateOrder({
        accessToken,
        data: {
          _id: removablesOrderDetails?._id,
          hasMissingTeeth: data?.hasMissingTeeth,
          numberSystem: data?.numberSystem,
        },
      })
    );

    data?.services?.forEach((service) => {
      const findService = removablesOrderDetails?.caseDetails?.services?.find(
        (d) => d?.serviceObjId === service?.serviceObjId
      );
      if (!findService?.serviceObjId) {
        requestList.push(
          saveOrderServiceDetails({
            accessToken: accessToken,
            _id: removablesOrderDetails?._id,
            serviceObjId: service?.serviceObjId,
            impressionTrayFor: service?.impressionTrayFor,
            needHandle: service?.needHandle,
            handlesDesired: service?.handlesDesired,
            holesInImpressionTray: service?.holesInImpressionTray,
            impressionMaterialHoles: service?.impressionMaterialHoles,
            preferredSoftware: service?.preferredSoftware,
            intraoralScansFor: service?.intraoralScansFor,
            digitalInversionFor: service?.digitalInversionFor,
            biteRegistrationDesired: service?.biteRegistrationDesired,
            dentureBaseProcessing: service?.dentureBaseProcessing,
            teethProcessing: service?.teethProcessing,
            monoblocPrintDesired: service?.monoblocPrintDesired,
            dentureThickness: service?.dentureThickness,
            splintThickness: service?.splintThickness,
            deprogrammerThickness: service?.deprogrammerThickness,
            finalLevelDistance: service?.finalLevelDistance,
            copyDentureDesired: service?.copyDentureDesired,
            splintProcessing: service?.splintProcessing,
            recontouringGuideProcessing: service?.recontouringGuideProcessing,
            softTissueReductionGuideFor: service?.softTissueReductionGuideFor,
            hardTissueReductionGuideFor: service?.hardTissueReductionGuideFor,
            preferredDeprogrammerDesign: service?.preferredDeprogrammerDesign,
            nightguardProcessing: service?.nightguardProcessing,
            archDeprorammerOn: service?.archDeprorammerOn,
            occlusionSetup: service?.occlusionSetup,
            toothMouldShape: service?.toothMouldShape,
            archNightguardOn: service?.archNightguardOn,
            archSplintOn: service?.archSplintOn,
            preferredSplintDesign: service?.preferredSplintDesign,
            teethSetupStyle: service?.teethSetupStyle,
            teethSize: service?.teethSize,
            crossbiteCreated: service?.crossbiteCreated,
            dentureForPhotoScan: service?.dentureForPhotoScan,
          })
        );
      } else if (findService?.serviceObjId && !checkObjects(service, findService)) {
        requestList.push(
          updateOrderServiceDetails({
            accessToken: accessToken,
            _id: removablesOrderDetails?._id,
            serviceObjId: service?.serviceObjId,
            impressionTrayFor: service?.impressionTrayFor,
            needHandle: service?.needHandle,
            handlesDesired: service?.handlesDesired,
            holesInImpressionTray: service?.holesInImpressionTray,
            impressionMaterialHoles: service?.impressionMaterialHoles,
            preferredSoftware: service?.preferredDesignSoftware,
            intraoralScansFor: service?.intraoralScansFor,
            digitalInversionFor: service?.digitalInversionFor,
            biteRegistrationDesired: service?.biteRegistrationDesired,
            dentureBaseProcessing: service?.dentureBaseProcessing,
            teethProcessing: service?.teethProcessing,
            monoblocPrintDesired: service?.monoblocPrintDesired,
            dentureThickness: service?.dentureThickness,
            splintThickness: service?.splintThickness,
            deprogrammerThickness: service?.deprogrammerThickness,
            finalLevelDistance: service?.finalLevelDistance,
            copyDentureDesired: service?.copyDentureDesired,
            splintProcessing: service?.splintProcessing,
            recontouringGuideProcessing: service?.recontouringGuideProcessing,
            softTissueReductionGuideFor: service?.softTissueReductionGuideFor,
            hardTissueReductionGuideFor: service?.hardTissueReductionGuideFor,
            preferredDeprogrammerDesign: service?.preferredDeprogrammerDesign,
            archDeprorammerOn: service?.archDeprorammerOn,
            occlusionSetup: service?.occlusionSetup,
            toothMouldShape: service?.toothMouldShape,
            archNightguardOn: service?.archNightguardOn,
            archSplintOn: service?.archSplintOn,
            preferredSplintDesign: service?.preferredSplintDesign,
            teethSetupStyle: service?.teethSetupStyle,
            teethSize: service?.teethSize,
            crossbiteCreated: service?.crossbiteCreated,
            dentureForPhotoScan: service?.dentureForPhotoScan,
          })
        );
      }
    });

    await Promise.all(requestList);
    fetchOrderDetails(accessToken);
    dispatch(setOrderFormLastProgressTab(2));
  };

  const submitUploadData = (data) => {
    dispatch(
      setRemovablesOrderDetails({
        ...removablesOrderDetails,
        uploadFiles: data,
      })
    );
    dispatch(setOrderFormLastProgressTab(3));
  };

  const placeOrderHandler = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let resp;
    resp = await updateOrder({
      accessToken,
      data: {
        _id: removablesOrderDetails?._id,
        isPriority: removablesOrderDetails?.isPriority,
        expectedDelivery: removablesOrderDetails?.expectedDelivery,
        categoryObjId: removablesOrderDetails?.categoryObjId,
        currentStatus: "1",
      },
    }).unwrap();
    if (resp) {
      GetToast(resp["message"]);
    }
    setIsOrderPlaced(true);
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = ["intraOralScans", "tnc"];

    return requiredFields.includes(fieldName);
  };

  const handleTabSuper = (tabName) => {
    scrollToTop();
    setActiveTab(tabName);
  };

  const outputObject = {
    [ORDER_STAGE.PATIENT_DETAILS]: (
      <PatientDetailsSection
        orderObjId={orderByData?._id}
        orderByData={orderByData}
        categoryObjId={categoryObjId}
        setActiveTab={handleTabSuper}
        orderPatientData={{
          ...removablesOrderDetails?.patientDetails,
          expectedDelivery: removablesOrderDetails?.expectedDelivery,
          isPriority: removablesOrderDetails?.isPriority,
        }}
        submitOrderData={submitPatientDetails}
      />
    ),
    [ORDER_STAGE.CASE_DETAILS]: (
      <RemovablesCaseDetailsSection
        orderObjId={orderByData?._id}
        orderByData={orderByData}
        categoryObjId={categoryObjId}
        setActiveTab={handleTabSuper}
        orderCaseData={removablesOrderDetails?.caseDetails}
        submitOrderData={submitCaseDetails}
        selectedServiceCategory={selectedServiceCategory}
      />
    ),
    [ORDER_STAGE.UPLOAD_FILES]: (
      <UploadPhotoGraphsSection
        orderObjId={orderByData?._id}
        orderByData={orderByData}
        categoryObjId={categoryObjId}
        setActiveTab={handleTabSuper}
        orderUploadData={removablesOrderDetails?.uploadFiles}
        submitOrderData={submitUploadData}
        isFieldRequired={isFieldRequired}
      />
    ),
    [ORDER_STAGE.DISCLAIMER]: (
      <DisclaimerSection
        orderObjId={orderByData?._id}
        orderByData={orderByData}
        categoryObjId={categoryObjId}
        submitOrderData={submitDisclaimerForm}
        setActiveTab={handleTabSuper}
        tncList={removablesTermsAndConditions}
        isTncChecked={isTncChecked}
        setIsTncChecked={setIsTncChecked}
        setDisclaimerFileUrl={setDisclaimerFileUrl}
        isDisclaimerFormValid={isDisclaimerFormValid}
      />
    ),
  };

  return (
    <>
      {isDisclaimerAccepted ? (
        <RemovablesOrderConfirmation
          userInfo={userInfo}
          userRole={userRole}
          orderObjId={orderByData?._id}
          orderFor={{ categoryObjId: categoryObjId }}
          setActiveTab={handleTabSuper}
          selectedServiceCategory={selectedServiceCategory}
          setIsDisclaimerAccepted={setIsDisclaimerAccepted}
          isOrderPlaced={isOrderPlaced}
          placeOrderHandler={placeOrderHandler}
          categoryObjId={categoryObjId}
          orderByData={orderByData}
        />
      ) : (
        <div className="MAIN-DASH bg-black md:p-4 px-4 pt-4 pb-20 w-full min-h-screen h-fit flex flex-col">
          <div className="NAVBAR flex justify-between items-center mb-4 ">
            <p className="text-[15px] text-[#999999] flex justify-between items-center">
              <img src={logo} className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden" alt="" />
              <span>
                <img src={hut} alt="" />
              </span>
              <span className="ml-3">Dashboard</span>
            </p>
            <div className="flex gap-4 items-center">
              {isAuthenticated ? (
                <div className="text-[15px] justify-center items-center hidden md:flex text-white">
                  Hello, {userProfileDetails?.firstName ?? user?.name}
                </div>
              ) : (
                <LoginButton />
              )}
              {userRole !== "89uij7" ? (
                userInfo?.length > 0 ? (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    {userInfo[0]?.active === 1 ? (
                      <>
                        {userInfo[0]?.crowdHireId}
                        <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                      </>
                    ) : (
                      <div
                        className="flex"
                        data-tooltip-id="status"
                        data-tooltip-content={
                          "Your registration is under review and you will be notified once admin approves your registration."
                        }
                      >
                        <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                        <img
                          className="relative top-[0px] ml-2 right-0 h-5 w-5"
                          src={redCross}
                          alt=""
                        />
                        <Tooltip id="status" place="bottom" />
                      </div>
                    )}
                  </button>
                ) : (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    Account Not Active
                    <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
                  </button>
                )
              ) : (
                <></>
              )}

              <Notification />
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <p className="font-medium text-[34px] text-white">Removables Order</p>
            {priceRange && (
              <PriceSlip
                priceFrom={priceRange?.priceRangeFrom}
                priceTo={priceRange?.priceRangeTo}
                small={true}
              />
            )}
            {orderByData?.orderNumber && (
              <p className="text-white">
                Order Id : <strong className="text-primary">{orderByData?.orderNumber}</strong>
              </p>
            )}
          </div>
          <div className="ORDER-DETAIL-COMPONENT flex-1 flex flex-col h-full rounded-xl">
            <div className="bg-backgroundV2 flex flex-row flex-wrap items-center gap-4 md:justify-around justify-between md:px-0 px-4 py-4 mb-4">
              <div className="flex items-center gap-4">
                <div className="bg-[#8ED259] h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
                  <AiOutlineCheck className=" " />
                </div>
                <p className="font-semibold text-white md:block hidden ">Service Details</p>
              </div>
              <div className="md:w-[200px] h-[20px] ">
                <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
              </div>
              <div className="flex items-center gap-4">
                <div className="bg-orange-500 h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
                  2
                </div>
                <p className="text-white font-semibold md:block hidden ">Order Details</p>
              </div>
              <div className="md:w-[200px] h-[20px] md:rotate-0 ">
                <FaAngleRight className="mx-auto text-white " />
              </div>
              <div className="flex items-center gap-4">
                <div className="bg-white h-8 w-8 rounded-full border text-[#999] font-medium flex items-center justify-center">
                  3
                </div>
                <p className="text-white md:block hidden ">Confirmation</p>
              </div>
            </div>
            <div className="TAB-SECTION flex-1 flex md:flex-row flex-col p-4 h-full bg-secondary">
              <ul className="NAV md:pr-8 flex flex-col items-start md:gap-4 gap-0 text-[16px] leading-4 font-medium md:border-r border-b border-[#222026] border-opacity-10">
                {tabNamesArray.map((title, i) => {
                  return (
                    <li
                      key={"tabItem" + i}
                      className="relative w-full"
                      onClick={() => {
                        if (orderFormLastProgressTab >= i) {
                          handleTabSuper(title);
                        }
                      }}
                    >
                      <div
                        className={
                          activeTab === title
                            ? "text-white bg-primary w-full p-4 rounded-lg"
                            : "hover:text-white hover:bg-primary cursor-pointer text-white font-normal w-full p-4 rounded-lg"
                        }
                      >
                        {title}
                      </div>
                    </li>
                  );
                })}
              </ul>

              <div className="outlet flex flex-col flex-1 overflow-visible w-full md:py-4 md:pl-8  md:pr-4 p-2 ">
                {Object.keys(outputObject).map((key, i) => {
                  if (tabNamesArray.includes(key)) {
                    return (
                      <Fragment key={"tab-item-" + i}>
                        {activeTab === key ? <>{outputObject[key]}</> : null}
                      </Fragment>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

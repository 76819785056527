import React, { useState, useEffect } from "react";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import hut from "../../Assets/images/hut.svg";
import bell from "../../Assets/images/bellIcon.svg";
import Navbar, { MiddleNavLayout } from "../../Components/Navbar-Comps/Navbar";
import DynamicTable from "../../Components/Services-Comps/DynamicTable";
import circle from "../../Assets/images/circle.png";
import searchIcon from "../../Assets/images/searchInputIcon.svg";
import { useNavigate } from "react-router-dom";
import {
  useGetOrdersForAdminMutation,
  useGetOrdersForDentistMutation,
  useGetOrdersForServiceProviderMutation,
  useDeleteOrderMutation,
} from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../Login/Login";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import { Tooltip } from "react-tooltip";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";

function Orders({ userRole, userInfo }) {
  return (
    <div className="flex">
      <Navbar
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active="Orders"
      />
      <OrderTab userRole={userRole} userInfo={userInfo} />
    </div>
  );
}

export default Orders;

const data = [
  // Add more data rows here as needed
];

export const OrderTab = ({ userRole, userInfo }) => {
  const headersOrder = [
    { headerId: "serviceName", headerName: "Service", headerSort: true },
    { headerId: "orderNumber", headerName: "Order Id", headerSort: true },
    {
      headerId: "isPriority",
      headerName: "Priority Order",
      headerSort: false,
    },
    {
      headerId: "createdDate",
      headerName: "Order Date",
      headerSort: false,
    },
    {
      headerId: "status",
      headerName: "Order Status",
      headerSort: false,
    },
    {
      headerId: "actions",
      headerName: "Actions",
      headerSort: false,
    },
    {
      headerId: "discard",
      headerName: "Discard",
      headerSort: false,
    },
  ];

  const headersOrder2 = [
    { headerId: "serviceName", headerName: "Service", headerSort: true },
    { headerId: "orderNumber", headerName: "Order Id", headerSort: true },
    {
      headerId: "isPriority",
      headerName: "Priority Order",
      headerSort: false,
    },
    {
      headerId: "createdDate",
      headerName: "Order Date",
      headerSort: false,
    },
    {
      headerId: "status",
      headerName: "Order Status",
      headerSort: false,
    },
    {
      headerId: "actions",
      headerName: "Actions",
      headerSort: false,
    },
  ];

  const navigate = useNavigate();

  const { user, isAuthenticated } = useAuth0();
  const [selectedButton, setSelectedButton] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [getOrdersForAdmin] = useGetOrdersForAdminMutation();
  const [getOrdersForDentist] = useGetOrdersForDentistMutation();
  const [getOrdersForServiceProvider] = useGetOrdersForServiceProviderMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const [ordersData, setOrdersData] = useState([]);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);
  const [activeTab, setActiveTab] = useState(userRole === "89uij7" ? "New Orders" : "All Orders");
  const [middleNavActive, setMiddleNavActive] = useState(true);
  //  Functions to handle Tab Switching

  const closeMiddleNav = () => {
    setMiddleNavActive((prev) => !prev);
  };

  const getOrdersData = async (accessToken, currentStatus) => {
    // debugger
    if (userRole === "89uij7") {
      const resp = await getOrdersForAdmin({ accessToken, data: { currentStatus } }).unwrap();
      if (resp) {
        setOrdersData(resp.data);
      }
    } else if (userRole === "676dza2") {
      const resp = await getOrdersForDentist({ accessToken, data: { currentStatus } }).unwrap();
      if (resp) {
        setOrdersData(resp.data);
      }
    } else if (userRole === "34fs3") {
      const resp = await getOrdersForServiceProvider({
        accessToken,
        data: { currentStatus },
      }).unwrap();
      if (resp) {
        if (resp.data.length === 0) {
          GetToast("Currently there are no orders in due", "success");
        }
        setOrdersData(resp.data);
      }
    }
  };

  const onClickDeleteRow = async (row) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await deleteOrder({
      accessToken,
      _id: row?._id,
    });
    if (resp?.data?.type === 1) {
      GetToast("Deleted Successfully", "");
      const resp = await getOrdersForDentist({ accessToken }).unwrap();
      if (resp) {
        setOrdersData(resp.data);
      }
    } else {
      GetToast("Error", "error");
    }
  };

  const readOrders = async (currentStatus) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getOrdersData(accessToken, currentStatus);
  };

  useEffect(() => {
    if (activeTab === "New Orders") {
      readOrders("1");
    } else if (activeTab === "All Orders") {
      readOrders();
    } else if (activeTab === "Orders In Draft") {
      readOrders("0");
    } else if (activeTab === "Orders In Progress") {
      readOrders("5");
    } else if (activeTab === "Orders Completed") {
      readOrders("8");
    } else if (activeTab === "Orders Closed") {
      readOrders("13");
    }
  }, [activeTab]);

  const middleTabTitleArray = [
    "New Orders",
    "All Orders",
    "Orders In Progress",
    ...(userRole === "676dza2" ? ["Orders In Draft"] : []),
    "Orders Completed",
    ...(userRole === "89uij7" ? ["Orders Closed"] : []),
  ];

  return (
    <>
      <>
        <MiddleNavLayout
          middleNavActive={middleNavActive}
          activeTab={activeTab}
          middleTabTitleArray={middleTabTitleArray}
          handleTabSuper={setActiveTab}
          closeMiddleNav={closeMiddleNav}
          middleNavHeading="Orders"
        />

        <div className="MAIN-DASH bg-background p-4 w-full min-h-screen h-full flex flex-col md:py-4 pt-4 pb-20">
          <div className="NAVBAR flex justify-between items-center mb-4 ">
            <p className="text-[15px] text-[#999999] flex justify-between items-center">
              <span>
                <img src={hut} alt="" />
              </span>
              <span className="ml-3 flex items-center text-textP">Dashboard</span>
            </p>
            <div className="flex gap-4 items-center">
              {isAuthenticated ? (
                <div className="text-[15px] justify-center items-center hidden md:flex text-white">
                  Hello, {userProfileDetails?.firstName ?? user?.name}
                </div>
              ) : (
                <LoginButton />
              )}
              {userRole !== "89uij7" ? (
                userInfo?.length > 0 ? (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    {userInfo[0]?.active === 1 ? (
                      <>
                        {userInfo[0]?.crowdHireId}
                        <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                      </>
                    ) : (
                      <div
                        className="flex"
                        data-tooltip-id="status"
                        data-tooltip-content={
                          "Your registration is under review and you will be notified once admin approves your registration."
                        }
                      >
                        <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                        <img
                          className="relative top-[0px] ml-2 right-0 h-5 w-5"
                          src={redCross}
                          alt=""
                        />
                        <Tooltip id="status" place="bottom" />
                      </div>
                    )}
                  </button>
                ) : (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    Account Not Active
                    <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
                  </button>
                )
              ) : (
                <></>
              )}
              <Notification />
            </div>
          </div>
          <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
            <div className="flex flex-1 gap-4 items-center w-full">
              <p className="font-medium text-[34px] text-textP">Orders</p>
              {/* <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search by Service name..."
              className="w-full placeholder:text-[#999] py-2  placeholder:text-[16px] text-[16px] pl-8 bg-[#fff] focus:outline-none border border-[#dcdcdc] rounded-md"
            />
            <img className="absolute top-3 left-2" src={searchIcon} alt="" />
          </div> */}
            </div>
            {userRole === "676dza2" ? (
              <button
                onClick={() => {
                  navigate("/addNewOrder");
                }}
                className="text-[16px] font-medium text-white bg-[#FF7E00] rounded-md px-4 py-2"
              >
                + Add New Order
              </button>
            ) : null}
          </div>
          <div className="bg-secondary p-4 rounded-md md:h-[80vh] h-[70vh]">
            <DynamicTable
              setSelectedButton={setSelectedButton}
              headers={userRole === "676dza2" ? headersOrder : headersOrder2}
              data={ordersData}
              includeImgVideoButton={true}
              orderTable={true}
              userRole={userRole}
              onClickDeleteRow={onClickDeleteRow}
            />
          </div>
        </div>
      </>
    </>
  );
};

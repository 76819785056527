import React, { useEffect, useState } from "react";
import MapInputField from "../InputField-Comps/MapInputField";
import LocationInput from "../InputField-Comps/LocationInput";
import TextInputField from "../InputField-Comps/TextInputField";
import NumberInputField from "../InputField-Comps/NumberInputField";
import {
  useUpdateClinicalDetailsMutation,
  useSaveClinicalDetailsMutation,
} from "../../services/user";
import UseAccessToken from "../../hooks/UseAccessToken";
import { GetToast } from "../Toast-Comps/GetToast";

const InputFieldsClinic = ({ setModalIsOpen, profileDetailData, setIsProfileUpdated }) => {
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [regAuthority, setRegAuthority] = useState("");
  const [isClinicFormSubmitted, setIsClinicFormSubmitted] = useState(false);
  const [isValid, setIsValid] = useState("");
  const accessToken = UseAccessToken();

  const [updateClinicalDetails] = useUpdateClinicalDetailsMutation();
  const [saveClinicalDetails] = useSaveClinicalDetailsMutation();

  useEffect(() => {
    setRegistrationNumber(profileDetailData?.clinicalDetails?.[0]?.regNumber);
    setRegAuthority(profileDetailData?.clinicalDetails?.[0]?.regAuthority);
  }, [profileDetailData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "registrationNumber") setRegistrationNumber(value);
    if (name === "regAuthority") setRegAuthority(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isRegistrationNumberValid =
      !isFieldRequired("registrationNumber") || validateRegistrationNumber(registrationNumber);

    const isRegAddressValid =
      !isFieldRequired("regAuthority") || validateRegAuthority(regAuthority);

    setIsValid(isRegistrationNumberValid && isRegAddressValid);

    setIsClinicFormSubmitted(true);

    if (isRegistrationNumberValid && isRegAddressValid) {
      // Submit the form or perform any other action
      const data = {
        regNumber: registrationNumber,
        regAuthority: regAuthority,
      };
      console.log("Form submitted", data);

      if (profileDetailData?.clinicalDetails?.length > 0) {
        const resp = await updateClinicalDetails({
          accessToken,
          ...data,
          _id: profileDetailData?.clinicalDetails?.[0]?._id,
        }).unwrap();
        if (resp?.type === 1) {
          GetToast("Clinic Information Updated Successfully!!");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }
        setIsProfileUpdated(true);
      } else {
        const resp = await saveClinicalDetails({
          accessToken,
          ...data,
        });
        if (resp?.type === 1) {
          GetToast("Clinic Information Saved Successfully!!");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }
        setIsProfileUpdated(true);
      }
    }
  };

  // validation for clinic information
  const validateRegistrationNumber = (value) => {
    const phoneRegex = /^.{3,10}$/;
    return phoneRegex.test(value);
  };
  const validateRegAuthority = (value) => {
    const emailRegex = /^.{3,25}$/;
    return emailRegex.test(value);
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = ["registrationNumber", "regAuthority"];
    return requiredFields.includes(fieldName);
  };

  return (
    <div className="h-full">
      <form onSubmit={handleSubmit} className=" h-full flex flex-col">
        <div className="flex-1 pt-8 pb-8">
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"Registration Number"}
              errorMessage={"Please enter the Registration Number"}
              placeholder={"Registration Number"}
              htmlFor={"registrationNumber"}
              name={"registrationNumber"}
              value={registrationNumber}
              handleInputChange={handleInputChange}
              validationFunctionName={validateRegistrationNumber}
              isSubmitted={isClinicFormSubmitted}
              isFieldRequired={isFieldRequired("registrationNumber")}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"Registration Authority"}
              errorMessage={"Please enter the Registration Authority"}
              placeholder={"Registration Authority"}
              htmlFor={"regAuthority"}
              name={"regAuthority"}
              value={regAuthority}
              handleInputChange={handleInputChange}
              validationFunctionName={validateRegAuthority}
              isSubmitted={isClinicFormSubmitted}
              isFieldRequired={isFieldRequired("regAuthority")}
            />
          </div>
        </div>

        <div className="flex pb-4 items-center justify-end gap-4">
          <button
            onClick={() => setModalIsOpen(false)}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputFieldsClinic;

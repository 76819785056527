import { CORRECTION_TECHNIQUE } from "../../constants/orderForms";

const OrthodonticOrderSummary = ({ orderData, selectedServiceCategory }) => {
  return (
    <>
      {orderData?.caseDetails?.services?.map((service) => {
        const serviceDetails = selectedServiceCategory?.catservices?.find(
          (d) => d?.serviceObjId === service?.serviceObjId
        );
        console.log("serviceDetails", serviceDetails);
        if (serviceDetails?.serviceObjId) {
          return (
            <div
              key={"serviceItem-" + serviceDetails?.serviceObjId}
              className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 rounded-xl bg-backgroundV2 p-6 my-4"
            >
              <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-primary">
                <p className="text-[15px] text-white font-semibold">Service Name</p>
                <p className="text-primary font-medium mt-2 uppercase">
                  {serviceDetails?.serviceName}
                </p>
              </div>
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">
                      Are there any teeth that should not be moved?
                    </p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.teethNotToMoveOption || "NA"}
                    </p>
                  </div>
                  {service?.teethNotToMoveOption === "Yes" && (
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">Teeth that should not be moved</p>
                      <p className="text-[#555] font-medium mt-2">
                        {service?.teethNotToMove?.join(", ") || "NA"}
                      </p>
                    </div>
                  )}
                </>
              )}
              {["Clear Retainer"].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Would you like a printable retainer or a model that you will use for
                    thermoforming?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.printableAligners || "NA"}
                  </p>
                </div>
              )}
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Options</p>
                  <p className="text-[#555] font-medium mt-2">{service?.options || "NA"}</p>
                </div>
              )}
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    If you have chosen STL files, would you like printable aligners or models that
                    you will use for thermoforming?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.printableAligners || "NA"}
                  </p>
                </div>
              )}
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Clinical Conditions</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.clinicalConditions?.join(", ") || "NA"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Arches to treat</p>
                <p className="text-[#555] font-medium mt-2">{service?.archesToTreat || "NA"}</p>
              </div>
              {["Clear Aligners full arch", "Clear Aligners full arch refinement"].includes(
                serviceDetails?.serviceName
              ) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Molar Occlusal Goals</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.molarOcclusalGoals || "NA"}
                  </p>
                </div>
              )}
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Midline</p>
                  <p className="text-[#555] font-medium mt-2">{service?.midline || "NA"}</p>
                </div>
              )}
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Inter Proximal Reduction (IPR)</p>
                  <p className="text-[#555] font-medium mt-2">{service?.ipr || "NA"}</p>
                </div>
              )}
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Overjet Correction</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.overjetCorrectionOption || "NA"}
                    </p>
                  </div>
                  {service?.overjetCorrectionOption === CORRECTION_TECHNIQUE.IMPROVE && (
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">Overjet Correction Improve length</p>
                      <p className="text-[#555] font-medium mt-2">
                        {service?.overjetCorrectionOptionImproveLength || "NA"}
                      </p>
                    </div>
                  )}
                </>
              )}
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white">Overbite Correction</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.overbiteCorrectionOption || "NA"}
                    </p>
                  </div>
                  {service?.overbiteCorrectionOption === CORRECTION_TECHNIQUE.IMPROVE && (
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">Overbite Correction Improve length</p>
                      <p className="text-[#555] font-medium mt-2">
                        {service?.overbiteCorrectionOptionImproveLength || "NA"}
                      </p>
                    </div>
                  )}
                </>
              )}
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white"> Crossbite Correction</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.crossbiteCorrection || "NA"}
                  </p>
                </div>
              )}
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(serviceDetails?.serviceName) && (
                <>
                  <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-[15px] text-white"> Attachments allowed?</p>
                    <p className="text-[#555] font-medium mt-2">
                      {service?.attachmentsAllowed || "NA"}
                    </p>
                  </div>
                  {service?.attachmentsAllowed === "Yes" && (
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white"> Attachments allowed on?</p>
                      <p className="text-[#555] font-medium mt-2">
                        {service?.attachmentsAllowedOn || "NA"}
                      </p>
                    </div>
                  )}
                </>
              )}

              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white"> Retainer Required?</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.retainerRequired || "NA"}
                  </p>
                </div>
              )}

              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Preferred design software?</p>
                <p className="text-[#555] font-medium mt-2">{service?.preferredSoftware || "NA"}</p>
              </div>

              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Additional Instructions </p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.additionalInstructions || "NA"}
                </p>
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default OrthodonticOrderSummary;

import { useEffect, useState } from "react";
import TextInputField from "../InputField-Comps/TextInputField";
import NumberInputField from "../InputField-Comps/NumberInputField";
import UseAccessToken from "../../hooks/UseAccessToken";
import { useNavigate } from "react-router-dom";
import {
  useSavePersonalDetailsSpMutation,
  useUpdatePersonalDetailsMutation,
  useGetProfileMutation,
} from "../../services/user";
import { GetToast } from "../Toast-Comps/GetToast";
import { useAuth0 } from "@auth0/auth0-react";
import { useSaveFileMutation } from "../../services/uploadFiles";
import TimeZoneInputField from "../InputField-Comps/TimeZoneInputField";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import { Checkbox } from "../InputField-Comps/CheckboxField";

import { useDeleteUserSoftwareMutation, useSaveUserSoftwareMutation } from "../../services/util";

const InputFieldsProfile = ({
  setModalIsOpen,
  emailFromUserProfile,
  setIsProfileUpdated,
  profileDetailData,
  profileId,
  readPersonalDetails,
  allCadSoftwareCategories,
  role,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const accessToken = UseAccessToken();

  console.log("profileDetailData654", profileDetailData);

  //profile details states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const [alreadyWorkingWithDentalLab, setAlreadyWorkingWithDentalLab] = useState();
  const [dentalDesignServiceProvider, setDentalDesignServiceProvider] = useState();
  const [softwaresUsed, setSoftwaresUsed] = useState({});
  const [registrationNumber, setRegistrationNumber] = useState("");

  const [updatePersonalDetails] = useUpdatePersonalDetailsMutation();
  const [savePersonalDetailsSp] = useSavePersonalDetailsSpMutation();
  const [saveUserSoftware] = useSaveUserSoftwareMutation();
  const [deleteUserSoftware] = useDeleteUserSoftwareMutation();

  const [isProfileFormSubmitted, setIsProfileFormSubmitted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isProfileValid, setIsProfileValid] = useState(false);

  //   FIRST FORM SUBMISSION FUNCTION
  const submitPersonDetails = async (e) => {
    e.preventDefault();
    // Perform validation for each input
    const isFirstNameValid = !isFieldRequired("firstName") || validateFirstName(firstName);
    const isLastNameValid = !isFieldRequired("lastName") || validateLastName(lastName);
    const isMobileNumberValid = !isFieldRequired("mobile") || validateMobileNumber(mobile);
    // const isCompanyNameValid =
    //   !isFieldRequired("companyName") || validateFirstName(companyName);
    const isRegistrationNumberValid =
      !isFieldRequired("registrationNumber") || validateFirstName(registrationNumber);

    // Set overall form validity
    setIsProfileValid(
      isFirstNameValid &&
        isLastNameValid &&
        isMobileNumberValid &&
        //isCompanyNameValid &&
        isRegistrationNumberValid
    );

    setIsProfileFormSubmitted(true);

    if (
      isFirstNameValid &&
      isLastNameValid &&
      isMobileNumberValid &&
      //isCompanyNameValid &&
      isRegistrationNumberValid
    ) {
      // Submit the form or perform any other action
      const profileDetailSave = {
        firstName: firstName,
        lastName: lastName,
        mobile: mobile,
        // companyName: companyName,
        gender: genderValue,
        registrationNumber: registrationNumber,
        alreadyWorkingWithDentalLab: alreadyWorkingWithDentalLab,
        dentalDesignServiceProvider: dentalDesignServiceProvider,
        email: emailFromUserProfile,
        timeZone: selectedTimeZone,
      };
      const profileDetailUpdate = {
        firstName: firstName,
        lastName: lastName,
        mobile: mobile,
        gender: genderValue,
        alreadyWorkingWithDentalLab: alreadyWorkingWithDentalLab,
        dentalDesignServiceProvider: dentalDesignServiceProvider,
        registrationNumber: registrationNumber,
        timeZone: selectedTimeZone,
        _id: profileId,
        userAuthId: profileDetailData?.userAuthId,
      };
      // updating the profile details here
      if (
        !profileDetailData?._id ||
        (!firstName &&
          !lastName &&
          !mobile &&
          !genderValue &&
          !alreadyWorkingWithDentalLab &&
          !dentalDesignServiceProvider &&
          !registrationNumber &&
          !selectedTimeZone)
      ) {
        console.log("SAVE");
        const response = await savePersonalDetailsSp({
          accessToken,
          ...profileDetailSave,
        }).unwrap();
        if (response?.type === 1) {
          GetToast("Profile Saved Successfully!!");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }
        setIsProfileUpdated(true);
        setModalIsOpen(false);
        // const _id = (await response).data[0]["_id"];
      } else {
        console.log("UPLOAD");
        const response = await updatePersonalDetails({
          accessToken,
          ...profileDetailUpdate,
        }).unwrap();
        if (response?.type === 1) {
          GetToast("Profile Updated Successfully!!");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }
      }

      setIsProfileUpdated(true);
      setModalIsOpen(false);
    } else {
      console.log("Form Not Valid");
    }
  };

  // FIRST FORM VALIDATIONS
  const validateFirstName = (value) => {
    return value?.trim().length > 0;
  };
  const validateLastName = (value) => {
    return value?.trim().length > 0;
  };
  const validateTimeZone = (value) => {
    return value?.trim().length > 0;
  };
  const validateGender = (value) => {
    return value?.trim().length > 0;
  };
  const validateWorkingWithLaboratory = (value) => {
    return value?.trim().length > 0;
  };
  const validateProvideIndependentDesignService = (value) => {
    return value?.trim().length > 0;
  };
  const validateMobileNumber = (value) => {
    const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number
    return phoneRegex.test(value);
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "firstName",
      // "lastName",
      // "pincode",
      // "textMessages",
      // "emailAlerts",
      // "tnc",
      "gender",

      // "registrationNumber",
      // "regAuthority",

      "address",
      // "country",
      // "pinCode",
    ];

    return requiredFields.includes(fieldName);
  };
  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === "firstName") setFirstName(value);
    if (name === "lastName") setLastName(value);
    if (name === "mobile") setMobile(value);
    //if (name === "companyName") setCompanyName(value);
    if (name === "registrationNumber") setRegistrationNumber(value);
    if (name === "timeZone") setSelectedTimeZone(value);
    if (name === "gender") setGenderValue(value);
    if (name === "alreadyWorkingWithDentalLab") setAlreadyWorkingWithDentalLab(value);
    if (name === "dentalDesignServiceProvider") setDentalDesignServiceProvider(value);
  };

  const handleSoftwaresCheckboxChange = async (value, checked, sofwareCategoryObjId) => {
    if (checked) {
      // If the checkbox is checked, add the value to the array
      const newSoftwaresUsed = JSON.parse(JSON.stringify(softwaresUsed));
      newSoftwaresUsed[sofwareCategoryObjId]?.selectedItems.push(value);
      setSoftwaresUsed(newSoftwaresUsed);
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      await saveUserSoftware({
        accessToken: accessToken,
        _id: profileDetailData?._id,
        sofwareCategoryObjId: sofwareCategoryObjId,
        softwareObjId: value,
      });
    } else {
      // If the checkbox is unchecked, remove the value from the array
      const newSoftwaresUsed = JSON.parse(JSON.stringify(softwaresUsed));
      newSoftwaresUsed[sofwareCategoryObjId].selectedItems = newSoftwaresUsed[
        sofwareCategoryObjId
      ]?.selectedItems.filter((item) => item !== value);
      setSoftwaresUsed(newSoftwaresUsed);
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      await deleteUserSoftware({
        accessToken: accessToken,
        _id: profileDetailData?._id,
      });
    }
  };

  useEffect(() => {
    if (allCadSoftwareCategories && allCadSoftwareCategories?.length > 0) {
      setSoftwaresUsed(
        allCadSoftwareCategories?.reduce((acc, item) => {
          acc[item?._id] = {
            _id: item?._id,
            softwareCategoryName: item?.softwareCategoryName,
            selectedItems: [],
          };

          return acc;
        }, {})
      );
    }
  }, []);

  useEffect(() => {
    setFirstName(profileDetailData?.firstName);
    setLastName(profileDetailData?.lastName);
    setMobile(profileDetailData?.mobile);
    setGenderValue(profileDetailData?.gender);
    setSelectedTimeZone(profileDetailData?.timeZone);
    //setCompanyName(profileDetailData?.clinicalDetails[0]?.regName);
    setRegistrationNumber(profileDetailData?.registrationNumber);
    setDentalDesignServiceProvider(profileDetailData?.dentalDesignServiceProvider ? "Yes" : "No");
    setAlreadyWorkingWithDentalLab(profileDetailData?.alreadyWorkingWithDentalLab ? "Yes" : "No");
    if (allCadSoftwareCategories && allCadSoftwareCategories?.length > 0) {
      setSoftwaresUsed(
        allCadSoftwareCategories?.reduce((acc, item) => {
          acc[item?._id] = {
            _id: item?._id,
            softwareCategoryName: item?.softwareCategoryName,
            selectedItems: [],
          };

          return acc;
        }, {})
      );
    }
    setSoftwaresUsed((prev) =>
      profileDetailData?.softwares?.reduce((acc, item) => {
        acc[item?.sofwareCategoryObjId] = {
          _id: item?.sofwareCategoryObjId,
          softwareCategoryName: item?.softwareCategoryName,
          selectedItems: [
            ...(acc[item?.sofwareCategoryObjId]?.selectedItems || []),
            item?.softwareObjId,
          ],
        };

        return acc;
      }, prev)
    );
  }, [profileDetailData]);

  console.log("profileDetailData", profileDetailData);
  console.log("softwaresUsed", softwaresUsed);
  // React.useEffect(() => {
  //   if (isUpdatePersonalDetailsSuccess) {
  //     readPersonalDetails(accessToken);
  //   }
  // }, [isUpdatePersonalDetailsSuccess]
  // )
  return (
    <div className="h-full">
      <form onSubmit={submitPersonDetails} className=" h-full flex flex-col">
        <div className="flex-1 pt-8 pb-8">
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"First Name "}
              errorMessage={"This field is required"}
              placeholder={"Enter your First Name"}
              isFieldRequired={isFieldRequired("firstName")}
              htmlFor={"firstName"}
              name={"firstName"}
              value={firstName}
              handleInputChange={handleInputChange}
              validationFunctionName={validateFirstName}
              isSubmitted={isProfileFormSubmitted}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"Last Name"}
              errorMessage={"Please enter your Last Name"}
              placeholder={"Enter your Last Name"}
              isFieldRequired={isFieldRequired("lastName")}
              htmlFor={"lastName"}
              name={"lastName"}
              value={lastName}
              handleInputChange={handleInputChange}
              validationFunctionName={validateLastName}
              isSubmitted={isProfileFormSubmitted}
            />
          </div>
          <div className="relative">
            <NumberInputField
              labelColor={"#777"}
              labelText={"Mobile Number"}
              errorMessage={"Please enter your Mobile Number"}
              placeholder={"Enter your Mobile Number"}
              isFieldRequired={isFieldRequired("mobile")}
              htmlFor={"mobile"}
              name={"mobile"}
              value={mobile}
              handleInputChange={handleInputChange}
              validationFunctionName={validateMobileNumber}
              isSubmitted={isProfileFormSubmitted}
            />
          </div>
          <div className="relative">
            <TimeZoneInputField
              labelColor={"#777"}
              labelText={"Time Zone "}
              errorMessage={"Please Select a Timezone"}
              placeholder={"Select Timezone"}
              isFieldRequired={isFieldRequired("timezone")}
              htmlFor={"timeZone"}
              name={"timeZone"}
              value={selectedTimeZone}
              handleInputChange={handleInputChange}
              validationFunctionName={validateTimeZone}
              isSubmitted={isProfileFormSubmitted}
            />
          </div>
          <div className="relative">
            <label className="text-[16px] text-white" htmlFor="gender">
              Gender
              {isFieldRequired("gender") ? <span className="text-red-500 ml-1">*</span> : ""}
            </label>
            <div className="flex-1 mt-4 mb-4 flex md:flex-row flex-col md:gap-12 gap-4">
              <div className="flex">
                <CircularCheckbox
                  value="Male"
                  name="gender"
                  checked={genderValue === "Male"}
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">Male</p>
              </div>
              <div className="flex">
                <CircularCheckbox
                  value="Female"
                  name="gender"
                  checked={genderValue === "Female"}
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">Female</p>
              </div>
              <div className="flex">
                <CircularCheckbox
                  value="Others"
                  name="gender"
                  checked={genderValue === "Others"}
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">Others</p>
              </div>
            </div>
            {isFieldRequired("gender") &&
              validateGender(genderValue) === false &&
              isProfileFormSubmitted && (
                <p className="text-red-500 text-[15px]">This field is required</p>
              )}
          </div>
          {role === "34fs3" && (
            <div>
              <div className="text-white flex flex-col mb-2 text-[16px]">
                Are you working with a dental laboratory?
                {isFieldRequired("alreadyWorkingWithDentalLab") ? (
                  <span className="text-red-500 ml-1">*</span>
                ) : (
                  ""
                )}
                <div className="flex gap-5">
                  <div className="flex items-center my-3">
                    <CircularCheckbox
                      value="Yes"
                      name="alreadyWorkingWithDentalLab"
                      checked={alreadyWorkingWithDentalLab === "Yes"}
                      onChange={handleInputChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      name="alreadyWorkingWithDentalLab"
                      checked={alreadyWorkingWithDentalLab === "No"}
                      onChange={handleInputChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              </div>
              {isFieldRequired("alreadyWorkingWithDentalLab") &&
                validateWorkingWithLaboratory(alreadyWorkingWithDentalLab) === false &&
                isProfileFormSubmitted && (
                  <p className="text-red-500 text-[15px]">This field is required</p>
                )}
            </div>
          )}
          {role === "34fs3" && (
            <div>
              <div className="text-white flex flex-col mb-2 text-[16px]">
                Do you provide independent dental design services?
                {isFieldRequired("dentalDesignServiceProvider") ? (
                  <span className="text-red-500 ml-1">*</span>
                ) : (
                  ""
                )}
                <div className="flex gap-5">
                  <div className="flex items-center my-3">
                    <CircularCheckbox
                      value="Yes"
                      name="dentalDesignServiceProvider"
                      checked={dentalDesignServiceProvider === "Yes"}
                      onChange={handleInputChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      name="dentalDesignServiceProvider"
                      checked={dentalDesignServiceProvider === "No"}
                      onChange={handleInputChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              </div>
              {isFieldRequired("dentalDesignServiceProvider") &&
                validateProvideIndependentDesignService(dentalDesignServiceProvider) === false &&
                isProfileFormSubmitted && (
                  <p className="text-red-500 text-[15px]">This field is required</p>
                )}
            </div>
          )}
          {role === "34fs3" && (
            <div>
              <p className="font-medium text-[24px] text-white mt-2 mb-4">
                What software do you use ?
                {isFieldRequired("cadSoftwaresUsed") ? (
                  <span className="text-red-500 ml-1">*</span>
                ) : (
                  ""
                )}
              </p>
              <div className="ml-3 mb-5">
                {allCadSoftwareCategories?.length > 0 &&
                  allCadSoftwareCategories.map((categoryItem, categoryIndex) => {
                    return (
                      categoryItem?.softwares?.length > 0 && (
                        <div key={"category-parent-" + categoryIndex}>
                          <p className="font-medium text-[16px] text-[#FF8503] mt-2 mb-4">
                            {categoryItem?.softwareCategoryName}
                          </p>
                          <div className="grid md:grid-cols-2 grid-cols-1 ml-4 gap-x-8 gap-y-4 text-[16px] text-white">
                            {categoryItem?.softwares?.length > 0 &&
                              categoryItem?.softwares?.map((item, index) => {
                                return (
                                  <Checkbox
                                    key={"category-child-" + index}
                                    label={item?.softwareName || ""}
                                    value={item?.softwareObjId || ""}
                                    icon={item?.softwareImageUrl || ""}
                                    iconClass="!w-10/12"
                                    checked={softwaresUsed?.[
                                      categoryItem?._id
                                    ]?.selectedItems?.includes(item.softwareObjId)}
                                    onChange={(value, checked) => {
                                      handleSoftwaresCheckboxChange(
                                        value,
                                        checked,
                                        categoryItem?._id
                                      );
                                    }}
                                  />
                                );
                              })}
                          </div>
                        </div>
                      )
                    );
                  })}
              </div>
            </div>
          )}
          {/* <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"Company Name"}
              errorMessage={"Please enter your Company Name"}
              placeholder={"Enter your Company Name"}
              isFieldRequired={isFieldRequired("companyName")}
              htmlFor={"companyName"}
              name={"companyName"}
              value={companyName}
              handleInputChange={handleInputChange}
              validationFunctionName={validateLastName}
              isSubmitted={isProfileFormSubmitted}
            />
          </div> */}
          {/* {
            role !== "676dza2" ?
              <div className="relative">
                <NumberInputField
                  labelColor={"#777"}
                  labelText={"Registration Number"}
                  errorMessage={"Please enter your Registration Number"}
                  placeholder={"Enter your Registration Number"}
                  isFieldRequired={isFieldRequired("registrationNumber")}
                  htmlFor={"registrationNumber"}
                  name={"registrationNumber"}
                  value={registrationNumber}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateLastName}
                  isSubmitted={isProfileFormSubmitted}
                />
              </div>
              :
              <></>
          } */}
        </div>

        <div className="flex items-center justify-end gap-4 mb-4">
          <button
            onClick={() => setModalIsOpen(false)}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputFieldsProfile;

// export function TextInputField({
//   labelText,
//   labelColor,
//   errorMessage,
//   placeholder,
//   isFieldRequired,
//   name,
//   handleInputChange,
//   isSubmitted,
//   validateName,
// }) {
//   return (
//     <>
//       <label
//         htmlFor="name"
//         style={{
//           color: `${
//             isSubmitted && !validateName(name) ? "border-red-500" : labelColor
//           }`,
//         }}
//         className="text-[16px] leading-4"
//       >
//         {labelText}
//       </label>

//       <input
//         type="text"
//         id="name"
//         name="name"
//         value={name}
//         onChange={handleInputChange}
//         placeholder={placeholder}
//         className={`border p-2 w-full text-[#555] text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 ${
//           isSubmitted && !validateName(name) ? "border-red-500" : ""
//         }`}
//         required={isFieldRequired}
//       />
//       {isSubmitted && !validateName(name) && isFieldRequired && (
//         <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
//           {errorMessage}
//         </p>
//       )}
//     </>
//   );
// }
// export function EmailInputField({
//   labelText,
//   labelColor,
//   errorMessage,
//   placeholder,
//   email,
//   handleInputChange,
//   isSubmitted,
//   validateEmail,
//   isFieldRequired,
// }) {
//   return (
//     <>
//       <label
//         htmlFor="email"
//         style={{
//           color: `${
//             isSubmitted && !validateEmail(email) ? "border-red-500" : labelColor
//           }`,
//         }}
//         className="text-[16px] leading-4"
//       >
//         {labelText}
//       </label>
//       <input
//         type="email"
//         id="email"
//         name="email"
//         value={email}
//         placeholder={placeholder}
//         onChange={handleInputChange}
//         className={`border p-2 w-full text-[#555] text-[16px] leading-4 mt-2 mb-4 rounded-md h-10  ${
//           isSubmitted && !validateEmail(email) && isFieldRequired
//             ? "border-red-500 "
//             : ""
//         }`}
//         required={isFieldRequired}
//       />
//       {isSubmitted && !validateEmail(email) && isFieldRequired && (
//         <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
//           {errorMessage}
//         </p>
//       )}
//     </>
//   );
// }
// export function NumberInputField({
//   labelText,
//   errorMessage,
//   labelColor,
//   placeholder,
//   phone,
//   handleInputChange,
//   isSubmitted,
//   validatePhone,
//   isFieldRequired,
// }) {
//   return (
//     <>
//       <label
//         htmlFor="phone"
//         style={{
//           color: `${
//             isSubmitted && !validatePhone(phone) ? "border-red-500" : labelColor
//           }`,
//         }}
//         className="text-[16px] text-[#777] leading-4"
//       >
//         {labelText}
//       </label>
//       <input
//         type="tel"
//         id="phone"
//         name="phone"
//         value={phone}
//         placeholder={placeholder}
//         onChange={handleInputChange}
//         className={`border p-2 w-full text-[#555] text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 ${
//           isSubmitted && !validatePhone(phone) ? "border-red-500" : ""
//         }`}
//         required={isFieldRequired}
//       />
//       {isSubmitted && !validatePhone(phone) && isFieldRequired && (
//         <p className="text-red-500  relative text-[15px] left-0 top-[-10px] ">
//           {errorMessage}
//         </p>
//       )}
//     </>
//   );
// }

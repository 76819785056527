import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { TeethV2 } from "../Teeth-Comps/TeethV2";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import RangeInputField from "../InputField-Comps/RangeInputField";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";

import {
  useDeleteOrderMissingTeethMutation,
  useDeleteOrderServiceDetailsMutation,
  useDeleteOrderServiceTeethMutation,
  useSaveOrderMissingTeethMutation,
  useSaveOrderServiceDetailsMutation,
  useSaveOrderServiceTeethMutation,
  useSaveOrderServiceMultiFieldMutation,
  useDeleteOrderServiceMultiFieldMutation,
} from "../../services/orders";
import { useGetSoftwareCategoryByNameMutation } from "../../services/softwareCategory";
import { setOrthodonticOrderDetails } from "../../reducers/orderFormReducer";

import classes from "../Teeth-Comps/Teeth.module.scss";
import { findExtraElement, updateTeethMapping } from "../../Utils/utility";

import {
  ARCHES_TO_TREAT,
  CLINICAL_CONDITIONS,
  CORRECTION_TECHNIQUE,
  IPR,
  MIDLINE,
  MODAL_OCCLUSAL_GOALS,
  ORDER_STAGE,
  ORTHODONTIC_OPTIONS,
  SERVICE_TEETH_MAPPING,
  THERMOFORMING_TYPE,
  THERMOFORMING_TYPE2,
} from "../../constants/orderForms";

function OrthodonticCaseDetailsSection({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  categoryObjId,
  submitOrderData,
  selectedServiceCategory,
}) {
  const dispatch = useDispatch();
  const { orthodonticOrderDetails } = useSelector((state) => state.orderFormDetails);

  const { getAccessTokenSilently } = useAuth0();
  const [saveOrderMissingTeeth] = useSaveOrderMissingTeethMutation();
  const [deleteOrderMissingTeeth] = useDeleteOrderMissingTeethMutation();
  const [saveOrderServiceDetails] = useSaveOrderServiceDetailsMutation();
  const [deleteOrderServiceDetails] = useDeleteOrderServiceDetailsMutation();
  const [saveOrderServiceTeeth] = useSaveOrderServiceTeethMutation();
  const [deleteOrderServiceTeeth] = useDeleteOrderServiceTeethMutation();
  const [saveOrderServiceMultiField] = useSaveOrderServiceMultiFieldMutation();
  const [deleteOrderServiceMultiField] = useDeleteOrderServiceMultiFieldMutation();

  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.hasMissingTeeth === null ? "" : orderCaseData?.hasMissingTeeth ? "Yes" : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeethSelectedTeeth || []
  );
  const [selectedService, setSelectedService] = useState("");
  const [restorativeTypeSelected, setRestorativeTypeSelected] = useState(
    orderCaseData?.services?.reduce((prev, curr) => {
      return { ...prev, [curr.serviceObjId]: curr };
    }, {}) ?? {}
  );

  const [preferredSoftwares, setPreferredSoftwares] = useState([]);

  console.log("restorativeTypeSelected", restorativeTypeSelected);

  const [notation, setNotation] = useState(orderCaseData?.numberSystem || "FDI");

  const [getSoftwareCategoryByName] = useGetSoftwareCategoryByNameMutation();
  const readSoftwareCategoryInfo = async (accessToken) => {
    const response = await getSoftwareCategoryByName({
      accessToken,
      softwareCategoryName: "Orthodontic Treatment Planning",
    }).unwrap();
    if (response?.data) {
      const softwaresList = response?.data;
      if (softwaresList?.length > 0) setPreferredSoftwares(softwaresList[0]?.softwares);
      else setPreferredSoftwares([]);
    }
  };

  const readSoftwareCategoriesInfoDetails = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await readSoftwareCategoryInfo(accessToken);
  };

  useEffect(() => {
    readSoftwareCategoriesInfoDetails();
  }, []);

  const getRestrictedTeeth = (serviceObjId, key) => {
    const restrictedTeethData = [];
    Object.keys(restorativeTypeSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...(restorativeTypeSelected?.[d]?.[key] ?? []));
      });
    return restrictedTeethData;
  };

  const deleteService = async (service) => {
    setRestorativeTypeSelected((prev) => {
      const copy = JSON.parse(JSON.stringify(prev));
      delete copy[service?.serviceObjId];
      return copy;
    });

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });

    deleteOrderServiceDetails({
      accessToken,
      _id: orderByData?._id,
      serviceObjId: service?.serviceObjId,
    });
  };

  const prepareServicesElements = useMemo(() => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              restorativeTypeSelected.hasOwnProperty(eachService?.serviceObjId) &&
              Object.keys(restorativeTypeSelected[eachService?.serviceObjId] ?? {})?.length > 0
            }
            handleSelectedIcon={() => deleteService(eachService)}
            id={categoryObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  }, [
    categoryObjId,
    restorativeTypeSelected,
    selectedService?.serviceObjId,
    selectedServiceCategory?.catservices,
  ]);

  const handleServiceMultiFieldSave = async (value, fieldKey, checked) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const serviceDetails = orthodonticOrderDetails?.caseDetails?.services?.find(
      (d) => d?.serviceObjId === selectedService?.serviceObjId
    );

    console.log("serviceDetails", serviceDetails);

    if (!serviceDetails?.serviceObjId) {
      dispatch(
        setOrthodonticOrderDetails({
          ...orthodonticOrderDetails,
          caseDetails: {
            ...(orthodonticOrderDetails?.caseDetails ?? {}),
            services: [
              ...(orthodonticOrderDetails?.caseDetails?.services ?? []),
              { serviceObjId: selectedService?.serviceObjId, [fieldKey]: value },
            ],
          },
        })
      );
      await saveOrderServiceDetails({
        accessToken: accessToken,
        _id: orthodonticOrderDetails?._id,
        serviceObjId: selectedService?.serviceObjId,
      });
    }

    if (checked) {
      saveOrderServiceMultiField({
        accessToken,
        _id: orderByData?._id,
        serviceObjId: selectedService?.serviceObjId,
        type: fieldKey,
        optionstring: value,
      });
    } else {
      deleteOrderServiceMultiField({
        accessToken,
        _id: orderByData?._id,
        serviceObjId: selectedService?.serviceObjId,
        type: fieldKey,
        optionstring: value,
      });
    }
  };

  const handleFieldChange = (value, name, isMulti = false, checked = false) => {
    setRestorativeTypeSelected((prev) => {
      let actualValue = value;

      if (isMulti) {
        if (checked) {
          actualValue = [...(prev?.[selectedService?.serviceObjId]?.[name] ?? []), value];
        } else {
          actualValue = (prev?.[selectedService?.serviceObjId]?.[name] ?? [])?.filter(
            (item) => item !== value
          );
        }
      }

      return {
        ...prev,
        [selectedService?.serviceObjId]: {
          ...(prev?.[selectedService?.serviceObjId] ?? {}),
          [name]: actualValue,
        },
      };
    });
  };

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
    setMissingTeethSelectedTeeth(updateTeethMapping(missingTeethSelectedTeeth, event.target.value));

    setRestorativeTypeSelected((prev) => ({
      ...prev,
      [selectedService?.serviceObjId]: {
        ...(prev?.[selectedService?.serviceObjId] ?? {}),
        teethNotToMove: updateTeethMapping(
          prev?.[selectedService?.serviceObjId]?.teethNotToMove ?? [],
          event.target.value
        ),
      },
    }));
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = ["additionalInstructions"];

    return requiredFields.includes(fieldName);
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
    if (event.target.value === "No") {
      setMissingTeethSelectedTeeth([]);
    }
  };

  const validateNumber = (value) => {
    return Number(value) ? true : false;
  };

  const submitCaseDetailsForm = async () => {
    submitOrderData({
      numberSystem: notation,
      hasMissingTeeth: hasMissingTeeth,
      missingTeethSelectedTeeth: missingTeethSelectedTeeth,
      services: Object.keys(restorativeTypeSelected)?.map((key) => ({
        ...restorativeTypeSelected[key],
        serviceObjId: key,
      })),
    });
    setActiveTab(ORDER_STAGE.UPLOAD_FILES);
  };

  const handleMissingTeethSelection = async (value) => {
    const actualMissingTeeth = structuredClone(missingTeethSelectedTeeth);
    let result = value(actualMissingTeeth || []);
    setMissingTeethSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualMissingTeeth?.length) {
      teethNo = findExtraElement(actualMissingTeeth, result);
      saveOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualMissingTeeth?.length) {
      teethNo = findExtraElement(result, actualMissingTeeth);
      deleteOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  const handleServiceTeethSave = async (value, teethKey) => {
    console.log("value", value);
    const actualNotToMoveTeeth = structuredClone(
      restorativeTypeSelected[selectedService?.serviceObjId]?.[teethKey] ?? []
    );
    console.log("actualNotToMoveTeeth", actualNotToMoveTeeth);

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });
    const serviceDetails = orthodonticOrderDetails?.caseDetails?.services?.find(
      (d) => d?.serviceObjId === selectedService?.serviceObjId
    );

    console.log("serviceDetails", serviceDetails);

    if (!serviceDetails?.serviceObjId) {
      await saveOrderServiceDetails({
        accessToken: accessToken,
        _id: orthodonticOrderDetails?._id,
        serviceObjId: selectedService?.serviceObjId,
      });
      dispatch(
        setOrthodonticOrderDetails({
          ...orthodonticOrderDetails,
          caseDetails: {
            ...(orthodonticOrderDetails?.caseDetails ?? {}),
            services: [
              ...(orthodonticOrderDetails?.caseDetails?.services ?? []),
              { serviceObjId: selectedService?.serviceObjId, [teethKey]: value },
            ],
          },
        })
      );
    }

    if (value?.length > actualNotToMoveTeeth?.length) {
      const teethNo = findExtraElement(actualNotToMoveTeeth, value);
      saveOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    } else if (value?.length < actualNotToMoveTeeth?.length) {
      const teethNo = findExtraElement(value, actualNotToMoveTeeth);
      deleteOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="w-10/12">
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            What tooth numbering system do you prefer?
            <span className="text-red-500 ml-1">*</span>
          </p>
          <div className="flex">
            <div className={classes.radioButtons}>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="UTN"
                  checked={notation === "UTN"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                UTN
              </label>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="FDI"
                  checked={notation === "FDI"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                FDI
              </label>
            </div>
          </div>
          <p className="text-[16px] text-[#777] mb-4">
            The Universal tooth numbering (UTN) system (1-32) and Fédération Dentaire Internationale
            (FDI) system (11-48) are both internationally recognised numbering systems used in
            different parts of the world. Please select a preference that will then apply to all the
            tooth charts in this form.
          </p>
        </div>
        <div className="text-white mb-8">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth
            <span className="text-red-500 ml-1">*</span>
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          {hasMissingTeeth === "Yes" && (
            <div className="mt-4">
              <p className="text-white text-[16px] mb-2">
                Please select missing teeth
                {isFieldRequired("missingTeethSelectedTeeth") && (
                  <span className="text-red-500 ml-1">*</span>
                )}
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={missingTeethSelectedTeeth}
                setSelectedTeeth={handleMissingTeethSelection}
                defaultNotation={notation}
                allowBulkTeethSelection={true}
                key={`missingTeethSelectedTeeth${notation}`}
                notationVisible={false}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <p className="font-medium text-[24px] text-white mb-4">
            Orthodontic Planning Service Type
          </p>
          <p className="text-[16px] text-[#777] mb-8">
            Please click an orthodontic planning service type below and choose the teeth that apply
            for that service type in the chart that appears.{" "}
            <span className="!text-white">
              Multiple orthodontic planning service plan types (for example: for different arches)
              can be selected and multiple teeth can be selected on each service type's chart.
            </span>{" "}
            If you prefer not to use our charting system, you can also provide written instructions
            in the Additional Instructions column below.
          </p>
          {selectedServiceCategory?._id && Object.keys(restorativeTypeSelected).length !== "" && (
            <>
              <div className="CAROUSEL mb-8">
                <div className="md:block hidden">
                  <CustomCarousel
                    rows={1}
                    cols={6}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={6}
                  />
                </div>
                <div className="md:hidden block">
                  <CustomCarousel
                    rows={1}
                    cols={2}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={2}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {selectedService?.serviceObjId && (
          <>
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) &&
              selectedService?.serviceObjId &&
              restorativeTypeSelected && (
                <>
                  <div>
                    <div className="text-white flex mt-4 mb-8">
                      Are there any teeth that should not be moved?
                      <span className="text-red-500 ml-1">*</span>
                      <div className="flex items-center mx-3">
                        <CircularCheckbox
                          value="Yes"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.teethNotToMoveOption === "Yes"
                          }
                          onChange={(e) =>
                            handleFieldChange(e.target.value, "teethNotToMoveOption")
                          }
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Yes</p>
                      </div>
                      <div className="flex items-center">
                        <CircularCheckbox
                          value="No"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.teethNotToMoveOption === "No"
                          }
                          onChange={(e) =>
                            handleFieldChange(e.target.value, "teethNotToMoveOption")
                          }
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">No</p>
                      </div>
                    </div>
                    {restorativeTypeSelected?.[selectedService?.serviceObjId]
                      ?.teethNotToMoveOption === "Yes" && (
                      <div className="mx-5 mb-4">
                        <p className="text-white text-[17px] mb-2">
                          Please select any teeth that should not be moved
                          {isFieldRequired("teethExtractionSelectedTeeth") && (
                            <span className="text-red-500 ml-1">*</span>
                          )}
                        </p>
                        <div className="">
                          <TeethV2
                            allTeethData={[]}
                            selectedTeeth={
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.teethNotToMove ?? []
                            }
                            setSelectedTeeth={(value) => {
                              console.log("value", value);
                              let result = value(
                                restorativeTypeSelected[selectedService?.serviceObjId]
                                  ?.teethNotToMove || []
                              );
                              handleFieldChange(result, "teethNotToMove");
                              handleServiceTeethSave(result, "teethNotToMove");
                            }}
                            defaultNotation={notation}
                            key={`teethNotToBeMovedSelectedTeeth${notation}`}
                            notationVisible={false}
                            missingTeeth={missingTeethSelectedTeeth}
                            restrictedTeeth={getRestrictedTeeth(
                              selectedService?.serviceObjId,
                              "teethNotToMove"
                            )}
                            restrictedTeethMessage={
                              "This tooth is already selected in other restorative type"
                            }
                            disabledTeeths={
                              [
                                "Clear Aligners anterior",
                                "Clear Aligners anterior refinement",
                              ].includes(selectedService?.serviceName)
                                ? notation === "FDI"
                                  ? [
                                      14, 15, 16, 17, 18, 24, 25, 26, 27, 28, 44, 45, 46, 47, 48,
                                      34, 35, 36, 37, 38,
                                    ]
                                  : [
                                      1, 2, 3, 4, 5, 12, 13, 14, 15, 16, 28, 29, 30, 31, 32, 17, 18,
                                      19, 20, 21,
                                    ]
                                : []
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            <div>
              {["Clear Retainer"].includes(selectedService?.serviceName) && (
                <div>
                  <p className="text-white my-4">
                    Would you like a printable retainer or a model that you will use for
                    thermoforming? <span className="text-red-500 ml-1">*</span>
                  </p>
                  <div className="text-white flex mb-8">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value={THERMOFORMING_TYPE.PRINTABLE_RETAINERS}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.printableAligners === THERMOFORMING_TYPE.PRINTABLE_RETAINERS
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "printableAligners")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">
                        {THERMOFORMING_TYPE.PRINTABLE_RETAINERS}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value={THERMOFORMING_TYPE.MODEL}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.printableAligners === THERMOFORMING_TYPE.MODEL
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "printableAligners")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{THERMOFORMING_TYPE.MODEL}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-white mb-4">
                  Options <span className="text-red-500 ml-1">*</span>
                </p>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-8 mb-6">
                  <div className="flex items-center">
                    <CircularCheckbox
                      value={ORTHODONTIC_OPTIONS.OTP}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.options ===
                        ORTHODONTIC_OPTIONS.OTP
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "options")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">{ORTHODONTIC_OPTIONS.OTP}</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value={ORTHODONTIC_OPTIONS.OTP_STL}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.options ===
                        ORTHODONTIC_OPTIONS.OTP_STL
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "options")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">{ORTHODONTIC_OPTIONS.OTP_STL}</p>
                  </div>
                </div>
              </div>
            )}
            <div>
              {[
                "Clear Aligners anterior",
                "Clear Aligners full arch",
                "Clear Aligners anterior refinement",
                "Clear Aligners full arch refinement",
              ].includes(selectedService?.serviceName) && (
                <div>
                  <p className="text-white my-4">
                    If you have chosen STL files, would you like printable aligners or models that
                    you will use for thermoforming? <span className="text-red-500 ml-1">*</span>
                  </p>
                  <div className="text-white flex mb-8">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value={THERMOFORMING_TYPE2.PRINTABLE_ALIGNERS}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.printableAligners === THERMOFORMING_TYPE2.PRINTABLE_ALIGNERS
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "printableAligners")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">
                        {THERMOFORMING_TYPE2.PRINTABLE_ALIGNERS}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value={THERMOFORMING_TYPE2.MODEL}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.printableAligners === THERMOFORMING_TYPE2.MODEL
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "printableAligners")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{THERMOFORMING_TYPE2.MODEL}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div className="mb-6">
                <p className="font-medium text-white mt-2 mb-4">
                  Clinical Conditions <span className="text-red-500 ml-1">*</span>
                </p>
                <div className="">
                  <div className="grid md:grid-cols-4 md:grid-rows-4 grid-cols-1  gap-x-8 gap-y-4 text-[16px] text-white">
                    {Object.values(CLINICAL_CONDITIONS)?.map((condition) => (
                      <div className="flex items-center" key={condition}>
                        <CircularCheckbox
                          value={condition}
                          checked={(
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.clinicalConditions ?? []
                          ).includes(condition)}
                          onChange={(e) => {
                            handleFieldChange(
                              e.target.value,
                              "clinicalConditions",
                              true,
                              !(
                                restorativeTypeSelected?.[selectedService?.serviceObjId]
                                  ?.clinicalConditions ?? []
                              ).includes(condition)
                            );
                            handleServiceMultiFieldSave(
                              e.target.value,
                              "clinicalConditions",
                              !(
                                restorativeTypeSelected?.[selectedService?.serviceObjId]
                                  ?.clinicalConditions ?? []
                              ).includes(condition)
                            );
                          }}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{condition}</p>
                      </div>
                    ))}
                  </div>
                  {!(
                    (
                      restorativeTypeSelected?.[selectedService?.serviceObjId]
                        ?.clinicalConditions ?? []
                    ).length > 0
                  ) ? (
                    <p className="text-sm text-red-500 ml-1 py-2">Select at least one</p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
            <div>
              <p className="font-medium text-white mb-4">
                Arches to treat <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="flex md:gap-32 gap-4 items-center mb-6">
                <div className="flex items-center">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.UPPER}
                    checked={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]?.archesToTreat ===
                      ARCHES_TO_TREAT.UPPER
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "archesToTreat")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.UPPER}</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.LOWER}
                    checked={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]?.archesToTreat ===
                      ARCHES_TO_TREAT.LOWER
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "archesToTreat")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.LOWER}</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value={ARCHES_TO_TREAT.BOTH}
                    checked={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]?.archesToTreat ===
                      ARCHES_TO_TREAT.BOTH
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "archesToTreat")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.BOTH}</p>
                </div>
              </div>
            </div>
            {["Clear Aligners full arch", "Clear Aligners full arch refinement"].includes(
              selectedService?.serviceName
            ) && (
              <div>
                <p className="font-medium text-white mb-4">
                  Molar Occlusal Goals <span className="text-red-500 ml-1">*</span>
                </p>
                <div className="font-medium text-white grid grid-rows md:gap-5 gap-4 mb-6">
                  <div className="flex items-center">
                    <CircularCheckbox
                      value={MODAL_OCCLUSAL_GOALS.CLASS1_MOLAR_OCCLUSION}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.molarOcclusalGoals === MODAL_OCCLUSAL_GOALS.CLASS1_MOLAR_OCCLUSION
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "molarOcclusalGoals")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">
                      {MODAL_OCCLUSAL_GOALS.CLASS1_MOLAR_OCCLUSION}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value={MODAL_OCCLUSAL_GOALS.MAINTAIN_MOLAR_RELATIONSHIP}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.molarOcclusalGoals === MODAL_OCCLUSAL_GOALS.MAINTAIN_MOLAR_RELATIONSHIP
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "molarOcclusalGoals")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">
                      {MODAL_OCCLUSAL_GOALS.MAINTAIN_MOLAR_RELATIONSHIP}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value={MODAL_OCCLUSAL_GOALS.CANINE_CORRECTION}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.molarOcclusalGoals === MODAL_OCCLUSAL_GOALS.CANINE_CORRECTION
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "molarOcclusalGoals")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">
                      {MODAL_OCCLUSAL_GOALS.CANINE_CORRECTION}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div className="mb-8">
                <p className="font-medium text-white mb-4">
                  Midline <span className="text-red-500 ml-1">*</span>
                </p>
                <div className="">
                  <div className="grid md:grid-cols-4 grid-cols-1 grid-rows-1 md:gap-0 gap-4 text-white text-[16px]">
                    {Object.values(MIDLINE)?.map((midline) => (
                      <div className="flex items-center" key={midline}>
                        <CircularCheckbox
                          value={midline}
                          checked={(
                            restorativeTypeSelected?.[selectedService?.serviceObjId]?.midline ?? []
                          ).includes(midline)}
                          onChange={(e) => {
                            handleFieldChange(
                              e.target.value,
                              "midline",
                              true,
                              !(
                                restorativeTypeSelected?.[selectedService?.serviceObjId]?.midline ??
                                []
                              ).includes(midline)
                            );
                            handleServiceMultiFieldSave(
                              e.target.value,
                              "midline",
                              !(
                                restorativeTypeSelected?.[selectedService?.serviceObjId]?.midline ??
                                []
                              ).includes(midline)
                            );
                          }}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{midline}</p>
                      </div>
                    ))}
                  </div>
                  {(
                    restorativeTypeSelected?.[selectedService?.serviceObjId]?.midline ?? []
                  ).includes(MIDLINE.OPTIMIZE) ||
                  (
                    restorativeTypeSelected?.[selectedService?.serviceObjId]?.midline ?? []
                  ).includes(MIDLINE.MAINTAIN) ||
                  (
                    restorativeTypeSelected?.[selectedService?.serviceObjId]?.midline ?? []
                  ).includes(MIDLINE.MOVE_LOWER_ONLY) ||
                  (
                    restorativeTypeSelected?.[selectedService?.serviceObjId]?.midline ?? []
                  ).includes(MIDLINE.MOVE_UPPER_ONLY) ? null : (
                    <p className="text-sm text-red-500 ml-1 py-2">Select at least one</p>
                  )}
                </div>
              </div>
            )}

            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-white mt-4">
                  Inter Proximal Reduction (IPR) <span className="text-red-500 ml-1">*</span>
                </p>
                <div
                  className={`flex md:gap-36 gap-4 items-center ${
                    restorativeTypeSelected?.[selectedService?.serviceObjId]?.ipr?.trim().length > 0
                      ? "mb-6"
                      : ""
                  }`}
                >
                  {Object.values(IPR)?.map((ipr) => (
                    <div className="flex items-center" key={ipr}>
                      <CircularCheckbox
                        value={ipr}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]?.ipr === ipr
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "ipr")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{ipr}</p>
                    </div>
                  ))}
                </div>
                {restorativeTypeSelected?.[selectedService?.serviceObjId]?.ipr?.trim().length >
                0 ? (
                  <></>
                ) : (
                  <p className="text-sm text-red-500 ml-1 py-2">Select at least one</p>
                )}
              </div>
            )}
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-white mb-4">
                  Overjet Correction <span className="text-red-500 ml-1">*</span>
                </p>
                <div
                  className={`${
                    [
                      CORRECTION_TECHNIQUE.OPTIMIZE,
                      CORRECTION_TECHNIQUE.MAINTAIN,
                      CORRECTION_TECHNIQUE.IMPROVE,
                    ].includes(
                      restorativeTypeSelected?.[selectedService?.serviceObjId]
                        ?.overjetCorrectionOption
                    )
                      ? "mb-6"
                      : ""
                  }`}
                >
                  <div className="grid md:grid-cols-4 grid-cols-1 grid-rows-1 md:gap-4 gap-4 text-white text-[16px]">
                    {Object.values(CORRECTION_TECHNIQUE)?.map((technique) => (
                      <div
                        className="flex items-center"
                        key={"overjetCorrectionOption" + technique}
                      >
                        <CircularCheckbox
                          value={technique}
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.overjetCorrectionOption === technique
                          }
                          onChange={(e) => {
                            handleFieldChange(e.target.value, "overjetCorrectionOption");
                          }}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{technique}</p>
                      </div>
                    ))}
                  </div>
                  {restorativeTypeSelected?.[selectedService?.serviceObjId]
                    ?.overjetCorrectionOption === CORRECTION_TECHNIQUE.IMPROVE && (
                    <div className="w-1/2 mt-4">
                      <RangeInputField
                        labelColor={"#fff"}
                        labelText={"Select improve length"}
                        errorMessage={"Please enter a valid cement gap value"}
                        placeholder={""}
                        htmlFor={"overjetCorrectionOptionImproveLength"}
                        name={"overjetCorrectionOptionImproveLength"}
                        value={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.overjetCorrectionOptionImproveLength ?? "0"
                        }
                        handleInputChange={(e) => {
                          handleFieldChange(e.target.value, e.target.name);
                        }}
                        validationFunctionName={validateNumber}
                        isSubmitted={isFieldRequired}
                        isFieldRequired={isFieldRequired("overjetCorrectionOptionImproveLength")}
                        unit="µ"
                        min={0}
                        max={5}
                        step={0.5}
                      />
                    </div>
                  )}
                  {[
                    CORRECTION_TECHNIQUE.OPTIMIZE,
                    CORRECTION_TECHNIQUE.MAINTAIN,
                    CORRECTION_TECHNIQUE.IMPROVE,
                  ].includes(
                    restorativeTypeSelected?.[selectedService?.serviceObjId]
                      ?.overjetCorrectionOption
                  ) ? null : (
                    <p className="text-sm text-red-500 ml-1 py-2">Select at least one</p>
                  )}
                </div>
              </div>
            )}
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-white mb-4">
                  Overbite Correction <span className="text-red-500 ml-1">*</span>
                </p>
                <div className="mb-6">
                  <div className="grid md:grid-cols-4 grid-cols-1 grid-rows-1 md:gap-4 gap-4 text-white text-[16px]">
                    {Object.values(CORRECTION_TECHNIQUE)?.map((technique) => (
                      <div
                        className="flex items-center"
                        key={"overbiteCorrectionOption" + technique}
                      >
                        <CircularCheckbox
                          value={technique}
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.overbiteCorrectionOption === technique
                          }
                          onChange={(e) => {
                            handleFieldChange(e.target.value, "overbiteCorrectionOption");
                          }}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{technique}</p>
                      </div>
                    ))}
                  </div>
                  {restorativeTypeSelected?.[selectedService?.serviceObjId]
                    ?.overbiteCorrectionOption === CORRECTION_TECHNIQUE.IMPROVE && (
                    <div className="w-1/2 mt-4">
                      <RangeInputField
                        labelColor={"#fff"}
                        labelText={"Select improve length"}
                        errorMessage={"Please enter a valid cement gap value"}
                        placeholder={""}
                        htmlFor={"overbiteCorrectionOptionImproveLength"}
                        name={"overbiteCorrectionOptionImproveLength"}
                        value={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.overbiteCorrectionOptionImproveLength ?? "0"
                        }
                        handleInputChange={(e) => {
                          handleFieldChange(e.target.value, e.target.name);
                        }}
                        validationFunctionName={validateNumber}
                        isSubmitted={isFieldRequired}
                        isFieldRequired={isFieldRequired("overbiteCorrectionOptionImproveLength")}
                        unit="µ"
                        min={0}
                        max={5}
                        step={0.5}
                      />
                    </div>
                  )}
                  {(
                    restorativeTypeSelected?.[selectedService?.serviceObjId]
                      ?.overbiteCorrectionOption ?? []
                  )?.includes(CORRECTION_TECHNIQUE.IMPROVE) ||
                  (
                    restorativeTypeSelected?.[selectedService?.serviceObjId]
                      ?.overbiteCorrectionOption ?? []
                  )?.includes(CORRECTION_TECHNIQUE.MAINTAIN) ||
                  (
                    restorativeTypeSelected?.[selectedService?.serviceObjId]
                      ?.overbiteCorrectionOption ?? []
                  )?.includes(CORRECTION_TECHNIQUE.OPTIMIZE) ? null : (
                    <p className="text-sm text-red-500 ml-1 py-2">Select at least one</p>
                  )}
                </div>
              </div>
            )}
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-white mb-4">
                  Crossbite Correction <span className="text-red-500 ml-1">*</span>
                </p>
                <div
                  className={`flex md:gap-36 gap-4 items-center ${
                    restorativeTypeSelected?.[
                      selectedService?.serviceObjId
                    ]?.crossbiteCorrection?.trim().length > 0
                      ? "mb-6"
                      : ""
                  }`}
                >
                  <div className="flex items-center">
                    <CircularCheckbox
                      value={CORRECTION_TECHNIQUE.OPTIMIZE}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.crossbiteCorrection === CORRECTION_TECHNIQUE.OPTIMIZE
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "crossbiteCorrection")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">{CORRECTION_TECHNIQUE.OPTIMIZE}</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value={CORRECTION_TECHNIQUE.MAINTAIN}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.crossbiteCorrection === CORRECTION_TECHNIQUE.MAINTAIN
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "crossbiteCorrection")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">{CORRECTION_TECHNIQUE.MAINTAIN}</p>
                  </div>
                </div>
                {restorativeTypeSelected?.[
                  selectedService?.serviceObjId
                ]?.crossbiteCorrection?.trim().length > 0 ? null : (
                  <p className="text-sm text-red-500 ml-1 py-2">Select at least one</p>
                )}
              </div>
            )}
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <>
                <div
                  className={`text-white flex mt-8 ${
                    restorativeTypeSelected?.[selectedService?.serviceObjId]?.attachmentsAllowed ===
                    "Yes"
                      ? "mb-4"
                      : "mb-8"
                  }`}
                >
                  Attachments allowed? <span className="text-red-500 ml-1">*</span>
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.attachmentsAllowed === "Yes"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "attachmentsAllowed")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.attachmentsAllowed === "No"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "attachmentsAllowed")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
                {restorativeTypeSelected?.[selectedService?.serviceObjId]?.attachmentsAllowed ===
                  "Yes" && (
                  <div>
                    <p className="text-white my-4">
                      Attachments allowed on? <span className="text-red-500 ml-1">*</span>
                    </p>
                    <div
                      className={`flex md:gap-36 gap-4 items-center ${
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.ipr?.trim()
                          .length > 0
                          ? "mb-6"
                          : ""
                      }`}
                    >
                      <div className="flex items-center">
                        <CircularCheckbox
                          value={IPR.ANTERIORS}
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.attachmentsAllowedOn === IPR.ANTERIORS
                          }
                          onChange={(e) =>
                            handleFieldChange(e.target.value, "attachmentsAllowedOn")
                          }
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{IPR.ANTERIORS}</p>
                      </div>
                      <div className="flex items-center">
                        <CircularCheckbox
                          value={IPR.ANTERIORS_AND_PREMOLARS}
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.attachmentsAllowedOn === IPR.ANTERIORS_AND_PREMOLARS
                          }
                          onChange={(e) =>
                            handleFieldChange(e.target.value, "attachmentsAllowedOn")
                          }
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{IPR.ANTERIORS_AND_PREMOLARS}</p>
                      </div>
                      <div className="flex items-center">
                        <CircularCheckbox
                          value="All Teeth"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.attachmentsAllowedOn === "All Teeth"
                          }
                          onChange={(e) =>
                            handleFieldChange(e.target.value, "attachmentsAllowedOn")
                          }
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{IPR.ALL_TEETH}</p>
                      </div>
                    </div>
                    {restorativeTypeSelected?.[selectedService?.serviceObjId]?.ipr?.trim().length >
                    0 ? null : (
                      <p className="text-sm text-red-500 ml-1 py-2">Select at least one</p>
                    )}
                  </div>
                )}
              </>
            )}

            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <div className="text-white flex my-8">
                Retainer Required? <span className="text-red-500 ml-1">*</span>
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]?.retainerRequired ===
                      "Yes"
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "retainerRequired")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]?.retainerRequired ===
                      "No"
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "retainerRequired")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            )}
            {preferredSoftwares?.length > 0 && (
              <div className="text-white flex flex-col mb-8">
                <p className="font-medium text-white mb-4">
                  Preferred design software?
                  {isFieldRequired("preferredSoftware") && (
                    <span className="text-red-500 ml-1">*</span>
                  )}
                </p>
                <div className="grid grid-cols-5 gap-y-3 mt-1">
                  {preferredSoftwares?.map((item) => {
                    return (
                      <div className="flex items-center mr-3" key={item?.softwareObjId}>
                        <CircularCheckbox
                          value={item?.softwareName}
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.preferredSoftware === item?.softwareName
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{item?.softwareName}</p>
                      </div>
                    );
                  })}
                  <div className="flex items-center mx-3 col-span-1">
                    <CircularCheckbox
                      value="No Preferance"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.preferredSoftware === "No Preferance"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No Preference</p>
                  </div>
                </div>
              </div>
            )}
            <div className="mb-6">
              <TextAreaInputField
                labelColor={"#777"}
                labelText={"Additional Instructions"}
                errorMessage={"This field is required"}
                placeholder={"Enter Additional Instructions"}
                isFieldRequired={isFieldRequired("additionalInstructions")}
                htmlFor={"additionalInstructions"}
                name={"additionalInstructions"}
                value={
                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                    ?.additionalInstructions ?? ""
                }
                handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                rows={4}
                cols={5}
              />
              {restorativeTypeSelected?.[
                selectedService?.serviceObjId
              ]?.additionalInstructions?.trim()?.length > 0 ? null : (
                <p className="text-sm text-red-500 ml-1">This field is required</p>
              )}
            </div>
            {[
              "Clear Aligners anterior",
              "Clear Aligners full arch",
              "Clear Aligners anterior refinement",
              "Clear Aligners full arch refinement",
            ].includes(selectedService?.serviceName) && (
              <p className="text-base text-white">
                For cases involving complex tooth movements and surgical Orthodontics, we do not
                recommend using in house fabricated aligners.
              </p>
            )}
          </>
        )}
      </div>
      <div className="BOTTOM-SECTION mt-8">
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab(ORDER_STAGE.PATIENT_DETAILS);
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitCaseDetailsForm();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrthodonticCaseDetailsSection;

import { useEffect, useState } from "react";
import hut from "../../Assets/images/hut.svg";
import { IoIosArrowForward } from "react-icons/io";
import DynamicTable from "../Services-Comps/DynamicTable";
import ToggleButton from "./ToggleButton";
import CustomTabsProfile from "../Profile-Comps/CustomTabsProfile";
import note from "../../Assets/images/note.svg";
import ServiceCard from "./ServiceCard";
import TabComponentProfile from "../Profile-Comps/TabComponentProfile";
import TabComponentCards from "./TabComponentCards";
import DetailTile from "../Profile-Comps/DetailTile";
import profileIcon from "../../Assets/images/profileIcon.svg";
import CustomCarousel from "./CustomCarousel";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useGetUserServiceMutation,
  useUpdateUserServiceMutation,
} from "../../services/userServices";
import { useGetCategoriesMutation } from "../../services/category";
import {
  useUpdateUserCategoriesMutation,
  useUpdateUserActiveStatusMutation,
} from "../../services/user";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import CheckboxForm from "../UserManagement-Comps/CircularForm";
import { LoginButton } from "../../Screens/Login/Login";
import { GetToast } from "../Toast-Comps/GetToast";
import Notification from "../Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { useGetServicesForCCMutation, useUpdateServicesMutation } from "../../services/services";
import { setAllServiceDetails } from "../../reducers/SPServiceDetailsReducer";
import { accessToken } from "mapbox-gl";
import { useGetServicesCategoriesMutation } from "../../services/servicesCategory";

export const ServiceProviderTab = ({
  numberOfSliders,
  numberOfSlidersMobile,
  setIsUpdated,
  setCardDetails,
  cardDetails,
  setSelectedCardInfo,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useAuth0();
  const { allService } = useSelector((state) => state.servicesDetails);

  const [getServicesCategories] = useGetServicesCategoriesMutation();
  const [updateServices] = useUpdateServicesMutation();

  const [selectedButton, setSelectedButton] = useState(null);
  const [userServices, setUserServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const [allServices, setAllServices] = useState(allService ?? []);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const [currentServicePrDetails, setCurrentServicePrDetails] = useState({});
  const [getUserService] = useGetUserServiceMutation();
  const [getCategories] = useGetCategoriesMutation();
  const [updateUserService] = useUpdateUserServiceMutation();
  const [updateUserCategories] = useUpdateUserCategoriesMutation();
  const [updateUserActiveStatus] = useUpdateUserActiveStatusMutation();

  console.log(currentServicePrDetails, "DATAAAAA");
  // table dummy data headers
  const headers = [
    {
      headerId: "name",
      headerName: "Name",
      headerSort: true,
    },
    {
      headerId: "Category",
      headerName: "Category",
      headerSort: true,
      //setting hyperlink to true will make it possible to use modal and will show text as hyperlink
      hyperLink: true,
    },
    {
      headerId: "crowdHireId",
      headerName: "Digital Dentition ID",
      headerSort: false,
    },
    {
      headerId: "activationDate",
      headerName: "Acitvation Date",
      headerSort: true,
    },
    {
      headerId: "actions",
      headerName: "Actions",
      headerSort: false,
    },
  ];

  const getServicesData = async (accessToken) => {
    const response = await getServicesCategories(accessToken).unwrap();
    dispatch(setAllServiceDetails(response?.data));
    setAllServices(response?.data);
  };

  const readServicesInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getServicesData(accessToken);
  };

  useEffect(() => {
    if (!allServices?.length) {
      readServicesInfo();
    }
  }, []);

  // Prepare the HTML element once servicesData has data
  // array of components with data for carousel here
  // data for table here
  const prepareServicePrData = () => {
    if (cardDetails?.details?.length > 0) {
      const arrayOfData = [];
      // debugger;
      setSelectedCardInfo(cardDetails?.details[0]?.catName);
      // Create HTML elements based on servicesData
      cardDetails.details.map((eachService) =>
        arrayOfData.push({
          Category: cardDetails?.catName || "Not Available",
          active: eachService.details.active || "Not Available",
          image: eachService?.picture,
          name:
            eachService?.details?.firstName + " " + eachService?.details?.lastName ||
            "Not Available",
          firstName: eachService?.details?.firstName || "Not Available",
          lastName: eachService?.details?.lastName || "Not Available",
          mobile: eachService?.details?.mobile || "Not Available",
          email: eachService?.details?.email || "Not Available",
          gender: eachService?.details?.gender || "Not Available",
          timeZone: eachService?.details?.timeZone || "Not Available",
          alreadyWorkingWithDentalLab:
            eachService?.details?.alreadyWorkingWithDentalLab === undefined
              ? "Not Available"
              : eachService?.details?.alreadyWorkingWithDentalLab
              ? "Yes"
              : "No",
          dentalDesignServiceProvider:
            eachService?.details?.dentalDesignServiceProvider === undefined
              ? "Not Available"
              : eachService?.details?.dentalDesignServiceProvider
              ? "Yes"
              : "No",
          id: eachService?.user_id || "Not Available",
          userAuthId: eachService?.details?.userAuthId || "Not Available",
          userid: eachService?.details?._id || "Not Available",
          crowdHireId: eachService?.details?.crowdHireId || "Not Available",
          activationDate: eachService?.details?.activationDate || "Not Available",
          country: eachService?.details?.userAddressDetails?.[0]?.country || "Not Available",
          address: eachService?.details?.userAddressDetails?.[0]?.address || "Not Available",
          pincode: eachService?.details?.userAddressDetails?.[0]?.pincode || "Not Available",
          ownPhysicalLaboratory:
            eachService?.details?.userAddressDetails?.[0]?.ownPhysicalLaboratory === undefined
              ? "Not Available"
              : eachService?.details?.userAddressDetails?.[0]?.ownPhysicalLaboratory
              ? "Yes"
              : "No",
          labAddress: eachService?.details?.userAddressDetails?.[0]?.labAddress || "Not Available",
          labCountry: eachService?.details?.userAddressDetails?.[0]?.labCountry || "Not Available",
          labPostalCode:
            eachService?.details?.userAddressDetails?.[0]?.labPostalCode || "Not Available",
          accountHolderName:
            eachService?.details?.bankDetails[0]?.accountHolderName || "Not Available",
          accountNo: eachService?.details?.bankDetails[0]?.accountNo || "Not Available",
          bankAddress: eachService?.details?.bankDetails[0]?.bankAddress || "Not Available",
          bankName: eachService?.details?.bankDetails[0]?.bankName || "Not Available",
          currency: eachService?.details?.bankDetails[0]?.currency || "Not Available",
          iBANNumber: eachService?.details?.bankDetails[0]?.iBANNumber || "Not Available",
          swiftCode: eachService?.details?.bankDetails[0]?.swiftCode || "Not Available",
        })
      );
      setSelectedCardInfo(cardDetails?.catName);

      return arrayOfData;
    }
    return [];
    // Return null if servicesData is empty or null
  };
  // ends here
  // these are the tiles for inside when user clicks the view details button in table
  const detailTileListOne = [
    <DetailTile
      heading={"First Name"}
      subHeading={currentServicePrDetails?.firstName}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Last Name"}
      subHeading={currentServicePrDetails?.lastName}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Mobile"}
      subHeading={currentServicePrDetails?.mobile}
      icon={profileIcon}
    />,
    <DetailTile heading={"Email"} subHeading={currentServicePrDetails?.email} icon={profileIcon} />,
    <DetailTile
      heading={"Gender"}
      subHeading={currentServicePrDetails?.gender}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Time Zone"}
      subHeading={currentServicePrDetails?.timeZone}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Working with a dental laboratory?"}
      subHeading={currentServicePrDetails?.alreadyWorkingWithDentalLab}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Provide independent dental design services?"}
      subHeading={currentServicePrDetails?.dentalDesignServiceProvider}
      icon={profileIcon}
    />,
  ];
  const detailTileListTwo = [
    <DetailTile
      heading={"Address"}
      subHeading={currentServicePrDetails?.address}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Country"}
      subHeading={currentServicePrDetails?.country}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Pincode"}
      subHeading={currentServicePrDetails?.pincode}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Own or operate or work with a physical dental laboratory?"}
      subHeading={currentServicePrDetails?.ownPhysicalLaboratory}
      icon={profileIcon}
    />,
    currentServicePrDetails?.ownPhysicalLaboratory && (
      <DetailTile
        heading={"Laboratory Address"}
        subHeading={currentServicePrDetails?.labAddress}
        icon={profileIcon}
      />
    ),
    currentServicePrDetails?.ownPhysicalLaboratory && (
      <DetailTile
        heading={"Laboratory Country"}
        subHeading={currentServicePrDetails?.labCountry}
        icon={profileIcon}
      />
    ),
    currentServicePrDetails?.ownPhysicalLaboratory && (
      <DetailTile
        heading={"Laboratory Pin code"}
        subHeading={currentServicePrDetails?.labPostalCode}
        icon={profileIcon}
      />
    ),
  ];

  const detailTileListThree = [
    <DetailTile
      heading={"Bank Name"}
      subHeading={currentServicePrDetails?.bankName}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Bank Account Number"}
      subHeading={currentServicePrDetails?.accountNo}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Account Holder Name"}
      subHeading={currentServicePrDetails?.accountHolderName}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Swift Code"}
      subHeading={currentServicePrDetails?.swiftCode}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Account Holder Address as Registered with bank"}
      subHeading={currentServicePrDetails?.bankAddress}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"IBAN/BSB/ABA or other routing number"}
      subHeading={currentServicePrDetails?.iBANNumber}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Account Currency"}
      subHeading={currentServicePrDetails?.currency}
      icon={profileIcon}
    />,
  ];

  const getUserServAPIData = async (accessToken, id) => {
    console.log(accessToken);
    const resp = await getUserService({ accessToken, id }).unwrap();
    // debugger;
    if (resp.type == "1") {
      // debugger;
      setUserServices(resp.data);
      console.log(resp.data, "WHATHH");
    }
  };
  const getServiceForUserData = async (id) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken) {
      // debugger;
      await getUserServAPIData(accessToken, id);
    }
  };

  const getCategoriesAPIData = async (accessToken, id, category = null) => {
    console.log(accessToken);
    const resp = await getCategories({ accessToken, id }).unwrap();
    // debugger;
    if (resp.type == "1") {
      // debugger;
      setCategories(resp.data);
      if (category) {
        let categoryDetails = resp.data.find((d) => d.categoryName === category);
        if (categoryDetails?._id) {
          setCurrentCategory(categoryDetails?._id);
        }
      }
    }
  };
  const getCategoriesData = async (id, category = null) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken) {
      // debugger;
      await getCategoriesAPIData(accessToken, id, category);
    }
  };

  // IMPORTANT HERE
  // this is the service card for profile details
  //  everything can be changes from here
  const detailTileListFour = [];

  const updatePrice = async (newPrice, oldPrice, _id, serviceDetails) => {
    console.log("serviceDetails", serviceDetails);
    // debugger;
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (newPrice !== 0 && newPrice !== oldPrice) {
      GetToast({ newPrice, oldPrice });
      const resp = await updateUserService({
        accessToken,
        _id: _id,
        portalPrice: newPrice,
      }).unwrap();
      if (resp?.type === 1) {
        GetToast("Portal Price for Services Updated Successfully");
      } else {
        GetToast(
          "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
          "error"
        );
      }
      setIsUpdated(true);
      setCardDetails([]);
    }
    console.log("newPrice", newPrice, serviceDetails?.serviceObjId);
    if (
      newPrice < serviceDetails?.serviceObjId?.priceRangeFrom ||
      newPrice > serviceDetails?.serviceObjId?.priceRangeTo
    ) {
      console.log("allServices", allServices);
      const serCategoryDetails = allServices?.find((cat) => {
        const find = cat?.catservices?.find(
          (d) => d?.serviceObjId === serviceDetails?.serviceObjId?._id
        );
        if (find?.serviceObjId) {
          return true;
        }
        return false;
      });

      console.log("serCategoryDetails", serCategoryDetails);

      const resp = await updateServices({
        accessToken,
        priceRangeFrom:
          newPrice < serviceDetails?.serviceObjId?.priceRangeFrom
            ? parseInt(newPrice)
            : serviceDetails?.serviceObjId?.priceRangeFrom,
        priceRangeTo:
          newPrice > serviceDetails?.serviceObjId?.priceRangeTo
            ? parseInt(newPrice)
            : serviceDetails?.serviceObjId?.priceRangeTo,
        _id: serCategoryDetails?._id,
        serviceObjId: serviceDetails?.serviceObjId?._id,
      });

      console.log("service price range update response", resp);
    }
  };

  const updateCategories = async (categoryObjId, _id, userAuthId) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await updateUserCategories({
      accessToken,
      categoryObjId: categoryObjId,
      userAuthId: userAuthId,
      _id: _id,
    }).unwrap();
    if (resp?.type === 1) {
      GetToast("Service Provider Category Updated Successfully !!");
    } else {
      GetToast(
        "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
        "error"
      );
    }
    setIsUpdated(true);
    setCardDetails([]);
  };

  const updateActiveStatus = async (active, _id, userAuthId) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await updateUserActiveStatus({
      accessToken,
      userAuthId: userAuthId,
      active: active,
      _id: _id,
    }).unwrap();
    if (resp?.type === 1) {
      GetToast(
        active === 1 ? "User Activated Successfully !!" : "User Deactivated Successfully !!"
      );
    } else {
      GetToast(
        "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
        "error"
      );
    }
    setIsUpdated(true);
    setCardDetails([]);
  };

  if (userServices?.length > 0) {
    // debugger;
    userServices.forEach((eachService) => {
      if (eachService.offerPrice || eachService.actualPrice) {
        detailTileListFour.push(
          <ServiceCard
            cardClick={(newPrice) => {
              updatePrice(newPrice, eachService.portalPrice, eachService._id, eachService);
            }}
            image={eachService.serviceObjId.image}
            title={eachService.serviceObjId.serviceName}
            desc={eachService.serviceObjId.serviceDescription}
            specializationList={[]}
            price={eachService.actualPrice}
            offerPrice={eachService.offerPrice}
            portalPrice={eachService.portalPrice || 0}
            className="h-full"
            bodyClassName="h-full justify-between"
          />
        );
      }
    });
  }

  // ends here
  // this is just a wrapper for the tiles
  const tileWrapperOne = <TabComponentProfile detailTileList={detailTileListOne} />;
  const tileWrapperTwo = <TabComponentProfile detailTileList={detailTileListTwo} />;
  const tileWrapperThree = (
    // here different tab is used as grid is different
    <TabComponentProfile detailTileList={detailTileListThree} />
  );

  const tileWrapperFour =
    // here different tab is used as grid is different
    detailTileListFour?.length > 0 ? (
      <TabComponentCards detailTileList={detailTileListFour} className="h-auto" />
    ) : (
      <div className="flex items-center justify-center text-center py-28 text-white">
        No Services Available
      </div>
    );
  // ends here
  // these are you tab names
  const tabNamesArray = ["Personal Details", "Address Details", "Bank Details", "Services"];
  // these are tab content objects
  const tileObject = {
    "Personal Details": tileWrapperOne,
    "Address Details": tileWrapperTwo,
    "Bank Details": tileWrapperThree,
    Services: tileWrapperFour,
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userId, setUserId] = useState("");

  console.log("currentServicePrDetails", currentServicePrDetails);

  return (
    <div className="h-full flex flex-col">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">
            User Management <IoIosArrowForward />{" "}
            <span className="font-medium text-[15px] text-primary"> Service Providers</span>
          </span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {/* <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
            CH00001
            <img
              className="relative top-[4px] right-0"
              src={greenTick}
              alt=""
            />
          </button>
          <img src={bell} alt="" /> */}
          <Notification />
        </div>
      </div>
      <p className="HEADING font-medium text-[34px] leading-7 mr-8 md:my-8 my-4 text-white">
        Service Providers
      </p>
      {numberOfSliders.length === 0 ? (
        <div
          role="status"
          className="animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
        >
          <div className="w-full flex gap-5">
            <div className="h-40 w-1/4 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
            <div className="h-40 w-1/4 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
            <div className="h-40 w-1/4 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
            <div className="h-40 w-1/4 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
          </div>
        </div>
      ) : (
        <>
          {selectedButton === null && (
            <div className="REPLACEABLE-COMPONENT">
              <div className="CAROUSEL-DESKTOP md:block hidden">
                <CustomCarousel
                  // rows and columns you want per slide with x and y gaps in pixels
                  rows={1}
                  cols={5}
                  gapX={20}
                  gapY={20}
                  //PASS DATA HERE
                  // completeSliders={
                  //   // data goes here
                  //  }
                  completeSliders={numberOfSliders}
                  numberOfCardsShownInSingleSlide={5}
                  // input onclick here for card
                  handleCardOnClick={(card) => {
                    // debugger;
                    setCardDetails({
                      details: card.props.details,
                      catName: card.props.header,
                    });
                    // you can change the table data in this func accordingly
                    // console.log(card.props.details);
                    // // switch case based on card title
                    // switch (card.props.header) {
                    //   case "Basic":
                    //     // here you can change the table data
                    //     break;
                    //   case "Intermediate":
                    //     // here you can change the table data
                    //     break;
                    //   case "Advanced":
                    //     // here you can change the table data
                    //     break;
                    //   default:
                    //     break;
                    // }
                  }}
                />
              </div>
              <div className="CAROUSEL-MOBILE md:hidden block">
                <CustomCarousel
                  // rows and columns you want per slide with x and y gaps in pixels
                  rows={1}
                  cols={1}
                  gapX={20}
                  gapY={20}
                  //PASS DATA HERE
                  // completeSliders={
                  //   // data goes here
                  //  }

                  completeSliders={numberOfSliders}
                  numberOfCardsShownInSingleSlide={1}
                  // input onclick here for card
                  handleCardOnClick={(card) => {
                    // you can change the table data in this func accordingly
                    console.log(card.props.header);
                    // switch case based on card title
                    switch (card.props.header) {
                      case "Basic":
                        // here you can change the table data
                        break;
                      case "Intermediate":
                        // here you can change the table data
                        break;
                      case "Advanced":
                        // here you can change the table data
                        break;
                      default:
                        break;
                    }
                  }}
                />
              </div>

              <div className="TABLE  bg-secondary flex-1 mt-8 rounded-t-lg">
                <div className="bg-secondary p-4 rounded-md flex-1 overflow-scroll overflow-x-hidden h-[50vh]">
                  <DynamicTable
                    setSelectedButton={setSelectedButton}
                    headers={headers}
                    // data as per the click should be here
                    data={prepareServicePrData()}
                    // data={data}
                    includeImgVideoButton={true}
                    categoryTable={false}
                    serviceProviderTable={true}
                    // here we provide state for modal of table
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    handleLinkForCategories={(row) => {
                      setModalIsOpen(true);
                      getCategoriesData(row.id, row.Category);
                      setUserId(row.userid);
                      setCurrentServicePrDetails(row);
                      // here u can pass function and access row data and everything and implement the functionality
                      //setSelectedButton(true);
                    }}
                    handleButtonClickForUM={(row) => {
                      // debugger;
                      setCurrentServicePrDetails(row);
                      getServiceForUserData(row.id);
                      // here u can pass function and access row data and everything and implement the functionality
                      setSelectedButton(true);
                      setUserId(row.userid);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {selectedButton && (
            <div className="WHITE-SECTION bg-secondary md:p-8 p-4 md:h-[70vh] overflow-auto">
              <div className="flex flex-col gap-4 md:gap-0 md:flex-row md:items-center items-start justify-between mb-4">
                <p className="text-white font-semibold text-xl">Profile Details</p>
                <div className="flex items-center justify-center gap-4">
                  <div
                    className="text-orange-500 underline cursor-pointer"
                    onClick={() => {
                      getCategoriesData(currentServicePrDetails.id);
                      setModalIsOpen(true);
                      setSelectedButton(null);
                    }}
                  >
                    {currentServicePrDetails.Category}
                  </div>
                  <div className="w-[1px] h-[20px] bg-black opacity-20"></div>
                  <ToggleButton
                    updateActiveStatus={(active, _id) =>
                      updateActiveStatus(active, _id, currentServicePrDetails?.userAuthId)
                    }
                    currentServicePrDetails={currentServicePrDetails}
                  />
                  {/* <img
                src={eye}
                alt="eye"
                onClick={() => {
                  // debugger;
                }}
              /> */}
                  <img src={note} alt="note" onClick={() => {}} />
                </div>
              </div>
              <div className="CUSTOM-TAB">
                <CustomTabsProfile tabNamesArray={tabNamesArray} outputObject={tileObject} />
              </div>
            </div>
          )}
        </>
      )}

      {/* MODAL FOR OUR TABLE  */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="button-modal custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full text-right" onClick={() => setModalIsOpen(false)}>
              <AiOutlineClose className="ml-auto" />
            </button>
            <p className="text-white text-[26px] leading-8 font-medium">Categories</p>
            <CheckboxForm
              setModalIsOpen={setModalIsOpen}
              data={categories}
              initialValue={currentCategory}
              updateCategories={updateCategories}
              userAuthId={currentServicePrDetails?.userAuthId}
              userId={userId}
            />
          </div>
        </>
      </Modal>
    </div>
  );
};

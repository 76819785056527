import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useDeleteCategoryMutation, useGetAllCategoriesMutation } from "../../services/category";
import bell from "../../Assets/images/bellIcon.svg";
import hut from "../../Assets/images/hut.svg";
import { IoIosArrowForward, IoIosClose } from "react-icons/io";
import DynamicTable from "./DynamicTable";
import Modal from "react-modal";
import InputFieldsCategory from "./InputFieldsCategory";
import { LoginButton } from "../../Screens/Login/Login";
import greenTick from "../../Assets/images/greenTick.svg";
import { GetToast } from "../Toast-Comps/GetToast";
import Notification from "../Notification/Notification";
import { useSelector } from "react-redux";

const headersForCategory = [
  { headerId: "categoryName", headerName: "Level Name", headerSort: false },
  {
    headerId: "categoryCode",
    headerName: "Level Code",
    headerSort: false,
  },
  {
    headerId: "categoryDescription",
    headerName: "Level Description",
    headerSort: false,
  },
  {
    headerId: "actions",
    headerName: "Actions",
    headerSort: false,
  },
];
export const CategoriesTab = () => {
  const { user, isAuthenticated } = useAuth0();
  const [isCategoriesUpdated, setIsCategoriesUpdated] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [getAllCategories] = useGetAllCategoriesMutation();
  const [deleteCategories] = useDeleteCategoryMutation();
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const getCategoryData = async (accessToken) => {
    const resp = await getAllCategories(accessToken).unwrap();
    if (resp.type == "1") {
      // debugger;
      setCategoryData(resp.data?.length > 0 ? [...resp.data].reverse() : []);
    }
  };

  const readProfileInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    console.log(accessToken, "accessToken");
    await getCategoryData(accessToken);
  };

  useEffect(() => {
    console.log("useEffect Provider Level working --- ");
    readProfileInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCategoriesUpdated]);

  // modal state and styles
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditCategoryModal, setIsEditCategoryModal] = useState(false);
  const [editingCategoryRow, setEditingCategoryRow] = useState(null);

  const closeModal = () => {
    setModalIsOpen(false);
    setIsEditCategoryModal(false);
  };

  const openEditCategoryModal = (row) => {
    setIsEditCategoryModal(true);
    setModalIsOpen(true);
    setEditingCategoryRow(row);
  };

  const onDeleteCategory = async (row) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await deleteCategories({ accessToken, _id: row._id }).unwrap();
    await getCategoryData(accessToken);
    GetToast("Level Successfully Deleted !!", "info");
  };

  return (
    <>
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">
            Settings <IoIosArrowForward />{" "}
            <span className="font-medium text-[15px] text-primary"> Provider Levels</span>
          </span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {/* <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
            CH00001
            <img
              className="relative top-[4px] right-0"
              src={greenTick}
              alt=""
            />
          </button>
          <img src={bell} alt="" /> */}
          <Notification />
        </div>
      </div>
      <div className="HEADING flex md:justify-between items-center gap-4 mb-4 mt-2">
        <p className=" font-medium text-[34px] leading-7 md:mr-8">Provider Level</p>
        <button
          onClick={() => setModalIsOpen(true)}
          className="bg-[#FF7E00] px-2 py-4 text-white rounded-lg text-[16px] leading-4 font-medium"
        >
          + {"   "} Add Provider Level
        </button>
      </div>
      <div className="bg-secondary p-4 rounded-md md:h-[80vh] h-[70vh]">
        {categoryData ? (
          <DynamicTable
            headers={headersForCategory}
            data={categoryData}
            includeImgVideoButton={true}
            categoryTable={true}
            onClickEditRow={openEditCategoryModal}
            onClickDeleteRow={onDeleteCategory}
          />
        ) : (
          "Loading..."
        )}
      </div>

      {/* modal here  */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full" onClick={closeModal}>
              <IoIosClose className="ml-auto text-[32px]" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-8">
              {isEditCategoryModal ? "Edit Provider Level" : "Add Provider Level"}
            </p>
            <div className="MODAL-TAB-SECTION h-3/5 md:flex-1">
              <InputFieldsCategory
                setIsCategoriesUpdated={setIsCategoriesUpdated}
                setModalIsOpen={setModalIsOpen}
                isEditing={isEditCategoryModal}
                editingCategoryRow={editingCategoryRow}
                getData={getCategoryData}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceProvider: {
    personalDetails: {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      gender: "",
      timeZone: "",
      registrationNumber: "",
      alertsForMobile: "",
      alertsForEmail: "",
      alreadyWorkingWithDentalLab: "",
      dentalDesignServiceProvider: "",
    },
    addressDetails: {
      address: "",
      latitude: "",
      longitude: "",
      country: "",
      pincode: "",
      ownPhysicalLaboratory: false,
      laboratoryAddress: "",
      laboratoryCountry: "",
      laboratoryPincode: "",
      laboratoryLatitude: "",
      laboratoryLongitude: "",
    },
    servicesOffered: [],
    bankDetails: {
      bankName: "",
      accountNo: "",
      accountHolderName: "",
      swiftCode: "",
      bankAddress: "",
      iBANNumber: "",
      currency: "",
    },
  },
  dentist: {
    personalDetails: {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      gender: "",
    },
    clinicDetails: {
      registrationNumber: "",
      registrationAuthority: "",
    },
    addressDetails: {
      address: "",
      latitude: "",
      longitude: "",
      country: "",
      pincode: "",
      ownPhysicalLaboratory: null,
      labAddress: "",
      labCountry: "",
      labPostalCode: "",
      labLatitude: "0",
      labLongitude: "0",
    },
  },
  role: "",
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setServiceProviderPersonalDetails: (state, action) => {
      state.serviceProvider.personalDetails = action.payload;
    },
    setServiceProviderAddressDetails: (state, action) => {
      state.serviceProvider.addressDetails = action.payload;
    },
    setServiceProviderServicesOffered: (state, action) => {
      state.serviceProvider.servicesOffered = action.payload;
    },
    setServiceProviderBankDetails: (state, action) => {
      state.serviceProvider.bankDetails = action.payload;
    },
    setDentistPersonalDetails: (state, action) => {
      state.dentist.personalDetails = action.payload;
    },
    setDentistClinicDetails: (state, action) => {
      state.dentist.clinicDetails = action.payload;
    },
    setDentistAddressDetails: (state, action) => {
      state.dentist.addressDetails = action.payload;
    },
    setUserRole: (state, action) => {
      state.role = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setServiceProviderPersonalDetails,
  setServiceProviderAddressDetails,
  setServiceProviderServicesOffered,
  setServiceProviderBankDetails,
  setDentistPersonalDetails,
  setDentistClinicDetails,
  setDentistAddressDetails,
  setUserRole,
} = registrationSlice.actions;

import React from "react";
import PriceSlip from "./PriceSlip";
function EstimatedServicePrice({ priceFrom, priceTo }) {
  return (
    <div className="ESTIMATED-SERVICE-PRICE">
      <p className="font-medium text-[20px] text-white mb-2">Estimated Service Price</p>
      <PriceSlip priceFrom={priceFrom} priceTo={priceTo} />
    </div>
  );
}

export default EstimatedServicePrice;

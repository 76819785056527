import { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { TeethV2 } from "../Teeth-Comps/TeethV2";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import RangeInputField from "../InputField-Comps/RangeInputField";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";

import {
  useDeleteOrderMissingTeethMutation,
  useDeleteOrderServiceDetailsMutation,
  useDeleteOrderServiceTeethMutation,
  useSaveOrderMissingTeethMutation,
  useSaveOrderServiceDetailsMutation,
  useSaveOrderServiceTeethMutation,
} from "../../services/orders";
import { useGetSoftwareCategoryByNameMutation } from "../../services/softwareCategory";

import { findExtraElement, updateTeethMapping } from "../../Utils/utility";
import classes from "../Teeth-Comps/Teeth.module.scss";
import { ORDER_STAGE, SERVICE_TEETH_MAPPING } from "../../constants/orderForms";
import { useDispatch, useSelector } from "react-redux";
import { setSmileOrderDetails } from "../../reducers/orderFormReducer";

export const SmileDesignCaseDetailsSection = ({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  categoryObjId,
  submitOrderData,
  selectedServiceCategory,
}) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [saveOrderMissingTeeth] = useSaveOrderMissingTeethMutation();
  const [deleteOrderMissingTeeth] = useDeleteOrderMissingTeethMutation();
  const [getSoftwareCategoryByName] = useGetSoftwareCategoryByNameMutation();
  const [saveOrderServiceDetails] = useSaveOrderServiceDetailsMutation();
  const [deleteOrderServiceDetails] = useDeleteOrderServiceDetailsMutation();
  const [saveOrderServiceTeeth] = useSaveOrderServiceTeethMutation();
  const [deleteOrderServiceTeeth] = useDeleteOrderServiceTeethMutation();

  const { smileOrderDetails } = useSelector((state) => state.orderFormDetails);

  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.hasMissingTeeth === null ? "" : orderCaseData?.hasMissingTeeth ? "Yes" : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeethSelectedTeeth || []
  );
  // eslint-disable-next-line no-unused-vars

  const [notation, setNotation] = useState(orderCaseData?.numberSystem || "FDI");
  const [preferredSoftwares, setPreferredSoftwares] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [restorativeTypeSelected, setRestorativeTypeSelected] = useState(
    orderCaseData?.services?.reduce((prev, curr) => {
      return { ...prev, [curr.serviceObjId]: curr };
    }, {}) ?? {}
  );

  const handleFieldChange = (value, name, isMulti = false, checked = false) => {
    setRestorativeTypeSelected((prev) => {
      let actualValue = value;

      if (isMulti) {
        if (checked) {
          actualValue = [...(prev?.[selectedService?.serviceObjId]?.[name] ?? []), value];
        } else {
          actualValue = (prev?.[selectedService?.serviceObjId]?.[name] ?? [])?.filter(
            (item) => item !== value
          );
        }
      }

      return {
        ...prev,
        [selectedService?.serviceObjId]: {
          ...(prev?.[selectedService?.serviceObjId] ?? {}),
          [name]: actualValue,
        },
      };
    });
  };

  console.log("restorativeTypeSelected", restorativeTypeSelected);
  console.log("missingTeethSelectedTeeth2", missingTeethSelectedTeeth);
  console.log("selectedService", selectedService);

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
    setMissingTeethSelectedTeeth(updateTeethMapping(missingTeethSelectedTeeth, event.target.value));
    if (restorativeTypeSelected[selectedService?.serviceObjId]?.length > 0) {
      setRestorativeTypeSelected({
        ...restorativeTypeSelected,
        [selectedService?.serviceObjId]: updateTeethMapping(
          restorativeTypeSelected[selectedService?.serviceObjId],
          event.target.value
        ),
      });
    }
  };

  const readSoftwareCategoryInfo = async (accessToken) => {
    const response = await getSoftwareCategoryByName({
      accessToken,
      softwareCategoryName: "Smile Design CAD",
    }).unwrap();
    if (response?.data) {
      const softwaresList = response?.data;
      if (softwaresList?.length > 0) {
        setPreferredSoftwares(softwaresList[0]?.softwares);
      } else setPreferredSoftwares([]);
    }

    const response1 = await getSoftwareCategoryByName({
      accessToken,
      softwareCategoryName: "CAD",
    }).unwrap();
    if (response1?.data) {
      const softwaresList1 = response1?.data;
      if (softwaresList1?.length > 0) setPreferredSoftwares(softwaresList1[0]?.softwares);
      else setPreferredSoftwares([]);
    }
  };

  const readSoftwareCategoriesInfoDeatails = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await readSoftwareCategoryInfo(accessToken);
  };

  const deleteService = async (service) => {
    setRestorativeTypeSelected((prev) => {
      const copy = JSON.parse(JSON.stringify(prev));
      delete copy[service?.serviceObjId];
      return copy;
    });

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });

    deleteOrderServiceDetails({
      accessToken,
      _id: orderByData?._id,
      serviceObjId: service?.serviceObjId,
    });
  };

  const prepareServicesElements = useMemo(() => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              restorativeTypeSelected.hasOwnProperty(eachService?.serviceObjId) &&
              Object.keys(restorativeTypeSelected[eachService?.serviceObjId] ?? {})?.length > 0
            }
            handleSelectedIcon={() => deleteService(eachService)}
            id={categoryObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  }, [
    categoryObjId,
    restorativeTypeSelected,
    selectedService?.serviceObjId,
    selectedServiceCategory?.catservices,
  ]);

  const getRestrictedTeeth = (serviceObjId) => {
    const restrictedTeethData = [];
    Object.keys(restorativeTypeSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...(restorativeTypeSelected[d]?.waxupSimulationTeeth ?? []));
      });
    return restrictedTeethData;
  };

  useEffect(() => {
    readSoftwareCategoriesInfoDeatails();
  }, []);

  const validateNumber = (value) => {
    return Number(value) ? true : false;
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
    if (event.target.value === "No") {
      setMissingTeethSelectedTeeth([]);
    }
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "additionalInstructions",
      "hasMissingTeeth",
      "interproximalContacts",
      "occlusalContacts",
      "needDesignPreview",
      "optionsValue",
      "printingCementGap",
      "designFileNeeded",
      "preferredSoftware",
      "needTwoPrintableModelFile",
      "needPrintableModelFile",
      "needPrintableEggshellFiles",
      "printableModelNeeded",
      "printableModelWithDies",
      "occlusalClearanceAction",
      "needPrintableEggshellFiles",
    ];

    return requiredFields.includes(fieldName);
  };

  const submitCaseDetailsForm = async () => {
    submitOrderData({
      numberSystem: notation,
      hasMissingTeeth: hasMissingTeeth,
      missingTeethSelectedTeeth: missingTeethSelectedTeeth,
      services: Object.keys(restorativeTypeSelected)?.map((key) => ({
        ...restorativeTypeSelected[key],
        serviceObjId: key,
      })),
    });
    setActiveTab(ORDER_STAGE.UPLOAD_FILES);
  };

  const handleMissingTeethSelection = async (value) => {
    console.log("value", value);
    const actualMissingTeeth = structuredClone(missingTeethSelectedTeeth);
    let result = value(actualMissingTeeth || []);
    setMissingTeethSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualMissingTeeth?.length) {
      teethNo = findExtraElement(actualMissingTeeth, result);
      saveOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualMissingTeeth?.length) {
      teethNo = findExtraElement(result, actualMissingTeeth);
      deleteOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  const handleServiceTeethSave = async (value, teethKey) => {
    console.log("value", value);
    const actualImplantSiteTeeth = structuredClone(
      restorativeTypeSelected[selectedService?.serviceObjId]?.[teethKey] ?? []
    );
    console.log("actualImplantSiteTeeth", actualImplantSiteTeeth);

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });

    const serviceDetails = smileOrderDetails?.caseDetails?.services?.find(
      (d) => d?.serviceObjId === selectedService?.serviceObjId
    );

    console.log("serviceDetails", serviceDetails);

    if (!serviceDetails?.serviceObjId) {
      await saveOrderServiceDetails({
        accessToken: accessToken,
        _id: smileOrderDetails?._id,
        serviceObjId: selectedService?.serviceObjId,
      });
      dispatch(
        setSmileOrderDetails({
          ...smileOrderDetails,
          caseDetails: {
            ...(smileOrderDetails?.caseDetails ?? {}),
            services: [
              ...(smileOrderDetails?.caseDetails?.services ?? []),
              { serviceObjId: selectedService?.serviceObjId, [teethKey]: value },
            ],
          },
        })
      );
    }

    if (value?.length > actualImplantSiteTeeth?.length) {
      const teethNo = findExtraElement(actualImplantSiteTeeth, value);
      saveOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    } else if (value?.length < actualImplantSiteTeeth?.length) {
      const teethNo = findExtraElement(value, actualImplantSiteTeeth);
      deleteOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    }
  };

  return (
    <div>
      <div className="w-10/12">
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            What tooth numbering system do you prefer?
            <span className="text-red-500 ml-1">*</span>
          </p>
          <div className="flex">
            <div className={classes.radioButtons}>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="UTN"
                  checked={notation === "UTN"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                UTN
              </label>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="FDI"
                  checked={notation === "FDI"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                FDI
              </label>
            </div>
          </div>
          <p className="text-[16px] text-[#777] mb-8">
            The Universal tooth numbering (UTN) system (1-32) and Fédération Dentaire Internationale
            (FDI) system (11-48) are both internationally recognised numbering systems used in
            different parts of the world. Please select a preference that will then apply to all the
            tooth charts in this form.
          </p>
        </div>
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth
            {isFieldRequired("hasMissingTeeth") && <span className="text-red-500 ml-1">*</span>}
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          <div className="mt-4">
            {hasMissingTeeth === "Yes" && (
              <>
                <p className="text-white text-[16px] mb-2">Please select missing teeth</p>
                <TeethV2
                  key={`missingTeethSelectedTeeth${notation}`}
                  allTeethData={[]}
                  selectedTeeth={missingTeethSelectedTeeth}
                  setSelectedTeeth={handleMissingTeethSelection}
                  defaultNotation={notation}
                  allowBulkTeethSelection={true}
                  notationVisible={false}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="font-medium text-[24px] text-white mb-4">Smile Design Service Type</p>
          <p className="text-[16px] text-[#777] mb-8">
            Please click a Smile Design service type below and choose the teeth that apply for that
            service type in the chart that appears.{" "}
            <span className="!text-white">
              Multiple case types can be selected and multiple teeth can be selected on each case
              type's chart.
            </span>{" "}
            If you prefer not to use our charting system, you can also provide written instructions
            in the Additional Instructions column below.
          </p>
          {selectedServiceCategory?._id && Object.keys(restorativeTypeSelected).length !== "" && (
            <>
              <div className="CAROUSEL mb-8">
                <div className="md:block hidden">
                  <CustomCarousel
                    rows={1}
                    cols={6}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={6}
                  />
                </div>
                <div className="md:hidden block">
                  <CustomCarousel
                    rows={1}
                    cols={2}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={2}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {selectedService?.serviceObjId && (
          <>
            {restorativeTypeSelected &&
              [
                "Partial wax-up for selected teeth",
                "Full arch upper and lower wax-up",
                "Injectable composite models with alternate tooth wax-up",
                "Simulation services for patient motivation",
              ].includes(selectedService?.serviceName) && (
                <div className="mb-8">
                  <p className="text-white text-[16px] mb-2">
                    Please select all wax up/simulation teeth as applicable
                  </p>
                  <TeethV2
                    key={
                      selectedService?.serviceObjId +
                      restorativeTypeSelected[selectedService?.serviceObjId]?.waxupSimulationTeeth
                        ?.length +
                      notation
                    }
                    allTeethData={[]}
                    restrictedTeeth={getRestrictedTeeth(selectedService?.serviceObjId)}
                    restrictedTeethMessage={
                      "This tooth is already selected in other restorative type"
                    }
                    selectedTeeth={
                      restorativeTypeSelected[selectedService?.serviceObjId]
                        ?.waxupSimulationTeeth || []
                    }
                    setSelectedTeeth={(value) => {
                      let result = value(
                        restorativeTypeSelected[selectedService?.serviceObjId]
                          ?.waxupSimulationTeeth || []
                      );
                      handleFieldChange(result, "waxupSimulationTeeth");
                      handleServiceTeethSave(result, "waxupSimulationTeeth");
                    }}
                    defaultNotation={notation}
                    notationVisible={false}
                    missingTeeth={missingTeethSelectedTeeth}
                  />
                </div>
              )}
            {[
              "Partial wax-up for selected teeth",
              "Full arch upper and lower wax-up",
              "Injectable composite models with alternate tooth wax-up",
              "Simulation services for patient motivation",
            ].includes(selectedService?.serviceName) && (
              <div className="text-white flex mb-8">
                Do you need to see a design preview?
                {isFieldRequired("needDesignPreview") && (
                  <span className="text-red-500 ml-1">*</span>
                )}
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]
                        ?.needDesignPreview === "Yes"
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "needDesignPreview")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]
                        ?.needDesignPreview === "No"
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "needDesignPreview")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            )}
            {[
              "Partial wax-up for selected teeth",
              "Full arch upper and lower wax-up",
              "Injectable composite models with alternate tooth wax-up",
              "Simulation services for patient motivation",
            ].includes(selectedService?.serviceName) && (
              <>
                <div className="text-white flex mb-2">
                  Design file to be provided?
                  {isFieldRequired("designFileNeeded") && (
                    <span className="text-red-500 ml-1">*</span>
                  )}
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.designFileNeeded === "Yes"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "designFileNeeded")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.designFileNeeded === "No"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "designFileNeeded")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
                <p className="text-[#777] text-[16px] mb-8">
                  This is a file in the original design software's format and you will need the
                  appropriate design software to open it. Please do not request it unless you have
                  the software or a subscription to it and know how to manipulate the file.
                </p>
              </>
            )}
            {[
              "Partial wax-up for selected teeth",
              "Full arch upper and lower wax-up",
              "Injectable composite models with alternate tooth wax-up",
              "Simulation services for patient motivation",
            ].includes(selectedService?.serviceName) &&
              preferredSoftwares?.length > 0 && (
                <div className="text-white flex flex-col mb-8">
                  <p>
                    Preferred design software?
                    {isFieldRequired("preferredSoftware") && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </p>
                  <div className="grid grid-cols-3 gap-y-3 mt-1">
                    {preferredSoftwares?.map((item) => {
                      return (
                        <div className="flex items-center mx-3" key={item?.softwareObjId}>
                          <CircularCheckbox
                            value={item?.softwareName}
                            checked={
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.preferredSoftware === item?.softwareName
                            }
                            onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">{item?.softwareName}</p>
                        </div>
                      );
                    })}
                    <div className="flex items-center mx-3 col-span-1">
                      <CircularCheckbox
                        value="No Preferance"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.preferredSoftware === "No Preferance"
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">No Preference</p>
                    </div>
                  </div>
                </div>
              )}

            {[
              "Partial wax-up for selected teeth",
              "Full arch upper and lower wax-up",
              "Injectable composite models with alternate tooth wax-up",
              "Simulation services for patient motivation",
            ].includes(selectedService?.serviceName) && (
              <div className="text-white flex flex-col mb-8">
                {selectedService?.serviceName ===
                "Injectable composite models with alternate tooth wax-up" ? (
                  <p className="text-[#777] text-[16px] my-4">
                    Designs are normally provided as viewer files. Please advise if printable model
                    .STL files are desired. This may however be possible only in some types of
                    software. If it is not possible in your chosen software, we may provide your
                    design in an appropriate software that allows this.{" "}
                    <span className="!text-white">
                      In order to allow for fabrication of stents, we typically provide two models.
                      The first model is a full wax up model of all teeth and the second model is a
                      full wax up model of alternate teeth. This allows for excellent proximal
                      access to finish the first set of restorations on alternate teeth before
                      allowing restorations on the remaining teeth.
                    </span>
                  </p>
                ) : (
                  <p className="text-[#777] text-[16px] my-4">
                    Designs are normally provided as viewer files. Please advise if a printable
                    model .STL file or printable eggshell temporary .STL files are desired. This may
                    however be possible only in some types of software. If it is not possible in
                    your chosen software, we may provide your design in an appropriate software that
                    allows this.{" "}
                  </p>
                )}

                <div className="mb-3">
                  <p className="mb-3">
                    {selectedService?.serviceName ===
                    "Injectable composite models with alternate tooth wax-up"
                      ? "Would you like to receive two printable model files in .STL format with alternate and complete restorative wax ups ?"
                      : "Would you like to receive a printable model file in .STL format ?"}{" "}
                    (additional cost).
                    {isFieldRequired(
                      selectedService?.serviceName ===
                        "Injectable composite models with alternate tooth wax-up"
                        ? "needTwoPrintableModelFile"
                        : "needPrintableModelFile"
                    ) && <span className="text-red-500 ml-1">*</span>}
                  </p>
                  <div className="flex">
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Yes"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]?.[
                            selectedService?.serviceName ===
                            "Injectable composite models with alternate tooth wax-up"
                              ? "needTwoPrintableModelFile"
                              : "needPrintableModelFile"
                          ] === "Yes"
                        }
                        onChange={(e) =>
                          handleFieldChange(
                            e.target.value,
                            selectedService?.serviceName ===
                              "Injectable composite models with alternate tooth wax-up"
                              ? "needTwoPrintableModelFile"
                              : "needPrintableModelFile"
                          )
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Yes</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="No"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]?.[
                            selectedService?.serviceName ===
                            "Injectable composite models with alternate tooth wax-up"
                              ? "needTwoPrintableModelFile"
                              : "needPrintableModelFile"
                          ] === "No"
                        }
                        onChange={(e) =>
                          handleFieldChange(
                            e.target.value,
                            selectedService?.serviceName ===
                              "Injectable composite models with alternate tooth wax-up"
                              ? "needTwoPrintableModelFile"
                              : "needPrintableModelFile"
                          )
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">No</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {[
              "Partial wax-up for selected teeth",
              "Full arch upper and lower wax-up",
              "Simulation services for patient motivation",
            ].includes(selectedService?.serviceName) && (
              <div className="mb-8 text-white">
                <p className="mb-3">
                  Would you like to receive printable eggshell temporary files in .STL format?
                  (additional cost).
                  {isFieldRequired("needPrintableEggshellFiles") && (
                    <span className="text-red-500 ml-1">*</span>
                  )}
                </p>
                <div className="flex">
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.needPrintableEggshellFiles === "Yes"
                      }
                      onChange={(e) =>
                        handleFieldChange(e.target.value, "needPrintableEggshellFiles")
                      }
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.needPrintableEggshellFiles === "No"
                      }
                      onChange={(e) =>
                        handleFieldChange(e.target.value, "needPrintableEggshellFiles")
                      }
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              </div>
            )}
            {["Nightguards for restorative protection"].includes(selectedService?.serviceName) &&
              preferredSoftwares?.length > 0 && (
                <>
                  <div className="text-white flex flex-col mb-8">
                    <p className="flex mb-2">
                      What arch would you like a nightguard on?
                      <span className="text-red-500 ml-1">*</span>
                    </p>

                    <p className="text-[#777] mb-5 text-[16px]">
                      We only provide flat plane nightguards for protection of cosmetic restorations
                      that extend only to the height of contour of the teeth. We prefer intraoral
                      scans in an open bite relationship for nightguard design to reduce the need
                      for adjustments. Please obtain intraoral scans of both arches and a bite scan
                      with a leaf gauge that records the bite in centric. This bite scan in an open
                      relationship needs to allow sufficient thickness for the nightguard material.
                    </p>
                    <div className="flex">
                      <div className="flex items-center mr-3">
                        <CircularCheckbox
                          value="Upper"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.archNightguardOn === "Upper"
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "archNightguardOn")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Upper</p>
                      </div>
                      <div className="flex items-center mr-3">
                        <CircularCheckbox
                          value="Lower"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.archNightguardOn === "Lower"
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "archNightguardOn")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Lower</p>
                      </div>
                      <div className="flex items-center">
                        <CircularCheckbox
                          value="Both"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.archNightguardOn === "Both"
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "archNightguardOn")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Both</p>
                      </div>
                    </div>
                  </div>
                  {preferredSoftwares?.length > 0 && (
                    <div className="text-white flex flex-col mb-8">
                      <p>
                        Preferred design software?
                        {isFieldRequired("preferredSoftware") && (
                          <span className="text-red-500 ml-1">*</span>
                        )}
                      </p>
                      <div className="grid grid-cols-5 gap-y-3 mt-1">
                        {preferredSoftwares
                          ?.filter(
                            (d) =>
                              !["Dentbird", "Blender for Dental", "Clinux", "Hypsocad"].includes(
                                d?.softwareName
                              )
                          )
                          ?.map((item) => {
                            return (
                              <div className="flex items-center mr-3" key={item?.softwareObjId}>
                                <CircularCheckbox
                                  value={item?.softwareName}
                                  checked={
                                    restorativeTypeSelected?.[selectedService?.serviceObjId]
                                      ?.preferredSoftware === item?.softwareName
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(e.target.value, "preferredSoftware")
                                  }
                                  borderColor={"#FF8503"}
                                  backgroundColor={"#FF8503"}
                                />
                                <p className="text-white text-[16px]">{item?.softwareName}</p>
                              </div>
                            );
                          })}
                        <div className="flex items-center mr-3 col-span-1">
                          <CircularCheckbox
                            value="No Preferance"
                            checked={
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.preferredSoftware === "No Preferance"
                            }
                            onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">No Preference</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="w-7/12">
                    <RangeInputField
                      labelColor="#fff"
                      labelText="How much thickness would you like for your nightguard(s)? (mm)"
                      subtitle="If you are unsure about how much thickness you require, please leave the setting at 0 mm. A default setting will then be applied to the design"
                      errorMessage={"Please enter a valid thickness value"}
                      placeholder={""}
                      htmlFor={"nightguardThickness"}
                      name={"nightguardThickness"}
                      value={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.nightguardThickness ?? "0"
                      }
                      handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                      validationFunctionName={validateNumber}
                      isSubmitted={isFieldRequired}
                      isFieldRequired={isFieldRequired("nightguardThickness")}
                      unit="mm"
                      min={0}
                      max={3}
                    />
                  </div>
                  <div className="text-white flex flex-col my-8">
                    <p>
                      Will you be printing or milling your nightguard?
                      <span className="text-red-500 ml-1">*</span>
                    </p>
                    <div className="flex gap-3 mt-1">
                      <div className="flex items-center mr-3">
                        <CircularCheckbox
                          value="Yes"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.nightguardProcessing === "Yes"
                          }
                          onChange={(e) =>
                            handleFieldChange(e.target.value, "nightguardProcessing")
                          }
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Yes</p>
                      </div>
                      <div className="flex items-center mx-3">
                        <CircularCheckbox
                          value="No"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.nightguardProcessing === "No"
                          }
                          onChange={(e) =>
                            handleFieldChange(e.target.value, "nightguardProcessing")
                          }
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">No</p>
                      </div>
                    </div>
                  </div>
                </>
              )}

            {selectedService?.serviceName ===
              "Gingival recontouring guide for restorative dentistry" && (
              <>
                <div className="text-white flex flex-col mb-8">
                  <p>
                    What arch would you like a soft tissue recontouring reduction guide for?
                    <span className="text-red-500 ml-1">*</span>
                  </p>
                  <p className="text-[#777] text-[16px] my-2">
                    It is highly recommended that you provide at least photographs with an outline
                    of what level of recontouring you require. A face scan is an even better tool to
                    provide as we can design in accordance with the patient's lip line.
                  </p>
                  <div className="grid grid-cols-7 gap-y-3 mt-1">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Upper"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.softTissueReductionGuideFor === "Upper"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "softTissueReductionGuideFor")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Upper</p>
                    </div>
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Lower"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.softTissueReductionGuideFor === "Lower"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "softTissueReductionGuideFor")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Lower</p>
                    </div>
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Both"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.softTissueReductionGuideFor === "Both"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "softTissueReductionGuideFor")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Both</p>
                    </div>
                  </div>
                </div>
                <div className="text-white flex flex-col mb-8">
                  <p>
                    If you would also like a hard tissue recontouring reduction guide, what arch
                    would you like the guide on? <span className="text-red-500 ml-1">*</span>
                  </p>
                  <div className="grid grid-cols-7 gap-y-3 mt-1">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Upper"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.hardTissueReductionGuideFor === "Upper"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "hardTissueReductionGuideFor")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Upper</p>
                    </div>
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Lower"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.hardTissueReductionGuideFor === "Lower"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "hardTissueReductionGuideFor")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Lower</p>
                    </div>
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Both"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.hardTissueReductionGuideFor === "Both"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "hardTissueReductionGuideFor")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Both</p>
                    </div>
                  </div>
                </div>
                {preferredSoftwares?.length > 0 && (
                  <div className="text-white flex flex-col mb-8">
                    <p>
                      Preferred design software?
                      {isFieldRequired("preferredSoftware") && (
                        <span className="text-red-500 ml-1">*</span>
                      )}
                    </p>
                    <div className="grid grid-cols-5 gap-y-3 mt-1">
                      {preferredSoftwares
                        ?.filter(
                          (d) =>
                            !["Dentbird", "Blender for Dental", "Clinux", "Hypsocad"].includes(
                              d?.softwareName
                            )
                        )
                        ?.map((item) => {
                          return (
                            <div className="flex items-center mr-3" key={item?.softwareObjId}>
                              <CircularCheckbox
                                value={item?.softwareName}
                                checked={
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.preferredSoftware === item?.softwareName
                                }
                                onChange={(e) =>
                                  handleFieldChange(e.target.value, "preferredSoftware")
                                }
                                borderColor={"#FF8503"}
                                backgroundColor={"#FF8503"}
                              />
                              <p className="text-white text-[16px]">{item?.softwareName}</p>
                            </div>
                          );
                        })}
                      <div className="flex items-center mr-3 col-span-1">
                        <CircularCheckbox
                          value="No Preferance"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.preferredSoftware === "No Preferance"
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">No Preference</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mb-8">
                  <p className="text-white text-[17px] mb-2">
                    Please select all teeth that you would like your recontouring guides to extend
                    to? <span className="text-red-500 ml-1">*</span>
                  </p>
                  <div className="">
                    <TeethV2
                      allTeethData={[]}
                      selectedTeeth={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.recontouringGuidesTeeth ?? []
                      }
                      setSelectedTeeth={(value) => {
                        let result = value(
                          restorativeTypeSelected[selectedService?.serviceObjId]
                            ?.recontouringGuidesTeeth || []
                        );
                        handleFieldChange(result, "recontouringGuidesTeeth");
                        handleServiceTeethSave(result, "recontouringGuidesTeeth");
                      }}
                      defaultNotation={notation}
                      key={`recontouringToExtendSelectedTeeth${notation}`}
                      notationVisible={false}
                      missingTeeth={missingTeethSelectedTeeth}
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <RangeInputField
                    labelColor={"#fff"}
                    labelText={
                      "If selecting a hard tissue recontouring guide as well, how much distance would you like between the final level of soft tissue and the final level of the hard tissue?"
                    }
                    errorMessage={"Please enter a valid thickness value"}
                    placeholder={""}
                    htmlFor={"finalLevelDistance"}
                    name={"finalLevelDistance"}
                    value={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]
                        ?.finalLevelDistance ?? "0"
                    }
                    handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                    validationFunctionName={validateNumber}
                    isSubmitted={isFieldRequired}
                    isFieldRequired={isFieldRequired("finalLevelDistance")}
                    unit="mm"
                    min={0}
                    max={3}
                  />
                </div>
                <div className="text-white flex flex-col my-8">
                  <p>
                    Will you be printing or milling your recontouring reduction guide?
                    {isFieldRequired("recontouringGuideProcessing") && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </p>
                  <p className="text-[#777] mb-4 text-[16px]">
                    Recontouring reduction guides are best printed. They do not lend themselves to
                    easy milling.Our recontouring reduction guides are designed to be as close to
                    tissue as possible to minimise surgical error. Path of insertion constraints may
                    however result in a distance between the guide and tissue (soft and hard).
                  </p>
                  <div className="flex gap-3">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Printing"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.recontouringGuideProcessing === "Printing"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "recontouringGuideProcessing")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Printing</p>
                    </div>
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Milling"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.recontouringGuideProcessing === "Milling"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "recontouringGuideProcessing")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Milling</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {["Deprogrammers for restorative dentistry"].includes(selectedService?.serviceName) && (
              <>
                <div className="text-white flex flex-col mb-8">
                  <p>
                    What deprogrammer design do you prefer?
                    {isFieldRequired("preferredDeprogrammerDesign") && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </p>
                  <div className="grid grid-cols-3 gap-y-3 mt-1">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Anterior full arch deprogrammer"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.preferredDeprogrammerDesign === "Anterior full arch deprogrammer"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "preferredDeprogrammerDesign")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Anterior full arch deprogrammer</p>
                    </div>
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Kois style deprogrammer"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.preferredDeprogrammerDesign === "Kois style deprogrammer"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "preferredDeprogrammerDesign")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Kois style deprogrammer</p>
                    </div>
                  </div>

                  <p className="text-[#777] mt-3 text-[16px]">
                    Deprogrammers are only meant to be used mainly for deprogramming an occlusion.
                    Our deprogrammers are specifically designed for this purpose. Our Kois style
                    deprogrammers do not include a labial bow.
                  </p>
                </div>
                <div className="text-white flex flex-col mb-4">
                  <p>
                    What arch would you like a deprogrammer on?
                    {isFieldRequired("archDeprorammerOn") && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </p>
                  <div className="grid grid-cols-7 gap-y-3 mt-1">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Upper"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.archDeprorammerOn === "Upper"
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "archDeprorammerOn")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Upper</p>
                    </div>
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Lower"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.archDeprorammerOn === "Lower"
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "archDeprorammerOn")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Lower</p>
                    </div>
                  </div>

                  <p className="text-[#777] mb-5 text-[16px]  my-2">
                    We only provide specific deprogrammer designs. We prefer intraoral scans in an
                    open bite relationship for deprogrammer design to reduce the need for
                    adjustments. Please obtain intraoral scans of both arches and a bite scan with a
                    leaf gauge that records the bite in centric. This open relationship intraoral
                    bite scan should allow the correct thickness for the deprogrammer height
                    desired.
                  </p>
                </div>
                {preferredSoftwares?.length > 0 && (
                  <div className="text-white flex flex-col mb-10">
                    <p>
                      Preferred design software?
                      {isFieldRequired("preferredSoftware") && (
                        <span className="text-red-500 ml-1">*</span>
                      )}
                    </p>
                    <div className="grid grid-cols-5 gap-y-3 mt-1">
                      {preferredSoftwares
                        ?.filter(
                          (d) =>
                            !["Dentbird", "Blender for Dental", "Clinux", "Hypsocad"].includes(
                              d?.softwareName
                            )
                        )
                        ?.map((item) => {
                          return (
                            <div className="flex items-center mr-3" key={item?.softwareObjId}>
                              <CircularCheckbox
                                value={item?.softwareName}
                                checked={
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.preferredSoftware === item?.softwareName
                                }
                                onChange={(e) =>
                                  handleFieldChange(e.target.value, "preferredSoftware")
                                }
                                borderColor={"#FF8503"}
                                backgroundColor={"#FF8503"}
                              />
                              <p className="text-white text-[16px]">{item?.softwareName}</p>
                            </div>
                          );
                        })}
                      <div className="flex items-center mr-3 col-span-1">
                        <CircularCheckbox
                          value="No Preferance"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.preferredSoftware === "No Preferance"
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">No Preference</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mb-8">
                  <p className="text-white text-[17px] mb-2">
                    Please select all teeth that you would like your deprogrammer to extend to?
                    <span className="text-red-500 ml-1">*</span>
                  </p>
                  <div className="">
                    <TeethV2
                      allTeethData={[]}
                      selectedTeeth={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.deprogrammerTeeth
                      }
                      setSelectedTeeth={(value) => {
                        let result = value(
                          restorativeTypeSelected[selectedService?.serviceObjId]
                            ?.deprogrammerTeeth || []
                        );
                        handleFieldChange(result, "deprogrammerTeeth");
                        handleServiceTeethSave(result, "deprogrammerTeeth");
                      }}
                      defaultNotation={notation}
                      key={`splintsToExtendSelectedTeeth${notation}`}
                      notationVisible={false}
                      missingTeeth={missingTeethSelectedTeeth}
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <RangeInputField
                    labelColor={"#fff"}
                    labelText="How much thickness would you like for your deprogrammer?"
                    subtitle="If you are unsure about how much thickness you require, please leave the setting at 0 mm. A default setting of 2 mm will then be applied to the design."
                    errorMessage={"Please enter a valid thickness value"}
                    placeholder={""}
                    htmlFor={"deprogrammerThickness"}
                    name={"deprogrammerThickness"}
                    value={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]
                        ?.deprogrammerThickness ?? "0"
                    }
                    handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                    validationFunctionName={validateNumber}
                    isSubmitted={isFieldRequired}
                    isFieldRequired={isFieldRequired("deprogrammerThickness")}
                    unit="mm"
                    min={0}
                    max={4}
                  />
                </div>
                <div className="text-white flex flex-col my-8">
                  <p>
                    Will you be printing or milling your deprogrammer?
                    {isFieldRequired("deprogrammerProcessing") && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </p>
                  <p className="text-[#777] mb-4 text-[16px]">
                    Deprogrammers can be milled with excellent results. If you are printing your
                    deprogrammer, please use a hard splint material.
                  </p>
                  <div className="flex gap-3">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Printing"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.deprogrammerProcessing === "Printing"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "deprogrammerProcessing")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Printing</p>
                    </div>
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Milling"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.deprogrammerProcessing === "Milling"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "deprogrammerProcessing")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Milling</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div>
              <TextAreaInputField
                labelColor={"#777"}
                labelText={"Additional Instructions"}
                errorMessage={"This field is required"}
                placeholder={"Please enter additional instructions here"}
                isFieldRequired={isFieldRequired("additionalInstructions")}
                htmlFor={"additionalInstructions"}
                name={"additionalInstructions"}
                value={
                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                    ?.additionalInstructions ?? ""
                }
                handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                rows={4}
                cols={5}
              />
              {restorativeTypeSelected?.[
                selectedService?.serviceObjId
              ]?.additionalInstructions?.trim()?.length > 0 ? null : (
                <p className="text-sm text-red-500 ml-1">This field is required</p>
              )}
            </div>
          </>
        )}
      </div>
      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab(ORDER_STAGE.PATIENT_DETAILS);
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitCaseDetailsForm();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
};
